// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */

export const abilitiesIds = {
	melee_auto_attack: 1,
	magic_auto_attack: 2,
	range_auto_attack: 3,
	slash: 4,
	stab: 5,
	crush: 6,
	fire_ball: 7,
	enhanced_ice_shard: 8,
	natures_sanctuary: 9,
	greater_chaos_rend: 10,
	lesser_chaos_rend: 11,
	artillery: 12,
	shatter_strike: 13,
	crystal_fracture: 14,
	titanic_strike: 15,
	lightning_strike: 16,
	entropic_burst: 17,
	flamethrower: 18,
	regal_might: 19,
	krakatoa: 20,
	triple_shot: 21,
	cleave: 22,
	deadshot: 23,
	retreating_shot: 24,
	apocalyptic_ritual: 25,
	bulwark: 26,
	piercing_ammo: 27,
	heavy_ammo: 28,
	serrated_ammo: 29,
	lesser_fire_bolt: 30,
	lesser_ice_bolt: 31,
	lesser_nature_bolt: 32,
	lesser_earth_bolt: 33,
	lesser_air_bolt: 34,
	greater_soul_steal: 35,
	infernal_cleave: 36,
	corrupted_soul_steal: 37,
	shapeless_cleave: 38,
	chefs_fury: 39,
	wild_slash: 40,
	greater_wild_slash: 41,
	elven_deadshot: 42,
	oceans_wrath: 43,
	the_ol_onetwo_1st: 44,
	lesser_earth_strike: 45,
	parry_this: 46,
	bandage_friendly: 47,
	call_for_aid: 48,
	defend_ally: 49,
	lightning_strikes_twice: 50,
	the_ol_onetwo_2nd: 51,
	execute: 52,
	the_limpe_sauce: 53,
	taunt: 54,
	poisonous_strike: 55,
	infos_lesson: 56,
	invisibility: 57,
	camouflage: 58,
	icy_shell: 59,
	set_traps: 60,
	infernal_impale: 61,
	sever: 62,
	quick_shot: 63,
	swift_shot: 64,
	tank_taunt: 65,
	impale: 66,
	ice_shard: 67,
	enhanced_ice_slash: 68,
	flaming_fist_jab: 69,
	flaming_fist_haymaker: 70,
	flaming_fist_chop: 71,
	obliterating_snap: 72,
	annihilating_snap: 73,
	erasing_snap: 74,
	phoenixs_flames: 75,
	acid_splash: 76,
	acid_wave: 77,
	armor_rend: 78,
	greater_elven_healing: 79,
	cleanse_ally: 80,
	skirmisher_stance: 81,
	sniper_stance: 82,
	shrimp_sting: 83,
	shrimp_whip: 84,
	shrimp_mist: 85,
	lesser_ward: 86,
	deadshot_stance: 87,
	dwarven_strike: 88,
	greater_nature_bolt: 89,
	greater_icy_shell: 90,
	greater_ice_bolt: 91,
	hailstorm: 92,
	ice_trident: 93,
	ebb: 94,
	flow: 95,
	lightspeed: 97,
	lightning_bolt: 98,
	bestial_camouflage: 99,
	wailing_souls: 100,
	blinding_souls: 101,
	greater_natures_sanctuary: 102,
	entangle: 103,
	entangle_thorns: 104,
	daze: 105,
	bleed: 106,
	forests_embrace: 107,
	channel_resurrection: 109,
	prepare_resurrection: 110,
	wrath: 111,
	war: 112,
	blademasters_slash: 113,
	blademasters_stab: 114,
	blademasters_crush: 115,
	blade_storm: 116,
	rallying_cry: 117,
	taunting_rally: 118,
	demoralizing_shout: 119,
	necrotic_splash: 120,
	necrotic_wave: 121,
	necrotic_touch: 122,
	mark: 123,
	defend: 124,
	sacrificial_pawns: 125,
	ice_stab: 126,
	blood_offering: 127,
	blood_sacrifice: 128,
	chaotic_slash: 129,
	tidal_fury: 130,
	necromancers_touch: 131,
	shrimp_crack: 132,
	shrimp_bolt: 133,
	natures_nocking: 134,
	infernal_puncture: 135,
	gaia_strike: 136,
	earthquake: 137,
	lesser_miasma: 138,
	decaying_grasp: 139,
	lingering_flame: 140,
	greater_miasma: 141,
	flaming_fist: 142,
	lesser_poison_bolt: 143,
	riptide: 144,
	manifest_kalanahmatti: 145,
	mauling_blow: 146,
	fury: 147,
	coral_claw: 148,
	crystal_crush: 149,
	entropic_strike: 150,
	piercing_ammo_specialization: 151,
	heavy_ammo_specialization: 152,
	serrated_ammo_specialization: 153,
	mark_ii: 154,
	mantis_punch: 155,
	mantis_blast: 156,
	mantis_bolt: 157,
	mortar: 158,
	grapeshot: 159,
	hot_shot: 160,
	prepare_volley: 161,
	fire_cannon: 162,
	fire_handgonne: 163,
	master_of_flames: 164,
	frostbite: 165,
	third_eye: 166,
	grasp_heart: 167,
	virulent_plague: 168,
	dimensional_tear: 300,
	greater_void_resolution: 301,
	lesser_void_resolution: 302,
	greater_void_verdict: 303,
	lesser_void_verdict: 304,
	structural_dissolution: 305,
	spatial_rending: 306,
	greater_chaotic_spikes: 307,
	lesser_chaotic_spikes: 308,
	greater_void_ray: 309,
	lesser_void_ray: 310,
	void_rend: 311,
	deflected_damage: 10000,
	thorns_damage: 10001,
	swift_agony_damage: 10002,
};
