import { itemList } from '../utils/itemList';
import { abilities } from '../utils/abilityList';
import { IItem, IItemStats } from '../../../game-server/src/modules/items/items.interface';
import { ILoadout } from '../../../game-server/src/modules/loadout/loadout.interface';
import { EnchantmentData } from '../components/game/EnchantmentData';
import debounce from 'lodash/debounce';
import { socket } from '../services/socket.service';
import { affixList } from '../utils/affixList';
import { TooltipLiterals } from '../components/game/Tooltips/Data/TooltipLiterals';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { cookingList } from '../utils/cookingList';
import { INFERNO_CAP } from '../utils/constantsCollection';
import { TRecipeData } from '../../../game-server/src/modules/crafting-augmenting/crafting-augmenting.interface';

export const formatNumberToString = (number: number): string => {
	let min = Number(number);
	let suffix = '';

	if (number >= 1000000000) {
		min = Math.floor(number / 10000000) / 100;
		if (min > 99) min = Math.floor(min);
		suffix = 'B';
	} else if (number >= 1000000) {
		min = Math.floor(number / 10000) / 100;
		if (min > 99) min = Math.floor(min);
		suffix = 'M';
	} else if (number >= 100000) {
		min = Math.floor(number / 1000);
		suffix = 'K';
	}

	return min.toLocaleString('en-us') + suffix;
};

export const formatNumberPerHour = (number: number, time: Date): string => {
	const timeDelta = (new Date().getTime() - new Date(time).getTime()) / 1000 / 3600;
	const perHour = number / timeDelta;
	const count = (perHour >= 10 ? Math.round(perHour) : Math.round(perHour * 10) / 10).toLocaleString();
	return `${count}/hr`;
};

export function parseCompactNumber(numberString: string) {
	numberString = numberString.replace(/[^0-9kKmMbB.]/g, '');
	let number = parseFloat(numberString);
	if (isNaN(number)) number = 0;
	const scale = {
		k: 1000,
		m: 1000000,
		b: 1000000000,
	};
	const stringExponent = numberString.match(/[kKmMbB]/i);
	if (stringExponent) {
		const factor = stringExponent[0];
		number *= scale[factor.toLowerCase() as keyof typeof scale];
	}
	return number;
}

export function sanitizeCompactNumber(numberString: string, min = 0, max = Infinity) {
	numberString = numberString.replace(/[^0-9kKmMbB.]/g, '');
	let number = parseFloat(numberString);
	if (isNaN(number)) number = 0;
	const scale = {
		k: 1000,
		m: 1000000,
		b: 1000000000,
	};
	const stringExponent = numberString.match(/[kKmMbB]/i);
	if (stringExponent) {
		const factor = stringExponent[0];
		numberString = number.toString() + factor;
		number *= scale[factor.toLowerCase() as keyof typeof scale];
	}
	if (number < min || number > max) {
		number = Math.max(min, Math.min(max, number));
		numberString = number.toString();
	}
	return numberString;
}

export const getItemColorFromNumber = (item: number) => {
	if (item >= 1_000_000_000) return 'lightblue-text';
	if (item >= 1_000_000) return 'lightgreen-text';
	return '';
};

export const getGoldColorFromNumber = (gold: number) => {
	if (gold >= 100_000_000_000) return 'lightblue-text gold-animation';
	if (gold >= 1_000_000_000) return 'lightblue-text';
	if (gold >= 1_000_000) return 'lightgreen-text';
	return '';
};

export const getHeatColorFromNumber = (heat: number) => {
	if (heat >= 100_000_000_000) return 'lightyellow-text heat-animation';
	if (heat >= 1_000_000_000) return 'lightyellow-text';
	if (heat >= 1_000_000) return 'lightorange-text';
	return 'lightred-text';
};

export const getAugmentNameColor = (augLevel: number) => {
	let start = 0;
	let end = 1;
	let startc: number[] = [];
	let endc: number[] = [];
	const white = [255, 255, 255];
	const green = [144, 238, 144];
	const blue = [173, 216, 230];
	const purple = [221, 160, 221];
	const orange = [255, 166, 0];
	const red = [255, 0, 0];
	if (augLevel >= 25) {
		return {
			background: 'linear-gradient(to right,red,orange,green,yellow,purple,violet)',
			WebkitBackgroundClip: 'text',
			WebkitTextFillColor: 'transparent',
			fontWeight: 'bold',
			textShadow: 'none',
		};
	} else if (augLevel >= 20) {
		start = 20;
		end = 24;
		startc = red;
		endc = red;
	} else if (augLevel >= 17) {
		start = 17;
		end = 19;
		startc = orange;
		endc = red;
	} else if (augLevel >= 14) {
		start = 14;
		end = 17;
		startc = purple;
		endc = orange;
	} else if (augLevel >= 10) {
		start = 10;
		end = 13;
		startc = blue;
		endc = purple;
	} else if (augLevel >= 5) {
		start = 5;
		end = 9;
		startc = green;
		endc = blue;
	} else {
		start = 0;
		end = 4;
		startc = white;
		endc = green;
	}
	const result = lerpHex(endc, startc, (augLevel - start) / (end - start));
	return { color: 'rgb(' + result.join() + ')' };
};

export const lerpHex = (color1: number[], color2: number[], weight: number) => {
	const w1 = weight;
	const w2 = 1 - w1;
	const rgb = [
		Math.round(color1[0] * w1 + color2[0] * w2),
		Math.round(color1[1] * w1 + color2[1] * w2),
		Math.round(color1[2] * w1 + color2[2] * w2),
	];
	return rgb;
};

export const paginate = (totalItems: number, currentPage = 1, pageSize = 10, maxPages = 10) => {
	// calculate total pages
	const totalPages = Math.ceil(totalItems / pageSize);

	// ensure current page isn't out of range
	if (currentPage < 1) {
		currentPage = 1;
	} else if (currentPage > totalPages) {
		currentPage = totalPages;
	}

	let startPage: number, endPage: number;
	if (totalPages <= maxPages) {
		// total pages less than max so show all pages
		startPage = 1;
		endPage = totalPages;
	} else {
		// total pages more than max so calculate start and end pages
		const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
		const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
		if (currentPage <= maxPagesBeforeCurrentPage) {
			// current page near the start
			startPage = 1;
			endPage = maxPages;
		} else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
			// current page near the end
			startPage = totalPages - maxPages + 1;
			endPage = totalPages;
		} else {
			// current page somewhere in the middle
			startPage = currentPage - maxPagesBeforeCurrentPage;
			endPage = currentPage + maxPagesAfterCurrentPage;
		}
	}

	// calculate start and end item indexes
	const startIndex = (currentPage - 1) * pageSize;
	const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

	// create an array of pages to ng-repeat in the pager control
	const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

	// return object with all pager properties required by the view
	return {
		totalItems: totalItems,
		currentPage: currentPage,
		pageSize: pageSize,
		totalPages: totalPages,
		startPage: startPage,
		endPage: endPage,
		startIndex: startIndex,
		endIndex: endIndex,
		pages: pages,
	};
};

export const getTimeString = (seconds: number, figures = 99) => {
	const SECONDS_IN_A_DAY = 3600 * 24;
	const days = Math.floor(seconds / SECONDS_IN_A_DAY);
	seconds -= days * SECONDS_IN_A_DAY;
	const hours = Math.floor(seconds / 3600);
	seconds -= hours * 3600;
	const minutes = Math.floor(seconds / 60);
	seconds -= minutes * 60;

	let string = '';
	if (days > 0) {
		string += days + 'd ';
		figures--;
	}
	if (figures <= 0) return string.trim();
	if (hours > 0) {
		string += hours + 'h ';
		figures--;
	}
	if (figures <= 0) return string.trim();
	if (minutes > 0) {
		string += minutes + 'm ';
		figures--;
	}
	if (figures <= 0) return string.trim();
	if (string === '') {
		string +=
			seconds.toLocaleString('en-us', {
				maximumFractionDigits: 1,
			}) + 's';
	} else if (seconds > 0) {
		string += seconds.toFixed(0) + 's';
	}

	return string;
};

export function timeMSToString(time: number) {
	// If it's under 60 seconds, show it in the format of SS.SSS
	if (time < 60000) {
		return (time / 1000).toFixed(3) + 's';
	}
	// Convert time in milliseconds to a string in the format of HH:MM:SS
	const hours = Math.floor(time / 3600000);
	const minutes = Math.floor((time % 3600000) / 60000);
	const seconds = Math.floor((time % 60000) / 1000);
	return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const getDateOfNextMonth = (currentDate: Date) => {
	if (typeof currentDate === 'string') {
		currentDate = new Date(currentDate);
	}
	const year = currentDate.getUTCFullYear();
	const month = currentDate.getUTCMonth();
	const day = currentDate.getUTCDate();
	const daysInThisMonth = new Date(year, month + 1, 0).getDate();
	const daysInNextMonth = new Date(year, month + 2, 0).getDate();
	let addDays = daysInThisMonth;
	if (daysInNextMonth < day) {
		addDays += daysInNextMonth - day;
	}
	return currentDate.getTime() + addDays * 24 * 3600 * 1000;
};

export const getMaxItemsCanCraft = (stockpile: IItem[], requiredItems: TRecipeData) => {
	let lowestItems = Infinity;

	for (const requiredItem in requiredItems) {
		const availableItem = stockpile.find((obj) => {
			return obj.itemID == itemList[requiredItem].id;
		});
		if (availableItem) {
			const numItems = Math.floor(availableItem.stackSize / requiredItems[requiredItem]);
			if (numItems < lowestItems) lowestItems = numItems;
		} else {
			return 0;
		}
	}

	return lowestItems;
};

export const playNotificationAudio = (volume: number) => {
	const audio = new Audio('sounds/notification.mp3');
	audio.volume = volume / 10;
	audio.play();
};

export const changeFaviconToNotify = () => {
	const favicon = document.getElementById('favicon') as HTMLAnchorElement;
	favicon.href = '/favicon-notification.ico?v=bOR6xkdLo6';
};

export const changeFaviconToDefault = () => {
	const favicon = document.getElementById('favicon') as HTMLAnchorElement;
	favicon.href = '/favicon.ico?v=bOR6xkdLo6';
};

export function dotter(
	dictionary: { [key: string]: any },
	ignoredKeys: string[],
	key = '',
	dots: { [key: string]: any } = {}
): { [key: string]: any } {
	if (typeof dictionary === 'object') {
		for (const currentKey in dictionary) {
			if (Object.hasOwn(dictionary, currentKey)) {
				if (!key && ignoredKeys.includes(currentKey)) continue;
				dotter(dictionary[currentKey], ignoredKeys, key ? `${key}.${currentKey}` : currentKey, dots);
			}
		}
	} else {
		dots[key] = dictionary;
	}
	return dots;
}

export function sortDictionaryByArray<T>(dictionary: Record<string, T>, orderArray: string[]) {
	return Object.entries(dictionary)
		.sort((entry1, entry2) => {
			return orderArray.indexOf(entry1[0]) - orderArray.indexOf(entry2[0]);
		})
		.reduce((sorted: Record<string, T>, currentStat: [string, T]) => {
			sorted[currentStat[0]] = currentStat[1];
			return sorted;
		}, {});
}

export const filterItem = (
	item: {
		itemID: IItem['itemID'];
		name?: IItem['name'];
		enchantmentID?: IItem['enchantmentID'];
		affixes?: IItemStats['affixes'];
	},
	filterString: string
) => {
	if (filterString === '') return true;
	try {
		const reg = new RegExp(filterString, 'i');

		if (item.name?.match(reg)) return true;

		if (item.affixes) {
			for (const affix of item.affixes) {
				const path = affix.path;
				if (path.match(reg)) return true;
				const affixData = affixList[path];
				if (affixData?.name?.match(reg)) return true;
				if (TooltipLiterals.affixesLiterals[path]?.match(reg)) return true;
			}
		}

		const itemListItem = itemList[item.itemID];
		if (itemListItem.name.match(reg)) return true;

		if (itemListItem.equipmentStats?.slot) {
			const slotName: string = itemListItem.equipmentStats.slot;
			if (slotName.match(reg)) return true;
			if (slotName === 'arrows' && ('pendant'.match(reg) || 'quiver'.match(reg))) return true;
			if (
				['pickaxe', 'hatchet', 'hoe', 'tacklebox', 'tongs', 'tome', 'ladle', 'chisel'].includes(slotName) &&
				'tool'.match(reg)
			)
				return true;
			if (['ring', 'necklace'].includes(slotName) && 'jewelry'.match(reg)) return true;
		}

		if (itemListItem.categories) {
			for (const category of itemListItem.categories) {
				if (category.match(reg)) return true;
			}
		}

		const enchantmentID = item.enchantmentID ?? itemListItem.enchantmentID;
		if (enchantmentID) {
			const enchantment = EnchantmentData.findEnchantmentByID(enchantmentID);
			if (enchantment?.name?.match(reg)) return true;
		}

		const tags = itemListItem.tags;
		if (tags) {
			for (const tag of tags) {
				if (tag.match(reg)) return true;
			}
		}
		const cookingData = cookingList[item.itemID];
		if (cookingData) {
			if (cookingData.ingredientTags) {
				for (const tag of cookingData.ingredientTags) {
					if (tag.match(reg)) return true;
				}
			}
			if (cookingData.alchemyEnchantment) {
				const enchantment = EnchantmentData.findEnchantmentByID(cookingData.alchemyEnchantment);
				if (enchantment.name.match(reg)) return true;
			}
			if (cookingData.cookingEnchantment) {
				const enchantment = EnchantmentData.findEnchantmentByID(cookingData.cookingEnchantment);
				if (enchantment.name.match(reg)) return true;
			}
		}
		const itemSet = itemListItem.equipmentStats?.itemSet;
		if (itemSet) {
			for (const set of itemSet) {
				const enchantment = EnchantmentData.findEnchantmentByID(set);
				if (enchantment?.name?.match(reg)) return true;
			}
		}
		const relatedAbilities: number[] = [];
		if (itemListItem.equipmentStats?.grantedAbility) {
			relatedAbilities.push(...itemListItem.equipmentStats.grantedAbility);
		}
		if (itemListItem.relatedAbility) {
			relatedAbilities.push(itemListItem.relatedAbility);
		}
		for (const abilityID of relatedAbilities) {
			const ability = abilities[abilityID];
			if (ability?.abilityName?.match(reg)) return true;
		}

		if (itemListItem.categories) {
			for (const category of itemListItem.categories) {
				if (category.match(reg)) return true;
			}
		}

		if (itemListItem.class?.match(reg)) return true;

		const rarity = itemListItem.rarity ?? 'common';
		if (rarity.match(reg)) return true;

		return false;
	} catch (err) {
		return true;
	}
};

export const getLoadoutIcon = (loadout: ILoadout) => {
	let iconItem = itemList[loadout.loadoutIconItemId ?? 0];
	if (!iconItem) {
		iconItem = itemList[itemsIds.potato];
		for (const equipment of loadout.loadoutEquipment) {
			if (equipment) {
				iconItem = itemList[equipment.itemID];
				break;
			}
		}
	}
	return { icon: iconItem?.itemIcon ?? (iconItem?.itemImage as string), alt: iconItem?.name ?? 'No Icon' };
};

export const socketEmitDebounced = debounce((command, data) => socket.emit(command, data), 250, {
	leading: false,
	trailing: true,
}) as typeof socket.emit;

export function infernoSpeed(infernoStrength: number) {
	let infernoSpeed = 0;
	let chance = 1;
	for (let i = 0; i < INFERNO_CAP; i++) {
		chance *= infernoStrength;
		infernoSpeed += chance;
	}
	return infernoSpeed;
}
