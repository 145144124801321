import React, { useState } from 'react';
import axios from 'axios';
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import { IRegister } from '../../../../game-server/src/http-routes/validation/register';
import setAuthToken from '../../helper/setAuthToken';

export default function HeaderClaimAccount() {
	const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
	const [errors, setErrors] = useState<Partial<IRegister>>({});
	const [username, setUsername] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
	const [email, setEmail] = useState('');

	function showClaimAccountModal(e: React.MouseEvent) {
		onOpen();
		e.stopPropagation();
	}

	function claimAccount() {
		const accountDetails = {
			username: username,
			password: newPassword,
			password2: newPasswordRepeat,
			email: email,
		};
		axios
			.post('/api/auth/claim-account', accountDetails)
			.then((res) => {
				document.location.reload();
			})
			.catch((err) => {
				setAuthToken(false);
				console.log(err);
				if (err.response && err.response.data) {
					setErrors(err.response.data);
				}
			});
	}

	return (
		<span>
			<span onClick={showClaimAccountModal} className='red-text header-unclaimed'>
				unclaimed
			</span>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent className='sell-item-dialog claim-account-dialog'>
					<ModalHeader>Claim Account</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Guest accounts are not allowed to use some of the game&apos;s features. Insert a unique username
						and a password to claim your account, and enable full access to the game. Email is optional, but
						without it, it will be a lot harder to recover your account if you ever forget your password.
						You also wont be able to use the marketplace and the shrine.
						<br />
						After claiming your account, the page will refresh automatically.
						<FormControl>
							<FormLabel>Username</FormLabel>
							<Box className='red-text'>{errors.username}</Box>
							<Input
								onChange={(e) => setUsername(e.target.value)}
								value={username}
								id='username'
								type='text'
								placeholder='Username'
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Email (optional)</FormLabel>
							<Box className='red-text'>{errors.email}</Box>
							<Input
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								id='email'
								type='text'
								placeholder='Email'
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Password</FormLabel>
							<Box className='red-text'>{errors.password}</Box>
							<Input
								onChange={(e) => setNewPassword(e.target.value)}
								value={newPassword}
								id='newPassword'
								type='password'
								placeholder='Password'
								maxLength={36}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Confirm Password</FormLabel>
							<Box className='red-text'>{errors.password2}</Box>
							<Input
								onChange={(e) => setNewPasswordRepeat(e.target.value)}
								value={newPasswordRepeat}
								id='newPasswordRepeat'
								type='password'
								placeholder='Password'
								maxLength={36}
							/>
						</FormControl>
						<ModalFooter>
							<IdlescapeButton variant='red' onClick={onClose}>
								Close
							</IdlescapeButton>
							<IdlescapeButton variant='green' onClick={claimAccount}>
								Claim
							</IdlescapeButton>
						</ModalFooter>
					</ModalBody>
				</ModalContent>
			</Modal>
		</span>
	);
}
