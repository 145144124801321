// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TTalentList } from '../../../game-server/src/modules/talent/Talent.interface';
import { abilitiesIds } from '../utils/lookup-dictionaries/lookupAbilityList';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { questsIds } from '../utils/lookup-dictionaries/lookupQuestList';
import { talentsIds } from '../utils/lookup-dictionaries/lookupTalentList';

/**
 * Design things to consider:
 * - Talent identity; talents should be unique and nearly build defining, especially at higher levels
 * - Talent requirements; most talents should have a requirement of a previous talent or have mutually exclusive talents
 * - Talent costs; talents should have a cost that scales with the power of the talent, often with cost = required mastery levels
 */
export const talentList: TTalentList = {
	// Gathering Talents, 1-999
	1: {
		id: 1,
		name: 'Gathering Master',
		description:
			'Your mastery of gathering gives you a chance to gather more resources! Gives +1 strength of Empowered Gathering.',
		enchantments: [
			{
				id: enchantmentsIds.empowered_gathering,
				strength: 1,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: false,
				skill: 'mining',
				level: 99,
			},
			{
				mastery: false,
				skill: 'foraging',
				level: 99,
			},
			{
				mastery: false,
				skill: 'fishing',
				level: 99,
			},
		],
		category: ['gathering'],
	},
	2: {
		id: 2,
		name: 'Gathering Grandmaster',
		description:
			'Your mastery of gathering gives you a chance to gather more resources! Gives +1 strength of Empowered Gathering.',
		enchantments: [
			{
				id: enchantmentsIds.empowered_gathering,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'mining',
				level: 9,
			},
			{
				mastery: true,
				skill: 'foraging',
				level: 9,
			},
			{
				mastery: true,
				skill: 'fishing',
				level: 9,
			},
		],
		talentRequirements: [talentsIds.gathering_master],
		category: ['gathering'],
	},
	3: {
		id: 3,
		name: 'Dwarven Student',
		description:
			'Deep study into the technology of the dwarves has given you insight into rock and stone. Increases your effective mining level by 30 but decreases your foraging and fishing levels by 10.',
		enchantments: [
			{
				id: enchantmentsIds.dwarven_student,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.dwarven_student],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'mining',
				level: 15,
			},
		],
		category: ['gathering'],
	},
	4: {
		id: 4,
		name: 'Elven Student',
		description:
			'Deep study into the way of the elves has given you insight into nature. Increases your effective foraging level by 30 but decreases your mining and fishing levels by 10.',
		enchantments: [
			{
				id: enchantmentsIds.elven_student,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.foraging_prodigy],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 15,
			},
		],
		category: ['gathering'],
	},
	5: {
		id: 5,
		name: 'Shrimp Student',
		description:
			'Deep study of the true rulers of the sea has given you an insight to the fickle nature of the waters. Increases your effective fishing level by 30 but decreases your mining and foraging levels by 10.',
		enchantments: [
			{
				id: enchantmentsIds.shrimp_student,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.fishing_prodigy],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'fishing',
				level: 15,
			},
		],
		talentIcon: '/images/fishing/raw_shrimp.png',
		category: ['gathering'],
	},
	6: {
		id: 6,
		name: 'Hamster Hunter',
		description: 'Increases your speed in the Underground Mines by 20%.',
		enchantments: [
			{
				id: enchantmentsIds.hamster_hunter,
				strength: 4,
			},
		],
		talentRequirements: [talentsIds.mining_prodigy],
		talentCost: 3,
		skillRequirements: [{ mastery: false, skill: 'mining', level: 60 }],
		talentIcon: '/images/combat/monsters/elite/hamster.png',
		category: ['gathering'],
	},
	7: {
		id: 7,
		name: 'Melter Smelter',
		description: 'Gives a 1% chance to instantly smelt an ore when mining.',
		enchantments: [
			{
				id: enchantmentsIds.melter_smelter,
				strength: 2,
			},
		],
		talentRequirements: [talentsIds.mining_prodigy, talentsIds.smithing_master],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'mining',
				level: 9,
			},
			{
				mastery: true,
				skill: 'smithing',
				level: 9,
			},
		],
		category: ['gathering'],
	},
	8: {
		id: 8,
		name: 'Fresh Forager',
		description: 'Gives a 1% chance to instantly prepare an ingredient.',
		enchantments: [
			{
				id: enchantmentsIds.fresh_forager,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.foraging_prodigy, talentsIds.cooking_master],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 9,
			},
			{
				mastery: true,
				skill: 'cooking',
				level: 9,
			},
		],
		category: ['gathering'],
	},
	9: {
		id: 9,
		name: 'Mining Prodigy',
		description: "You're a natural at mining! Increases your mining effective level by 10.",
		enchantments: [
			{
				id: enchantmentsIds.mining_prodigy,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'mining',
				level: 3,
			},
		],
		category: ['gathering'],
	},
	10: {
		id: 10,
		name: 'Foraging Prodigy',
		description: "You're a natural at foraging! Increases your foraging effective level by 10.",
		enchantments: [
			{
				id: enchantmentsIds.foraging_prodigy,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 3,
			},
		],
		category: ['gathering'],
	},
	11: {
		id: 11,
		name: 'Fishing Prodigy',
		description: "You're a natural at fishing! Increases your fishing effective level by 10.",
		enchantments: [
			{
				id: enchantmentsIds.fishing_prodigy,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'fishing',
				level: 3,
			},
		],
		category: ['gathering'],
	},
	12: {
		id: 12,
		name: 'Mining Grandmaster',
		description:
			"You're the uncontested master of mining. Increases your mining effective level by 5% from boosts and equipment.",
		enchantments: [
			{
				id: enchantmentsIds.mining_grandmaster,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.mining_prodigy],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'mining',
				level: 9,
			},
		],
		category: ['gathering'],
	},
	13: {
		id: 13,
		name: 'Foraging Grandmaster',
		description:
			"You're the uncontested master of foraging. Increases your foraging effective level by 5% from boosts and equipment.",
		enchantments: [
			{
				id: enchantmentsIds.foraging_grandmaster,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.foraging_prodigy],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 9,
			},
		],
		category: ['gathering'],
	},
	14: {
		id: 14,
		name: 'Fishing Grandmaster',
		description:
			"You're the uncontested master of fishing. Increases your fishing effective level by 5% from boosts and equipment.",
		enchantments: [
			{
				id: enchantmentsIds.fishing_grandmaster,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.fishing_prodigy],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'fishing',
				level: 9,
			},
		],
		category: ['gathering'],
	},
	15: {
		id: 15,
		name: 'Forest Cleaner',
		description: 'Gives a 1% chance while foraging tree seeds to also receive 5 matching logs.',
		enchantments: [
			{
				id: enchantmentsIds.forest_cleaning,
				strength: 1,
			},
		],
		talentRequirements: [talentsIds.foraging_prodigy],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 6,
			},
		],
		category: ['gathering'],
	},
	16: {
		id: 16,
		name: 'Snowman',
		description: 'Triples all snow drops.',
		enchantments: [
			{
				id: enchantmentsIds.snowman,
				strength: 3,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: false,
				skill: 'foraging',
				level: 50,
			},
		],
		category: ['gathering'],
	},
	17: {
		id: 17,
		name: 'Tea Break',
		description: 'Gain roughly 25% more uses from each buff stack on average.',
		enchantments: [
			{
				id: enchantmentsIds.prolonging,
				strength: 1,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 9,
			},
			{
				mastery: true,
				skill: 'cooking',
				level: 9,
			},
		],
		category: ['gathering', 'production'],
	},
	18: {
		id: 18,
		name: 'Fleet Fungi',
		description: 'Gives a 1% chance whilst Foraging in the Fungal Grotto to grant 40 stacks of Haste 2',
		enchantments: [
			{
				id: enchantmentsIds.fleet_fungi,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'foraging',
				level: 12,
			},
		],
		category: ['gathering'],
	},
	19: {
		id: 19,
		name: 'It Belongs in a Museum',
		description:
			'Grants you 1 level of Archaeology, giving you access to an additional loot table while Gathering.',
		enchantments: [
			{
				id: enchantmentsIds.archaeology,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'mining',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['gathering'],
	},
	20: {
		id: 20,
		name: 'Rough Seas Fishing',
		description: 'Grants you 1 level of Fishing, increasing your fish catch chance by 2.5%.',
		enchantments: [
			{
				id: enchantmentsIds.fishing,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'fishing',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['gathering'],
	},
	21: {
		id: 21,
		name: 'Oil Rig',
		description:
			'Grants you 1 level of Oil Spill, giving you a 7.5% chance to try and extract fish oil from a caught fish.',
		enchantments: [
			{
				id: enchantmentsIds.oil_spill,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'fishing',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['gathering'],
	},

	// Combat Talents, 1000-1999
	1000: {
		id: 1000,
		name: 'Precision',
		description: 'Increases your accuracy by 5%. Nice and basic!',
		enchantments: [
			{
				id: enchantmentsIds.accuracy,
				strength: 2,
			},
		],
		talentCost: 1,
		talentIcon: '/images/magic/buffs/accuracy_icon.png',
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 1,
			},
		],
		category: ['combat'],
	},
	1001: {
		id: 1001,
		name: 'Bound Anger',
		description:
			'Do up to 1.2x max damage when your health drops below 33%, scaling with your missing health. Stacks with other similar effects',
		enchantments: [
			{
				id: enchantmentsIds.bound_anger,
				strength: 4,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1002: {
		id: 1002,
		name: 'Infernal Knowledge',
		description: 'Grants you access to the Infernal Lance abilities without needing the weapon equipped.',
		abilities: [abilitiesIds.infernal_cleave, abilitiesIds.infernal_impale, abilitiesIds.infernal_puncture],
		talentCost: 5,
		talentIcon: '/images/combat/equipment/infernal_lance_icon.png',
		skillRequirements: [
			{
				mastery: true,
				skill: 'strength',
				level: 15,
			},
		],
		category: ['combat'],
	},
	// Decrease the merc base strength and boost this talent?
	1003: {
		id: 1003,
		name: 'Mercenary Guild Trainer',
		description: 'Increases your mercenaries base stats by 10%, but increases their junk split by 10% as well.',
		enchantments: [
			{
				id: enchantmentsIds.accuracy, // TODO: Change this to something else
				strength: 1,
			},
		],
		talentCost: 5,
		inactive: true,
		category: ['combat'],
	},
	1004: {
		id: 1004,
		name: 'Mercenary Guild Paymaster',
		description: 'Decreases your mercenaries junk split by 15%.',
		enchantments: [
			{
				id: enchantmentsIds.accuracy, // TODO: Change this to something else
				strength: 1,
			},
		],
		talentCost: 10,
		talentRequirements: [talentsIds.mercenary_guild_trainer],
		inactive: true,
		category: ['combat'],
	},
	1005: {
		id: 1005,
		name: 'Lucky Looter',
		description: 'Gives a 1% chance to double junk drops before any other modifiers.',
		enchantments: [
			{
				id: enchantmentsIds.lucky_looter,
				strength: 2,
			},
		],
		talentCost: 5,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 10,
			},
		],
		category: ['combat'],
	},
	1006: {
		id: 1006,
		name: 'Critical Weakpoint',
		description: 'Gives a 5% chance on crit to apply vulnerability to the target, increasing their damage taken.',
		enchantments: [
			{
				id: enchantmentsIds.critical_weakpoint,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1007: {
		id: 1007,
		name: 'Careful Criticals',
		description: 'Halves your crit chance, but doubles your crit damage.',
		enchantments: [
			{
				id: enchantmentsIds.careful_criticals,
				strength: 2,
			},
		],
		talentCost: 2,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 99,
			},
		],
		category: ['combat'],
	},
	1008: {
		id: 1008,
		name: 'Unbound Rage',
		description:
			'Do up to 1.4x max damage when your health drops below 33%, scaling with your missing health, but sacrifices a flat 40% defensive affinities to melee, magic, and range. Stacks with other similar effects, such as Bound Anger. Incompatible with Silent Fury.',
		enchantments: [
			{
				id: enchantmentsIds.unbound_rage,
				strength: 8,
			},
		],
		talentCost: 6,
		talentRequirements: [talentsIds.bound_anger],
		incompatibleTalents: [talentsIds.silent_fury],
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 12,
			},
		],
		category: ['combat'],
	},
	1009: {
		id: 1009,
		name: 'Unadorned Agility',
		description:
			'Each empty primary armor slot (helmet, torso, legs) additively increases agility by 100. If all three are empty then agility is increased by an additional 100 for a total of 400.',
		enchantments: [
			{
				id: enchantmentsIds.unadorned_agility,
				strength: 4,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'defense',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1010: {
		id: 1010,
		name: 'Arsonist',
		description:
			'Increases your offensive and defensive affinities to fire by 25%, but decreases your defensive affinities to the primary affinities (melee/range/magic) by 25%.',
		enchantments: [
			{
				id: enchantmentsIds.arsonist,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 6,
			},
		],
		incompatibleTalents: [talentsIds.coldblooded, talentsIds.poisoner, talentsIds.druidism],
		category: ['combat'],
	},
	1011: {
		id: 1011,
		name: "Giant's Blood",
		description:
			"Giant's blood runs through your veins! Increases your stamina and strength by 10% but decreases your agility and healing gained through consumables by 10%.",
		enchantments: [
			{
				id: enchantmentsIds.giants_blood,
				strength: 1,
			},
		],
		talentCost: 5,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 10,
			},
			{
				mastery: true,
				skill: 'strength',
				level: 10,
			},
		],
		category: ['combat'],
	},
	1012: {
		id: 1012,
		name: 'Featherweight Fighter',
		description:
			'Your attacks come out 5% faster and your agility is increased by 10%, but your damage is decreased by 15%.',
		enchantments: [
			{
				id: enchantmentsIds.featherweight_fighter,
				strength: 1,
			},
		],
		talentCost: 5,
		skillRequirements: [
			{
				mastery: true,
				skill: 'defense',
				level: 10,
			},
			{
				mastery: true,
				skill: 'attack',
				level: 10,
			},
		],
		category: ['combat'],
	},
	1013: {
		id: 1013,
		name: 'Nine-Lives',
		description:
			'Gives you a flat 0.9% chance to survive a lethal hit with 1 hp left. This chance is increased by 0.09% for every 0.9% of health you had before the hit, up to a maximum of 9% at full health.',
		enchantments: [
			{
				id: enchantmentsIds.ninelives,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 12,
			},
		],
		category: ['combat'],
	},
	1014: {
		id: 1014,
		name: 'Cold-Blooded',
		description:
			'Increases your offensive and defensive affinities to ice by 25%, but decreases your defensive affinities to the primary affinities (melee/range/magic) by 25%.',
		enchantments: [
			{
				id: enchantmentsIds.coldblooded,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 6,
			},
		],
		incompatibleTalents: [talentsIds.arsonist, talentsIds.poisoner, talentsIds.druidism],
		category: ['combat'],
	},
	1015: {
		id: 1015,
		name: 'Goblin Hunter',
		description: 'Increases your accuracy and damage against Goblins by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.goblin_slayer,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1016: {
		id: 1016,
		name: 'Demon Hunter',
		description: 'Increases your accuracy and damage against Demonic entities by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.exorcist,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1017: {
		id: 1017,
		name: 'Undead Hunter',
		description: 'Increases your accuracy and damage against Undead creatures by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.crusader,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1018: {
		id: 1018,
		name: 'Goblin Slayer',
		description:
			'Increases your accuracy and damage against Goblins by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.goblin_slayer,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.goblin_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	1019: {
		id: 1019,
		name: 'Demon Slayer',
		description:
			'Increases your accuracy and damage against Demonic entities by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.exorcist,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.demon_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	1020: {
		id: 1020,
		name: 'Undead Slayer',
		description:
			'Increases your accuracy and damage against Undead creatures by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.crusader,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.undead_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	1021: {
		id: 1021,
		name: 'Featherweight Champion',
		description:
			'20% of your agility is converted into strength, dexterity, and intellect. Only works if agility is positive.',
		enchantments: [
			{
				id: enchantmentsIds.featherweight_champion,
				strength: 4,
			},
		],
		talentCost: 10,
		talentRequirements: [talentsIds.featherweight_fighter],
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 20,
			},
		],
		category: ['combat'],
	},
	1022: {
		id: 1022,
		name: 'Were-Druid',
		description: 'Gain up to 20% healing power based on the moon phase. Maximum effect at full moon.',
		enchantments: [
			{
				id: enchantmentsIds.accuracy, // TODO: Change this to something else
				strength: 1,
			},
		],
		talentCost: 9,
		inactive: true,
		category: ['combat'],
	},
	1023: {
		id: 1023,
		name: 'Were-Elf',
		description: 'Gain up to 20% agility based on the moon phase. Maximum effect at new moon.',
		enchantments: [
			{
				id: enchantmentsIds.accuracy, // TODO: Change this to something else
				strength: 1,
			},
		],
		talentCost: 9,
		inactive: true,
		category: ['combat'],
	},
	1024: {
		id: 1024,
		name: 'Loaded Dice',
		description: 'Gives an additional 0.5% chance to double junk drops.',
		enchantments: [
			{
				id: enchantmentsIds.lucky_looter,
				strength: 1,
			},
		],
		talentCost: 10,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 10,
			},
		],
		talentRequirements: [talentsIds.lucky_looter],
		category: ['combat'],
	},
	// Human - Elf - Dwarf - Giant - Golem - Beast - Shrimp - Chaotic - Corrupted - Woodlike
	// Human - vigilante
	1025: {
		id: 1025,
		name: 'Human Hunter',
		description: 'Increases your accuracy and damage against Humans by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.vigilante,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1026: {
		id: 1026,
		name: 'Human Slayer',
		description:
			'Increases your accuracy and damage against Humans by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.vigilante,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.human_hunter],
		incompatibleTalents: [
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Elf - elf exterminator
	1027: {
		id: 1027,
		name: 'Elf Hunter',
		description: 'Increases your accuracy and damage against Elves by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.elf_exterminator,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1028: {
		id: 1028,
		name: 'Elf Slayer',
		description:
			'Increases your accuracy and damage against Elves by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.elf_exterminator,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.elf_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Dwarf - luddite
	1029: {
		id: 1029,
		name: 'Dwarf Hunter',
		description: 'Increases your accuracy and damage against Dwarves by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.luddite,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1030: {
		id: 1030,
		name: 'Dwarf Slayer',
		description:
			'Increases your accuracy and damage against Dwarves by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.luddite,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.dwarf_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Giant - giant slayer
	1031: {
		id: 1031,
		name: 'Giant Hunter',
		description: 'Increases your accuracy and damage against Giants by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.giant_slayer,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1032: {
		id: 1032,
		name: 'Giant Slayer',
		description:
			'Increases your accuracy and damage against Giants by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.giant_slayer,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.giant_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Golem - technophobe
	1033: {
		id: 1033,
		name: 'Golem Hunter',
		description: 'Increases your accuracy and damage against Golems by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.technophobe,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1034: {
		id: 1034,
		name: 'Golem Slayer',
		description:
			'Increases your accuracy and damage against Golems by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.technophobe,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.golem_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Beast - big game hunter
	1035: {
		id: 1035,
		name: 'Beast Hunter',
		description: 'Increases your accuracy and damage against Beasts by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.big_game_hunter,
				strength: 5,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1036: {
		id: 1036,
		name: 'Beast Slayer',
		description:
			'Increases your accuracy and damage against Beasts by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.big_game_hunter,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.beast_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Shrimp - shrimpbane
	1037: {
		id: 1037,
		name: 'Shrimp Hunter',
		description: 'Increases your accuracy and damage against Shrimp by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.shrimpbane,
				strength: 5,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1038: {
		id: 1038,
		name: 'Shrimp Slayer',
		description:
			'Increases your accuracy and damage against Shrimp by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.shrimpbane,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.shrimp_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Chaotic - order from chaos
	1039: {
		id: 1039,
		name: 'Chaotic Hunter',
		description: 'Increases your accuracy and damage against Chaotic entities by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.order_from_chaos,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1040: {
		id: 1040,
		name: 'Chaotic Slayer',
		description:
			'Increases your accuracy and damage against Chaotic entities by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.order_from_chaos,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.chaotic_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Corrupted - purifier
	1041: {
		id: 1041,
		name: 'Corrupted Hunter',
		description: 'Increases your accuracy and damage against Corrupted entities by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.purifier,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1042: {
		id: 1042,
		name: 'Corrupted Slayer',
		description:
			'Increases your accuracy and damage against Corrupted entities by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.purifier,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.corrupted_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.woodlike_slayer,
		],
		category: ['combat'],
	},
	// Woodlike - arboreal annihilator
	1043: {
		id: 1043,
		name: 'Woodlike Hunter',
		description: 'Increases your accuracy and damage against Woodlike entities by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.arboreal_annihilator,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1044: {
		id: 1044,
		name: 'Woodlike Slayer',
		description:
			'Increases your accuracy and damage against Woodlike entities by 10%, stacking with the previous tier for a total of 15%. ONLY ONE SLAYER TALENT CAN BE TAKEN.',
		enchantments: [
			{
				id: enchantmentsIds.arboreal_annihilator,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.woodlike_hunter],
		incompatibleTalents: [
			talentsIds.human_slayer,
			talentsIds.elf_slayer,
			talentsIds.dwarf_slayer,
			talentsIds.giant_slayer,
			talentsIds.golem_slayer,
			talentsIds.undead_slayer,
			talentsIds.goblin_slayer,
			talentsIds.beast_slayer,
			talentsIds.shrimp_slayer,
			talentsIds.chaotic_slayer,
			talentsIds.demon_slayer,
			talentsIds.corrupted_slayer,
		],
		category: ['combat'],
	},
	1045: {
		id: 1045,
		name: 'Silent Fury',
		description:
			'When determining your current health for effects that change in potency, such as bonus damage at low health, your health will be considered 25% lower, making the effect become more potent at higher health. This only affects checks where lower health is better. Incompatible with Unbound Rage.',
		enchantments: [
			{
				id: enchantmentsIds.silent_fury,
				strength: 5,
			},
		],
		talentCost: 6,
		talentRequirements: [talentsIds.bound_anger],
		incompatibleTalents: [talentsIds.unbound_rage],
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 12,
			},
		],
		category: ['combat'],
	},
	1046: {
		id: 1046,
		name: 'Arrow Expenditure',
		description:
			'Ranged attacks that do not consume ammunition will now do so and use the increased damage and accuracy from them.',
		enchantments: [
			{
				id: enchantmentsIds.arrow_expenditure,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1047: {
		id: 1047,
		name: 'Elven Exaltation',
		description:
			'Adds an additional level of Royal Reverence, increasing your agility by 2.5% and adding a 2.5% chance to gain a stack of Acrobatics 5.',
		enchantments: [
			{
				id: enchantmentsIds.royal_reverence,
				strength: 1,
			},
		],
		talentCost: 7,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 15,
			},
		],
		category: ['combat'],
	},
	1048: {
		id: 1048,
		name: 'Piercing Ammo Specialization',
		description:
			'Unlocks the enhanced variant of the Piercing Ammo auto-attack that has +10% piercing affinity and no base stat maluses; incompatible with Serrated and Heavy Ammo Specializations.',
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 6,
			},
		],
		category: ['combat'],
		abilities: [abilitiesIds.piercing_ammo_specialization],
	},
	1049: {
		id: 1049,
		name: 'Serrated Ammo Specialization',
		description:
			'Unlocks the enhanced variant of the Serrated Ammo auto-attack that has +10% slashing affinity and no base stat maluses; incompatible with Piercing and Heavy Ammo Specializations.',
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 6,
			},
		],
		category: ['combat'],
		abilities: [abilitiesIds.serrated_ammo_specialization],
	},
	1050: {
		id: 1050,
		name: 'Heavy Ammo Specialization',
		description:
			'Unlocks the enhanced variant of the Heavy Ammo auto-attack that has +10% blunt affinity and no base stat maluses; incompatible with Piercing and Serrated Ammo Specializations.',
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 6,
			},
		],
		category: ['combat'],
		abilities: [abilitiesIds.heavy_ammo_specialization],
	},
	1051: {
		id: 1051,
		name: 'Mark II',
		description:
			'Unlocks the enhanced variant of the Mark ability that applies the Marked debuff with twice as many stacks and double the strength of the original without marking yourself; this will generally force your party members to attack your marked target.',
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 3,
			},
		],
		category: ['combat'],
		abilities: [abilitiesIds.mark_ii],
	},
	1052: {
		id: 1052,
		name: 'Ammunition Overload',
		description:
			'Decreases your chance to preserve ammunition by a flat 25% and increases the ammo stat buffs by 5% which enhancing the ammunition by half a tier.',
		talentCost: 3,
		enchantments: [
			{
				id: enchantmentsIds.ammunition_overload,
				strength: 5,
			},
		],
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1053: {
		id: 1053,
		name: 'Ammunition Over-overload',
		description:
			'Decreases your chance to preserve ammunition by an additional 25%, for a total of 50%, and increases the ammo stat buffs by 5%, for a total of 10% which enhances the ammunition by a full tier',
		talentCost: 3,
		enchantments: [
			{
				id: enchantmentsIds.ammunition_overload,
				strength: 5,
			},
		],
		talentRequirements: [talentsIds.ammunition_overload],
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1054: {
		id: 1054,
		name: 'Poisoner',
		description:
			'Increases your offensive and defensive affinities to poison by 25%, but decreases your defensive affinities to the primary affinities (melee/range/magic) by 25%.',
		enchantments: [
			{
				id: enchantmentsIds.poisoner,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 6,
			},
		],
		incompatibleTalents: [talentsIds.druidism, talentsIds.arsonist, talentsIds.coldblooded],
		category: ['combat'],
	},
	1055: {
		id: 1055,
		name: 'Druidism',
		description:
			'Increases your offensive and defensive affinities to nature by 25%, but decreases your defensive affinities to the primary affinities (melee/range/magic) by 25%.',
		enchantments: [
			{
				id: enchantmentsIds.druidism,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 6,
			},
		],
		incompatibleTalents: [talentsIds.poisoner, talentsIds.arsonist, talentsIds.coldblooded],
		category: ['combat'],
	},
	1056: {
		id: 1056,
		name: 'Novice Treasure Hunter',
		description:
			'Grants you 1 level of Treasure Hunter, multiplicatively increasing your chance to find items in combat by 3%.',
		enchantments: [
			{
				id: enchantmentsIds.treasure_hunter,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1057: {
		id: 1057,
		name: 'Master Treasure Hunter',
		description:
			'Grants you 1 level of Treasure Hunter, multiplicatively increasing your chance to find items in combat by 3%.',
		enchantments: [
			{
				id: enchantmentsIds.treasure_hunter,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 9,
			},
		],
		talentRequirements: [talentsIds.novice_treasure_hunter],
		category: ['combat'],
	},
	1058: {
		id: 1058,
		name: 'Critical Striker',
		description:
			'Grants you 1 level of Critical Strike, increasing your critical chance and damage multiplier by an additive 2.5%.',
		enchantments: [
			{
				id: enchantmentsIds.critical_strike,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1059: {
		id: 1059,
		name: 'Thick Skinned',
		description:
			'Grants you 1 level of Demon Skin, reducing damage taken by 9 or 2%, whichever decreases damage more.',
		enchantments: [
			{
				id: enchantmentsIds.demon_skin,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'defense',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1060: {
		id: 1060,
		name: 'Lightfooted',
		description:
			'Grants you 1 level of Nimble, multiplicatively increasing your defense rolls in dodge calculations by 10%.',
		enchantments: [
			{
				id: enchantmentsIds.nimble,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'defense',
				level: 6,
			},
		],
		category: ['combat'],
	},
	1061: {
		id: 1061,
		name: 'Fortitude I',
		description: 'Grants you 1 level of Fortitude, increasing your Stamina by 10.',
		enchantments: [
			{
				id: enchantmentsIds.fortitude,
				strength: 1,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 1,
			},
		],
		category: ['combat'],
	},
	1062: {
		id: 1062,
		name: 'Fortitude II',
		description: 'Grants you 2 levels of Fortitude, increasing your Stamina by 20.',
		enchantments: [
			{
				id: enchantmentsIds.fortitude,
				strength: 2,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 6,
			},
		],
		talentRequirements: [talentsIds.fortitude_i],
		category: ['combat'],
	},
	1063: {
		id: 1063,
		name: 'Reckless Attacker',
		description:
			'Grants you 1 level of Recklessness, increasing your offensive magic, range, and melee affinity by 2% but reducing the same amount for the respective defensive affinities.',
		enchantments: [
			{
				id: enchantmentsIds.recklessness,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1064: {
		id: 1064,
		name: 'Elemental Stability',
		description:
			'Grants you 5 levels of Elemental Stabilization, neutralizing negative offensive and defensive affinities by a flat 10%, but never exceeding a neutral state.',
		enchantments: [
			{
				id: enchantmentsIds.elemental_stabilization,
				strength: 5,
			},
		],
		talentCost: 7,
		skillRequirements: [
			{
				mastery: true,
				skill: 'attack',
				level: 15,
			},
			{
				mastery: true,
				skill: 'defense',
				level: 15,
			},
		],
		category: ['combat'],
	},
	// Force I, Force II uses Overpowering Force
	1065: {
		id: 1065,
		name: 'Force I',
		description: 'Grants you 3 level of Force, boosting your strength by 3% of your protection.',
		enchantments: [
			{
				id: enchantmentsIds.force,
				strength: 3,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'strength',
				level: 5,
			},
		],
		category: ['combat'],
	},
	1066: {
		id: 1066,
		name: 'Force II',
		description: 'Grants you 10 levels of Overpowering Force, converting 10% of your protection to strength.',
		enchantments: [
			{
				id: enchantmentsIds.overpowering_force,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'strength',
				level: 10,
			},
		],
		talentRequirements: [talentsIds.force_i],
		category: ['combat'],
	},
	1067: {
		id: 1067,
		name: 'Force III',
		description:
			'Grants you 5 levels of Overpowering Force, converting an additional 5% of your protection to strength, for a total of 15%.',
		enchantments: [
			{
				id: enchantmentsIds.overpowering_force,
				strength: 5,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'strength',
				level: 15,
			},
		],
		talentRequirements: [talentsIds.force_ii],
		category: ['combat'],
	},
	// Convergence I, Convergence II and III uses Overpowering Convergence
	1068: {
		id: 1068,
		name: 'Convergence I',
		description: 'Grants you 3 levels of Convergence, boosting your intellect by 3% of your resistance.',
		enchantments: [
			{
				id: enchantmentsIds.convergence,
				strength: 3,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 5,
			},
		],
		category: ['combat'],
	},
	1069: {
		id: 1069,
		name: 'Convergence II',
		description:
			'Grants you 10 levels of Overpowering Convergence, converting 10% of your resistance to intellect.',
		enchantments: [
			{
				id: enchantmentsIds.overpowering_convergence,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 10,
			},
		],
		talentRequirements: [talentsIds.convergence_i],
		category: ['combat'],
	},
	1070: {
		id: 1070,
		name: 'Convergence III',
		description:
			'Grants you 5 levels of Overpowering Convergence, converting an additional 5% of your resistance to intellect, for a total of 15%.',
		enchantments: [
			{
				id: enchantmentsIds.overpowering_convergence,
				strength: 5,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 15,
			},
		],
		talentRequirements: [talentsIds.convergence_ii],
		category: ['combat'],
	},
	// Finesse I, Finesse II and III uses Overpowering Finesse
	1071: {
		id: 1071,
		name: 'Finesse I',
		description: 'Grants you 3 levels of Finesse, boosting your dexterity by 3% of your agility.',
		enchantments: [
			{
				id: enchantmentsIds.finesse,
				strength: 3,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 5,
			},
		],
		category: ['combat'],
	},
	1072: {
		id: 1072,
		name: 'Finesse II',
		description: 'Grants you 10 levels of Overpowering Finesse, converting 10% of your agility to dexterity.',
		enchantments: [
			{
				id: enchantmentsIds.overpowering_finesse,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 10,
			},
		],
		talentRequirements: [talentsIds.finesse_i],
		category: ['combat'],
	},
	1073: {
		id: 1073,
		name: 'Finesse III',
		description:
			'Grants you 5 levels of Overpowering Finesse, converting an additional 5% of your agility to dexterity, for a total of 15%.',
		enchantments: [
			{
				id: enchantmentsIds.overpowering_finesse,
				strength: 5,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'range',
				level: 15,
			},
		],
		talentRequirements: [talentsIds.finesse_ii],
		category: ['combat'],
	},
	1074: {
		id: 1074,
		name: 'Messy Eater',
		description: 'Reduces all food related cooldowns by 15%, but decreases healing from food by the same amount.',
		enchantments: [
			{
				id: enchantmentsIds.messy_eater,
				strength: 15,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: true,
				skill: 'constitution',
				level: 1,
			},
		],
		category: ['combat'],
	},
	1075: {
		id: 1075,
		name: 'Wild Magic I',
		description:
			'Gives a 1% chance to apply a random debuff or damage over time effect when hitting a Magic attack.',
		enchantments: [
			{
				id: enchantmentsIds.wild_magic,
				strength: 1,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 3,
			},
		],
		category: ['combat'],
	},
	1076: {
		id: 1076,
		name: 'Wild Magic II',
		description:
			'Gives an additional 1% chance to apply a random debuff or damage over time effect when hitting a Magic attack.',
		enchantments: [
			{
				id: enchantmentsIds.wild_magic,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 9,
			},
		],
		talentRequirements: [talentsIds.wild_magic_i],
		category: ['combat'],
	},
	1077: {
		id: 1077,
		name: 'Wild Magic III',
		description:
			'Gives an additional 1% chance to apply a random debuff or damage over time effect when hitting a Magic attack.',
		enchantments: [
			{
				id: enchantmentsIds.wild_magic,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 15,
			},
		],
		talentRequirements: [talentsIds.wild_magic_ii],
		category: ['combat'],
	},
	1078: {
		id: 1078,
		name: 'Wild Magic Master',
		description:
			'This research did pay off! Gives a 2% chance to apply a random debuff or damage over time effect when hitting a Magic attack.',
		enchantments: [
			{
				id: enchantmentsIds.wild_magic,
				strength: 2,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: true,
				skill: 'magic',
				level: 0,
			},
		],
		questRequirements: [questsIds.elemental_exiles],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['combat'],
	},
	1079: {
		id: 1079,
		name: 'Deflection',
		description:
			'Grants 5 levels of deflect, reducing damage taken by 5% and reflecting the same amount to the attacker.',
		enchantments: [
			{
				id: enchantmentsIds.lesser_deflect,
				strength: 5,
			},
		],
		talentCost: 20,
		skillRequirements: [
			{
				mastery: true,
				skill: 'defense',
				level: 30,
			},
		],
		category: ['combat'],
	},
	1080: {
		id: 1080,
		name: "Giant King's Vitality",
		description:
			"Grants you 1 level of Valley's Vigor, increasing Stamina by 3% and defensive affinities for Ice, Fire, and Nature by 1.5%. Also increases health gained from food by 3%.",
		enchantments: [
			{
				id: enchantmentsIds.valleys_vigor,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'defense',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.giant_problems],
		category: ['combat'],
	},
	1081: {
		id: 1081,
		name: 'Vault Hunter',
		description:
			'Grants you 1 level of Loot Goblin, granting you on average 2 encounters with a Loot Goblin in combat a day.',
		enchantments: [
			{
				id: enchantmentsIds.loot_goblin,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.vault_hunter],
		category: ['combat'],
	},
	1082: {
		id: 1082,
		name: "Shrimp's Blessing",
		description:
			'One can only be exposed to the void for so long without being affected. Is this truly a blessing? Grants you 1 level of Voidblessed, increasing the Chaos scaling of abilities by 10%.',
		enchantments: [
			{
				id: enchantmentsIds.voidblessed,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.shrimp_god_slayer],
		category: ['combat'],
	},
	1083: {
		id: 1083,
		name: 'Deviant Shrimp Studier',
		description:
			'Just a glimpse into the true nature of reality. Grants you +1% accuracy and damage against Shrimps.',
		enchantments: [
			{
				id: enchantmentsIds.shrimpbane,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.shrimp_aberrancy],
		category: ['combat'],
	},
	1084: {
		id: 1084,
		name: 'Goblin Tactics',
		description: 'Study of Goblin tactics has increased your agility by 12.',
		enchantments: [
			{
				id: enchantmentsIds.reinforcement,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'defense',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.goblin_extermination],
		category: ['combat'],
	},
	1085: {
		id: 1085,
		name: 'Alloyed Joints',
		description:
			'Recovered armor samples has shown you how to better armor the weakspots at your joints, increasing your protection by 20.',
		enchantments: [
			{
				id: enchantmentsIds.protection,
				strength: 2,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'defense',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.dark_necessities],
		category: ['combat'],
	},
	1086: {
		id: 1086,
		name: 'Elder Error',
		description:
			'Learning from their mistakes is a lot easier than making your own. Your accuracy is increased by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.accuracy,
				strength: 2,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.elder_error],
		category: ['combat'],
	},
	1087: {
		id: 1087,
		name: 'Castle Hunting',
		description:
			'Taking on apex predators have given you a better handle on taking out their lessers. Grants you +1% damage against Beasts.',
		enchantments: [
			{
				id: enchantmentsIds.big_game_hunter,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.castle_hunting],
		category: ['combat'],
	},
	1088: {
		id: 1088,
		name: 'Grave Digger',
		description: 'Gives a 0.5% chance to double junk drops before any other modifiers.',
		enchantments: [
			{
				id: enchantmentsIds.lucky_looter,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.archaeology_in_force],
		category: ['combat'],
	},
	1089: {
		id: 1089,
		name: 'Sunscreen',
		description:
			'All that time in the palace has led to a novel invention- Sunscreen! Increases defensive fire affinity by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.sunscreen,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'defense',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.enjoying_demonic_architecture],
		category: ['combat'],
	},
	1090: {
		id: 1090,
		name: 'Eye of the Shrimpholder',
		description: 'Grants you +1% accuracy and damage against Shrimps.',
		enchantments: [
			{
				id: enchantmentsIds.shrimpbane,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'attack',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.chaotic_seas],
		category: ['combat'],
	},
	1091: {
		id: 1091,
		name: 'Reap the Storm',
		description: 'Grants Patience 3, increasing damage of slower abilities by a large amount.',
		enchantments: [
			{
				id: enchantmentsIds.patience,
				strength: 3,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'strength',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.hurricane_hunter],
		category: ['combat'],
	},

	// Production Talents, 2000-2999
	2000: {
		id: 2000,
		name: 'Runecrafting Master',
		description:
			'Your mastery of runecrafting has given you insight into how best to optimize your production, giving you +1 strength in the Runecrafting and Engraving enchantments.',
		enchantments: [
			{
				id: enchantmentsIds.runecrafting,
				strength: 1,
			},
			{
				id: enchantmentsIds.engraving,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 3,
			},
		],
		category: ['production'],
	},
	2001: {
		id: 2001,
		name: 'Augmentation Afficionado II',
		description: 'Further increases your chance to successfully augment an item by 2.5% to 5%.',
		enchantments: [
			{
				id: enchantmentsIds.augmentation_afficionado,
				strength: 1,
			},
		],
		talentCost: 15,
		skillRequirements: [
			{
				mastery: true,
				skill: 'enchanting',
				level: 15,
			},
		],
		talentRequirements: [talentsIds.augmentation_afficionado_i],
		category: ['production'],
	},
	// 5 Crafting Talents
	2002: {
		id: 2002,
		name: 'Novice Crafter',
		description: 'Reduces the materials needed to craft items by an additive 2%.',
		enchantments: [
			{
				id: enchantmentsIds.crafting_talent,
				strength: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.crafting_tools_i],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2003: {
		id: 2003,
		name: 'Apprentice Crafter',
		description: 'Reduces the materials needed to craft items by an additive 2%.',
		enchantments: [
			{
				id: enchantmentsIds.crafting_talent,
				strength: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.crafting_tools_ii],
		talentRequirements: [talentsIds.novice_crafter],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2004: {
		id: 2004,
		name: 'Journeyman Crafter',
		description: 'Reduces the materials needed to craft items by an additive 2%.',
		enchantments: [
			{
				id: enchantmentsIds.crafting_talent,
				strength: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.crafting_tools_iii],
		talentRequirements: [talentsIds.apprentice_crafter],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2005: {
		id: 2005,
		name: 'Master Crafter',
		description: 'Reduces the materials needed to craft items by an additive 2%.',
		enchantments: [
			{
				id: enchantmentsIds.crafting_talent,
				strength: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.crafting_tools_iv],
		talentRequirements: [talentsIds.journeyman_crafter],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2006: {
		id: 2006,
		name: 'Grandmaster Crafter',
		description: 'Reduces the materials needed to craft items by an additive 2%.',
		enchantments: [
			{
				id: enchantmentsIds.crafting_talent,
				strength: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.crafting_tools_v],
		talentRequirements: [talentsIds.master_crafter],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2007: {
		id: 2007,
		name: 'Runecrafting Grandmaster',
		description:
			'Your mastery of runecrafting has given you insight into how best to optimize your production, granting an additional +1 strength to the Runecrafting and Engraving enchantments.',
		enchantments: [
			{
				id: enchantmentsIds.runecrafting,
				strength: 1,
			},
			{
				id: enchantmentsIds.engraving,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Prospecting Prospect - Smithing Master
	2008: {
		id: 2008,
		name: 'Slag Refinement',
		description: 'Gives a 5% chance to re-roll your refining reward if you rolled Metal Slag.',
		enchantments: [
			{
				id: enchantmentsIds.slag_refinement,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 10,
			},
		],
		category: ['production'],
	},
	// Seed Sifter - Cooking Master
	2009: {
		id: 2009,
		name: 'Seed Sifter',
		description: 'Gives a 1% chance to produce a seed when preparing a vegetable.',
		enchantments: [
			{
				id: enchantmentsIds.seed_sifter,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'cooking',
				level: 10,
			},
		],
		category: ['production'],
	},
	// Pungent Runes - Runecrafting Master
	2010: {
		id: 2010,
		name: 'Pungent Runes',
		description: 'Gives a 1% chance per runecrafting action to roll a random Fishing droptable.',
		enchantments: [
			{
				id: enchantmentsIds.pungent_runes,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 9,
			},
			{
				mastery: true,
				skill: 'fishing',
				level: 9,
			},
		],
		category: ['production'],
	},
	// Smithing Master
	2011: {
		id: 2011,
		name: 'Smithing Master',
		description:
			'Your mastery of smithing has given you insight into how best to optimize your production, giving you +1 Forge Enhancement and Forge Maintenance enchantments.',
		enchantments: [
			{
				id: enchantmentsIds.forge_enhancement,
				strength: 1,
			},
			{
				id: enchantmentsIds.forge_maintenance,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Cooking Master
	2012: {
		id: 2012,
		name: 'Cooking Master',
		description:
			'Your mastery of cooking has given you insight into how best to optimize your production, giving you +1 Cooking enchantment strength.',
		enchantments: [
			{
				id: enchantmentsIds.cooking,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'cooking',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Transformation Talent - Enchanting Master
	2013: {
		id: 2013,
		name: 'Transformation Talent',
		description: 'Grants an additional level of Chances, increasing the chance an item transforms.',
		enchantments: [
			{
				id: enchantmentsIds.chances,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'enchanting',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Enchanting Master
	2014: {
		id: 2014,
		name: 'Augmentation Afficionado I',
		description:
			'Grants a level of Augmentation Afficionado, increasing your chance to critically augment by 2.5%.',
		enchantments: [
			{
				id: enchantmentsIds.augmentation_afficionado,
				strength: 1,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'enchanting',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Bulk Cooking - Cooking Master
	2015: {
		id: 2015,
		name: 'Bulk Cooking',
		description:
			'Gain a level of Blitzing and Bulk Cooking, increasing the rate that you will prepare and cook food significantly.',
		enchantments: [
			{
				id: enchantmentsIds.blitzing,
				strength: 1,
			},
			{
				id: enchantmentsIds.bulk_cooking,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'cooking',
				level: 9,
			},
		],
		category: ['production'],
	},
	// Overheating - Smithing Master, +1 max heat at all Forges
	2016: {
		id: 2016,
		name: 'Overheating',
		description: 'Grants 2 levels of Metallurgy, increasing max heat at all forges by 1.',
		enchantments: [
			{
				id: enchantmentsIds.metallurgy,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 6,
			},
		],
		category: ['production'],
	},
	// The forge talents mutually exclusive?
	// Obsidian Forgery - Smithing Master, adds obsidian glass to the City Forge refining table
	2017: {
		id: 2017,
		name: 'Obsidian Forgery',
		description: 'Increases chance to produce Obsidian Glass from refining at the City Forge by an additive 3%.',
		enchantments: [
			{
				id: enchantmentsIds.obsidian_forgery,
				strength: 3,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Dwarven Refinement - Smithing Master, increases effective refining at dwarven forge by 1 or 2
	2018: {
		id: 2018,
		name: 'Dwarven Refinement',
		description: 'Increases your effective refining level by 1 at the Dwarven Forge.',
		enchantments: [
			{
				id: enchantmentsIds.dwarven_refinement,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Molten Lava - Smithing Master, +2 max heat at Volcanic Forge
	2019: {
		id: 2019,
		name: 'Molten Metal',
		description: 'Increases your max heat by 2 at the Volcanic Forge.',
		enchantments: [
			{
				id: enchantmentsIds.molten_metal,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Pyromania - Smithing and Cooking master, gives a level of pyromancy
	2020: {
		id: 2020,
		name: 'Pyromania',
		description: 'Gives you a level of Pyromancy.',
		enchantments: [
			{
				id: enchantmentsIds.pyromancy,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'smithing',
				level: 6,
			},
			{
				mastery: true,
				skill: 'cooking',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Greenest Thumb - increase growth speed by 10%
	2021: {
		id: 2021,
		name: 'Greenest Thumb',
		description: 'Increases farming growth speed by 10%.',
		enchantments: [
			{
				id: enchantmentsIds.greenest_thumb,
				strength: 2,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Totemic Wisdom - farming totems give full effect at half age
	2022: {
		id: 2022,
		name: 'Totemic Wisdom',
		description: 'Farming totems give their full effect at half their maximum age.',
		enchantments: [
			{
				id: enchantmentsIds.totemic_wisdom,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 9,
			},
		],
		category: ['production'],
	},
	// Mysterious Harvest - increase maximum loot for all plants by 2% for every myst seed with a unique size
	2023: {
		id: 2023,
		name: 'Mysterious Harvest',
		description:
			'Increases maximum harvest roll for all plants by 2% for every Mysterious Seed with a unique size.',
		enchantments: [
			{
				id: enchantmentsIds.mysterious_harvest,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Trapper - 10% chance to recover a trap when harvesting
	2024: {
		id: 2024,
		name: 'Trapper',
		description: 'Gives a 10% chance to recover a trap when harvesting.',
		enchantments: [
			{
				id: enchantmentsIds.trapper,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Farm Blitz - get up to 10 blitzing 3 stacks every time a 1x1 seed is harvested
	2025: {
		id: 2025,
		name: 'Farm Blitz',
		description: 'Gives up to 10 Blitzing 3 stacks every time a 1x1 seed is harvested.',
		enchantments: [
			{
				id: enchantmentsIds.farm_blitz,
				strength: 10,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 6,
			},
			{
				mastery: true,
				skill: 'cooking',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Sympathetic Growth - minimum loot increased by 1% for each plant of the same type
	2026: {
		id: 2026,
		name: 'Sympathetic Growth',
		description:
			'Increases minimum harvest roll by 1% for a plant for each duplicate plant in your field. For example, if you have 3 potatoes planted, each potato will have a 3% increased minimum harvest roll.',
		enchantments: [
			{
				id: enchantmentsIds.sympathetic_growth,
				strength: 1,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 9,
			},
		],
		category: ['production'],
	},
	// Crop Rotation - +10% growth speed if no duplicate plants
	2027: {
		id: 2027,
		name: 'Crop Rotation',
		description: 'Increases growth speed of your field by 10% if there are no duplicate plot types.',
		enchantments: [
			{
				id: enchantmentsIds.crop_rotation,
				strength: 2,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 1,
			},
		],
		category: ['production'],
	},
	// Tree Hugger - +10% loot from trees
	2028: {
		id: 2028,
		name: 'Tree Hugger',
		description: 'Increases harvest from trees by 10%.',
		enchantments: [
			{
				id: enchantmentsIds.tree_hugger,
				strength: 2,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Heart of the Forest - +2 heart of the oak aug on drop
	2029: {
		id: 2029,
		name: 'Heart of the Forest',
		description:
			'Increases the augmentation level of all Heart of the Oak drops in your field by +2. This allows it to exceed its normal cap of +10, moving the cap up to +12.',
		enchantments: [
			{
				id: enchantmentsIds.heart_of_the_forest,
				strength: 2,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 9,
			},
		],
		category: ['production'],
	},
	// Farming Master - increase farm height by 1, needs more support for this
	2030: {
		id: 2030,
		name: 'Farming Master',
		description: 'Increases farm height by 1. Maybe width too? Dunno, WIP!',
		enchantments: [
			{
				id: enchantmentsIds.farming,
				strength: 2,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 1,
			},
		],
		inactive: true,
		category: ['production'],
	},
	// Mysterious Nutrients - increase exp and loot from non-quadratic myst seeds by 20%
	2031: {
		id: 2031,
		name: 'Mysterious Nutrients',
		description: 'Increases experience and loot from non-quadratic mysterious seeds by 20%.',
		enchantments: [
			{
				id: enchantmentsIds.farming,
				strength: 2,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'farming',
				level: 9,
			},
		],
		inactive: true,
		category: ['production'],
	},
	2032: {
		id: 2032,
		name: 'Research Ready',
		description:
			'Increases your chance to successfully research an item by 2.5%. Incompatible with the Mad Scientist talent.',
		enchantments: [
			{
				id: enchantmentsIds.research_ready,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'enchanting',
				level: 6,
			},
		],
		incompatibleTalents: [talentsIds.mad_scientist],
		category: ['production'],
	},
	2033: {
		id: 2033,
		name: 'Mad Scientist',
		description:
			'Decreases your chance to successfully research an item by 10%. Incompatible with the Research Ready talent.',
		enchantments: [
			{
				id: enchantmentsIds.mad_scientist,
				strength: 4,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'enchanting',
				level: 6,
			},
		],
		incompatibleTalents: [talentsIds.research_ready],
		category: ['production'],
	},
	// Lingering Enlightenment - 200 stacks of Enlightenment when consuming a talisman, but halves talisman cosumption gain
	2034: {
		id: 2034,
		name: 'Lingering Enlightenment',
		description:
			'Grants 200 stacks of Enlightenment when consuming a talisman, but halves the amount of essence gained when consuming talismans.',
		enchantments: [
			{
				id: enchantmentsIds.lingering_enlightenment,
				strength: 2,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 6,
			},
		],
		category: ['production'],
	},
	// Spiraling out of Control - 1% faster action speed in slate spires for each mastery in runecrafting
	2035: {
		id: 2035,
		name: 'Spiraling out of Control',
		description: '1% faster action speed in Slate Spires for each mastery level in Runecrafting',
		enchantments: [
			{
				id: enchantmentsIds.spiraling_out_of_control,
				strength: 4,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 3,
			},
			{
				mastery: true,
				skill: 'mining',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Affix Affinity - Re-rolls your affixes an additional time for free if a re-roll attempt results in all affixes being worse than prior
	2036: {
		id: 2036,
		name: 'Affix Affinity',
		description:
			'Re-rolls your affixes an additional time for free if a re-roll attempt results in all affixes being worse than the prior roll.',
		enchantments: [
			{
				id: enchantmentsIds.affix_affinity,
				strength: 1,
			},
		],
		talentCost: 10,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 15,
			},
		],
		category: ['production'],
	},
	// Fantastic Fiber Finder - +3 Fiber Finder
	2037: {
		id: 2037,
		name: 'Fantastic Fiber Finder',
		description: 'Increases your Fiber Finder by 3.',
		enchantments: [
			{
				id: enchantmentsIds.fiber_finder,
				strength: 3,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'fishing',
				level: 3,
			},
			{
				mastery: true,
				skill: 'runecrafting',
				level: 3,
			},
		],
		category: ['production'],
	},
	// Talisman Talent - Doubles passive effect of consumed talismans
	2038: {
		id: 2038,
		name: 'Talisman Talent',
		description: 'Doubles the passive effect of consumed talismans.',
		enchantments: [
			{
				id: enchantmentsIds.talisman_talent,
				strength: 5,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 9,
			},
		],
		category: ['production'],
	},
	2039: {
		id: 2039,
		name: 'Essence Concentration Expert',
		description: 'Reduces the negative impact of Essence Concentration by an additive 30%.',
		enchantments: [
			{
				id: enchantmentsIds.essence_concentration_expert,
				strength: 3,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 6,
			},
		],
		category: ['production'],
	},
	2040: {
		id: 2040,
		name: 'Essence Concentration Master',
		description: 'Reduces the negative impact of Essence Concentration by an additional additive 20%.',
		enchantments: [
			{
				id: enchantmentsIds.essence_concentration_expert,
				strength: 2,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 12,
			},
		],
		category: ['production'],
	},
	2041: {
		id: 2041,
		name: 'Consistent Chef',
		description: 'Always cook the higher quality food, ignoring the cooking chance to increase quality.',
		enchantments: [
			{
				id: enchantmentsIds.consistent_chef,
				strength: 1,
			},
		],
		talentCost: 2,
		skillRequirements: [
			{
				mastery: false,
				skill: 'cooking',
				level: 99,
			},
		],
		category: ['production'],
	},
	2042: {
		id: 2042,
		name: 'Mass Crafter I',
		description:
			'Increases output of crafting recipes with bulk output (creates more than 1 item per craft, such as ammunition) by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.mass_crafter,
				strength: 5,
			},
		],
		talentCost: 3,
		skillRequirements: [
			{
				mastery: true,
				skill: 'crafting',
				level: 3,
			},
		],
		category: ['production'],
	},
	2043: {
		id: 2043,
		name: 'Mass Crafter II',
		description:
			'Increases output of crafting recipes with bulk output (creates more than 1 item per craft, such as ammunition) by an additional 3%, stacking to 8% with the first talent.',
		enchantments: [
			{
				id: enchantmentsIds.mass_crafter,
				strength: 3,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'crafting',
				level: 9,
			},
		],
		talentRequirements: [talentsIds.mass_crafter_i],
		category: ['production'],
	},
	2044: {
		id: 2044,
		name: 'Mass Crafter III',
		description:
			'Increases output of crafting recipes with bulk output (creates more than 1 item per craft, such as ammunition) by an additional 2%, stacking to 10% with the first two talents.',
		enchantments: [
			{
				id: enchantmentsIds.mass_crafter,
				strength: 2,
			},
		],
		talentCost: 9,
		skillRequirements: [
			{
				mastery: true,
				skill: 'crafting',
				level: 15,
			},
		],
		talentRequirements: [talentsIds.mass_crafter_ii],
		category: ['production'],
	},
	2045: {
		id: 2045,
		name: 'Smithing Grandmaster',
		description: 'Increases effective Smithing level by 15.',
		enchantments: [
			{
				id: enchantmentsIds.smithing_grandmaster,
				strength: 3,
			},
		],
		skillRequirements: [
			{
				mastery: false,
				skill: 'smithing',
				level: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.smithing_grandmaster],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2046: {
		id: 2046,
		name: 'Smithing Legend',
		description: 'Increases effective Smithing level by 10%.',
		enchantments: [
			{
				id: enchantmentsIds.smithing_legend,
				strength: 2,
			},
		],
		skillRequirements: [
			{
				mastery: false,
				skill: 'smithing',
				level: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.smithing_legend],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2047: {
		id: 2047,
		name: 'Cooking Grandmaster',
		description: 'Increases effective Cooking level by 15.',
		enchantments: [
			{
				id: enchantmentsIds.cooking_grandmaster,
				strength: 3,
			},
		],
		skillRequirements: [
			{
				mastery: false,
				skill: 'cooking',
				level: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.cooking_grandmaster],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2048: {
		id: 2048,
		name: 'Cooking Legend',
		description:
			'Increases effective Cooking level by 10% and grants 1 level of Gold Leaf Cook, giving a 2% chance to instantly complete another cooking action when using the Golden Spoon.',
		enchantments: [
			{
				id: enchantmentsIds.cooking_legend,
				strength: 2,
			},
			{
				id: enchantmentsIds.gold_leaf_cook,
				strength: 1,
			},
		],
		skillRequirements: [
			{
				mastery: false,
				skill: 'cooking',
				level: 1,
			},
		],
		talentCost: 0,
		questRequirements: [questsIds.cooking_legend],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		category: ['production'],
	},
	2049: {
		id: 2049,
		name: 'Runic Racing',
		description: 'Gives a 4% chance to instantly complete another Runecrafting action, capped at 5 procs.',
		enchantments: [
			{
				id: enchantmentsIds.runic_racing,
				strength: 1,
			},
		],
		talentCost: 6,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 9,
			},
		],
		category: ['production'],
	},
	2050: {
		id: 2050,
		name: 'Gold Leaf Cook I',
		description: 'Gives a 2% chance to instantly complete another Cooking action when using the Golden Spoon.',
		enchantments: [
			{
				id: enchantmentsIds.gold_leaf_cook,
				strength: 1,
			},
		],
		talentCost: 2,
		skillRequirements: [
			{
				mastery: true,
				skill: 'cooking',
				level: 5,
			},
		],
		category: ['production'],
	},
	2051: {
		id: 2051,
		name: 'Gold Leaf Cook II',
		description:
			'Gives an additional 2% chance to instantly complete another Cooking action when using the Golden Spoon.',
		enchantments: [
			{
				id: enchantmentsIds.gold_leaf_cook,
				strength: 1,
			},
		],
		talentCost: 4,
		skillRequirements: [
			{
				mastery: true,
				skill: 'cooking',
				level: 10,
			},
		],
		talentRequirements: [talentsIds.gold_leaf_cook_i],
		category: ['production'],
	},
	2052: {
		id: 2052,
		name: 'Prismatic Power',
		description:
			'Grants an additional level of Chances, increasing the chance an item transforms, as well as Quick Study, which gives a 2% chance to complete the next Enchanting action instantly.',
		enchantments: [
			{
				id: enchantmentsIds.chances,
				strength: 1,
			},
			{
				id: enchantmentsIds.quick_study,
				strength: 1,
			},
		],
		skillRequirements: [
			{
				mastery: false,
				skill: 'enchanting',
				level: 1,
			},
		],
		talentCost: 0,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.prismatic_power],
		category: ['production'],
	},
	2053: {
		id: 2053,
		name: 'Shrimp Aberrancy',
		description:
			'Grants you 1 level of Shrimp Lord, giving you a 5% chance per cooking action to gain one Raw Shrimp.',
		enchantments: [
			{
				id: enchantmentsIds.shrimp_lord,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'cooking',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		// questRequirements: [questsIds.shrimp_aberrancy],
		category: ['production'],
	},
	2054: {
		id: 2054,
		name: 'Goblin Recipe Book',
		description:
			'Grants you 1 level of Leftovers, giving you a 1% chance per cooking or brewing action to create a random buff consumable.',
		enchantments: [
			{
				id: enchantmentsIds.leftovers,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'cooking',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		// questRequirements: [questsIds.goblin_extermination],
		category: ['production'],
	},
	2055: {
		id: 2055,
		name: 'Dark Alloying',
		description:
			'Grants you 1 level of Pure Metals, reducing the amount of materials required to smelt a bar by 2%.',
		enchantments: [
			{
				id: enchantmentsIds.pure_metals,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'smithing',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		// questRequirements: [questsIds.dark_necessities],
		category: ['production'],
	},
	2056: {
		id: 2056,
		name: 'Seeds of Doubt',
		description: 'Grants you 1 level of Cultivation, giving a 2% chance to get a seed back when harvesting.',
		enchantments: [
			{
				id: enchantmentsIds.cultivation,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'farming',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		// questRequirements: [questsIds.elder_error],
		category: ['production'],
	},
	2057: {
		id: 2057,
		name: 'Experienced Hunter',
		description: 'Grants you 1 level of Trapper, giving a 5% chance to recover a trap when harvesting.',
		enchantments: [
			{
				id: enchantmentsIds.trapper,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'farming',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		// questRequirements: [questsIds.castle_hunting],
		category: ['production'],
	},
	2058: {
		id: 2058,
		name: 'Demonic Architecture',
		description:
			'Grants you 1 level of Engraving, giving a 2% chance to not consume rune slates while Runecrafting.',
		enchantments: [
			{
				id: enchantmentsIds.engraving,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'runecrafting',
				level: 1,
			},
		],
		inactive: true,
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		// questRequirements: [questsIds.enjoying_demonic_architecture],
		category: ['production'],
	},
	2059: {
		id: 2059,
		name: 'Do Ya Membah',
		description: 'Grants you 1 level of Intuition, increasing experience gain by 5%.',
		enchantments: [
			{
				id: enchantmentsIds.intuition,
				strength: 1,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'farming',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.do_ya_membah],
		category: ['production'],
	},
	2060: {
		id: 2060,
		name: 'Flowing Breath I',
		description:
			'Grants you 10 levels of Flowing Breath, giving a +10% chance to double base air essence gathered.',
		enchantments: [
			{
				id: enchantmentsIds.flowing_breath,
				strength: 10,
			},
		],
		talentCost: 1,
		skillRequirements: [
			{
				mastery: true,
				skill: 'runecrafting',
				level: 1,
			},
		],
		category: ['production'],
	},
	2061: {
		id: 2061,
		name: 'Flowing Breath II',
		description: 'Grants you 5 levels of Flowing Breath, giving a +5% chance to double base air essence gathered.',
		enchantments: [
			{
				id: enchantmentsIds.flowing_breath,
				strength: 5,
			},
		],
		talentCost: 0,
		skillRequirements: [
			{
				mastery: false,
				skill: 'runecrafting',
				level: 1,
			},
		],
		automaticallyChosen: true,
		hideIfUnmetRequirements: true,
		questRequirements: [questsIds.idling_like_a_champ],
		category: ['production'],
	},
};
