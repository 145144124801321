// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */

export const locationsIds = {
	the_void: 5,
	the_forges: 6,
	the_cooking_guild: 7,
	the_academy_of_augmentation: 8,
	the_runecrafting_shrines: 9,
	the_preparation_halls: 10,
	the_college_of_scrollcrafting: 11,
	the_institute_of_research: 12,
	clay_pit: 50,
	city_outskirts_mine: 51,
	village_quarry: 52,
	desert_mine: 53,
	underground_mine: 54,
	hidden_mine: 55,
	volcano_mine: 56,
	slate_spires: 57,
	deep_pit: 80,
	grasslands: 101,
	verdant_valley: 102,
	fungal_grotto: 103,
	the_tangle: 104,
	misty_marsh: 105,
	frozen_tundra: 106,
	haunted_woods: 107,
	living_forest: 108,
	shallow_pond: 151,
	lazy_river: 152,
	still_lake: 153,
	open_ocean: 154,
	stormy_seas: 155,
	deep_pit_encounter: 200,
	stormy_seas_encounter: 201,
	living_forest_encounter: 202,
	farm: 1000,
	caves: 1001,
	city: 1002,
	lava_maze: 1003,
	corrupted_lands: 1004,
	valley_of_giants: 1005,
	chaos_wastes: 1006,
	enchanted_armory: 1007,
	fallen_academy: 1008,
	overgrown_woods: 1009,
	elven_encampment: 1010,
	living_armory: 2000,
	giants_hills: 2001,
	corroded_battlefield: 2002,
	goblin_kitchen: 2003,
	ot_tos_farm: 2004,
	the_pit: 2005,
	sacred_grove: 2006,
	chaotic_rift: 2007,
	stormier_seas: 2008,
	obsidian_research_facility: 2009,
	fallen_wizard_tower: 2010,
	nox_woods: 2011,
	royal_encampment: 2012,
	gazing_into_the_abyss: 3000,
	goblin_settlement: 3001,
	dark_fortress: 3002,
	giants_keep: 3003,
	elder_ruins: 3004,
	overgrown_castle: 3005,
	ancient_barrows: 3006,
	palace_of_flame: 3007,
	eye_of_the_storm: 3008,
	the_maelstrom: 3009,
	rise_of_info: 3010,
	beyond_the_edge: 3011,
	abandoned_academy_base: 3012,
	nature_atheneum: 3013,
	dwarven_research_station: 3014,
	dark_vault: 3015,
	training_ground: 9999,
	dungeon_test_1: 10000,
	dungeon_test_2: 10001,
	dungeon_test_3: 10002,
	dungeon_test_4: 10003,
	dungeon_test_5: 10004,
	skill_test: 10005,
	skill_test_2: 10006,
};
