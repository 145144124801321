import { Box, Grid, Img, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { IMarketOffer } from '../../../../../game-server/src/repositories/MarketOffer.repository';
import { formatNumberToString } from '../../../helper/helperFunctions';
import { itemList } from '../../../utils/itemList';
import { BuyMarketplaceDialogue } from '../../layout/dialogs/BuyMarketplaceDialogue';
import { IMarketListingWithStackSize } from './MarketplaceBuyItemList';
import { shareMarketplaceOfferSelector } from '../../../atoms/chatInputAtom';
import ItemTooltip from '../Tooltips/ItemTooltip';
import useIsMobile from '../../../hooks/useIsMobile';
import {
	itemAugment,
	itemEnchant,
	itemHigherAugment,
	itemImage,
	textAugment,
	textEnchant,
} from '../../../helper/itemHelperFunctions';

export default function MarketplaceMyAuctionsOffer(props: { offer: IMarketOffer }) {
	const shareMarketplaceListing = useSetRecoilState(shareMarketplaceOfferSelector);

	const isMobile = useIsMobile();

	const listing: IMarketListingWithStackSize = {
		listingId: props.offer.offerId,
		item: {
			id: 0,
			inventoryItemId: 0,
			name: itemList[props.offer.itemID].name,
			itemID: props.offer.itemID,
			stackSize: props.offer.leftToPurchase,
			augmentations: props.offer.augmentations ?? undefined,
			enchantmentID: props.offer.enchantment_id ?? undefined,
			enchantmentStrength: props.offer.enchantment_strength ?? undefined,
			order: 1,
		},
		price: props.offer.price,
		sellerId: props.offer.buyerId,
		listingDate: props.offer.offerDate,
		league: props.offer.league,
		belongsToCurrentUser: true,
		stackSize: props.offer.leftToPurchase,
		isOffer: true,
		higherAugments: props.offer.higherAugments,
		anyEnchantment: props.offer.anyEnchantment,
	};
	const itemData = itemList[props.offer.itemID];

	const item: TPartialItemWithItemID<IItem> = { itemID: props.offer.itemID, name: itemData.name };
	const { isOpen, onOpen, onClose } = useDisclosure();
	if (props.offer.augmentations) {
		item.augmentations = props.offer.augmentations;
	}
	if (props.offer.enchantment_id) {
		item.enchantmentID = props.offer.enchantment_id;
	}
	if (props.offer.enchantment_strength) {
		item.enchantmentStrength = props.offer.enchantment_strength;
	}

	return (
		<>
			<Grid
				gridTemplateColumns={'30% 15% 20% 20% 15%'}
				gridTemplateRows={'50px'}
				onClick={onOpen}
				position={'relative'}
				className={'marketplace-table-row'}
				cursor='pointer'
				_hover={{ backgroundColor: 'rgba(104, 104, 104, 0.9) !important' }}
				alignItems={'center'}
			>
				<Box position='relative'>
					{textAugment(item)}
					{textEnchant(item)}
				</Box>
				<Box margin='auto' position='relative' width='45px' height='45px'>
					{itemImage(itemList[item.itemID])}
					{itemEnchant(item, props.offer.anyEnchantment)}
					{itemAugment(item)}
					{itemHigherAugment(props.offer.higherAugments)}
				</Box>
				<Box>{props.offer.leftToPurchase.toLocaleString('en-us')}</Box>
				<Box>
					<Text as='span'>{props.offer.price.toLocaleString('en-us')}</Text>
					<br />
					<Text as='span'>({formatNumberToString(props.offer.price * props.offer.leftToPurchase)})</Text>
				</Box>
				<Box zIndex={1000} onClick={(e) => e.stopPropagation()}>
					<Img
						src='/images/chaticons/chat-icon-market-chat.png'
						className='share-marketplace-listing-button'
						onClick={() => shareMarketplaceListing(props.offer.offerId.toString())}
					></Img>
				</Box>
				{!isMobile && (
					<ItemTooltip item={item} forceDefaultPositions={true} listingDate={props.offer.offerDate} />
				)}
			</Grid>
			{isOpen && (
				<BuyMarketplaceDialogue itemIdProp={props.offer.itemID} listingProp={listing} onClose={onClose} />
			)}
		</>
	);
}
