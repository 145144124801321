import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import React, { useEffect, useRef, useState } from 'react';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../hooks/useIsMobile';
import {
	augmentationGlow,
	itemAffix,
	itemAmounts,
	itemAugment,
	itemEnchant,
	itemImage,
} from '../../../helper/itemHelperFunctions';
import { itemList } from '../../../utils/itemList';
import ItemTooltip from '../Tooltips/ItemTooltip';
import ItemTooltipDialog from './ItemDialogs/ItemTooltipDialog';

export interface ItemProps extends BoxProps {
	item: IItem;
	showTooltip?: boolean;
	allowAnimation?: boolean;
	extraClassNames?: string;
	hideCount?: boolean;
	mobileTooltip?: boolean;
	mobileModal?: boolean;
}

export const Item = forwardRef<ItemProps, 'div'>(
	(
		{
			item,
			extraClassNames,
			showTooltip = true,
			allowAnimation = false,
			hideCount = false,
			mobileTooltip = true,
			mobileModal = true,
			...props
		},
		ref
	) => {
		const [animating, setAnimating] = useState('');
		const itemData = itemList[item.itemID];
		const itemAmount = item.christmasSpirit ?? item.stackSize;
		const { isOpen, onOpen, onClose } = useDisclosure();
		const isMobile = useIsMobile();

		const prevQuantity = useRef(item.stackSize);
		useEffect(() => {
			if (allowAnimation && prevQuantity.current !== item.stackSize) {
				setAnimating(item.stackSize > prevQuantity.current ? 'item-animating-gain' : 'item-animating-lose');
				prevQuantity.current = item.stackSize;
				setTimeout(() => {
					setAnimating('');
				}, 2000);
			}
		}, [item.stackSize]);

		return (
			<Box
				className={`item ${itemData.class} ${animating} ${extraClassNames ?? ''}`}
				data-itemid={item.itemID}
				ref={ref}
				onClick={onOpen}
				{...props}
			>
				{augmentationGlow(item)}
				{itemImage(itemData)}
				{itemEnchant(item)}
				{itemAugment(item)}
				{itemAffix(item)}
				{!hideCount && itemAmounts(itemAmount)}

				{showTooltip &&
					(isMobile && mobileModal ? (
						isOpen && mobileTooltip && <ItemTooltipDialog item={item} onClose={onClose} />
					) : (
						<ItemTooltip item={item} />
					))}
			</Box>
		);
	}
);
