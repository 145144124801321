import { cloneDeep } from 'lodash';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { usePlayerField } from '../../../../hooks/hooks';
import { doItemsMatch } from '../../../../utils/itemFunctions';
import { itemList } from '../../../../utils/itemList';

export type TMercStatus = {
	status: 'ok' | 'missing-gear' | 'missing-food' | 'conflicting-gear';
	missing: TPartialItemWithItemID<IItem>[];
};

export default function getMercenaryStatus(loadouts: ILoadout[]): TMercStatus[] {
	const stockpile = usePlayerField('stockpile');
	const vault = usePlayerField('vault');
	const stockpileCopy = cloneDeep(stockpile);
	const vaultCopy = cloneDeep(vault);
	const status: TMercStatus[] = loadouts.map(() => ({
		status: 'ok',
		missing: [],
	}));
	const equipmentInUse: IItem[] = [];

	verifyFood();
	verifyGear();

	function verifyFood() {
		for (let i = 0; i < loadouts.length; i++) {
			const loadout = loadouts[i];
			if (!loadout.loadoutMercUseFood) continue;
			for (const item of loadout.loadoutInventory) {
				if (!searchInventory(item, stockpileCopy)) {
					status[i].status = 'missing-food';
					addToMissing(item, i);
				}
			}
		}
	}

	function verifyGear() {
		for (let i = 0; i < loadouts.length; i++) {
			const loadout = loadouts[i];
			for (const item of loadout.loadoutEquipment) {
				const itemData = itemList[item.itemID];
				if (isTool(itemData.equipmentStats?.slot)) {
					continue;
				}
				if (!searchInventory(item, stockpileCopy) && !searchInventory(item, vaultCopy)) {
					if (equipmentInUse.some((usedItem) => doItemsMatch(usedItem, item))) {
						status[i].status = 'conflicting-gear';
					} else {
						status[i].status = 'missing-gear';
					}
					addToMissing(item, i);
				}
			}
		}
	}

	function searchInventory(item: TPartialItemWithItemID<IItem>, inventory: IItem[]): boolean {
		const found = inventory.findIndex((slotItem) => doItemsMatch(slotItem, item));
		if (found === -1) return false;
		equipmentInUse.push(inventory[found]);
		if (inventory[found].stackSize > 1) {
			inventory[found].stackSize--;
		} else {
			inventory.splice(found, 1);
		}
		return true;
	}

	function isTool(slotName: string | undefined) {
		return (
			slotName === 'hoe' ||
			slotName === 'hatchet' ||
			slotName === 'pickaxe' ||
			slotName === 'tacklebox' ||
			slotName === 'tongs' ||
			slotName === 'tome' ||
			slotName === 'ladle' ||
			slotName === 'chisel'
		);
	}

	function addToMissing(item: TPartialItemWithItemID<IItem>, index: number) {
		const found = status[index].missing.findIndex((missingItem) => doItemsMatch(missingItem, item));
		if (found !== -1) {
			status[index].missing[found].stackSize = (status[index].missing[found].stackSize ?? 1) + 1;
		} else {
			status[index].missing.push(structuredClone(item));
		}
	}

	return status;
}
