// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { IItem, IItemData } from '../../../game-server/src/modules/items/items.interface';
import {
	IFarmingObject,
	IFarmingPlant,
	IFarmingPos,
	TFarmingPlot,
} from '../../../game-server/src/modules/skills/farming/Farming.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const getAllPlants = (plot: TFarmingPlot) => {
	return Object.values(plot).reduce(
		(acc: IFarmingPlant[], row) => acc.concat(Object.values(row)),
		[] as IFarmingPlant[]
	);
};

export const getPlantsOverlappingArea = (plot: TFarmingPlot, start?: IFarmingPos, end?: IFarmingPos) => {
	if (!start || !end) {
		return [];
	}
	const allPlants = getAllPlants(plot);
	const overlappingPlants = allPlants.filter((plant) => {
		return (
			plant.row <= end.row &&
			plant.row + plant.height - 1 >= start.row &&
			plant.column <= end.column &&
			plant.column + plant.width - 1 >= start.column
		);
	});
	return overlappingPlants;
};

export const isMystSeed = (seedId: IItem['itemID']) => {
	return seedId === itemsIds.mysterious_seed;
};

export const getOccupiedTiles = (farming: IFarmingObject) => {
	const occupiedTiles = Array.from({ length: farming.height }, () => Array(farming.width).fill(false));
	// set occupied tiles to true
	for (const row in farming.plot) {
		for (const column in farming.plot[row]) {
			const plant = farming.plot[row][column];
			for (let i = 0; i < plant.height; i++) {
				for (let j = 0; j < plant.width; j++) {
					occupiedTiles[plant.row + i][plant.column + j] = true;
				}
			}
		}
	}
	return occupiedTiles;
};

export const overlapsWithExistingPlant = (
	occupiedTiles: boolean[][],
	row: number,
	column: number,
	seedHeight: number,
	seedWidth: number
) => {
	for (let i = 0; i < seedHeight; i++) {
		for (let j = 0; j < seedWidth; j++) {
			if (occupiedTiles[row + i][column + j]) {
				return true;
			}
		}
	}
	return false;
};

export const getSeedSize = (seed: IItemData, start: IFarmingPos, end: IFarmingPos) => {
	if (!seed.farmingStats) return { height: 0, width: 0 };
	const selectionRows = Math.abs(start.row - end.row) + 1;
	const selectionColumns = Math.abs(start.column - end.column) + 1;
	const seedHeight = seed.farmingStats.maxHeight
		? Math.min(seed.farmingStats.maxHeight, selectionRows)
		: seed.farmingStats.height;
	const seedWidth = seed.farmingStats.maxHeight
		? Math.min(seed.farmingStats.maxHeight, selectionColumns)
		: seed.farmingStats.width;
	return { height: seedHeight, width: seedWidth };
};

export const getPlantSpotsInArea = (
	occupiedTiles: boolean[][],
	start: IFarmingPos,
	end: IFarmingPos,
	seed: IItemData
) => {
	if (!seed.farmingStats) return [];
	({ start, end } = normalizeFarmingSelection(start, end));
	const { height, width } = getSeedSize(seed, start, end);

	// Allow single click planting of multi slot plants
	if (start.row === end.row && start.column === end.column) {
		end.row = Math.min(end.row + height - 1, occupiedTiles.length - 1);
		end.column = Math.min(end.column + width - 1, occupiedTiles[0].length - 1);
	}

	const plantSpots: IFarmingPos[] = [];
	for (let row = start.row; row + height - 1 <= end.row; row += height) {
		for (let column = start.column; column + width - 1 <= end.column; column += width) {
			if (!overlapsWithExistingPlant(occupiedTiles, row, column, height, width)) {
				plantSpots.push({ row, column });
			}
		}
	}
	return plantSpots;
};

function normalizeFarmingSelection(start: undefined, end: undefined): { start: undefined; end: undefined };
function normalizeFarmingSelection(start: IFarmingPos, end: IFarmingPos): { start: IFarmingPos; end: IFarmingPos };
function normalizeFarmingSelection(
	start: IFarmingPos | undefined,
	end: IFarmingPos | undefined
): { start: IFarmingPos | undefined; end: IFarmingPos | undefined };
function normalizeFarmingSelection(start: IFarmingPos | undefined, end: IFarmingPos | undefined) {
	if (start === undefined || end === undefined) {
		return { start: undefined, end: undefined };
	}
	const startRow = Math.min(start.row, end.row);
	const endRow = Math.max(start.row, end.row);
	const startColumn = Math.min(start.column, end.column);
	const endColumn = Math.max(start.column, end.column);
	return {
		start: { row: startRow, column: startColumn },
		end: { row: endRow, column: endColumn },
	};
}
export { normalizeFarmingSelection };

export const getBasePlantCost = (seedData: IItemData, height: number, width: number) => {
	return seedData.farmingStats?.maxHeight && seedData.farmingStats.maxWidth ? height * width : 1;
};
