// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TChatIconList } from '../../../game-server/src/modules/chat/Chat.interface';

export const chatIconList: TChatIconList = Object.freeze({
	1: {
		iconID: 1,
		source: '/images/chaticons/chat-icon-admin.png',
		name: 'Admin',
	},
	2: {
		iconID: 2,
		source: '/images/chaticons/chat-icon-arthorse.png',
		name: 'Sir Artisto Horsicus Masteri the Third',
	},
	3: {
		iconID: 3,
		source: '/images/chaticons/chat-icon-boohi.svg',
		name: 'Creator',
	},
	4: {
		iconID: 4,
		source: '/images/chaticons/chat-icon-dev.png',
		name: 'Dev Wizard Hat',
	},
	5: {
		iconID: 5,
		source: '/images/chaticons/chat-icon-mod.svg',
		name: 'Moderator',
	},

	// donation icons
	6: {
		iconID: 6,
		source: '/images/chaticons/donate-5.png',
		name: 'Coin',
		platinumUnlock: 1000,
	},
	7: {
		iconID: 7,
		source: '/images/chaticons/donate-20.png',
		name: 'Money Bag',
		platinumUnlock: 4000,
	},
	8: {
		iconID: 8,
		source: '/images/chaticons/donate-50.png',
		name: 'Sapphire',
		platinumUnlock: 10000,
	},
	9: {
		iconID: 9,
		source: '/images/chaticons/donate-100.png',
		name: 'Emerald',
		platinumUnlock: 20000,
	},
	10: {
		iconID: 10,
		source: '/images/chaticons/donate-250.png',
		name: 'Ruby',
		platinumUnlock: 50000,
	},
	11: {
		iconID: 11,
		source: '/images/chaticons/donate-500.png',
		name: 'Diamond',
		platinumUnlock: 100000,
	},
	12: {
		iconID: 12,
		source: '/images/chaticons/donate-1000.png',
		name: 'Glorious Crown',
		platinumUnlock: 200000,
	},
	13: {
		iconID: 13,
		source: '/images/chaticons/donate-2000.png',
		name: 'Diamond Crown',
		platinumUnlock: 400000,
	},
	14: {
		iconID: 14,
		source: '/images/chaticons/donate-5000.png',
		name: 'Infernal Crown',
		platinumUnlock: 1000000,
	},

	// Forever King exclusive
	15: {
		iconID: 15,
		source: '/images/chaticons/prismatic-crown.png',
		name: 'Prismatic Crown',
	},

	20: {
		iconID: 20,
		source: '/images/chaticons/chat-icon-bugtester-grey.png',
		name: 'Silver Bug Finder',
	},
	21: {
		iconID: 21,
		source: '/images/chaticons/chat-icon-bugtester-gold.png',
		name: 'Gold Bug Finder',
	},
	22: {
		iconID: 22,
		source: '/images/chaticons/chat-icon-bugtester-diamond.png',
		name: 'Diamond Bug Finder',
	},
	23: {
		iconID: 23,
		source: '/images/chaticons/chat-icon-lead-mod.png',
		name: 'Super Moderator',
	},

	25: {
		iconID: 25,
		source: '/images/chaticons/star-icon-bronze.png',
		name: 'Bronze Star',
	},
	26: {
		iconID: 26,
		source: '/images/chaticons/star-icon-silver.png',
		name: 'Silver Star',
	},
	27: {
		iconID: 27,
		source: '/images/chaticons/star-icon-gold.png',
		name: 'Gold Star',
	},
	28: {
		iconID: 28,
		source: '/images/chaticons/star-icon-diamond.png',
		name: 'Diamond Star',
	},
	29: {
		iconID: 29,
		source: '/images/chaticons/star-icon-red-3.png',
		name: 'Distinguished Community Member',
	},

	// 1 Referral
	30: {
		iconID: 30,
		source: '/images/ui/friend_icon.png',
		name: 'Friendly',
	},
	// 5 Referrals
	31: {
		iconID: 31,
		source: '/images/ui/group_icon.png',
		name: 'Socialite',
	},

	// A few different lorekeeper variants
	32: {
		iconID: 32,
		source: '/images/misc/sealed_scroll.png',
		name: 'Lorekeeper',
	},
	33: {
		iconID: 33,
		source: '/images/misc/book.png',
		name: 'Lorekeeper',
	},

	50: {
		iconID: 50,
		source: '/images/chaticons/shrimp_lord.png',
		name: 'Shrimp Lord',
	},

	60: {
		iconID: 60,
		source: '/images/chaticons/birthday_cake.png',
		name: 'Birthday Cake',
	},

	70: {
		iconID: 70,
		source: '/images/chaticons/doodlesaurus.png',
		name: 'Doodlesaurus',
	},

	71: {
		iconID: 71,
		source: '/images/chaticons/chat_mod.png',
		name: 'Chat Moderator',
	},

	100: {
		iconID: 100,
		source: '/images/premium/premium_icon.svg',
		name: 'Idlescape Plus',
	},

	150: {
		iconID: 150,
		source: '/images/chaticons/discord.png',
		name: 'Discord',
	},

	// Custom / Semi-Custom Icons
	200: {
		iconID: 200,
		source: '/images/chaticons/ordew.png',
		name: 'Moldy Ordew',
	},
	201: {
		iconID: 201,
		source: '/images/chaticons/ordew-no-glow.png',
		name: 'Mini Moldy Ordew',
	},
	202: {
		iconID: 202,
		source: '/images/chaticons/lemon.png',
		name: 'L E M O N',
	},
	203: {
		iconID: 203,
		source: '/images/chaticons/lemon-no-glow.png',
		name: 'Mini Lemon',
	},
	204: {
		iconID: 204,
		source: '/images/chaticons/Holy_cheese_wedge.png',
		name: 'The Holy Cheese Wedge',
	},
	205: {
		iconID: 205,
		source: '/images/chaticons/Holy_cheese_wedge_alt.png',
		name: 'Not-So-Holy Cheese Wedge',
	},
	206: {
		// EndOfTimes
		iconID: 206,
		source: '/images/chaticons/angry_stump2.png',
		name: 'Angry Stump',
	},
	207: {
		// Charactermood
		iconID: 207,
		source: '/images/chaticons/aquamarine_original.png',
		name: 'Ancient Fisherman',
	},
	208: {
		iconID: 208,
		source: '/images/chaticons/coin_gang.png',
		name: 'Coin Gang',
	},
	209: {
		iconID: 209,
		source: '/images/chaticons/GB_icon3.png',
		name: 'Gameboyatron',
	},
	210: {
		iconID: 210,
		source: '/images/chaticons/otis.png',
		name: 'O T I S',
	},
	211: {
		iconID: 211,
		source: '/images/chaticons/zoomba.png',
		name: 'Dog with a Temper',
	},
	212: {
		iconID: 212,
		source: '/images/chaticons/FPC.png',
		name: 'Flying Purple Carrot',
	},
	213: {
		iconID: 213,
		source: '/images/chaticons/karateswords.png',
		name: 'karateswords',
	},

	// Leaderboard Icons
	500: {
		iconID: 500,
		source: '/images/total_level.png',
		name: 'Top 3 Total Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	501: {
		iconID: 501,
		source: '/images/total_level_mastery_icon.png',
		name: 'Top 3 Total Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	502: {
		iconID: 502,
		source: '/images/mining/iron_pickaxe.svg',
		name: 'Top 3 Mining Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	503: {
		iconID: 503,
		source: '/images/mining/iron_pickaxe.svg',
		name: 'Top 3 Mining Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	504: {
		iconID: 504,
		source: '/images/foraging/foraging_icon.png',
		name: 'Top 3 Foraging Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	505: {
		iconID: 505,
		source: '/images/foraging/foraging_icon.png',
		name: 'Top 3 Foraging Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	506: {
		iconID: 506,
		source: '/images/fishing/fishing_logo.png',
		name: 'Top 3 Fishing Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	507: {
		iconID: 507,
		source: '/images/fishing/fishing_logo.png',
		name: 'Top 3 Fishing Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	508: {
		iconID: 508,
		source: '/images/farming/farming_icon.png',
		name: 'Top 3 Farming Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	509: {
		iconID: 509,
		source: '/images/farming/farming_icon.png',
		name: 'Top 3 Farming Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	510: {
		iconID: 510,
		source: '/images/enchanting/enchanting_logo.png',
		name: 'Top 3 Enchanting Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	511: {
		iconID: 511,
		source: '/images/enchanting/enchanting_logo.png',
		name: 'Top 3 Enchanting Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	512: {
		iconID: 512,
		source: '/images/runecrafting/RuneCraftingIcon.png',
		name: 'Top 3 Runecrafting Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	513: {
		iconID: 513,
		source: '/images/runecrafting/RuneCraftingIcon.png',
		name: 'Top 3 Runecrafting Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	514: {
		iconID: 514,
		source: '/images/smithing/smithing_icon.png',
		name: 'Top 3 Smithing Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	515: {
		iconID: 515,
		source: '/images/smithing/smithing_icon.png',
		name: 'Top 3 Smithing Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	516: {
		iconID: 516,
		source: '/images/ui/crafting_icon.png',
		name: 'Top 3 Crafting Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	517: {
		iconID: 517,
		source: '/images/ui/crafting_icon.png',
		name: 'Top 3 Crafting Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	518: {
		iconID: 518,
		source: '/images/cooking/cooking_icon.png',
		name: 'Top 3 Cooking Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	519: {
		iconID: 519,
		source: '/images/cooking/cooking_icon.png',
		name: 'Top 3 Cooking Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	520: {
		iconID: 520,
		source: '/images/combat/constitution_icon.png',
		name: 'Top 3 Constitution Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	521: {
		iconID: 521,
		source: '/images/combat/constitution_icon.png',
		name: 'Top 3 Constitution Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	522: {
		iconID: 522,
		source: '/images/combat/attack_icon.png',
		name: 'Top 3 Attack Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	523: {
		iconID: 523,
		source: '/images/combat/attack_icon.png',
		name: 'Top 3 Attack Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	524: {
		iconID: 524,
		source: '/images/combat/strength_icon.png',
		name: 'Top 3 Strength Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	525: {
		iconID: 525,
		source: '/images/combat/strength_icon.png',
		name: 'Top 3 Strength Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	526: {
		iconID: 526,
		source: '/images/combat/defense_icon.png',
		name: 'Top 3 Defense Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	527: {
		iconID: 527,
		source: '/images/combat/defense_icon.png',
		name: 'Top 3 Defense Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	528: {
		iconID: 528,
		source: '/images/magic/magic_logo.png',
		name: 'Top 3 Magic Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	529: {
		iconID: 529,
		source: '/images/magic/magic_logo.png',
		name: 'Top 3 Magic Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	530: {
		iconID: 530,
		source: '/images/combat/range_icon.png',
		name: 'Top 3 Range Level',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	531: {
		iconID: 531,
		source: '/images/combat/range_icon.png',
		name: 'Top 3 Range Mastery Level',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	532: {
		iconID: 532,
		source: '/images/misc/shrine_icon.png',
		name: 'Top Weekly Altar Donator',
		css: 'top-20-icon-normal',
		tempOnly: true,
	},
	533: {
		iconID: 533,
		source: '/images/misc/shrine_icon.png',
		name: 'Top 3 Global Shrine Donator',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	// Dungeon Icons
	600: {
		iconID: 600,
		source: '/images/misc/keys/goblin_map.png',
		name: 'Goblin Settlement Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	601: {
		iconID: 601,
		source: '/images/misc/keys/fortress_key.png',
		name: 'Dark Fortress Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	602: {
		iconID: 602,
		source: '/images/misc/keys/cursed_spade.png',
		name: 'Ancient Barrows Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	603: {
		iconID: 603,
		source: '/images/misc/keys/flame_feather.png',
		name: 'Palace of Flame Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	604: {
		iconID: 604,
		source: '/images/misc/keys/pyramid.png',
		name: 'Eye of the Storm Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	605: {
		iconID: 605,
		source: '/images/misc/shrimp_bauble_cracked.png',
		name: 'Aberrant Shrimp Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	606: {
		iconID: 606,
		source: '/images/misc/keys/Complete_Disc.png',
		name: "Giant's Keep Master",
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	607: {
		iconID: 607,
		source: '/images/misc/keys/lockstone.png',
		name: 'Elder Ruins Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	608: {
		iconID: 608,
		source: '/images/misc/keys/castle_map.png',
		name: 'Overgrown Castle Master',
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},
	609: {
		iconID: 609,
		source: '/images/misc/keys/prism.png',
		name: 'Master of the Maelstrom', // this sounds cooler than The Maelstrom Master
		css: 'top-20-icon-mastery',
		tempOnly: true,
	},

	// Platinum Icons
	1000: {
		iconID: 1000,
		source: '/images/misc/Elite_Scroll.png',
		name: 'Elite Scroll Enthusiast',
	},
	1001: {
		iconID: 1001,
		source: '/images/combat/combat_level.png',
		name: 'Combat Chad',
	},
	1002: {
		iconID: 1002,
		source: '/images/cooking/burnt_food.png',
		name: 'Burnt Food Connoisseur',
	},
	1003: {
		iconID: 1003,
		source: '/images/fishing/material/aquamarine.png',
		name: 'Marine Geologist',
	},
	1004: {
		iconID: 1004,
		source: '/images/magic/pris_dust.png',
		name: 'Promise of the Prismatic',
	},
	1005: {
		iconID: 1005,
		source: '/images/misc/shrine_icon.png',
		name: 'le toucan has arrived',
	},
	1006: {
		iconID: 1006,
		source: '/images/mining/stone.png',
		name: 'To the Moon!',
	},
	1007: {
		iconID: 1007,
		source: '/images/chaticons/jester.png',
		name: 'Court Jester',
	},

	// Event Icons
	2000: {
		iconID: 2000,
		source: '/images/chaticons/wiki_madness_participant.png',
		name: 'Wiki Madness Participant',
	},
	2001: {
		iconID: 2001,
		source: '/images/chaticons/wiki_madness_bronze.png',
		name: 'Wiki Madness Bronze',
	},
	2002: {
		iconID: 2002,
		source: '/images/chaticons/wiki_madness_silver.png',
		name: 'Wiki Madness Silver',
	},
	2003: {
		iconID: 2003,
		source: '/images/chaticons/wiki_madness_gold.png',
		name: 'Wiki Madness Gold',
	},
	2004: {
		iconID: 2004,
		source: '/images/chaticons/season_two_participant.png',
		name: 'Buff Madness Participant',
	},
	2005: {
		iconID: 2005,
		source: '/images/chaticons/season_two_participant.png', // TODO: asset
		name: 'Pre-Launch Participant',
	},
});
