import { Flex, Box, Text, Image } from '@chakra-ui/react';
import { IdlescapeFrameBox, IdlescapeContainer } from '@idlescape/ui';
import {
	IQuestCompleted,
	IQuestProgress,
	TQuestDifficulty,
} from '../../../../../game-server/src/modules/quest/Quest.interface';
import { questPointIcon } from '../../../helper/itemHelperFunctions';
import { questList } from '../../../utils/questList';
import useIsMobile from '../../../hooks/useIsMobile';
import { usePlayerField } from '../../../hooks/hooks';
import { TSkillName } from '../../../../../game-server/src/Atypes/Skills';

interface QuestSummaryProps {
	isAchievements: boolean;
	questProgress: IQuestProgress[];
	questsCompleted: IQuestCompleted[];
}

export default function QuestSummary({ isAchievements, questProgress, questsCompleted }: QuestSummaryProps) {
	const skills = usePlayerField('skills');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);

	const isMobile = useIsMobile();

	const totalQuests = Object.values(questList).reduce((acc, quest) => {
		if (quest.achievement === isAchievements) {
			acc++;
		}
		return acc;
	}, 0);

	// Filter questIds by whether they are achievements or not
	const filteredCompleted = questIdsCompleted.filter((questId) => {
		const quest = questList[questId];
		if (isAchievements) {
			return quest?.achievement;
		} else {
			return quest?.achievement === undefined;
		}
	});
	// Same for questProgress
	const filteredProgress = questProgress.filter((questId) => {
		const quest = questList[questId.id];
		if (isAchievements) {
			return quest?.achievement;
		} else {
			return quest?.achievement === undefined;
		}
	});

	let questPoints = 0;
	let achievementPoints = 0;
	Object.values(questList).forEach((quest) => {
		if (questIdsCompleted.includes(quest.id)) {
			questPoints += quest?.questRewards?.questPoints ?? 0;
			achievementPoints += quest?.questRewards?.achievementPoints ?? 0;
		}
	});

	function getSumCompletedByDifficulty(difficulty: TQuestDifficulty) {
		let sum = 0;
		Object.values(questList).forEach((quest) => {
			if (questIdsCompleted.includes(quest.id)) {
				if (quest.difficulty === difficulty) {
					sum += 1;
				}
			}
		});
		return sum;
	}

	const targetName = isAchievements ? 'Achievement' : 'Quest';

	const totalEasy = getSumCompletedByDifficulty('Easy');
	const totalMedium = getSumCompletedByDifficulty('Medium');
	const totalHard = getSumCompletedByDifficulty('Hard');
	const totalElite = getSumCompletedByDifficulty('Elite');
	const totalMaster = getSumCompletedByDifficulty('Master');

	if (isAchievements) {
		return (
			<Flex justifyContent='center' alignItems='center' columnGap='10px' flexWrap='wrap'>
				<h1 style={{ textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' }}>
					Achievements ({filteredCompleted.length} / {totalQuests})
				</h1>
				<h2 style={{ textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' }}>
					Points: {achievementPoints}
					<Image
						src={'/images/tutorial/achievement_point.png'}
						alt='Achievement Points'
						height='40px'
						width='40px'
						marginLeft='5px'
					/>
				</h2>
			</Flex>
		);
	}

	let alert: React.ReactNode = null;
	if (!isAchievements) {
		let unacceptedCount = 0;
		Object.values(questList).forEach((quest) => {
			if (!questIdsCompleted.includes(quest.id) && !questProgress.find((q) => q.id === quest.id)) {
				// Check if they have the requirements to start the quest
				const requirements = quest.questRequirements;
				if (requirements) {
					// Quest Points Check
					if (requirements.questPoints) {
						if (questPoints < requirements.questPoints) {
							return;
						}
					}
					// Required Quests Check
					if (requirements.requiredQuests) {
						return;
					}
					// Required Skills Check
					if (requirements.skillRequirements) {
						for (const [skillName, level] of Object.entries(requirements.skillRequirements)) {
							const skill = skills[skillName as TSkillName];
							if (!skill || skill.level < level) {
								return;
							}
						}
					}
				}
				unacceptedCount++;
			}
		});
		if (unacceptedCount > 0) {
			alert = (
				<h4 style={{ color: 'red', textShadow: '2px 2px 4px black' }}>{unacceptedCount} unaccepted quests</h4>
			);
		}
		// Get the count of quests that are complete but not turned in
		let completedButNotTurnedIn = 0;
		Object.values(questList).forEach((quest) => {
			const isInProgress = questProgress.find((q) => q.id === quest.id);
			if (!isInProgress) {
				return;
			}
			const onLastStep = quest?.questSteps?.length === questProgress.find((q) => q.id === quest.id)?.stepID;
			const progressExceedsOrMatchesTarget =
				(quest.questSteps[quest?.questSteps?.length - 1]?.targetAmount ?? 1337) <=
				(questProgress.find((q) => q.id === quest.id)?.current ?? 999);
			if (!questIdsCompleted.includes(quest.id) && onLastStep && progressExceedsOrMatchesTarget) {
				completedButNotTurnedIn++;
			}
		});
		if (completedButNotTurnedIn > 0) {
			if (alert) {
				alert = (
					<>
						{alert}
						<h4 style={{ color: 'green', textShadow: '2px 2px 4px black' }}>
							{completedButNotTurnedIn} completed quests ready for turn in
						</h4>
					</>
				);
			} else {
				alert = (
					<h4 style={{ color: 'green', textShadow: '2px 2px 4px black' }}>
						{completedButNotTurnedIn} completed quests ready for turn in
					</h4>
				);
			}
		}
	}

	return (
		<Box textAlign='center'>
			<IdlescapeContainer>
				<Flex alignItems='center' height='150px'>
					<IdlescapeFrameBox padding='10px' margin='5px' _hover={{}}>
						{questPointIcon(100)}
					</IdlescapeFrameBox>
					<Text fontSize={isMobile ? '35px' : '50px'}>{targetName} Summary</Text>
				</Flex>
			</IdlescapeContainer>
			<IdlescapeContainer fontSize='28px'>
				{alert}
				<p>
					Total {targetName} Points: {questPoints}
					<br />
					Completed: {filteredCompleted.length}
					<br />
					In Progress: {filteredProgress.length}
				</p>
				<p>
					Easy Completed: {totalEasy}
					<br />
					Medium Completed: {totalMedium}
					<br />
					Hard Completed: {totalHard}
					<br />
					Elite Completed: {totalElite}
					<br />
					Master Completed: {totalMaster}
				</p>
			</IdlescapeContainer>
		</Box>
	);
}
