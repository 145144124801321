import { BoxProps, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { TRelatedSkill } from '../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { IShrineBucket, IShrineBuffBucket } from '../../../../../game-server/src/modules/shrine/Shrine.interface';
import { SHRINE_BASE_TIME, SHRINE_TIME_PER_LEVEL } from '../../../utils/constantsCollection';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { getTimeString } from '../../../helper/helperFunctions';
import { enchantmentsIds } from '../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { tabs } from '../../../helper/NavigationTabsList';
import { Buff } from '../../layout/StatusBar/Buff';
import { ProgressBar } from '../../layout/UI/ProgressBar';

export function getThemeIcon(theme: TRelatedSkill) {
	switch (theme) {
		case 'attack':
			return tabs['attack'].icon;
		case 'defense':
			return tabs['defense'].icon;
		case 'combat':
			return '/images/ui/equipment_icon.png';
		case 'constitution':
			return tabs['constitution'].icon;
		case 'cooking':
			return tabs['cooking'].icon;
		case 'crafting':
			return tabs['crafting'].icon;
		case 'enchanting':
			return tabs['enchanting'].icon;
		case 'farming':
			return tabs['farming'].icon;
		case 'fishing':
			return tabs['fishing'].icon;
		case 'foraging':
			return tabs['foraging'].icon;
		case 'gathering':
			return '/images/magic/gathering_colorized.png';
		case 'magic':
			return tabs['magic'].icon;
		case 'mining':
			return tabs['mining'].icon;
		case 'production':
			return '/images/magic/production.png';
		case 'range':
			return tabs['range'].icon;
		case 'runecrafting':
			return tabs['runecrafting'].icon;
		case 'smithing':
			return tabs['smithing'].icon;
		case 'strength':
			return tabs['strength'].icon;
		case 'skilling':
			return '/images/leagues/default_league_icon.png';
		case 'total':
			return '/images/total_level.png';
	}
}

const shrineGods = [
	'the Holy Shrimp',
	'Le Toucan',
	'the Infinite Void',
	'the Elven King',
	'the Mother of Goblins',
	'the Insatiable',
	'the Great One',
	'the Keeper of Dreams',
	'the Queen of Swords',
	'the Thief Guild',
	'your local Temple',
	'the Druid Circle',
	'INFO',
	'Anti-INFO propaganda',
	'the World Serpent',
	'God #16',
] as const;
export function getShrineGod(seed: number) {
	// get god from the list, jumping around
	const index = ((seed + 2549) * 17257) % shrineGods.length;
	return shrineGods[index];
}

export function BucketIcon({ bucket, size, ...styles }: { bucket: IShrineBucket; size: number } & BoxProps) {
	const style = {
		width: `${size}px`,
		height: `${size}px`,
		...styles,
	};
	switch (bucket.type) {
		case 'time':
			return <Image src='/images/clock.png' alt='Time Bucket' {...style} />;
		case 'buff': {
			const theme = (bucket as IShrineBuffBucket).theme;
			return <Image src={getThemeIcon(theme)} alt={`${theme} Buff Bucket`} {...style} />;
		}
		case 'madnessStrength':
			return <Image src='/images/combat/stats-icon-enemy.png' alt='Madness Strength Bucket' {...style} />;
		case 'madnessBuff':
			return <Image src='/images/combat/stats-icon-enemy.png' alt='Madness Buff Bucket' {...style} />;
	}
}

export function buffStrengths(bucket: IShrineBuffBucket) {
	const buffs = bucket.buffs;
	const buffLevels = [...bucket.buffLevels];
	// Last level isn't completed yet
	buffLevels.pop();
	const buffStrengths = buffLevels.reduce(
		(acc, level) => {
			const buff = acc.find((buff) => buff.id === level);
			if (buff) buff.strength++;
			return acc;
		},
		buffs.map((buff) => ({
			id: buff,
			strength: 0,
		}))
	);
	return buffStrengths;
}

export function getBucketInfo(bucket: IShrineBucket, compact: boolean) {
	switch (bucket.type) {
		case 'time':
			return timeInfo(bucket);
		case 'buff': {
			const strengthArray = buffStrengths(bucket as IShrineBuffBucket);
			if (compact) {
				return (
					<Flex gap='5px' justifyContent='center'>
						{strengthArray.map((buff) => (
							<Buff
								key={buff.id}
								enchantmentID={buff.id}
								enchantmentStrength={buff.strength}
								source='shrine-next'
							/>
						))}
					</Flex>
				);
			} else {
				return strengthArray.map((buff) => {
					const enchantmentData = enchantmentsList[buff.id];
					return (
						<Flex key={buff.id} alignItems='center' gap='5px' gridColumn='1 / 2'>
							<Buff enchantmentID={buff.id} enchantmentStrength={buff.strength} source='shrine-next' />
							<Text
								color='rgb(201, 30, 207)'
								fontSize='1.2rem'
								fontWeight='bold'
								margin='0'
								textAlign='left'
							>
								{enchantmentData.name} {buff.strength}
							</Text>
						</Flex>
					);
				});
			}
		}
		case 'madnessStrength':
			return 'Embrace the madness!';
		case 'madnessBuff':
			return 'Release the chaos!';
	}
}

function timeInfo(bucket: IShrineBucket) {
	const time = SHRINE_BASE_TIME + bucket.highestUnlockedLevel * SHRINE_TIME_PER_LEVEL;
	return `Duration of all buff altars: ${getTimeString(time / 1000)}`;
}

export function BucketProgress({ bucket, ...style }: { bucket: IShrineBucket } & BoxProps) {
	return (
		<Flex alignItems='center' {...style}>
			<Text margin='5px' fontWeight='bold' textShadow='2px 2px 2px black'>
				{bucket.highestUnlockedLevel}
			</Text>
			<ProgressBar value={bucket.progressToNextLevel} max={bucket.costOfNextLevel} theme='shrine' height='100%' />
			<Text margin='5px' fontWeight='bold' textShadow='2px 2px 2px black'>
				{bucket.highestUnlockedLevel + 1}
			</Text>
		</Flex>
	);
}
