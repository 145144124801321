import { Box, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import { IoPersonCircle, IoSettingsSharp } from 'react-icons/io5';
import { useSetRecoilState } from 'recoil';
import { profileEditorOpenAtom } from '../../../atoms/profileEditorOpenAtom';
import { socket } from '../../../services/socket.service';
import ChatSettingsPopup from './ChatSettingsPopup';

const ChatFunctions = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const setProfileEditorOpen = useSetRecoilState(profileEditorOpenAtom);

	const getPublicChannels = () => {
		socket.emit('chat:channels:get');
	};

	return (
		<Box className='chat-channels-functions' position='absolute' top='25px' right='25px' userSelect='none'>
			<div className='chat-channel-function' onClick={() => setProfileEditorOpen(true)}>
				<IoPersonCircle />
			</div>
			<div className='chat-channel-function' onClick={onOpen}>
				<IoSettingsSharp />
			</div>
			<ChatSettingsPopup isOpen={isOpen} onClose={onClose} />
			<div className='chat-channel-function' onClick={getPublicChannels}>
				<HiChatBubbleLeftRight />
			</div>
		</Box>
	);
};

export default ChatFunctions;
