import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeChannelAtom } from '../../../atoms/activeChannelAtom';
import { messageAtom } from '../../../atoms/messageAtom';
import { getSocket } from '../../../services/socket.service';
import { produce } from 'immer';
import { IChannelSettings, IChatMessage } from '../../../../../game-server/src/modules/chat/Chat.interface';
import { Box } from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { profileEditorOpenAtom } from '../../../atoms/profileEditorOpenAtom';
import { addToChatInputSelector } from '../../../atoms/chatInputAtom';

export default function ChatMessageTooltip({
	username,
	setIsTooltipEnabled,
	status,
}: {
	username: string;
	setIsTooltipEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	status?: IChatMessage['userStatus'];
}) {
	const activeChannelId = useRecoilValue(activeChannelAtom);
	const setActiveChannel = useSetRecoilState(activeChannelAtom);
	const friendList = usePlayerField('friendList');
	const ownUsername = usePlayerField('username');
	const accountStatus = usePlayerField('accountStatus');
	const isMod = ['chat-mod', 'mod', 'dev', 'lead-mod', 'admin'].includes(accountStatus);
	const isChatMod = accountStatus === 'chat-mod';

	const [messages, setMessages] = useRecoilState(messageAtom);
	const setProfileEditorOpen = useSetRecoilState(profileEditorOpenAtom);
	const addToChatInput = useSetRecoilState(addToChatInputSelector);

	const socket = getSocket();

	const handleInspect = () => {
		if (username === ownUsername) {
			setProfileEditorOpen(true);
			setIsTooltipEnabled(false);
			return;
		}
		const command = '/whois ' + username;

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);

		setIsTooltipEnabled(false);
	};

	const handleInvite = () => {
		socket.emit('group:invite', { username: username });
		setIsTooltipEnabled(false);
	};

	const handleAddFriend = () => {
		socket.emit('social:addFriend', { username: username });
		setIsTooltipEnabled(false);
	};

	const handleRemoveFriend = () => {
		const command = '/unfriend ' + username;

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);

		setIsTooltipEnabled(false);
	};

	const handleBlockUser = () => {
		socket.emit('social:block', { username: username });
		setIsTooltipEnabled(false);
	};

	const handleWhisper = () => {
		const channelName = username;

		setMessages(
			produce((draft) => {
				if (!draft[channelName]) {
					const settings: IChannelSettings = {
						channelName: username,
						channelId: null,
						type: 'settings',
					};

					draft[channelName] = [settings];
				}
			})
		);

		const newChannelIndex = Object.keys(messages).length;

		setActiveChannel(newChannelIndex);
		setIsTooltipEnabled(false);
	};

	const handleDetails = () => {
		const command = '/details ' + username;

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);

		setIsTooltipEnabled(false);
	};

	const handleMute = (e: React.MouseEvent, time: string) => {
		const command = '/mute ' + username + ' ' + time;

		setIsTooltipEnabled(false);
		if (!e.shiftKey) {
			addToChatInput(command + ' ');
			return;
		}

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	};

	const handlePurge = (e: React.MouseEvent, time: string) => {
		const command = '/purge ' + username + ' ' + time;

		setIsTooltipEnabled(false);
		if (!e.shiftKey) {
			addToChatInput(command + ' ');
			return;
		}

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	};

	const handleBan = (e: React.MouseEvent, time: string) => {
		const command = '/ban ' + username + ' ' + time;

		setIsTooltipEnabled(false);
		if (!e.shiftKey) {
			addToChatInput(command + ' ');
			return;
		}

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	};

	const handleShadowMute = (e: React.MouseEvent, time: string) => {
		const command = '/shadowmute ' + username + ' ' + time;

		setIsTooltipEnabled(false);
		if (!e.shiftKey) {
			addToChatInput(command + ' ');
			return;
		}

		const commandToSend = {
			channelId: activeChannelId,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	};

	const handleCopyName = () => {
		navigator.clipboard.writeText(username);
		setIsTooltipEnabled(false);
	};

	function punishmentOption(name: string, handle: (e: React.MouseEvent, time: string) => void) {
		const timeOptions = ['1m', '1h', '1d', '1w', '1mo'];
		const chatModTimeOptions = ['1m', '1h', '1d'];
		return (
			<Box
				className='chat-message-tooltip-options'
				position='relative'
				_after={{
					content: '""',
					position: 'absolute',
					top: '50%',
					left: '90%',
					marginTop: '-5px',
					borderWidth: '5px',
					borderStyle: 'solid',
					borderColor: 'transparent transparent transparent #fff',
				}}
				key={name.toLowerCase()}
			>
				<div className='chat-message-tooltip-action'>{name}</div>
				<Box
					className='chat-message-tooltip-secondary-actions'
					display='none'
					position='absolute'
					top='-5px'
					left='100%'
					gap='5px'
					padding='5px'
					style={{
						borderImageSource: 'url(/images/ui/stone-9slice-dark-gray.png)',
						borderImageSlice: '100 fill',
						borderImageWidth: 'auto',
						borderImageOutset: '0',
						borderImageRepeat: 'round',
					}}
				>
					{(isChatMod ? chatModTimeOptions : timeOptions).map((timeOption: string) => (
						<div
							onClick={(e) => handle(e, timeOption)}
							className='chat-message-tooltip-action'
							key={timeOption}
						>
							{timeOption}
						</div>
					))}
				</Box>
			</Box>
		);
	}

	const options: React.ReactElement[] = [];

	// If clicking on self then only allow inspect
	if (username === ownUsername) {
		options.push(
			<div onClick={handleInspect} className='chat-message-tooltip-action' key='inspect'>
				Inspect
			</div>
		);
	} else {
		options.push(
			<div onClick={handleWhisper} className='chat-message-tooltip-action' key='whisper'>
				Whisper
			</div>
		);
		options.push(
			<div onClick={handleInspect} className='chat-message-tooltip-action' key='inspect'>
				Inspect
			</div>
		);
		options.push(
			<div onClick={handleCopyName} className='chat-message-tooltip-action' key='copy-name'>
				Copy Name
			</div>
		);

		const alreadyInGroup = false;
		if (!alreadyInGroup) {
			options.push(
				<div onClick={handleInvite} className='chat-message-tooltip-action' key='invite'>
					Invite To Group
				</div>
			);
		}

		const isFriend = friendList?.find((friend) => friend.username === username);
		if (!isFriend) {
			options.push(
				<div onClick={handleAddFriend} className='chat-message-tooltip-action' key='add-friend'>
					Add Friend
				</div>
			);
		}

		options.push(
			<div onClick={handleBlockUser} className='chat-message-tooltip-action' key='block'>
				Block
			</div>
		);
		options.push(
			<div className='chat-message-tooltip-action' key='report'>
				Report
			</div>
		);

		if (isFriend) {
			options.push(<div className='decorative-line' key='line-1' />);
			options.push(
				<div onClick={handleRemoveFriend} className='chat-message-tooltip-action' key='remove-friend'>
					Remove Friend
				</div>
			);
		}
		if (isMod) {
			options.push(<div className='decorative-line' key='line-2' />);
			options.push(
				<div onClick={handleDetails} className='chat-message-tooltip-action' key='details'>
					Details
				</div>
			);
			options.push(punishmentOption('Mute', handleMute));
			options.push(punishmentOption('Purge', handlePurge));
			if (!isChatMod) {
				options.push(punishmentOption('Ban', handleBan));
				options.push(punishmentOption('Shadow Mute', handleShadowMute));
			}
		}
	}
	const statusString = status && status !== 'normal' ? status : '';
	const renderStatus = (
		<>
			<div className='decorative-line' key='line-3' />
			{statusString}
		</>
	);
	return (
		<div className='chat-message-tooltip'>
			{username}
			{statusString !== '' ? renderStatus : ''}
			<div className='decorative-line' />
			{options}
		</div>
	);
}
