import { Flex } from '@chakra-ui/react';
import React from 'react';
import { TiPlusOutline, TiTimesOutline } from 'react-icons/ti';
import { IAffixes, TAffixesPaths } from '../../../../../../game-server/src/modules/affixes/affixes.interface';
import Tooltip from '../../../layout/UI/Tooltip';
import { TooltipLiterals } from '../../Tooltips/Data/TooltipLiterals';
import { itemList } from '../../../../utils/itemList';
import { AFFIX_RARITY_OPTIONS } from '../../../../utils/constantsCollection';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { formatBasicStat, formatMultiplierToPercentage } from '../../../../helper/StringFormatter';
import {
	getAffixStrength,
	getApplicableAffixTier,
	getCalculatedAffixStrengths,
} from '../../../../utils/affixFunctions';

export default function AffixTooltip({
	affixData,
	affixPath,
	totalFrequency,
	itemData,
}: {
	affixData: IAffixes;
	affixPath: TAffixesPaths;
	totalFrequency: number;
	itemData: IItemData;
}) {
	const affixTierData = getApplicableAffixTier(affixData, itemData);
	if (!affixTierData) return <></>;

	const name = affixData.name.replace('of ', '').replace('the ', '');
	const { minStrength, maxStrength, reducedEffect } = getCalculatedAffixStrengths(affixData, itemData);
	const minStrengthString = formatAffixStrength(affixData, minStrength);
	const maxStrengthString = formatAffixStrength(affixData, maxStrength);
	const chanceToRoll = (
		(AFFIX_RARITY_OPTIONS[affixData?.rarity ?? 'common'].frequency / totalFrequency) *
		100
	).toFixed(2);
	const reducedCssStyle = reducedEffect ? { color: 'orange' } : {};
	return (
		<Tooltip>
			<div>
				<div>
					<span className={`rarity-${affixData.rarity}`}>{name}</span>
				</div>
				<hr />
				<div style={{ marginBottom: '10px' }}>{TooltipLiterals.affixesLiterals[affixPath]}</div>
				{affixData.longDescription ? (
					<div style={{ marginBottom: '10px' }}>{affixData.longDescription}</div>
				) : null}
				<div>
					Range: {minStrengthString} to {maxStrengthString}
				</div>
				<Flex alignItems='center' color='white'>
					Type: {affixData.type} {affixData.type === 'additive' ? <TiPlusOutline /> : <TiTimesOutline />}
				</Flex>
				{(affixData.slots || affixData.reducedEffectSlots) && <hr />}
				{affixData.slots && <div>Slots: {affixData.slots.join(', ')}</div>}
				{affixData.reducedEffectSlots && (
					<div style={reducedCssStyle}>Reduced effect slots: {affixData.reducedEffectSlots.join(', ')}</div>
				)}
				<hr />
				{totalFrequency !== -1 ? <div>Chance to roll: {chanceToRoll}%</div> : null}
			</div>
		</Tooltip>
	);
}

export function formatAffixStrength(affixData: IAffixes, affixStrength: number) {
	switch (affixData.displayType) {
		case 'number':
			return formatBasicStat(affixStrength);
		case 'percent':
			return formatMultiplierToPercentage(affixStrength);
		case 'multiplier':
			return formatMultiplierToPercentage(affixStrength - 1);
	}
}

export function formatEquipmentAffixStrength(affixData: IAffixes, affixValue: number, itemId: number) {
	const itemData = itemList[itemId];
	const affixStrength = getAffixStrength(affixData, affixValue, itemData);
	switch (affixData.displayType) {
		case 'number':
			return formatBasicStat(affixStrength);
		case 'percent':
			return formatMultiplierToPercentage(affixStrength);
		case 'multiplier':
			return formatMultiplierToPercentage(affixStrength - 1);
	}
}
