// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { IItemData, TItemListClient } from '../../../game-server/src/modules/items/items.interface';
import { abilitiesIds } from '../utils/lookup-dictionaries/lookupAbilityList';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { leaguesIds } from '../utils/lookup-dictionaries/lookupLeagueList';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';
import { questsIds } from '../utils/lookup-dictionaries/lookupQuestList';

export const itemList: TItemListClient = {
	1: {
		id: 1,
		name: 'Gold',
		tradeable: false,
		itemImage: '/images/money_icon.png',
		extraTooltipInfo:
			'Gold is used to purchase items from the market and shops. You can get more gold by selling resources or fighting monsters.',
		class: 'gold',
		tags: [],
	},
	2: {
		id: 2,
		name: 'Heat',
		tradeable: false,
		itemImage: '/images/heat_icon.png',
		extraTooltipInfo:
			'Heat is used to smelt bars and cook food. You can get heat by burning various items such as logs, branches, driftwood and coal.',
		class: 'heat',
		tags: ['heat'],
	},
	3: {
		id: 3,
		name: 'Air Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/air_essence.png',
		extraTooltipInfo: 'Air Essence can be gathered by waiting patiently.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	4: {
		id: 4,
		name: 'Earth Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/earth_essence.png',
		extraTooltipInfo: 'Earth Essence can be gathered while mining.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	5: {
		id: 5,
		name: 'Fire Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/fire_essence.png',
		extraTooltipInfo: 'Fire Essence can be gathered while smithing or cooking.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	6: {
		id: 6,
		name: 'Water Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/water_essence.png',
		extraTooltipInfo: 'Water Essence can be gathered by fishing.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	7: {
		id: 7,
		name: 'Blood Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/blood_essence.png',
		extraTooltipInfo: 'Blood Essence can be gathered by dealing damage.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	8: {
		id: 8,
		name: 'Death Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/death_essence.png',
		extraTooltipInfo: 'Death Essence can be gathered by killing opponents.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	9: {
		id: 9,
		name: 'Chaos Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/chaos_essence.png',
		extraTooltipInfo: 'Chaos Essence can be gathered by attempting to hit an enemy.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	10: {
		id: 10,
		name: 'Nature Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/nature_essence.png',
		extraTooltipInfo: 'Nature Essence can be gathered by foraging and farming.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	11: {
		id: 11,
		name: 'Mind Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/mind_essence.png',
		extraTooltipInfo: 'Mind Essence can be gathered by enchanting and augmenting items or using magic in combat.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	12: {
		id: 12,
		name: 'Cosmic Essence',
		tradeable: false,
		itemImage: '/images/runecrafting/cosmic_essence.png',
		extraTooltipInfo: 'Cosmic Essence can be gathered by enchanting or runecrafting.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
	},
	20: {
		id: 20,
		name: 'Platinum',
		tradeable: false,
		itemImage: '/images/premium/platinum_icon.png',
		extraTooltipInfo:
			'Platinum is used to purchase items from the Platinum Shop and to purchase server-wide Global Buffs.',
		class: 'platinum',
		tags: ['platinum'],
		rarity: 'legendary',
	},
	50: {
		name: 'Book',
		id: 50,
		value: 500,
		tradeable: true,
		heat: 50,
		itemImage: '/images/misc/book.png',
		extraTooltipInfo: 'A dusty tome. Perhaps I should read it?',
		class: 'gem',
		tags: ['book', 'heat'],
	},
	51: {
		name: 'Task',
		id: 51,
		tradeable: false,
		itemImage: '/images/misc/task.png',
		extraTooltipInfo: 'Adventure awaits! HUZZAH!',
		class: 'gem',
		tags: ['book', 'heat'],
	},
	60: {
		id: 60,
		name: 'Raw Shrimp',
		level: 1,
		value: 5,
		tradeable: true,
		experience: 5,
		itemImage: '/images/fishing/raw_shrimp.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking and bait. S h r i m p',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	61: {
		id: 61,
		name: 'Raw Anchovy',
		level: 15,
		value: 15,
		tradeable: true,
		experience: 10,
		itemImage: '/images/fishing/raw_anchovy.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking and bait. In what scape would these look like blue shrimp?',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	62: {
		id: 62,
		name: 'Raw Trout',
		level: 20,
		value: 25,
		tradeable: true,
		experience: 25,
		itemImage: '/images/fishing/raw_trout.png',
		class: 'fish',
		extraTooltipInfo: "An ingredient for cooking and bait. The fisherman's participation award.",
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	63: {
		id: 63,
		name: 'Raw Salmon',
		level: 35,
		value: 50,
		tradeable: true,
		experience: 40,
		itemImage: '/images/fishing/raw_salmon.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking and bait. Not going up any rivers anymore.',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	64: {
		id: 64,
		name: 'Raw Lobster',
		level: 40,
		value: 75,
		tradeable: true,
		experience: 75,
		itemImage: '/images/fishing/raw_lobster.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking. Raw Lobster!',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	65: {
		id: 65,
		name: 'Raw Tuna',
		level: 50,
		value: 100,
		tradeable: true,
		experience: 100,
		itemImage: '/images/fishing/raw_tuna.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking. Tuna Tuna Tuna',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
		rarity: 'uncommon',
	},
	66: {
		id: 66,
		name: 'Raw Shark',
		level: 70,
		value: 125,
		tradeable: true,
		experience: 125,
		itemImage: '/images/fishing/raw_shark.png',
		class: 'fish',
		extraTooltipInfo: "An ingredient for cooking. A big toothy fish. It's a fish, right?",
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
		rarity: 'rare',
	},
	67: {
		id: 67,
		name: 'Raw Megalodon',
		level: 90,
		value: 350,
		tradeable: true,
		experience: 175,
		itemImage: '/images/fishing/raw_megalodon.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking. A monstrously large shark with incredibly tough meat.',
		time: 10000,
		tags: ['cooking', 'fishing'],
		rarity: 'epic',
	},
	68: {
		id: 68,
		name: 'Raw Ancient Lobster',
		level: 40,
		value: 225,
		tradeable: true,
		experience: 150,
		itemImage: '/images/fishing/raw_ancient_lobster.png',
		class: 'fish',
		extraTooltipInfo:
			"An ingredient for cooking. A mean looking and monstrously heavy lobster. It's definitely a lot older than you are.",
		time: 10000,
		tags: ['cooking', 'fishing'],
	},
	69: {
		id: 69,
		name: 'Raw Tentacle Meat',
		level: 40,
		value: 400,
		tradeable: true,
		experience: 200,
		itemImage: '/images/fishing/raw_tentacle_chunk.png',
		class: 'fish',
		extraTooltipInfo:
			'An ingredient for cooking and bait. A massive chunk of ancient rubbery meat torn from some sort of tentacled monster.',
		time: 10000,
		tags: ['cooking', 'fishing'],
	},
	70: {
		id: 70,
		name: 'Raw Huge Tuna',
		level: 70,
		value: 150,
		tradeable: true,
		experience: 150,
		itemImage: '/images/fishing/raw_huge_tuna.png',
		class: 'fish',
		extraTooltipInfo: 'An ingredient for cooking. A huge tuna!',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
		rarity: 'rare',
	},
	71: {
		id: 71,
		name: 'Seaweed',
		tradeable: true,
		value: 25,
		experience: 10,
		itemImage: 'images/fishing/seaweed.png',
		extraTooltipInfo: 'An ingredient for cooking. Smells real bad.',
		class: 'fish',
		tags: ['cooking', 'fishing'],
	},
	72: {
		id: 72,
		name: 'Raw Magnetic Minnow',
		tradeable: true,
		value: 150,
		experience: 50,
		itemImage: 'images/fishing/raw_minnow.png',
		extraTooltipInfo: "An ingredient for cooking. It didn't even bite the hook, it just got stuck to it.",
		class: 'fish',
		tags: ['fish', 'cooking'],
	},
	73: {
		id: 73,
		name: 'Raw Tigerfish',
		tradeable: true,
		value: 100,
		experience: 50,
		itemImage: 'images/fishing/raw_tigerfish.png',
		extraTooltipInfo: 'An ingredient for cooking. Mean looking and strong to boot!',
		class: 'fish',
		tags: ['fish', 'cooking'],
	},
	74: {
		id: 74,
		name: 'Raw Slippery Eel',
		tradeable: true,
		value: 125,
		experience: 75,
		itemImage: 'images/fishing/raw_eel.png',
		extraTooltipInfo: 'An ingredient for cooking. Slippery and oily!',
		class: 'fish',
		tags: ['fish', 'cooking'],
	},
	75: {
		id: 75,
		name: 'Raw Great White Shark',
		tradeable: true,
		value: 150,
		experience: 125,
		itemImage: 'images/fishing/raw_greatwhite.png',
		extraTooltipInfo: 'An ingredient for cooking. A deadly creature to be sure.',
		class: 'fish',
		tags: ['fish', 'cooking', 'fishing'],
	},
	76: {
		id: 76,
		name: 'Raw Hammerhead Shark',
		value: 150,
		tradeable: true,
		experience: 125,
		itemImage: '/images/fishing/raw_hammerhead.png',
		extraTooltipInfo: "An ingredient for cooking. Probably can't actually be used as a hammer.",
		class: 'fish',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	77: {
		id: 77,
		name: 'Raw Crab',
		value: 85,
		tradeable: true,
		experience: 75,
		itemImage: '/images/fishing/raw_crab.png',
		extraTooltipInfo:
			"An ingredient for cooking. In some places it's considered less desirable than lobster, but not here.",
		class: 'fish',
		time: 10000,
		tags: ['fish', 'cooking', 'fishing'],
	},
	95: {
		id: 95,
		name: 'Burnt Fish',
		value: 1,
		tradeable: true,
		itemImage: '/images/cooking/burnt_fish.png',
		class: 'cookedFish',
		extraTooltipInfo: 'The result of an overzealous cook. Perhaps studying this will make you a better cook?',
		tags: ['cooking'],
	},
	96: {
		id: 96,
		name: 'Burnt Food',
		value: 1,
		tradeable: true,
		itemImage: '/images/cooking/burnt_food.png',
		class: 'cookedFish',
		extraTooltipInfo: 'The result of an overzealous cook. Perhaps studying this will make you a better cook?',
		tags: ['cooking'],
	},
	101: {
		id: 101,
		name: 'Copper Ore',
		level: 1,
		value: 5,
		tradeable: true,
		experience: 5,
		time: 5000,
		itemImage: '/images/mining/copper_ore.png',
		extraTooltipInfo: 'Can be smelted into bronze bars with the help of some tin.',
		tags: ['mining', 'smithing'],
		class: 'ore',
	},
	102: {
		id: 102,
		name: 'Tin Ore',
		level: 1,
		value: 5,
		tradeable: true,
		experience: 5,
		time: 5000,
		itemImage: '/images/mining/tin_ore.png',
		extraTooltipInfo: 'Can be smelted into bronze bars with the help of some copper.',
		tags: ['mining', 'smithing'],
		class: 'ore',
	},
	103: {
		id: 103,
		name: 'Iron Ore',
		level: 15,
		value: 20,
		tradeable: true,
		experience: 15,
		time: 15000,
		itemImage: '/images/mining/iron_ore.png',
		extraTooltipInfo: 'Can be smelted into the most basic of basic metal bars, iron.',
		tags: ['mining', 'smithing'],
		class: 'ore',
	},
	104: {
		id: 104,
		name: 'Gold Ore',
		level: 30,
		value: 100,
		tradeable: true,
		experience: 25,
		time: 30000,
		itemImage: '/images/mining/gold_ore.png',
		extraTooltipInfo: 'A large chunk of gold ready to be smelted into a bar.',
		class: 'ore',
		tags: ['mining', 'smithing'],
	},
	105: {
		id: 105,
		name: 'Mithril Ore',
		level: 50,
		value: 75,
		tradeable: true,
		experience: 50,
		time: 45000,
		itemImage: '/images/mining/mithril_ore.png',
		extraTooltipInfo: 'A dark blue tinted and hardy metal that can be smelted into bars.',
		class: 'ore',
		tags: ['mining', 'smithing'],
	},
	106: {
		id: 106,
		name: 'Adamantite Ore',
		level: 60,
		value: 100,
		tradeable: true,
		experience: 75,
		time: 60000,
		itemImage: '/images/mining/adamantite_ore.png',
		extraTooltipInfo: 'A green hued metal most consider nearly worthless that can be smelted into bars.',
		class: 'ore',
		tags: ['mining', 'smithing'],
	},
	107: {
		id: 107,
		name: 'Runite Ore',
		level: 85,
		value: 200,
		tradeable: true,
		experience: 100,
		time: 120000,
		itemImage: '/images/mining/runite_ore.png',
		extraTooltipInfo: 'One of the hardest naturally occuring metals that may be smelted into tough bars.',
		class: 'ore',
		tags: ['mining', 'smithing'],
		rarity: 'uncommon',
	},
	108: {
		id: 108,
		name: 'Clay',
		level: 1,
		value: 1,
		tradeable: true,
		experience: 1,
		time: 5000,
		itemImage: '/images/mining/clay.png',
		extraTooltipInfo:
			'Used in crafting. It may not be what plants crave, but you could probably mix this with sand to make fertilizer.',
		class: 'ore',
		tags: ['mining'],
	},
	109: {
		id: 109,
		name: 'Stone',
		level: 1,
		value: 1,
		tradeable: true,
		experience: 1,
		time: 5000,
		itemImage: '/images/mining/stone.png',
		extraTooltipInfo: 'Used in crafting. A solid foundation for castles... but who would want that?',
		class: 'ore',
		tags: ['mining'],
	},
	110: {
		id: 110,
		name: 'Sand',
		level: 1,
		value: 1,
		tradeable: true,
		experience: 1,
		time: 5000,
		itemImage: '/images/mining/sand.png',
		extraTooltipInfo:
			"Used in crafting and cooking. One can find great nutrients in sand for fertilizer, though eating the sand itself doesn't seem like a good idea...",
		class: 'ore',
		tags: ['mining'],
	},
	111: {
		id: 111,
		name: 'Silver',
		level: 30,
		value: 100,
		tradeable: true,
		experience: 25,
		time: 5000,
		itemImage: '/images/mining/silver.png',
		extraTooltipInfo: 'A great material for channeling magic essence.',
		class: 'ore',
		tags: ['mining', 'enchanting'],
	},
	112: {
		id: 112,
		name: 'Coal',
		level: 1,
		value: 20,
		tradeable: true,
		experience: 10,
		time: 5000,
		heat: 10,
		itemImage: '/images/mining/coal.png',
		extraTooltipInfo:
			"Burn it up to make heat! It's okay, the industrial revolution hasn't happened yet so you're safe from environmental activists.",
		class: 'ore',
		tags: ['mining', 'heat'],
	},
	113: {
		id: 113,
		name: 'Rune Slate',
		level: 1,
		value: 15,
		tradeable: true,
		experience: 10,
		time: 5000,
		itemImage: '/images/runecrafting/rune_slate.png',
		extraTooltipInfo: 'A blank magical canvas.',
		class: 'ore',
		tags: ['mining', 'runecrafting', 'enchanting'],
	},
	114: {
		id: 114,
		name: 'Stygian Ore',
		level: 90,
		value: 500,
		tradeable: true,
		experience: 150,
		time: 5000,
		itemImage: '/images/mining/stygian_ore.png',
		extraTooltipInfo:
			'An unnaturally glittering ore sourced from the deepest of delves. May be smelted into bars with the aid of ichor.',
		class: 'ore',
		tags: ['mining', 'smithing'],
		rarity: 'rare',
	},
	115: {
		id: 115,
		name: 'Void Stone',
		level: 90,
		value: 1500,
		tradeable: true,
		experience: 175,
		time: 5000,
		itemImage: '/images/mining/void_shard.png',
		extraTooltipInfo: 'A stone pulsing with chaotic energy. Perhaps you could further process it?',
		class: 'ore',
		tags: ['mining', 'smithing'],
		rarity: 'epic',
	},
	150: {
		id: 150,
		name: 'Fertilizer',
		value: 15,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/fertilizer.png',
		extraTooltipInfo: 'Stinky sack of nutrients. Increases minimum yield and experience of plants in farming.',
		tags: ['farming', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 40,
			category: 'Farming',
			craftable: true,
			multiplier: 4,
			description: 'Stinky sack of nutrients. Increases minimum yield and experience of plants in farming.',
		},
	},
	151: {
		id: 151,
		name: 'Carrot Seed',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/carrot_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 1,
			image: '/images/farming/carrot_plant.png',
			align: 'bottom',
			experience: 30,
			time: 30,
		},
		extraTooltipInfo: "Plant this on your farm. Won't improve your vision yet.",
	},
	152: {
		id: 152,
		name: 'Peppercorn Seed',
		value: 5,
		experience: 20,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/peppercorn_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 30,
			image: '/images/farming/peppercorn_plant.png',
			align: 'bottom',
			experience: 120,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm. May cause uncontrolled sneezing on harvest.',
	},
	153: {
		id: 153,
		name: 'Sugarcane Seed',
		value: 5,
		experience: 15,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/sugarcane_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 20,
			image: '/images/farming/sugarcane_plant.png',
			align: 'bottom',
			experience: 80,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm for a sweet treat.',
	},
	154: {
		id: 154,
		name: 'Wheat Seed',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/wheat_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 8,
			image: '/images/farming/wheat_plant.png',
			align: 'bottom',
			experience: 50,
			time: 30,
		},
		extraTooltipInfo: "Plant this on your farm. It's honest work.",
	},
	155: {
		id: 155,
		name: 'Potato Seed',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/potato_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 3,
			image: '/images/farming/potato_plant.png',
			align: 'bottom',
			experience: 40,
			time: 30,
		},
		extraTooltipInfo:
			'Plant this on your farm. Adventurers would often use potatos to show others their desire to dungeon delve with one another.',
	},
	156: {
		id: 156,
		name: 'Rice Seed',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/rice_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 25,
			image: '/images/farming/rice_plant.png',
			align: 'bottom',
			experience: 100,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm.',
	},
	157: {
		id: 157,
		name: 'Tomato Seed',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/tomato_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 12,
			image: '/images/farming/tomato_plant.png',
			align: 'bottom',
			experience: 60,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm. Definitely a Vegetable.',
	},
	158: {
		id: 158,
		name: 'Wildberry Bush Seed',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/wildberry_bush_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 2,
			requiredLevel: 5,
			image: '/images/farming/wildberry_plant.png',
			align: 'bottom',
			experience: 200,
			time: 60,
		},
		extraTooltipInfo: 'Plant this on your farm. Careful with the thorns!',
	},
	159: {
		id: 159,
		name: 'Chili Pepper Seed',
		value: 5,
		experience: 30,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/chili_pepper_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 20,
			image: '/images/farming/chili_pepper_plant.png',
			align: 'bottom',
			experience: 90,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm. Even the seeds are hot to the touch.',
	},
	160: {
		id: 160,
		name: 'Pumpkin Seed',
		value: 5,
		experience: 30,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/pumpkin_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 25,
			image: '/images/farming/pumpkin_plant.png',
			align: 'bottom',
			experience: 110,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm. The orange melon.',
	},
	161: {
		id: 161,
		name: 'Mushroom Spore',
		value: 5,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/mushroom_spore.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 15,
			image: '/images/farming/mushroom_plant.png',
			align: 'bottom',
			experience: 70,
			time: 30,
		},
		extraTooltipInfo: 'Plant this on your farm.',
	},
	170: {
		id: 170,
		name: 'Tree Seed',
		value: 5,
		experience: 20,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 10,
			image: '/images/farming/normal_tree_plant.png',
			align: 'bottom',
			experience: 450,
			time: 120,
		},
		extraTooltipInfo: 'Plant this on your farm. Good place to take naps under.',
	},
	171: {
		id: 171,
		name: 'Oak Tree Seed',
		value: 5,
		experience: 40,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/oak_tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 17,
			image: '/images/farming/oak_tree_plant.png',
			align: 'bottom',
			experience: 1250,
			time: 120,
		},
		extraTooltipInfo: 'Plant this on your farm.',
	},
	172: {
		id: 172,
		name: 'Willow Tree Seed',
		value: 5,
		experience: 60,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/willow_tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 20,
			image: '/images/farming/willow_tree_plant.png',
			align: 'bottom',
			experience: 1800,
			time: 120,
		},
		extraTooltipInfo: 'Plant this on your farm.',
	},
	173: {
		id: 173,
		name: 'Maple Tree Seed',
		value: 5,
		experience: 80,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/maple_tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 25,
			image: '/images/farming/maple_tree_plant.png',
			align: 'bottom',
			experience: 2000,
			time: 120,
		},
		extraTooltipInfo:
			"Plant this on your farm. Sometimes sticky sap is found on this tree, but it's safe to ignore.",
	},
	174: {
		id: 174,
		name: 'Yew Tree Seed',
		value: 5,
		experience: 100,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/yew_tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 35,
			image: '/images/farming/yew_tree_plant.png',
			align: 'bottom',
			experience: 2200,
			time: 120,
		},
		extraTooltipInfo: 'Plant this on your farm.',
	},
	175: {
		id: 175,
		name: 'Banana Tree Seed',
		value: 5,
		experience: 25,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/banana_tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 20,
			image: '/images/farming/banana_tree_plant.png',
			align: 'bottom',
			experience: 2250,
			time: 150,
		},
		extraTooltipInfo: 'Plant this on your farm.',
	},
	176: {
		id: 176,
		name: 'Apple Tree Seed',
		value: 5,
		experience: 25,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/apple_tree_seed.png',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 2,
			width: 2,
			requiredLevel: 20,
			image: '/images/farming/apple_tree_plant.png',
			align: 'bottom',
			experience: 2250,
			time: 150,
		},
		extraTooltipInfo: 'Plant this on your farm. Not a good place to take naps under.',
	},
	177: {
		id: 177,
		name: 'Elder Tree Seed',
		value: 100,
		experience: 50,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/elder_tree_seed.png',
		extraTooltipInfo: 'Every type of forest can be traced back to one of these trees.',
		tags: ['seed', 'farming', 'foraging'],
		rarity: 'uncommon',
		farmingStats: {
			height: 4,
			width: 4,
			requiredLevel: 50,
			image: '/images/farming/elder_tree_plant.png',
			align: 'bottom',
			experience: 20000,
			time: 240,
		},
	},
	180: {
		id: 180,
		name: 'Sageberry Bush Seed',
		value: 5,
		experience: 25,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/sageberry_seed.png',
		extraTooltipInfo: 'It may be wise to plant this on your farm.',
		tags: ['seed', 'farming', 'foraging'],
		farmingStats: {
			height: 1,
			width: 2,
			requiredLevel: 25,
			image: '/images/farming/sageberry_bush.png',
			align: 'bottom',
			experience: 450,
			time: 60,
		},
	},
	185: {
		id: 185,
		name: 'Mysterious Seed',
		value: 7,
		experience: 50,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/mysterious_seed.png',
		extraTooltipInfo: 'Life is not necessarily the opposite of corruption. I wonder what this could grow into?',
		tags: ['seed', 'farming', 'craftable'],
		rarity: 'rare',
		farmingStats: {
			height: 1,
			maxHeight: 4,
			width: 1,
			maxWidth: 4,
			requiredLevel: 1,
			image: '/images/farming/mysterious_plant.png',
			align: 'center',
			experience: 600,
			time: 60,
		},
		craftingStats: {
			level: 70,
			experience: 6000,
			category: 'Farming',
			craftable: true,
			multiplier: 16,
			description: 'The blessing of life steeped in the ooze of corruption.',
		},
	},
	186: {
		id: 186,
		name: 'Durian Seed',
		value: 5,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/durian_seed.png',
		extraTooltipInfo:
			'Legend states that these seeds are so fragile, only someone with extraordinary power could remove the seed from the fruit in one piece... It appears to be an award for defeating a powerful enemy, rather than a seed that can be planted.',
		tags: ['farming'],
		rarity: 'epic',
	},
	201: {
		id: 201,
		name: 'Bronze Bar',
		level: 1,
		value: 25,
		tradeable: true,
		experience: 10,
		time: 10000,
		itemImage: '/images/smithing/bronze_bar.png',
		class: 'bar',
		extraTooltipInfo: 'Used as a basic crafting material.',
		tags: ['mining', 'smithing'],
	},
	202: {
		id: 202,
		name: 'Iron Bar',
		level: 10,
		value: 250,
		tradeable: true,
		experience: 45,
		time: 24000,
		itemImage: '/images/smithing/iron_bar.png',
		class: 'bar',
		extraTooltipInfo: 'May be used to craft iron equipment or to augment obsidian gear.',
		tags: ['mining', 'smithing'],
	},
	203: {
		id: 203,
		name: 'Gold Bar',
		level: 20,
		value: 1000,
		tradeable: true,
		experience: 150,
		time: 60000,
		itemImage: '/images/smithing/gold_bar.png',
		class: 'bar',
		extraTooltipInfo:
			"May be used to craft or augment jewelry. Sadly you aren't authorized to create your own currency.",
		tags: ['mining', 'smithing'],
	},
	204: {
		id: 204,
		name: 'Mithril Bar',
		level: 30,
		value: 500,
		tradeable: true,
		experience: 200,
		time: 60000,
		itemImage: '/images/smithing/mithril_bar.png',
		class: 'bar',
		extraTooltipInfo: 'May be used to craft mithril equipment or to augment obsidian gear.',
		tags: ['mining', 'smithing'],
	},
	205: {
		id: 205,
		name: 'Adamantite Bar',
		level: 40,
		value: 2000,
		tradeable: true,
		experience: 600,
		time: 150000,
		itemImage: '/images/smithing/adamantite_bar.png',
		class: 'bar',
		extraTooltipInfo: 'A rather limited, yet hard, metal. May be used to create Adamantite gear.',
		tags: ['mining', 'smithing'],
		rarity: 'uncommon',
	},
	206: {
		id: 206,
		name: 'Runite Bar',
		level: 50,
		value: 7000,
		tradeable: true,
		experience: 840,
		time: 180000,
		itemImage: '/images/smithing/runite_bar.png',
		class: 'bar',
		extraTooltipInfo: 'One of the toughest natural metals. May be used to craft Runite equipment.',
		tags: ['mining', 'smithing'],
		rarity: 'rare',
	},
	207: {
		id: 207,
		name: 'Stygian Bar',
		level: 60,
		value: 9000,
		tradeable: true,
		experience: 1500,
		time: 300000,
		itemImage: '/images/smithing/stygian_bar.png',
		class: 'bar',
		extraTooltipInfo:
			'An unnaturally tough metal that is almost crystalline in nature. May be used to craft Stygian equipment.',
		tags: ['mining', 'smithing'],
		rarity: 'rare',
	},
	208: {
		id: 208,
		name: 'Chaotic Crystal',
		level: 75,
		smithingTier: 6,
		value: 15000,
		tradeable: true,
		experience: 2000,
		time: 400000,
		itemImage: '/images/mining/void_crystal.png',
		class: 'bar',
		extraTooltipInfo:
			'A condensed crystal formed through processing a large amount of void stones. May be used to craft or enhance unique equipment.',
		tags: ['mining', 'smithing'],
		rarity: 'rare',
	},
	209: {
		id: 209,
		name: 'Obsidian Glass',
		value: 3000,
		tradeable: true,
		itemImage: '/images/combat/materials/obsidian_glass.png',
		extraTooltipInfo:
			'A splinter of mildly reflective obsidian glass; can be used to augment obsidian or obsidian-adjacent gear.',
		class: 'bar',
		tags: ['mining'],
	},
	210: {
		id: 210,
		name: 'Golem Marrow',
		value: 300,
		tradeable: true,
		itemImage: '/images/combat/materials/golem_marrow.png',
		extraTooltipInfo: 'A piece of golem marrow, the lifeblood of an artificial construct.',
		class: 'bar',
		tags: ['mining', 'smithing'],
	},
	211: {
		id: 211,
		name: 'Chaos Stone',
		value: 6360000,
		tradeable: true,
		itemImage: '/images/combat/materials/chaos_stone_refined.png',
		extraTooltipInfo:
			'Crafting Material. A shard of chaotic essence that is not only contained, but refined. The process to do this is forever lost to the ages.',
		class: 'bar',
		tags: ['shard'],
		rarity: 'legendary',
	},
	212: {
		id: 212,
		name: 'Living Shard',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/living_stone.png',
		extraTooltipInfo:
			'Crafting Material. A shard of dense material that pulsates with life. Could it be the heart of some undying creature?',
		class: 'bar',
		tags: ['shard'],
		rarity: 'legendary',
	},
	213: {
		id: 213,
		name: 'Runed Stone',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/runed_stone.png',
		extraTooltipInfo:
			'Crafting Material. An impossibly heavy stone tablet bearing an unknown rune that still glows with power.',
		class: 'bar',
		tags: ['shard'],
		rarity: 'epic',
	},
	214: {
		id: 214,
		name: 'Magma Core',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/magma_core.png',
		extraTooltipInfo:
			'Crafting Material. A chunk of stone with an unquenchable fire burning within. What could you do with this?',
		class: 'bar',
		tags: ['shard'],
		rarity: 'epic',
	},
	215: {
		id: 215,
		name: 'Heavy Chaos Shard',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/chaos_stone_unrefined.png',
		extraTooltipInfo:
			'Crafting Material. An unrefined splinter of chaotic energy. Though dulled compared to the chaos it was drawn from, it may still have a potent effect.',
		class: 'bar',
		tags: ['shard'],
		rarity: 'epic',
	},
	216: {
		id: 216,
		name: 'Worn Ancient Splinter',
		value: 6360000,
		tradeable: true,
		itemImage: '/images/combat/materials/ancient_splinter.PNG',
		extraTooltipInfo:
			'Crafting Material. A splinter of some long lost metal; it has more in common with stone than it does metal and would require a master craftsman to work it.',
		class: 'bar',
		tags: ['shard'],
		rarity: 'legendary',
	},
	217: {
		id: 217,
		name: 'Royal Ice Shard',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/ice_shard.PNG',
		extraTooltipInfo:
			'Crafting Material. A somewhat workable shard of frozen stone sourced from the frozen wastes within the Valley of Giants. Appears to be a material commonly used by Ice Giants to form their accessories; perhaps you could make something useful with it?',
		class: 'bar',
		tags: ['shard'],
		rarity: 'epic',
	},
	301: {
		id: 301,
		name: 'Branch',
		level: 1,
		value: 1,
		tradeable: true,
		heat: 1,
		experience: 1,
		time: 2000,
		itemImage: '/images/foraging/branch.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat.',
		tags: ['foraging', 'heat'],
	},
	302: {
		id: 302,
		name: 'Log',
		level: 5,
		value: 3,
		tradeable: true,
		heat: 5,
		experience: 20,
		time: 5000,
		itemImage: '/images/foraging/log.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat.',
		tags: ['foraging', 'heat', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 10,
			category: 'General',
			craftable: true,
			description: 'Combine your branches to craft one log',
		},
	},
	303: {
		id: 303,
		name: 'Oak Log',
		level: 10,
		value: 10,
		tradeable: true,
		heat: 10,
		experience: 40,
		time: 10000,
		itemImage: '/images/foraging/oak_log.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat.',
		tags: ['foraging', 'heat'],
	},
	304: {
		id: 304,
		name: 'Willow Log',
		level: 30,
		value: 20,
		tradeable: true,
		heat: 20,
		experience: 60,
		time: 15000,
		itemImage: '/images/foraging/willow_log.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat.',
		tags: ['foraging', 'heat'],
	},
	305: {
		id: 305,
		name: 'Maple Log',
		level: 45,
		value: 40,
		tradeable: true,
		heat: 70,
		experience: 80,
		time: 25000,
		itemImage: '/images/foraging/maple_log.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat. Still sticky somehow.',
		tags: ['foraging', 'heat'],
	},
	306: {
		id: 306,
		name: 'Yew Log',
		level: 60,
		value: 100,
		tradeable: true,
		heat: 200,
		experience: 100,
		time: 60000,
		itemImage: '/images/foraging/yew_log.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat.',
		tags: ['foraging', 'heat'],
		rarity: 'rare',
	},
	307: {
		id: 307,
		name: 'Elder Log',
		level: 70,
		value: 250,
		tradeable: true,
		heat: 350,
		experience: 150,
		time: 60000,
		itemImage: '/images/foraging/elder_log.png',
		class: 'log',
		extraTooltipInfo: 'Used as a crafting material or turned into heat.',
		tags: ['foraging', 'heat'],
		rarity: 'rare',
	},
	400: {
		id: 400,
		name: 'Sapphire',
		value: 1000,
		tradeable: true,
		itemImage: '/images/mining/sapphire.png',
		class: 'gem',
		extraTooltipInfo: 'An inexpensive blue gem that may be used for crafting.',
		tags: ['mining', 'gem'],
	},
	401: {
		id: 401,
		name: 'Emerald',
		value: 2000,
		tradeable: true,
		itemImage: '/images/mining/emerald.png',
		class: 'gem',
		extraTooltipInfo: 'An inexpensive green gem that may be used for crafting.',
		tags: ['mining', 'gem'],
	},
	402: {
		id: 402,
		name: 'Ruby',
		value: 5000,
		tradeable: true,
		itemImage: '/images/mining/ruby.png',
		class: 'gem',
		extraTooltipInfo: 'An uncommon red gem that may be used for crafting.',
		tags: ['mining', 'gem'],
	},
	403: {
		id: 403,
		name: 'Diamond',
		value: 10000,
		tradeable: true,
		itemImage: '/images/mining/diamond.png',
		class: 'gem',
		extraTooltipInfo: 'An uncommon gem that may be used for crafting.',
		tags: ['mining', 'gem'],
	},
	404: {
		id: 404,
		name: 'Black Opal',
		value: 50000,
		tradeable: true,
		itemImage: '/images/mining/black_opal.png',
		class: 'gem',
		extraTooltipInfo:
			'A rare black gem that may be used for crafting. It is said that you can see the whole universe inside of one of these.',
		tags: ['mining', 'gem'],
		rarity: 'uncommon',
	},
	405: {
		id: 405,
		name: 'Runic Etchant',
		value: 100,
		tradeable: true,
		itemImage: '/images/magic/runic_etchant.png',
		class: 'gem',
		extraTooltipInfo:
			'Shards of sapphire suspended in a fermented seaweed solution. Used by master runecrafters in producing precise cuts.',
		tags: ['gem', 'craftable'],
		craftingStats: {
			level: 5,
			experience: 530,
			category: 'Jewelry',
			craftable: true,
			multiplier: 100,
			description:
				'Shards of sapphire suspended in a fermented seaweed solution. Used by master runecrafters in producing precise cuts.',
		},
	},
	406: {
		id: 406,
		name: 'Corrupted Substrate',
		value: 300,
		tradeable: true,
		itemImage: '/images/magic/corrupted_substrate.png',
		class: 'gem',
		extraTooltipInfo: 'Finely crushed emerald suspended in strong acid, imbued with corrupted magic',
		tags: ['gem', 'craftable'],
		craftingStats: {
			level: 10,
			experience: 1030,
			category: 'Jewelry',
			craftable: true,
			multiplier: 100,
			description: 'Finely crushed emerald suspended in strong acid, imbued with corrupted magic',
		},
	},
	407: {
		id: 407,
		name: 'Reactive Powder',
		value: 600,
		tradeable: true,
		itemImage: '/images/magic/reactive_powder.png',
		class: 'gem',
		extraTooltipInfo: 'A combustible red powder, imbued with magic, used in alchemical crafting.',
		tags: ['gem', 'craftable'],
		craftingStats: {
			level: 20,
			experience: 2030,
			category: 'Jewelry',
			craftable: true,
			multiplier: 100,
			description: 'A combustible red powder, imbued with magic, used in alchemical crafting.',
		},
	},
	408: {
		id: 408,
		name: 'Polishing Paste',
		value: 1000,
		tradeable: true,
		itemImage: '/images/magic/polishing_paste.png',
		class: 'gem',
		extraTooltipInfo: 'Finely ground diamond suspended in oil with highly abrasive properties.',
		tags: ['gem', 'craftable'],
		craftingStats: {
			level: 40,
			experience: 3430,
			category: 'Jewelry',
			craftable: true,
			multiplier: 100,
			description: 'Finely ground diamond suspended in oil with highly abrasive properties.',
		},
	},
	409: {
		id: 409,
		name: 'Sanguine Infusion',
		value: 1000,
		tradeable: true,
		itemImage: '/images/magic/sanguine_infusion.png',
		class: 'gem',
		extraTooltipInfo: 'A highly viscous crimson elixir swirling with the malevolent power of demon blood.',
		tags: ['gem', 'craftable'],
		craftingStats: {
			level: 60,
			experience: 5030,
			category: 'Jewelry',
			craftable: true,
			multiplier: 100,
			description: 'A highly viscous crimson elixir swirling with the malevolent power of demon blood.',
		},
	},
	500: {
		id: 500,
		name: 'Air Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 3,
		itemImage: '/images/runecrafting/air_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to air magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	501: {
		id: 501,
		name: 'Earth Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 4,
		itemImage: '/images/runecrafting/earth_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to earth magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	502: {
		id: 502,
		name: 'Fire Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 5,
		itemImage: '/images/runecrafting/fire_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to fire magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	503: {
		id: 503,
		name: 'Water Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 6,
		itemImage: '/images/runecrafting/water_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to water magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	504: {
		id: 504,
		name: 'Blood Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 7,
		itemImage: '/images/runecrafting/blood_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to blood magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	505: {
		id: 505,
		name: 'Death Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 8,
		itemImage: '/images/runecrafting/death_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to death magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	506: {
		id: 506,
		name: 'Chaos Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 9,
		itemImage: '/images/runecrafting/chaos_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to chaos magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	507: {
		id: 507,
		name: 'Nature Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 10,
		itemImage: '/images/runecrafting/nature_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to nature magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	508: {
		id: 508,
		name: 'Mind Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 11,
		itemImage: '/images/runecrafting/mind_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to mind magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	509: {
		id: 509,
		name: 'Cosmic Talisman',
		tradeable: true,
		value: 20000,
		essenceID: 12,
		itemImage: '/images/runecrafting/cosmic_talisman.png',
		extraTooltipInfo: 'A magical talisman that can be consumed to strengthen your link to cosmic magic.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 105,
		},
	},
	510: {
		name: 'Air Rune',
		id: 510,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/air_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	511: {
		name: 'Earth Rune',
		id: 511,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/earth_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	512: {
		name: 'Fire Rune',
		id: 512,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/fire_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	513: {
		name: 'Water Rune',
		id: 513,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/water_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	514: {
		name: 'Blood Rune',
		id: 514,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/blood_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	515: {
		name: 'Death Rune',
		id: 515,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/death_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	516: {
		name: 'Chaos Rune',
		id: 516,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/chaos_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	517: {
		name: 'Nature Rune',
		id: 517,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/nature_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	518: {
		name: 'Mind Rune',
		activeMessage: 'Crafting mind runes',
		id: 518,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/mind_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	519: {
		name: 'Cosmic Rune',
		id: 519,
		value: 10,
		tradeable: true,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/runecrafting/cosmic_rune.png',
		extraTooltipInfo: 'A slate imbued with magic essence. Often needed to perform magic.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'enchanting'],
	},
	520: {
		id: 520,
		name: 'Unstable Talisman',
		value: 25000,
		itemImage: '/images/runecrafting/unstable_talisman.png',
		extraTooltipInfo:
			'An unstable talisman that is brimming with potential. Requires essence in order to condense it into a normal talisman, though some essence may be lost in the conversion process.',
		class: 'rune',
		tags: ['magic', 'runecrafting'],
		rarity: 'uncommon',
	},
	550: {
		id: 550,
		name: 'Basic Runic Dust',
		itemImage: '/images/runecrafting/dust/runic_dust_common.png',
		value: 500,
		extraTooltipInfo: 'A fine dust that can be used to enhance equipment.',
		class: 'rune',
		rarity: 'common',
		tags: ['runecrafting', 'craftable'],
		tradeable: true,
		craftingStats: {
			level: 1,
			experience: 40,
			category: 'Runecrafting',
			craftable: true,
			multiplier: 1,
		},
	},
	551: {
		id: 551,
		name: 'Unusual Runic Dust',
		itemImage: '/images/runecrafting/dust/runic_dust_uncommon.png',
		value: 1000,
		extraTooltipInfo: 'Unusual quality dust that can be used to enhance equipment.',
		class: 'rune',
		rarity: 'uncommon',
		tags: ['runecrafting', 'craftable'],
		tradeable: true,
		craftingStats: {
			level: 20,
			experience: 200,
			category: 'Runecrafting',
			craftable: true,
			multiplier: 1,
		},
	},
	552: {
		id: 552,
		name: 'Singular Runic Dust',
		itemImage: '/images/runecrafting/dust/runic_dust_rare.png',
		value: 2000,
		extraTooltipInfo: 'Singular quality dust that can be used to enhance equipment.',
		class: 'rune',
		rarity: 'rare',
		tags: ['runecrafting', 'craftable'],
		tradeable: true,
		craftingStats: {
			level: 40,
			experience: 760,
			category: 'Runecrafting',
			craftable: true,
			multiplier: 1,
		},
	},
	553: {
		id: 553,
		name: 'Prime Runic Dust',
		itemImage: '/images/runecrafting/dust/runic_dust_purple.png',
		value: 4000,
		extraTooltipInfo: 'Prime quality dust that can be used to enhance equipment.',
		class: 'rune',
		rarity: 'epic',
		tags: ['runecrafting', 'craftable'],
		tradeable: true,
		craftingStats: {
			level: 60,
			experience: 1800,
			category: 'Runecrafting',
			craftable: true,
			multiplier: 1,
		},
	},
	554: {
		id: 554,
		name: 'Mythical Runic Dust',
		itemImage: '/images/runecrafting/dust/runic_dust_legendary.png',
		value: 8000,
		extraTooltipInfo: 'Mythical quality dust that can be used to enhance equipment.',
		class: 'rune',
		rarity: 'legendary',
		tags: ['runecrafting', 'craftable'],
		tradeable: true,
		craftingStats: {
			level: 80,
			experience: 4000,
			category: 'Runecrafting',
			craftable: true,
			multiplier: 1,
		},
	},
	555: {
		id: 555,
		name: 'Common Gear Scraps',
		itemImage: '/images/runecrafting/dust/gear_scrap_common.png',
		value: 500,
		extraTooltipInfo: 'Scraps from common gear, ready to be repurposed.',
		class: 'rune',
		rarity: 'common',
		tags: ['runecrafting'],
		tradeable: true,
	},
	556: {
		id: 556,
		name: 'Uncommon Gear Scraps',
		itemImage: '/images/runecrafting/dust/gear_scrap_uncommon.png',
		value: 1000,
		extraTooltipInfo: 'Scraps from uncommon gear, ready to be repurposed.',
		class: 'rune',
		rarity: 'uncommon',
		tags: ['runecrafting'],
		tradeable: true,
	},
	557: {
		id: 557,
		name: 'Rare Gear Scraps',
		itemImage: '/images/runecrafting/dust/gear_scrap_rare.png',
		value: 2000,
		extraTooltipInfo: 'Scraps from rare gear, ready to be repurposed.',
		class: 'rune',
		rarity: 'rare',
		tags: ['runecrafting'],
		tradeable: true,
	},
	558: {
		id: 558,
		name: 'Epic Gear Scraps',
		itemImage: '/images/runecrafting/dust/gear_scrap_purple.png',
		value: 4000,
		extraTooltipInfo: 'Scraps from epic gear, ready to be repurposed.',
		class: 'rune',
		rarity: 'epic',
		tags: ['runecrafting'],
		tradeable: true,
	},
	559: {
		id: 559,
		name: 'Legendary Gear Scraps',
		itemImage: '/images/runecrafting/dust/gear_scrap_legendary.png',
		value: 8000,
		extraTooltipInfo: 'Scraps from legendary gear, ready to be repurposed.',
		class: 'rune',
		rarity: 'legendary',
		tags: ['runecrafting'],
		tradeable: true,
	},
	600: {
		id: 600,
		name: 'Bronze Pickaxe',
		value: 1272,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/mining/bronze_pickaxe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 10 to your effective mining level when determining speed.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 10,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 0.5,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 240,
			category: 'Mining',
			craftable: true,
			description: 'Adds 10 to your effective mining level when determining speed.',
		},
	},
	601: {
		id: 601,
		name: 'Iron Pickaxe',
		value: 22920,
		tradeable: true,
		enchantmentTier: 2,
		requiredLevel: {
			mining: 10,
		},
		itemImage: '/images/mining/iron_pickaxe.svg',
		class: 'equipment',
		extraTooltipInfo: 'Adds 20 to your mining level when determining speed.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 20,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 10,
			experience: 1640,
			category: 'Mining',
			craftable: true,
			description: 'Adds 20 to your mining level when determining speed.',
		},
	},
	602: {
		id: 602,
		name: 'Mithril Pickaxe',
		value: 79560,
		tradeable: true,
		enchantmentTier: 3,
		requiredLevel: {
			mining: 30,
		},
		itemImage: '/images/mining/mithril_pickaxe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 30 to your mining level when determining speed.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 30,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1.5,
				},
			],
		},
		craftingStats: {
			level: 30,
			experience: 7900,
			category: 'Mining',
			craftable: true,
			description: 'Adds 30 to your mining level when determining speed.',
		},
	},
	603: {
		id: 603,
		name: 'Adamantite Pickaxe',
		value: 484800,
		tradeable: true,
		enchantmentTier: 4,
		requiredLevel: {
			mining: 40,
		},
		itemImage: '/images/mining/adamantite_pickaxe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 40 to your mining level when determining speed.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 40,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 40,
			experience: 15600,
			category: 'Mining',
			craftable: true,
			description: 'Adds 40 to your mining level when determining speed.',
		},
	},
	604: {
		id: 604,
		name: 'Runite Pickaxe',
		value: 2284200,
		tradeable: true,
		enchantmentTier: 5,
		requiredLevel: {
			mining: 50,
		},
		itemImage: '/images/mining/rune_pickaxe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 50 to your mining level when determining speed.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 50,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 2.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 20800,
			category: 'Mining',
			craftable: true,
			description: 'Adds 50 to your mining level when determining speed.',
		},
	},
	605: {
		id: 605,
		name: 'Stygian Pickaxe',
		value: 3782100,
		tradeable: true,
		enchantmentTier: 6,
		requiredLevel: {
			mining: 60,
		},
		itemImage: '/images/mining/stygian_pickaxe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 60 to your mining level when determining speed.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 60,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 3,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 30000,
			category: 'Mining',
			craftable: true,
			description: 'Adds 60 to your mining level when determining speed.',
		},
	},
	606: {
		id: 606,
		name: 'Dwarven Rocksmasher',
		value: 12960000,
		tradeable: false,
		enchantmentTier: 8,
		requiredLevel: {
			mining: 80,
		},
		itemImage: '/images/mining/dwarven_pickaxe.png',
		class: 'equipment',
		extraTooltipInfo: 'A legendary mining tool using lost dwarven technology.',
		tags: ['mining', 'craftable'],
		equipmentStats: {
			slot: 'pickaxe',
			toolBoost: [
				{
					skill: 'mining',
					boost: 90,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 5,
				},
			],
			itemSet: [10007, 10008],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 160000,
			category: 'Mining',
			craftable: true,
			description: 'A legendary mining tool using lost dwarven technology.',
			unlockQuest: 211,
		},
	},
	610: {
		id: 610,
		name: 'Bronze Hatchet',
		value: 1272,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/foraging/bronze_hatchet.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 10 to your effective foraging level when determining speed.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 10,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 0.5,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 240,
			category: 'Foraging',
			craftable: true,
			description: 'Adds 10 to your effective foraging level when determining speed.',
		},
	},
	611: {
		id: 611,
		name: 'Iron Hatchet',
		value: 22920,
		tradeable: true,
		enchantmentTier: 2,
		requiredLevel: {
			foraging: 10,
		},
		itemImage: '/images/foraging/iron_hatchet.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 20 to your foraging level when determining speed.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 20,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 10,
			experience: 1640,
			category: 'Foraging',
			craftable: true,
			description: 'Adds 20 to your foraging level when determining speed.',
		},
	},
	612: {
		id: 612,
		name: 'Mithril Hatchet',
		value: 79560,
		tradeable: true,
		enchantmentTier: 3,
		requiredLevel: {
			foraging: 30,
		},
		itemImage: '/images/foraging/mithril_hatchet.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 30 to your foraging level when determining speed.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 30,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 1.5,
				},
			],
		},
		craftingStats: {
			level: 30,
			experience: 7900,
			category: 'Foraging',
			craftable: true,
			description: 'Adds 30 to your foraging level when determining speed.',
		},
	},
	613: {
		id: 613,
		name: 'Adamantite Hatchet',
		value: 484800,
		tradeable: true,
		enchantmentTier: 4,
		requiredLevel: {
			foraging: 40,
		},
		itemImage: '/images/foraging/adamantite_hatchet.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 40 to your foraging level when determining speed.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 40,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 40,
			experience: 15600,
			category: 'Foraging',
			craftable: true,
			description: 'Adds 40 to your foraging level when determining speed.',
		},
	},
	614: {
		id: 614,
		name: 'Runite Hatchet',
		value: 2284200,
		tradeable: true,
		enchantmentTier: 5,
		requiredLevel: {
			foraging: 50,
		},
		itemImage: '/images/foraging/rune_hatchet.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 50 to your foraging level when determining speed.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 50,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 2.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 20800,
			category: 'Foraging',
			craftable: true,
			description: 'Adds 50 to your foraging level when determining speed.',
		},
	},
	615: {
		id: 615,
		name: 'Stygian Hatchet',
		value: 3782100,
		tradeable: true,
		enchantmentTier: 6,
		requiredLevel: {
			foraging: 60,
		},
		itemImage: '/images/foraging/stygian_hatchet.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 60 to your foraging level when determining speed.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 60,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 3,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 30000,
			category: 'Foraging',
			craftable: true,
			description: 'Adds 60 to your foraging level when determining speed.',
		},
	},
	616: {
		id: 616,
		name: 'The Grovekeeper',
		value: 9270000,
		tradeable: false,
		enchantmentTier: 8,
		requiredLevel: {
			foraging: 80,
		},
		itemImage: '/images/foraging/grovekeeper.png',
		class: 'equipment',
		extraTooltipInfo: 'A legendary foraging tool. It appears to actually be alive.',
		tags: ['foraging', 'craftable'],
		equipmentStats: {
			slot: 'hatchet',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 90,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 5,
				},
			],
			itemSet: [10005, 10006],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 86000,
			category: 'Foraging',
			craftable: true,
			description: 'A legendary foraging tool. It appears to actually be alive.',
			unlockQuest: 213,
		},
	},
	620: {
		id: 620,
		name: 'Sapphire Ring',
		value: 181200,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/jewellery/sapphire_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'A ring capable of holding weak enchantments.',
		equipmentStats: {
			slot: 'ring',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Ice',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 5,
			experience: 3000,
			category: 'Jewelry',
			craftable: true,
			description: 'A ring capable of holding weak enchantments.',
		},
		tags: ['craftable'],
	},
	621: {
		id: 621,
		name: 'Sapphire Necklace',
		value: 181200,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/jewellery/sapphire_necklace.png',
		class: 'equipment',
		extraTooltipInfo: 'A necklace capable of holding weak enchantments.',
		equipmentStats: {
			slot: 'necklace',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Ice',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 5,
			experience: 3000,
			category: 'Jewelry',
			craftable: true,
			description: 'A necklace capable of holding weak enchantments.',
		},
		tags: ['craftable'],
	},
	622: {
		id: 622,
		name: 'Emerald Ring',
		value: 182400,
		tradeable: true,
		enchantmentTier: 2,
		itemImage: '/images/jewellery/emerald_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'A ring capable of holding moderate enchantments.',
		equipmentStats: {
			slot: 'ring',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Nature',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 10,
			experience: 3500,
			category: 'Jewelry',
			craftable: true,
			description: 'A ring capable of holding moderate enchantments.',
		},
		tags: ['craftable'],
	},
	623: {
		id: 623,
		name: 'Emerald Necklace',
		value: 182400,
		tradeable: true,
		enchantmentTier: 2,
		itemImage: '/images/jewellery/emerald_necklace.png',
		class: 'equipment',
		extraTooltipInfo: 'A necklace capable of holding moderate enchantments.',
		equipmentStats: {
			slot: 'necklace',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Nature',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 10,
			experience: 3500,
			category: 'Jewelry',
			craftable: true,
			description: 'A necklace capable of holding moderate enchantments.',
		},
		tags: ['craftable'],
	},
	624: {
		id: 624,
		name: 'Ruby Ring',
		value: 186000,
		tradeable: true,
		enchantmentTier: 3,
		itemImage: '/images/jewellery/ruby_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'A ring capable of holding decent enchantments.',
		equipmentStats: {
			slot: 'ring',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Fire',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 25,
			experience: 4500,
			category: 'Jewelry',
			craftable: true,
			description: 'A ring capable of holding decent enchantments.',
		},
		tags: ['craftable'],
	},
	625: {
		id: 625,
		name: 'Ruby Necklace',
		value: 186000,
		tradeable: true,
		enchantmentTier: 3,
		itemImage: '/images/jewellery/ruby_necklace.png',
		class: 'equipment',
		extraTooltipInfo: 'A necklace capable of holding decent enchantments.',
		equipmentStats: {
			slot: 'necklace',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Fire',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 25,
			experience: 4500,
			category: 'Jewelry',
			craftable: true,
			description: 'A necklace capable of holding decent enchantments.',
		},
		tags: ['craftable'],
	},
	626: {
		id: 626,
		name: 'Diamond Ring',
		value: 192000,
		tradeable: true,
		enchantmentTier: 4,
		itemImage: '/images/jewellery/diamond_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'A ring capable of holding strong enchantments.',
		equipmentStats: {
			slot: 'ring',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Lightning',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 5500,
			category: 'Jewelry',
			craftable: true,
			description: 'A ring capable of holding strong enchantments.',
		},
		tags: ['craftable'],
	},
	627: {
		id: 627,
		name: 'Diamond Necklace',
		value: 192000,
		tradeable: true,
		enchantmentTier: 4,
		itemImage: '/images/jewellery/diamond_necklace.png',
		class: 'equipment',
		extraTooltipInfo: 'A necklace capable of holding strong enchantments.',
		equipmentStats: {
			slot: 'necklace',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Lightning',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 5500,
			category: 'Jewelry',
			craftable: true,
			description: 'A necklace capable of holding strong enchantments.',
		},
		tags: ['craftable'],
	},
	628: {
		id: 628,
		name: 'Prismatic Necklace',
		value: 92793600,
		tradeable: true,
		enchantmentTier: 6,
		overrideItemTier: 8,
		itemImage: '/images/jewellery/prismatic_necklace.png',
		extraTooltipInfo: 'A necklace capable of holding extraordinary enchantments.',
		class: 'equipment',
		equipmentStats: {
			slot: 'necklace',
			toolBoost: [
				{
					skill: 'farming',
					boost: 0,
				},
				{
					skill: 'smithing',
					boost: 0,
				},
				{
					skill: 'runecrafting',
					boost: 0,
				},
				{
					skill: 'cooking',
					boost: 0,
				},
				{
					skill: 'enchanting',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 2,
				},
				{
					stat: 'toolBoost.farming',
					value: 1,
				},
				{
					stat: 'toolBoost.smithing',
					value: 1.5,
				},
				{
					stat: 'toolBoost.runecrafting',
					value: 0.25,
				},
				{
					stat: 'toolBoost.cooking',
					value: 0.5,
				},
				{
					stat: 'toolBoost.enchanting',
					value: 0.25,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 70,
			experience: 325000,
			category: 'Jewelry',
			craftable: true,
		},
		tags: ['craftable'],
	},
	629: {
		id: 629,
		name: 'Prismatic Ring',
		value: 92793600,
		tradeable: true,
		enchantmentTier: 6,
		overrideItemTier: 8,
		itemImage: '/images/jewellery/prismatic_ring.png',
		extraTooltipInfo: 'A ring capable of holding extraordinary enchantments.',
		class: 'equipment',
		equipmentStats: {
			slot: 'ring',
			toolBoost: [
				{
					skill: 'mining',
					boost: 0,
				},
				{
					skill: 'foraging',
					boost: 0,
				},
				{
					skill: 'fishing',
					boost: 0,
				},
				{
					skill: 'fishingBaitPower',
					boost: 0,
				},
				{
					skill: 'fishingRarityPower',
					boost: 0,
				},
				{
					skill: 'fishingReelPower',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 2,
				},
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 70,
			experience: 325000,
			category: 'Jewelry',
			craftable: true,
			description: 'A ring capable of holding extraordinary enchantments.',
		},
		tags: ['craftable'],
	},
	630: {
		id: 630,
		name: 'Black Opal Ring',
		value: 240000,
		tradeable: true,
		enchantmentTier: 5,
		itemImage: '/images/jewellery/black_opal_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'A ring capable of holding powerful enchantments.',
		equipmentStats: {
			slot: 'ring',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 2,
				},
			],
		},
		scrappingSuccessItem: {
			itemID: 12012,
			chance: 0.1,
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 7500,
			category: 'Jewelry',
			craftable: true,
			description: 'A ring capable of holding powerful enchantments.',
		},
		tags: ['craftable'],
	},
	631: {
		id: 631,
		name: 'Black Opal Necklace',
		value: 240000,
		tradeable: true,
		enchantmentTier: 5,
		itemImage: '/images/jewellery/black_opal_necklace.png',
		class: 'equipment',
		extraTooltipInfo: 'A necklace capable of holding powerful enchantments.',
		equipmentStats: {
			slot: 'necklace',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 2,
				},
			],
		},
		scrappingSuccessItem: {
			itemID: 12012,
			chance: 0.1,
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 7500,
			category: 'Jewelry',
			craftable: true,
			description: 'A necklace capable of holding powerful enchantments.',
		},
		tags: ['craftable'],
	},
	640: {
		id: 640,
		name: 'Gold Ring',
		value: 120000,
		tradeable: true,
		enchantmentTier: 0,
		overrideItemTier: 1,
		itemImage: '/images/jewellery/gold_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'A simple golden ring, ready to hold limitless potential.',
		equipmentStats: {
			slot: 'ring',
		},
		craftingStats: {
			level: 1,
			experience: 5000,
			category: 'Jewelry',
			craftable: true,
			description: 'A simple golden ring, ready to hold limitless potential.',
		},
		tags: ['craftable'],
	},
	641: {
		id: 641,
		name: 'Gold Necklace',
		value: 120000,
		tradeable: true,
		enchantmentTier: 0,
		overrideItemTier: 1,
		itemImage: '/images/jewellery/gold_necklace.png',
		class: 'equipment',
		extraTooltipInfo: 'A simple golden necklace, ready to hold limitless potential.',
		equipmentStats: {
			slot: 'necklace',
		},
		craftingStats: {
			level: 1,
			experience: 5000,
			category: 'Jewelry',
			craftable: true,
			description: 'A simple golden necklace, ready to hold limitless potential.',
		},
		tags: ['craftable'],
	},
	642: {
		id: 642,
		name: 'Corrupted Ring',
		value: 594000,
		tradeable: false,
		enchantmentTier: 3,
		forcedEnchant: 43,
		forcedEnchantAmount: 3,
		requiredLevel: {
			defense: 40,
			attack: 40,
		},
		itemImage: '/images/jewellery/corrupted_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'None are incorruptible. Succumb to the hunger.',
		tags: ['melee', 'craftable'],
		equipmentStats: {
			slot: 'ring',
			weaponBonus: {
				strength: 5,
				intellect: 0,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 55,
			experience: 35000,
			category: 'Jewelry',
			craftable: true,
			description:
				'None are incorruptible. Succumb to the hunger. Once crafted the Corruption enchant will be applied to the ring.',
		},
	},
	643: {
		id: 643,
		name: 'Ancient Nature Amulet',
		value: 200000,
		tradeable: true,
		enchantmentTier: 3,
		enchantmentOverrideSlot: 'combat-talisman',
		forcedEnchant: 44,
		forcedEnchantAmount: 4,
		itemImage: '/images/jewellery/ancient_nature_talisman.png',
		class: 'equipment',
		extraTooltipInfo:
			'An ancient energy keeps you from attempting to absorb the talisman. You feel safe and healthy with this on.',
		tags: ['ability'],
		requiredLevel: {
			defense: 40,
			magic: 40,
		},
		equipmentStats: {
			grantedAbility: [9, 89],
			slot: 'necklace',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 3,
				agility: 0,
				stamina: 0,
			},
			offensiveDamageAffinity: {
				Nature: 1.2,
			},
			defensiveDamageAffinity: {
				Magic: 1.025,
				Nature: 1.15,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
			],
			itemSet: [10019],
		},
		rarity: 'epic',
	},
	644: {
		id: 644,
		name: 'Ancient Fire Amulet',
		value: 200000,
		tradeable: true,
		enchantmentTier: 1,
		enchantmentOverrideSlot: 'combat-talisman',
		forcedEnchant: 45,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/ancient_fire_talisman.png',
		class: 'equipment',
		extraTooltipInfo:
			'An ancient energy keeps you from attempting to absorb the talisman. With this you could wield an aspect of flame.',
		tags: ['melee', 'ability'],
		requiredLevel: {
			defense: 40,
			strength: 40,
			magic: 40,
		},
		equipmentStats: {
			grantedAbility: [7],
			slot: 'necklace',
			weaponBonus: {
				strength: 3,
				intellect: 0,
				dexterity: 0,
			},
			offensiveDamageAffinity: {
				Fire: 1.15,
			},
			defensiveDamageAffinity: {
				Fire: 1.15,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 0.5,
				},
			],
			itemSet: [10036],
		},
		rarity: 'epic',
	},
	645: {
		id: 645,
		name: 'Ancient Water Amulet',
		value: 200000,
		tradeable: true,
		enchantmentTier: 1,
		enchantmentOverrideSlot: 'combat-talisman',
		forcedEnchant: 70,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/ancient_water_talisman.png',
		class: 'equipment',
		requiredLevel: {
			defense: 40,
			magic: 40,
		},
		extraTooltipInfo:
			'An ancient energy keeps you from attempting to absorb the talisman. With this you could wield an aspect of ice.',
		tags: ['magic', 'ability'],
		equipmentStats: {
			grantedAbility: [8],
			slot: 'necklace',
			weaponBonus: {
				strength: 0,
				intellect: 3,
				dexterity: 0,
			},
			offensiveDamageAffinity: {
				Ice: 1.15,
			},
			defensiveDamageAffinity: {
				Ice: 1.15,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
			],
		},
		rarity: 'epic',
	},
	646: {
		id: 646,
		name: 'Ancient Blood Amulet',
		value: 200000,
		tradeable: true,
		enchantmentTier: 1,
		enchantmentOverrideSlot: 'combat-talisman',
		forcedEnchant: 15,
		forcedEnchantAmount: 2,
		itemImage: '/images/jewellery/ancient_blood_talisman.png',
		class: 'equipment',
		extraTooltipInfo:
			'An ancient energy keeps you from attempting to absorb the talisman. With this you bathe in the blood of your enemies. Very edgy.',
		tags: ['melee', 'ability'],
		requiredLevel: {
			defense: 40,
			strength: 40,
		},
		equipmentStats: {
			grantedAbility: [62],
			slot: 'necklace',
			weaponBonus: {
				strength: 3,
				intellect: 0,
				dexterity: 0,
			},
			offensiveDamageAffinity: {
				Slashing: 1.15,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 0.5,
				},
			],
		},
		rarity: 'epic',
	},
	647: {
		id: 647,
		name: 'Ancient Air Amulet',
		value: 200000,
		tradeable: true,
		enchantmentTier: 1,
		enchantmentOverrideSlot: 'combat-talisman',
		itemImage: '/images/jewellery/ancient_air_talisman.png',
		class: 'equipment',
		extraTooltipInfo:
			'An ancient energy keeps you from attempting to absorb the talisman. It is so light it could almost float in the air on its own.',
		tags: ['range', 'ability'],
		requiredLevel: {
			defense: 40,
			range: 40,
		},
		equipmentStats: {
			grantedAbility: [63, 64],
			slot: 'necklace',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 3,
			},
			offensiveDamageAffinity: {
				Range: 1.1,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 0.5,
				},
			],
		},
		rarity: 'epic',
	},
	648: {
		id: 648,
		name: 'Ancient Chaos Amulet',
		value: 200000,
		tradeable: true,
		enchantmentTier: 4,
		forcedEnchant: 102,
		forcedEnchantAmount: 4,
		enchantmentOverrideSlot: 'combat-talisman',
		itemImage: '/images/jewellery/ancient_chaos_talisman.png',
		class: 'equipment',
		extraTooltipInfo:
			'Your instincts refuse to allow you to try and absorb this talisman; you feel as if there is a cost to merely holding it. It shifts in and out of focus if you look too closely.',
		tags: ['melee', 'ability'],
		requiredLevel: {
			defense: 80,
			attack: 80,
		},
		equipmentStats: {
			grantedAbility: [307, 309, 311],
			slot: 'necklace',
			weaponBonus: {
				strength: 3,
				intellect: 3,
				dexterity: 3,
			},
			offensiveDamageAffinity: {
				Chaos: 1.5,
			},
			defensiveDamageAffinity: {
				Chaos: 0.75,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 1,
				},
			],
		},
		rarity: 'epic',
	},
	660: {
		id: 660,
		name: 'Bronze Hoe',
		value: 1272,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/farming/bronze_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 10 to your effective farming level when determining plant yield.',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 10,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 0.5,
				},
			],
		},
		craftingStats: {
			level: 2,
			experience: 240,
			category: 'Farming',
			craftable: true,
			description: 'Adds 10 to your effective farming level when determining plant yield.',
		},
	},
	661: {
		id: 661,
		name: 'Iron Hoe',
		value: 22920,
		tradeable: true,
		enchantmentTier: 2,
		requiredLevel: {
			farming: 10,
		},
		itemImage: '/images/farming/iron_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 20 to your farming level when determining plant yield.',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 20,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 7,
			experience: 1640,
			category: 'Farming',
			craftable: true,
			description: 'Adds 20 to your farming level when determining plant yield.',
		},
	},
	662: {
		id: 662,
		name: 'Mithril Hoe',
		value: 79560,
		tradeable: true,
		enchantmentTier: 3,
		requiredLevel: {
			farming: 30,
		},
		itemImage: '/images/farming/mithril_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 30 to your farming level when determining plant yield.',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 30,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 1.5,
				},
			],
		},
		craftingStats: {
			level: 24,
			experience: 7900,
			category: 'Farming',
			craftable: true,
			description: 'Adds 30 to your farming level when determining plant yield.',
		},
	},
	663: {
		id: 663,
		name: 'Adamantite Hoe',
		value: 484800,
		tradeable: true,
		enchantmentTier: 4,
		requiredLevel: {
			farming: 40,
		},
		itemImage: '/images/farming/adamantite_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 40 to your farming level when determining plant yield.',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 40,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 41,
			experience: 15600,
			category: 'Farming',
			craftable: true,
			description: 'Adds 40 to your farming level when determining plant yield.',
		},
	},
	664: {
		id: 664,
		name: 'Runite Hoe',
		value: 2284200,
		tradeable: true,
		enchantmentTier: 5,
		requiredLevel: {
			farming: 50,
		},
		itemImage: '/images/farming/runite_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 50 to your farming level when determining plant yield.',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 50,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 2.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 51,
			experience: 20800,
			category: 'Farming',
			craftable: true,
			description: 'Adds 50 to your farming level when determining plant yield.',
		},
	},
	665: {
		id: 665,
		name: 'Stygian Hoe',
		value: 3782100,
		tradeable: true,
		enchantmentTier: 6,
		requiredLevel: {
			farming: 60,
		},
		itemImage: '/images/farming/stygian_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 60 to your farming level when determining plant yield.',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 60,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 3,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 61,
			experience: 30000,
			category: 'Farming',
			craftable: true,
			description: 'Adds 60 to your farming level when determining plant yield.',
		},
	},
	666: {
		id: 666,
		name: 'Bronze Tongs',
		value: 1272,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/smithing/Bronze_Tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 10 to your effective smithing level.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 10,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 0.5,
				},
			],
		},
		craftingStats: {
			level: 2,
			experience: 240,
			category: 'Skilling',
			craftable: true,
			description: 'Adds 10 to your effective smithing level.',
		},
	},
	667: {
		id: 667,
		name: 'Iron Tongs',
		value: 22920,
		tradeable: true,
		enchantmentTier: 2,
		requiredLevel: {
			smithing: 10,
		},
		itemImage: '/images/smithing/Iron_Tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 20 to your smithing level.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 20,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 7,
			experience: 1640,
			category: 'Skilling',
			craftable: true,
			description: 'Adds 20 to your smithing level.',
		},
	},
	668: {
		id: 668,
		name: 'Mithril Tongs',
		value: 79560,
		tradeable: true,
		enchantmentTier: 3,
		overrideItemTier: 3,
		requiredLevel: {
			smithing: 20,
		},
		itemImage: '/images/smithing/Mithril_Tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 30 to your smithing level.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 30,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1.5,
				},
			],
		},
		craftingStats: {
			level: 24,
			experience: 7900,
			category: 'Skilling',
			craftable: true,
			description: 'Adds 30 to your smithing level.',
		},
	},
	669: {
		id: 669,
		name: 'Adamantite Tongs',
		value: 484800,
		tradeable: true,
		enchantmentTier: 4,
		overrideItemTier: 4,
		requiredLevel: {
			smithing: 30,
		},
		itemImage: '/images/smithing/Adamantite_Tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 40 to your smithing level.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 40,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 41,
			experience: 15600,
			category: 'Skilling',
			craftable: true,
			description: 'Adds 40 to your smithing level.',
		},
	},
	670: {
		id: 670,
		name: 'Runite Tongs',
		value: 2284200,
		tradeable: true,
		enchantmentTier: 5,
		overrideItemTier: 5,
		requiredLevel: {
			smithing: 40,
		},
		itemImage: '/images/smithing/Runite_Tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 50 to your smithing level.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 50,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 2.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 51,
			experience: 20800,
			category: 'Skilling',
			craftable: true,
			description: 'Adds 50 to your smithing level.',
		},
	},
	671: {
		id: 671,
		name: 'Stygian Tongs',
		value: 3782100,
		tradeable: true,
		enchantmentTier: 6,
		overrideItemTier: 6,
		requiredLevel: {
			smithing: 50,
		},
		itemImage: '/images/smithing/Stygian_Tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'Adds 60 to your smithing level.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 60,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 3,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 61,
			experience: 30000,
			category: 'Skilling',
			craftable: true,
			description: 'Adds 60 to your smithing level.',
		},
	},
	672: {
		id: 672,
		name: 'Lesser Magic Tome',
		value: 180,
		itemImage: '/images/enchanting/Lesser_Magic_Tome_embossed.png',
		class: 'equipment',
		extraTooltipInfo: 'A tome of magic that grants you minor benefits for augmentation.',
		tags: ['enchanting', 'craftable'],
		equipmentStats: {
			slot: 'tome',
			toolBoost: [
				{
					skill: 'enchanting',
					boost: 10,
				},
			],
		},
		craftingStats: {
			level: 15,
			experience: 1500,
			category: 'Skilling',
			craftable: true,
			description: 'A tome of magic that grants you minor benefits for augmentation.',
			unlockQuest: 200,
		},
	},
	673: {
		id: 673,
		name: 'Major Magic Tome',
		value: 600,
		requiredLevel: {
			enchanting: 10,
		},
		itemImage: '/images/enchanting/Major_Magic_Tome.png',
		class: 'equipment',
		extraTooltipInfo: 'A tome of magic that grants you some benefits for augmentation.',
		tags: ['enchanting', 'craftable'],
		equipmentStats: {
			slot: 'tome',
			toolBoost: [
				{
					skill: 'enchanting',
					boost: 20,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 6000,
			category: 'Skilling',
			craftable: true,
			description: 'A tome of magic that grants you some benefits for augmentation.',
			unlockQuest: 201,
		},
	},
	674: {
		id: 674,
		name: 'Greater Magic Tome',
		value: 1200,
		requiredLevel: {
			enchanting: 20,
		},
		itemImage: '/images/enchanting/Greater_Magic_Tome.png',
		class: 'equipment',
		extraTooltipInfo: 'A tome of magic that grants you benefits for augmentation.',
		tags: ['enchanting', 'craftable'],
		equipmentStats: {
			slot: 'tome',
			toolBoost: [
				{
					skill: 'enchanting',
					boost: 30,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 45,
			experience: 15000,
			category: 'Skilling',
			craftable: true,
			description: 'A tome of magic that grants you benefits for augmentation.',
			unlockQuest: 202,
		},
	},
	675: {
		id: 675,
		name: 'Elder Magic Tome',
		value: 2400,
		requiredLevel: {
			enchanting: 30,
		},
		itemImage: '/images/enchanting/Elder_Magic_Tome.png',
		class: 'equipment',
		extraTooltipInfo: 'A tome of magic that grants you strong benefits for augmentation.',
		tags: ['enchanting', 'craftable'],
		equipmentStats: {
			slot: 'tome',
			toolBoost: [
				{
					skill: 'enchanting',
					boost: 40,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 30000,
			category: 'Skilling',
			craftable: true,
			description: 'A tome of magic that grants you strong benefits for augmentation.',
			unlockQuest: 203,
		},
	},
	676: {
		id: 676,
		name: 'Ancient Magic Tome',
		value: 4800,
		requiredLevel: {
			enchanting: 40,
		},
		itemImage: '/images/enchanting/Ancient_Magic_Tome.png',
		class: 'equipment',
		extraTooltipInfo: 'An ancient tome of magic that grants you the strongest benefits for augmentation.',
		tags: ['enchanting', 'craftable'],
		equipmentStats: {
			slot: 'tome',
			toolBoost: [
				{
					skill: 'enchanting',
					boost: 50,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 75,
			experience: 75000,
			category: 'Skilling',
			craftable: true,
			description: 'An ancient tome of magic that grants you the strongest benefits for augmentation.',
			unlockQuest: 204,
		},
	},
	677: {
		id: 677,
		name: 'Apprentice Runic Chisel',
		value: 24000,
		itemImage: '/images/runecrafting/chisel.png',
		class: 'equipment',
		extraTooltipInfo:
			'A basic chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		tags: ['runecrafting', 'craftable'],
		equipmentStats: {
			slot: 'chisel',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 10,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 0.5,
				},
			],
		},
		enchantmentTier: 2,
		requiredLevel: {
			runecrafting: 20,
		},
		tradeable: true,
		craftingStats: {
			level: 15,
			experience: 2500,
			category: 'Runecrafting',
			craftable: true,
			description:
				'A basic chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		},
	},
	678: {
		id: 678,
		name: 'Journeyman Runic Chisel',
		value: 190800,
		itemImage: '/images/runecrafting/chisel_0.png',
		class: 'equipment',
		extraTooltipInfo: 'A chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		tags: ['runecrafting', 'craftable'],
		equipmentStats: {
			slot: 'chisel',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 20,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
			],
		},
		enchantmentTier: 4,
		requiredLevel: {
			runecrafting: 40,
		},
		tradeable: true,
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 55500,
			category: 'Runecrafting',
			craftable: true,
			description: 'A chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		},
	},
	679: {
		id: 679,
		name: 'Master Runic Chisel',
		value: 543600,
		itemImage: '/images/runecrafting/chisel_1.PNG',
		class: 'equipment',
		extraTooltipInfo:
			'An advanced chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		tags: ['runecrafting', 'craftable'],
		equipmentStats: {
			slot: 'chisel',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 30,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 1.5,
				},
			],
		},
		enchantmentTier: 6,
		requiredLevel: {
			runecrafting: 60,
		},
		tradeable: true,
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 141000,
			category: 'Runecrafting',
			craftable: true,
			description:
				'An advanced chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		},
	},
	680: {
		id: 680,
		name: 'Ancient Runic Chisel',
		value: 10194000,
		itemImage: '/images/runecrafting/chisel_2.png',
		class: 'equipment',
		extraTooltipInfo:
			'An ancient chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
		tags: ['runecrafting', 'craftable'],
		equipmentStats: {
			slot: 'chisel',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 50,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 2.5,
				},
			],
			itemSet: [10027, 10028],
		},
		enchantmentTier: 8,
		requiredLevel: {
			runecrafting: 80,
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 385000,
			category: 'Runecrafting',
			craftable: true,
			description:
				'An ancient chisel designed to help you in engraving runes onto slate or drawing magic out of fibers.',
			unlockQuest: 217,
		},
	},
	690: {
		id: 690,
		name: 'Fishing Net',
		value: 100,
		tradeable: true,
		itemImage: '/images/fishing/net.png',
		extraTooltipInfo: 'A rough net assembled from branches. Used as part of tackleboxes and other fishing gear.',
		class: 'fish',
		tags: ['fishing', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 75,
			category: 'Fishing',
			craftable: true,
			description: 'A rough net assembled from branches. Used as part of tackleboxes and other fishing gear.',
		},
	},
	691: {
		id: 691,
		name: 'Fly Fishing Rod',
		value: 200,
		tradeable: true,
		itemImage: '/images/fishing/fishing_rod.png',
		extraTooltipInfo: 'String on a stick. Used as part of tackleboxes and other fishing gear.',
		class: 'fish',
		tags: ['fishing', 'craftable'],
		craftingStats: {
			level: 5,
			experience: 300,
			category: 'Fishing',
			craftable: true,
			description: 'String on a stick. Used as part of tackleboxes and other fishing gear.',
		},
	},
	692: {
		id: 692,
		name: 'Cage',
		value: 500,
		tradeable: true,
		itemImage: '/images/fishing/fishing_cage_2.png',
		extraTooltipInfo: 'An iron cage. Used as part of tackleboxes and other fishing gear.',
		class: 'fish',
		tags: ['fishing', 'craftable'],
		craftingStats: {
			level: 22,
			experience: 1800,
			category: 'Fishing',
			craftable: true,
			description: 'An iron cage. Used as part of tackleboxes and other fishing gear.',
		},
	},
	693: {
		id: 693,
		name: 'Harpoon',
		value: 1000,
		tradeable: true,
		itemImage: '/images/fishing/harpoon.png',
		extraTooltipInfo: 'A barbed spear. Used as part of tackleboxes and other fishing gear.',
		class: 'fish',
		tags: ['fishing', 'craftable'],
		craftingStats: {
			level: 40,
			experience: 5350,
			category: 'Fishing',
			craftable: true,
			description: 'A barbed spear. Used as part of tackleboxes and other fishing gear.',
		},
	},
	700: {
		id: 700,
		name: 'Feather',
		tradeable: true,
		value: 6,
		itemImage: 'images/fishing/feather.png',
		extraTooltipInfo: 'Feather, from a chicken most likely. Useful for making ranged ammunition.',
		class: 'fish',
		tags: [],
	},
	701: {
		id: 701,
		name: 'Fish Oil',
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/fish_oil.png',
		extraTooltipInfo: 'Ew, slimy. Should make logs burn longer or make cooking faster, though!',
		class: 'cookedFish',
		tags: ['cooking'],
	},
	702: {
		id: 702,
		name: 'Pyre Log',
		value: 60,
		tradeable: true,
		heat: 100,
		itemImage: '/images/foraging/pyre.png',
		extraTooltipInfo: 'These can be burned for some heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		craftingStats: {
			level: 5,
			experience: 60,
			category: 'General',
			craftable: true,
			description: 'Pour your fish oil on logs and make them incredibly flammable',
		},
	},
	703: {
		id: 703,
		name: 'Pyre Oak Log',
		value: 200,
		tradeable: true,
		heat: 200,
		itemImage: '/images/foraging/oak_pyre.png',
		extraTooltipInfo: 'These can be burned for moderate heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		craftingStats: {
			level: 10,
			experience: 120,
			category: 'General',
			craftable: true,
			description: 'Pour your fish oil on oak logs and make them incredibly flammable',
		},
	},
	704: {
		id: 704,
		name: 'Pyre Willow Log',
		value: 400,
		tradeable: true,
		heat: 400,
		itemImage: '/images/foraging/willow_pyre.png',
		extraTooltipInfo: 'These can be burned for high heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		craftingStats: {
			level: 20,
			experience: 200,
			category: 'General',
			craftable: true,
			description: 'Pour your fish oil on willow logs and make them incredibly flammable',
		},
	},
	705: {
		id: 705,
		name: 'Pyre Maple Log',
		value: 1100,
		tradeable: true,
		heat: 1100,
		itemImage: '/images/foraging/maple_pyre.png',
		extraTooltipInfo: 'These can be burned for a lot of heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		craftingStats: {
			level: 30,
			experience: 280,
			category: 'General',
			craftable: true,
			description: 'Pour your fish oil on maple logs and make them incredibly flammable',
		},
	},
	706: {
		id: 706,
		name: 'Pyre Yew Log',
		value: 3000,
		tradeable: true,
		heat: 3000,
		itemImage: '/images/foraging/yew_pyre.png',
		extraTooltipInfo: 'These can be burned for massive heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		craftingStats: {
			level: 40,
			experience: 360,
			category: 'General',
			craftable: true,
			description: 'Pour your fish oil on yew logs and make them incredibly flammable',
		},
	},
	707: {
		id: 707,
		name: 'Pyre Elder Log',
		value: 5000,
		tradeable: true,
		heat: 5000,
		itemImage: '/images/foraging/elder_pyre.png',
		extraTooltipInfo: 'These can be burned for massive heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 440,
			category: 'General',
			craftable: true,
			description: 'Pour your fish oil on elder logs and make them incredibly flammable',
		},
	},
	708: {
		id: 708,
		name: 'Inert Heat Stone',
		value: 3000,
		tradeable: true,
		itemImage: '/images/misc/inert_heat_rune.png',
		extraTooltipInfo: 'These can be charged with heat to transfer heat elsewhere.',
		class: 'log',
		tags: ['crafting', 'heat'],
		rarity: 'uncommon',
	},
	709: {
		id: 709,
		name: 'Charged Heat Stone',
		value: 30000,
		tradeable: true,
		itemImage: '/images/misc/imbued_heat_rune.png',
		extraTooltipInfo: 'These can be broken for massive heat.',
		class: 'log',
		tags: ['crafting', 'heat', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 35,
			experience: 150,
			category: 'General',
			craftable: true,
			description: 'Trap heat within the stone to transfer it.',
		},
		canBeOpened: true,
	},
	800: {
		id: 800,
		name: 'Ichor',
		value: 10,
		tradeable: true,
		itemImage: '/images/smithing/ichor.png',
		extraTooltipInfo: 'A strange substance found in the presence of demons.',
		class: 'bar',
		tags: ['mining', 'smithing', 'cooking'],
	},
	801: {
		id: 801,
		name: "Phoenix's Feather",
		value: 500000,
		tradeable: true,
		itemImage: '/images/magic/phoenix_feather.png',
		extraTooltipInfo: 'A white-hot feather taken from a fallen phoenix.',
		class: 'gem',
		tags: ['enchanting'],
		rarity: 'epic',
	},
	900: {
		name: 'Geode',
		id: 900,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/geode.png',
		extraTooltipInfo: 'Perhaps you could crack this open?',
		canBeOpened: true,
		class: 'gem',
		tags: ['mining', 'chest'],
		rarity: 'uncommon',
	},
	901: {
		name: "Bird's Nest",
		id: 901,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/bird_nest.png',
		extraTooltipInfo: 'You see various seeds as well as some strange items inside. Is that a glint of gold?',
		canBeOpened: true,
		class: 'gem',
		tags: ['foraging', 'chest'],
		rarity: 'uncommon',
	},
	902: {
		name: 'Sunken Treasure',
		id: 902,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/sunken_treasure.png',
		extraTooltipInfo: 'Who knows what the sea holds? You could if you opened it.',
		canBeOpened: true,
		class: 'gem',
		tags: ['fishing', 'chest'],
		rarity: 'uncommon',
	},
	903: {
		name: 'Satchel',
		id: 903,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/satchel.png',
		extraTooltipInfo:
			"Strange that a monster had this, perhaps it belonged to an adventurer? Might as well open it, as they don't need it anymore.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'uncommon',
	},
	904: {
		name: 'Ancient Treasure',
		id: 904,
		value: 100000,
		tradeable: true,
		itemImage: '/images/misc/unsunken_treasure.png',
		extraTooltipInfo: "It's said that these might contain ancient tools, open it and see if you are lucky.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest', 'fishing'],
		rarity: 'rare',
	},
	905: {
		name: 'Greater Geode',
		id: 905,
		value: 100000,
		tradeable: true,
		itemImage: '/images/misc/greater_geode.png',
		extraTooltipInfo:
			"Bigger than a normal geode, that's for sure. Wonder why the dwarves were so interested in these... Only one way to find out.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest', 'mining'],
		rarity: 'rare',
	},
	906: {
		name: "Greater Bird's Nest",
		id: 906,
		value: 100000,
		tradeable: true,
		itemImage: '/images/misc/greater_bird_nest.png',
		extraTooltipInfo:
			'What kind of bird would use this nest? Whatever it was, it liked picking up odd items... Open it to see what is inside.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest', 'foraging'],
		rarity: 'rare',
	},
	1000: {
		name: 'Bronze Scimitar',
		id: 1000,
		tradeable: true,
		enchantmentTier: 1,
		value: 1236,
		itemImage: '/images/combat/equipment/bronze_scimitar.png',
		itemIcon: '/images/combat/equipment/bronze_scimitar_icon.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo: 'A simple bronze sword. Not very good, but not bad.',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 4,
				Magic: -14,
				Range: -14,
				Slashing: 5,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 11,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.6,
				},
			],
		},
		craftingStats: {
			level: 2,
			experience: 270,
			category: 'Melee',
			craftable: true,
			description: 'Slash your enemies. Requires Attack level of 1 to wield.',
		},
	},
	1001: {
		name: 'Bronze Battleaxe',
		id: 1001,
		enchantmentTier: 1,
		value: 1872,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_battleaxe.png',
		itemIcon: '/images/combat/equipment/bronze_battleaxe_icon.png',
		class: 'equipment',
		visual: 'battleaxe',
		extraTooltipInfo: "A rather heavy bronze axe. It's easier to just wack enemies with this.",
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 3,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 8,
				Slashing: 5,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 17,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.9,
				},
			],
		},
		craftingStats: {
			level: 2,
			experience: 270,
			category: 'Melee',
			craftable: true,
			description: 'Crush your enemies. Requires Attack level of 1 to wield.',
		},
	},
	1002: {
		name: 'Bronze Daggers',
		id: 1002,
		enchantmentTier: 1,
		value: 618,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/bronze_dagger_1.png',
				css: 'daggers-1',
			},
			{
				image: '/images/combat/equipment/bronze_dagger_2.png',
				css: 'daggers-2',
			},
		],
		extraTooltipInfo: 'Smaller more pointy swords, easy to handle but not very strong. Good for stabbing.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 5,
				Magic: -10,
				Range: -10,
				Piercing: 3,
				Blunt: -8,
				Slashing: 3,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 5,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 2,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.1,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 270,
			category: 'Melee',
			craftable: true,
			description: 'Stab your enemies. Requires Attack level of 1 to wield.',
		},
	},
	1003: {
		name: 'Bronze Greatsword',
		id: 1003,
		enchantmentTier: 1,
		value: 2454,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_2h_sword.png',
		itemIcon: '/images/combat/equipment/bronze_2h_sword_icon.png',
		class: 'equipment',
		visual: 'sword-2h',
		extraTooltipInfo: 'A big heavy bronze sword. At least you can slash someone with this easily.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 5,
				Magic: -20,
				Range: -20,
				Piercing: 7,
				Blunt: 3,
				Slashing: 8,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 21,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.1,
				},
			],
		},
		craftingStats: {
			level: 5,
			experience: 405,
			category: 'Melee',
			craftable: true,
			description: 'Powerfully slash your enemies. Requires Attack level of 1 to wield.',
		},
	},
	1004: {
		name: 'Iron Scimitar',
		id: 1004,
		enchantmentTier: 2,
		value: 22680,
		tradeable: true,
		requiredLevel: {
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_scimitar.png',
		itemIcon: '/images/combat/equipment/iron_scimitar_icon.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo: 'A standard iron sword. Getting better.',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 6,
				Magic: -14,
				Range: -14,
				Slashing: 7,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 13,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.7,
				},
			],
		},
		craftingStats: {
			level: 11,
			experience: 1560,
			category: 'Melee',
			craftable: true,
			description: 'Slash your enemies. Requires Attack level of 10 to wield.',
		},
	},
	1005: {
		name: 'Iron Battleaxe',
		id: 1005,
		enchantmentTier: 2,
		value: 30360,
		tradeable: true,
		requiredLevel: {
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_battleaxe.png',
		itemIcon: '/images/combat/equipment/iron_battleaxe_icon.png',
		class: 'equipment',
		visual: 'battleaxe',
		extraTooltipInfo: "A rather heavy iron axe. It's easier to just wack enemies with this.",
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 5,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 12,
				Slashing: 8,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 20,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 11,
			experience: 1620,
			category: 'Melee',
			craftable: true,
			description: 'Crush your enemies. Requires Attack level of 10 to wield.',
		},
	},
	1006: {
		name: 'Iron Daggers',
		id: 1006,
		enchantmentTier: 2,
		value: 15120,
		tradeable: true,
		requiredLevel: {
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/iron_dagger_1.png',
				css: 'daggers-1',
			},
			{
				image: '/images/combat/equipment/iron_dagger_2.png',
				css: 'daggers-2',
			},
		],
		extraTooltipInfo: 'Smaller more pointy swords, easy to handle but not very strong. Good for stabbing.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 8,
				Magic: -10,
				Range: -10,
				Piercing: 5,
				Blunt: -8,
				Slashing: 5,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 6,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 2,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.1,
				},
			],
		},
		craftingStats: {
			level: 10,
			experience: 1540,
			category: 'Melee',
			craftable: true,
			description: 'Stab your enemies. Requires Attack level of 10 to wield.',
		},
	},
	1007: {
		name: 'Iron Greatsword',
		id: 1007,
		enchantmentTier: 2,
		value: 37800,
		tradeable: true,
		requiredLevel: {
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_2h_sword.png',
		itemIcon: '/images/combat/equipment/iron_2h_sword_icon.png',
		class: 'equipment',
		visual: 'sword-2h',
		extraTooltipInfo: 'A big heavy iron sword. At least you can slash someone with this easily.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 7,
				Magic: -20,
				Range: -20,
				Piercing: 10,
				Blunt: 4,
				Slashing: 12,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 25,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.3,
				},
			],
		},
		craftingStats: {
			level: 14,
			experience: 2100,
			category: 'Melee',
			craftable: true,
			description: 'Powerfully slash your enemies. Requires Attack level of 10 to wield.',
		},
	},
	1008: {
		name: 'Obsidian Scimitar',
		id: 1008,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 92000,
		tradeable: true,
		requiredLevel: {
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_scimitar.png',
		itemIcon: '/images/combat/equipment/black_scimitar_icon.png',
		extraTooltipInfo: 'You feel hidden potential in this blade...',
		class: 'equipment',
		visual: 'scimitar',
		tags: ['melee', 'onehand'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 15,
				Magic: -14,
				Range: -14,
				Slashing: 18,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 22,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.1,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1009: {
		name: 'Obsidian Battleaxe',
		id: 1009,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 116000,
		tradeable: true,
		requiredLevel: {
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_battleaxe.png',
		itemIcon: '/images/combat/equipment/black_battleaxe_icon.png',
		class: 'equipment',
		extraTooltipInfo: "Curiously, you didn't see any knights using a Battleaxe... Where did this come from?",
		visual: 'battleaxe',
		tags: ['melee', 'onehand'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 13,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 30,
				Slashing: 20,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 33,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.7,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.7,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1010: {
		name: 'Obsidian Daggers',
		id: 1010,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 68000,
		tradeable: true,
		requiredLevel: {
			strength: 20,
		},
		extraTooltipInfo: 'You feel hidden potential in these daggers...',
		itemImage: '/images/combat/equipment/black_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/black_dagger_1.png',
				css: 'daggers-1',
			},
			{
				image: '/images/combat/equipment/black_dagger_2.png',
				css: 'daggers-2',
			},
		],
		tags: ['melee', 'twohand'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 20,
				Magic: -10,
				Range: -10,
				Piercing: 13,
				Blunt: -8,
				Slashing: 13,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 11,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 4,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1011: {
		name: 'Obsidian Greatsword',
		id: 1011,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 140000,
		tradeable: true,
		requiredLevel: {
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_2h_sword.png',
		itemIcon: '/images/combat/equipment/black_2h_sword_icon.png',
		extraTooltipInfo: 'You feel hidden potential in this sword... Something great...',
		class: 'equipment',
		visual: 'sword-2h',
		tags: ['melee', 'twohand'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 18,
				Magic: -20,
				Range: -20,
				Piercing: 25,
				Blunt: 10,
				Slashing: 30,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 41,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 0.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.1,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1012: {
		name: 'Mithril Scimitar',
		id: 1012,
		enchantmentTier: 3,
		value: 78600,
		tradeable: true,
		requiredLevel: {
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_scimitar.png',
		itemIcon: '/images/combat/equipment/mithril_scimitar_icon.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo: 'An average mithril sword. Could do some serious damage.',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 26,
				Magic: -14,
				Range: -14,
				Slashing: 31,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 33,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.7,
				},
			],
		},
		craftingStats: {
			level: 31,
			experience: 7900,
			category: 'Melee',
			craftable: true,
			description: 'Slash your enemies. Requires Attack level of 30 to wield.',
		},
	},
	1013: {
		name: 'Mithril Battleaxe',
		id: 1013,
		enchantmentTier: 3,
		value: 97200,
		tradeable: true,
		requiredLevel: {
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_battleaxe.png',
		itemIcon: '/images/combat/equipment/mithril_battleaxe_icon.png',
		class: 'equipment',
		visual: 'battleaxe',
		extraTooltipInfo: "A rather heavy mithril axe. It's easier to just wack enemies with this.",
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 22,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 52,
				Slashing: 35,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 49,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.5,
				},
			],
		},
		craftingStats: {
			level: 31,
			experience: 7900,
			category: 'Melee',
			craftable: true,
			description: 'Crush your enemies. Requires Attack level of 30 to wield.',
		},
	},
	1014: {
		name: 'Mithril Daggers',
		id: 1014,
		enchantmentTier: 3,
		value: 60600,
		tradeable: true,
		requiredLevel: {
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/mithril_dagger_1.png',
				css: 'daggers-1',
			},
			{
				image: '/images/combat/equipment/mithril_dagger_2.png',
				css: 'daggers-2',
			},
		],
		extraTooltipInfo: 'Smaller more pointy swords, easy to handle but not very strong. Good for stabbing.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 35,
				Magic: -10,
				Range: -10,
				Piercing: 22,
				Blunt: -8,
				Slashing: 22,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 16,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 6,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
			],
		},
		craftingStats: {
			level: 30,
			experience: 7900,
			category: 'Melee',
			craftable: true,
			description: 'Stab your enemies. Requires Attack level of 30 to wield.',
		},
	},
	1015: {
		name: 'Mithril Greatsword',
		id: 1015,
		enchantmentTier: 3,
		value: 117960,
		tradeable: true,
		requiredLevel: {
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_2h_sword.png',
		itemIcon: '/images/combat/equipment/mithril_2h_sword_icon.png',
		class: 'equipment',
		visual: 'sword-2h',
		extraTooltipInfo: 'A big heavy mithril sword. At least you can slash someone with this easily.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 31,
				Magic: -20,
				Range: -20,
				Piercing: 44,
				Blunt: 17,
				Slashing: 52,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 60,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
			],
		},
		craftingStats: {
			level: 35,
			experience: 11650,
			category: 'Melee',
			craftable: true,
			description: 'Powerfully slash your enemies. Requires Attack level of 30 to wield.',
		},
	},
	1016: {
		name: 'Adamantite Scimitar',
		id: 1016,
		enchantmentTier: 4,
		value: 481920,
		tradeable: true,
		requiredLevel: {
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_scimitar.png',
		itemIcon: '/images/combat/equipment/adamantite_scimitar_icon.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo: 'A simple adamantite sword. The edge is very sharp.',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 38,
				Magic: -14,
				Range: -14,
				Slashing: 45,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 44,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 41,
			experience: 15600,
			category: 'Melee',
			craftable: true,
			description: 'Slash your enemies. Requires Attack level of 40 to wield.',
		},
	},
	1017: {
		name: 'Adamantite Battleaxe',
		id: 1017,
		enchantmentTier: 4,
		value: 579360,
		tradeable: true,
		requiredLevel: {
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_battleaxe.png',
		itemIcon: '/images/combat/equipment/adamantite_battleaxe_icon.png',
		class: 'equipment',
		visual: 'battleaxe',
		extraTooltipInfo: "A rather heavy adamantite axe. It's easier to just wack enemies with this.",
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 32,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 77,
				Slashing: 51,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 67,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3.4,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 41,
			experience: 15600,
			category: 'Melee',
			craftable: true,
			description: 'Crush your enemies. Requires Attack level of 40 to wield.',
		},
	},
	1018: {
		name: 'Adamantite Daggers',
		id: 1018,
		enchantmentTier: 4,
		value: 385440,
		tradeable: true,
		requiredLevel: {
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/adamantite_dagger_1.png',
				css: 'daggers-1',
			},
			{
				image: '/images/combat/equipment/adamantite_dagger_2.png',
				css: 'daggers-2',
			},
		],
		extraTooltipInfo: 'Smaller more pointy swords, easy to handle but not very strong. Good for stabbing.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 51,
				Magic: -10,
				Range: -10,
				Piercing: 32,
				Blunt: -8,
				Slashing: 32,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 22,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 8,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 40,
			experience: 15600,
			category: 'Melee',
			craftable: true,
			description: 'Stab your enemies. Requires Attack level of 40 to wield.',
		},
	},
	1019: {
		name: 'Adamantite Greatsword',
		id: 1019,
		enchantmentTier: 4,
		value: 674640,
		tradeable: true,
		requiredLevel: {
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_2h_sword.png',
		itemIcon: '/images/combat/equipment/adamantite_2h_sword_icon.png',
		class: 'equipment',
		visual: 'sword-2h',
		extraTooltipInfo: 'A big heavy adamantite sword. At least you can slash someone with this easily.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 45,
				Magic: -20,
				Range: -20,
				Piercing: 64,
				Blunt: 26,
				Slashing: 77,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 82,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 4.2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 45,
			experience: 23100,
			category: 'Melee',
			craftable: true,
			description: 'Powerfully slash your enemies. Requires Attack level of 40 to wield.',
		},
	},
	1020: {
		name: 'Runite Scimitar',
		id: 1020,
		enchantmentTier: 5,
		value: 2274600,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_scimitar.png',
		itemIcon: '/images/combat/equipment/rune_scimitar_icon.png',
		extraTooltipInfo:
			'A decent blade, you feel lightning form whenever you augment this.. before it suddenly vanishes. Strange.',
		class: 'equipment',
		visual: 'scimitar',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 52,
				Magic: -14,
				Range: -14,
				Slashing: 60,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 57,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.9,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 51,
			experience: 20800,
			category: 'Melee',
			craftable: true,
			description: 'Slash your enemies. Requires Attack level of 50 to wield.',
		},
	},
	1021: {
		name: 'Runite Battleaxe',
		id: 1021,
		enchantmentTier: 5,
		value: 2657400,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_battleaxe.png',
		itemIcon: '/images/combat/equipment/rune_battleaxe_icon.png',
		class: 'equipment',
		visual: 'battleaxe',
		extraTooltipInfo: "A rather heavy runite axe. It's easier to just wack enemies with this.",
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 43,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 104,
				Slashing: 69,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 86,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 4.3,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 51,
			experience: 20800,
			category: 'Melee',
			craftable: true,
			description: 'Crush your enemies. Requires Attack level of 50 to wield.',
		},
	},
	1022: {
		name: 'Runite Daggers',
		id: 1022,
		enchantmentTier: 5,
		value: 1895400,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/rune_dagger_1.png',
				css: 'daggers-1',
			},
			{
				image: '/images/combat/equipment/rune_dagger_2.png',
				css: 'daggers-2',
			},
		],
		extraTooltipInfo: 'Smaller more pointy swords, easy to handle but not very strong. Good for stabbing.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 69,
				Magic: -10,
				Range: -10,
				Piercing: 43,
				Blunt: -8,
				Slashing: 43,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 29,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 10,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 20800,
			category: 'Melee',
			craftable: true,
			description: 'Stab your enemies. Requires Attack level of 50 to wield.',
		},
	},
	1023: {
		name: 'Runite Greatsword',
		id: 1023,
		enchantmentTier: 5,
		value: 3032400,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_2h_sword.png',
		itemIcon: '/images/combat/equipment/rune_2h_sword_icon.png',
		class: 'equipment',
		visual: 'sword-2h',
		extraTooltipInfo: 'A big heavy runite sword. At least you can slash someone with this easily.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 60,
				Magic: -20,
				Range: -20,
				Piercing: 86,
				Blunt: 35,
				Slashing: 104,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 106,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 5.4,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 55,
			experience: 30800,
			category: 'Melee',
			craftable: true,
			description: 'Powerfully slash your enemies. Requires Attack level of 50 to wield.',
		},
	},
	1024: {
		name: 'Stygian Scimitar',
		id: 1024,
		enchantmentTier: 6,
		value: 3780840,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_scimitar.png',
		itemIcon: '/images/combat/equipment/stygian_scimitar_icon.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo: "A sword forged with demonic metal, it's razor sharp.",
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 66,
				Magic: -14,
				Range: -14,
				Slashing: 77,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 71,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3.6,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 61,
			experience: 30000,
			category: 'Melee',
			craftable: true,
			description: 'Slash your enemies. Requires Attack level of 60 to wield.',
		},
	},
	1025: {
		name: 'Stygian Battleaxe',
		id: 1025,
		enchantmentTier: 6,
		value: 4321440,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_battleaxe.png',
		itemIcon: '/images/combat/equipment/stygian_battleaxe_icon.png',
		class: 'equipment',
		visual: 'stygian-battleaxe',
		extraTooltipInfo: 'A rather heavy stygian axe. Will you stop using the blunt end already?',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 55,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 132,
				Slashing: 88,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 107,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 5.4,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 61,
			experience: 30000,
			category: 'Melee',
			craftable: true,
			description: 'Crush your enemies. Requires Attack level of 60 to wield.',
		},
	},
	1026: {
		name: 'Stygian Daggers',
		id: 1026,
		enchantmentTier: 6,
		value: 3240720,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/stygian_dagger_1.png',
				css: 'stygian-daggers-1',
			},
			{
				image: '/images/combat/equipment/stygian_dagger_2.png',
				css: 'stygian-daggers-2',
			},
		],
		extraTooltipInfo: 'Pointy daggers infused with demonic energy. Still great at stabbing.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 88,
				Magic: -10,
				Range: -10,
				Piercing: 55,
				Blunt: -8,
				Slashing: 55,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 35,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 12,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 30000,
			category: 'Melee',
			craftable: true,
			description: 'Stab your enemies. Requires Attack level of 60 to wield.',
		},
	},
	1027: {
		name: 'Stygian Greatsword',
		id: 1027,
		enchantmentTier: 6,
		value: 4861080,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_2h_sword.png',
		itemIcon: '/images/combat/equipment/stygian_2h_sword_icon.png',
		class: 'equipment',
		visual: 'stygian-sword-2h',
		extraTooltipInfo: 'A big heavy stygian sword. You could cleave enemies in two with this.',
		tags: ['melee', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 77,
				Magic: -20,
				Range: -20,
				Piercing: 110,
				Blunt: 44,
				Slashing: 132,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 131,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3.4,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 6.6,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 45000,
			category: 'Melee',
			craftable: true,
			description: 'Powerfully slash your enemies. Requires Attack level of 60 to wield.',
		},
	},
	1050: {
		name: 'Bronze Helm',
		id: 1050,
		enchantmentTier: 1,
		value: 600,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/bronze_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/bronze_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 2,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.1,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 100,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1051: {
		name: 'Iron Helm',
		id: 1051,
		enchantmentTier: 2,
		value: 15000,
		tradeable: true,
		requiredLevel: {
			defense: 10,
		},
		itemImage: '/images/combat/equipment/iron_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/iron_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/iron_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 3,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
			],
		},
		craftingStats: {
			level: 10,
			experience: 1000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 10 to wield.',
		},
	},
	1052: {
		name: 'Obsidian Helm',
		id: 1052,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 68000,
		tradeable: true,
		requiredLevel: {
			defense: 20,
		},
		extraTooltipInfo: 'You feel hidden fiery potential in this helm...',
		itemImage: '/images/combat/equipment/black_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/black_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/black_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 8,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1053: {
		name: 'Mithril Helm',
		id: 1053,
		enchantmentTier: 3,
		value: 60000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
		},
		itemImage: '/images/combat/equipment/mithril_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/mithril_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/mithril_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 13,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
			],
		},
		craftingStats: {
			level: 30,
			experience: 7500,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 30 to wield.',
		},
	},
	1054: {
		name: 'Adamantite Helm',
		id: 1054,
		enchantmentTier: 4,
		value: 384000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/adamantite_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/adamantite_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 19,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 40,
			experience: 15000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 40 to wield.',
		},
	},
	1055: {
		name: 'Runite Helm',
		id: 1055,
		enchantmentTier: 5,
		value: 1890000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
		},
		itemImage: '/images/combat/equipment/rune_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/rune_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/rune_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 26,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 20000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 50 to wield.',
		},
	},
	1056: {
		name: 'Stygian Helm',
		id: 1056,
		enchantmentTier: 6,
		value: 3240720,
		tradeable: true,
		requiredLevel: {
			defense: 60,
		},
		itemImage: '/images/combat/equipment/stygian_med_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/stygian_med_helm_1.png',
				css: 'med-helm-1',
			},
			{
				image: '/images/combat/equipment/stygian_med_helm_2.png',
				css: 'med-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.97,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			armorBonus: {
				protection: 33,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.7,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 30000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1060: {
		name: 'Bronze Full Helm',
		id: 1060,
		enchantmentTier: 1,
		value: 1200,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_full_helm.png',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 3,
				resistance: -1,
				agility: -1,
				stamina: 3.75,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.2,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 4,
			experience: 250,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1061: {
		name: 'Iron Full Helm',
		id: 1061,
		enchantmentTier: 2,
		value: 22500,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_full_helm.png',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 5,
				resistance: -1,
				agility: -1,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.2,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 13,
			experience: 1500,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 10 to wield.',
		},
	},
	1062: {
		name: 'Obsidian Full Helm',
		id: 1062,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 89600,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_full_helm.png',
		extraTooltipInfo: 'You feel hidden powerful potential in this full helm...',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 13,
				resistance: -1,
				agility: -1,
				stamina: 5,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.3,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1063: {
		name: 'Mithril Full Helm',
		id: 1063,
		enchantmentTier: 3,
		value: 78000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_full_helm.png',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 22,
				resistance: -1,
				agility: -1,
				stamina: 6,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.1,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 33,
			experience: 9000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 30 to wield.',
		},
	},
	1064: {
		name: 'Adamantite Full Helm',
		id: 1064,
		enchantmentTier: 4,
		value: 480000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_full_helm.png',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 32,
				resistance: -1,
				agility: -1,
				stamina: 7,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.6,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 43,
			experience: 18000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 40 to wield.',
		},
	},
	1065: {
		name: 'Runite Full Helm',
		id: 1065,
		enchantmentTier: 5,
		value: 2268000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_full_helm.png',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 43,
				resistance: -1,
				agility: -1,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 53,
			experience: 24000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 50 to wield.',
		},
	},
	1066: {
		name: 'Stygian Full Helm',
		id: 1066,
		enchantmentTier: 6,
		value: 3780840,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_full_helm.png',
		class: 'equipment',
		visual: 'full-helm',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.1,
				Blunt: 1.05,
				Slashing: 1.1,
			},
			armorBonus: {
				protection: 55,
				resistance: -1,
				agility: -1,
				stamina: 9,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.8,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'rare',
		craftingStats: {
			level: 63,
			experience: 36000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1070: {
		name: 'Bronze Shield',
		id: 1070,
		enchantmentTier: 1,
		value: 1800,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_shield_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/bronze_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/bronze_shield_2.png',
				css: 'shield-2',
			},
		],
		extraTooltipInfo: 'A crude bronze shield. Better then nothing.',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 4,
				resistance: -1,
				agility: -1,
				stamina: 3.75,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.2,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 3,
			experience: 250,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1071: {
		name: 'Iron Shield',
		id: 1071,
		enchantmentTier: 2,
		value: 30000,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_shield_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/iron_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/iron_shield_2.png',
				css: 'shield-2',
			},
		],
		extraTooltipInfo: 'A simple iron shield. The standard for most guards.',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 6,
				resistance: -1,
				agility: -1,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.2,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 12,
			experience: 1500,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 10 to wield.',
		},
	},
	1072: {
		name: 'Obsidian Shield',
		id: 1072,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 116000,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_shield_icon.png',
		extraTooltipInfo: 'You feel hidden potential in this shield...',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/black_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/black_shield_2.png',
				css: 'shield-2',
			},
		],
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 15,
				resistance: -1,
				agility: -1,
				stamina: 5,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.8,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.3,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1073: {
		name: 'Mithril Shield',
		id: 1073,
		enchantmentTier: 3,
		value: 96000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_shield_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/mithril_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/mithril_shield_2.png',
				css: 'shield-2',
			},
		],
		extraTooltipInfo: 'An average mithril shield. You feel safer already.',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 26,
				resistance: -1,
				agility: -1,
				stamina: 6,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 32,
			experience: 9000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 30 to wield.',
		},
	},
	1074: {
		name: 'Adamantite Shield',
		id: 1074,
		enchantmentTier: 4,
		value: 576000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_shield_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/adamantite_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/adamantite_shield_2.png',
				css: 'shield-2',
			},
		],
		extraTooltipInfo: "A strong adamantite shield. It's comfortable as well!",
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 38,
				resistance: -1,
				agility: -1,
				stamina: 7,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.9,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 42,
			experience: 18000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 40 to wield.',
		},
	},
	1075: {
		name: 'Runite Shield',
		id: 1075,
		enchantmentTier: 5,
		value: 2646000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_shield_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/rune_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/rune_shield_2.png',
				css: 'shield-2',
			},
		],
		extraTooltipInfo: 'A professionally made runite shield. Hardly ever dents or bends.',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 52,
				resistance: -1,
				agility: -1,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.6,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 52,
			experience: 24000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 50 to wield.',
		},
	},
	1076: {
		name: 'Stygian Shield',
		id: 1076,
		enchantmentTier: 6,
		value: 4320960,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_shield_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/stygian_shield_1.png',
				css: 'shield-1',
			},
			{
				image: '/images/combat/equipment/stygian_shield_2.png',
				css: 'shield-2',
			},
		],
		extraTooltipInfo: 'A mastercrafted stygian shield. The demonic powers seems to repair the shield.',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 1.15,
				Range: 1.15,
				Piercing: 1.13,
				Blunt: 1.33,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 66,
				resistance: -1,
				agility: -1,
				stamina: 9,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'rare',
		craftingStats: {
			level: 62,
			experience: 36000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1077: {
		name: 'Castle Door',
		id: 1077,
		enchantmentTier: 7,
		value: 4840000,
		tradeable: true,
		requiredLevel: {
			defense: 70,
			strength: 70,
		},
		itemImage: '/images/combat/equipment/door_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/door_1.png',
				css: 'door-1',
			},
			{
				image: '/images/combat/equipment/door_2.png',
				css: 'door-2',
			},
		],
		tags: ['armor', 'melee'],
		extraTooltipInfo:
			"A portion of a castle's door that can be used as a shield. Incredibly heavy, but very protective!",
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.33,
				damageMultiplier: 0.5,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 0.75,
				Range: 0.75,
			},
			defensiveDamageAffinity: {
				Melee: 1.5,
				Magic: 1.5,
				Range: 1.5,
				Piercing: 1.33,
				Blunt: 1.33,
				Slashing: 1.33,
			},
			armorBonus: {
				protection: 102,
				resistance: 64,
				agility: -40,
				stamina: 24,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 5.2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 3.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 1.3,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'epic',
	},
	1090: {
		name: 'Bronze Breastplate',
		id: 1090,
		enchantmentTier: 1,
		value: 3000,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 9,
				resistance: -6,
				agility: -6,
				stamina: 7.75,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		craftingStats: {
			level: 6,
			experience: 500,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1091: {
		name: 'Bronze Chainmail',
		id: 1091,
		enchantmentTier: 1,
		value: 1800,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_chainbody.png',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 5,
				resistance: -3,
				agility: 3,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.3,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
			],
		},
		craftingStats: {
			level: 4,
			experience: 300,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1092: {
		name: 'Iron Breastplate',
		id: 1092,
		enchantmentTier: 2,
		value: 45000,
		tradeable: true,
		requiredLevel: {
			defense: 10,
		},
		itemImage: '/images/combat/equipment/iron_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 13,
				resistance: -6,
				agility: -6,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		craftingStats: {
			level: 15,
			experience: 3000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 10 to wield.',
		},
	},
	1093: {
		name: 'Iron Chainmail',
		id: 1093,
		enchantmentTier: 2,
		value: 30000,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_chainbody.png',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 8,
				resistance: -3,
				agility: 4,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
			],
		},
		craftingStats: {
			level: 13,
			experience: 2000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 10 to wield.',
		},
	},
	1094: {
		name: 'Obsidian Breastplate',
		id: 1094,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 164000,
		tradeable: true,
		requiredLevel: {
			defense: 20,
		},
		itemImage: '/images/combat/equipment/black_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 33,
				resistance: -6,
				agility: -6,
				stamina: 9,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.7,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1095: {
		name: 'Obsidian Chainmail',
		id: 1095,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 116000,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_chainbody.png',
		extraTooltipInfo: 'You feel hidden potential in the chainmail...',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 20,
				resistance: -3,
				agility: 8,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1096: {
		name: 'Mithril Breastplate',
		id: 1096,
		enchantmentTier: 3,
		value: 138000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
		},
		itemImage: '/images/combat/equipment/mithril_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 57,
				resistance: -6,
				agility: -6,
				stamina: 10,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.9,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		craftingStats: {
			level: 35,
			experience: 15000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 30 to wield.',
		},
	},
	1097: {
		name: 'Mithril Chainmail',
		id: 1097,
		enchantmentTier: 3,
		value: 96000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_chainbody.png',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 35,
				resistance: -3,
				agility: 12,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.8,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
			],
		},
		craftingStats: {
			level: 33,
			experience: 11250,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 30 to wield.',
		},
	},
	1098: {
		name: 'Adamantite Breastplate',
		id: 1098,
		enchantmentTier: 4,
		value: 768000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 83,
				resistance: -6,
				agility: -6,
				stamina: 11,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.6,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 45,
			experience: 45000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 40 to wield.',
		},
	},
	1099: {
		name: 'Adamantite Chainmail',
		id: 1099,
		enchantmentTier: 4,
		value: 576000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_chainbody.png',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 51,
				resistance: -3,
				agility: 16,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.6,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.8,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 43,
			experience: 30000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 40 to wield.',
		},
	},
	1100: {
		name: 'Runite Breastplate',
		id: 1100,
		enchantmentTier: 5,
		value: 3402000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
		},
		itemImage: '/images/combat/equipment/rune_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 112,
				resistance: -6,
				agility: -6,
				stamina: 12,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 5.7,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.7,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 55,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 50 to wield.',
		},
	},
	1101: {
		name: 'Runite Chainmail',
		id: 1101,
		enchantmentTier: 5,
		value: 2646000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_chainbody.png',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 69,
				resistance: -3,
				agility: 20,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 1,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 53,
			experience: 40000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 50 to wield.',
		},
	},
	1102: {
		name: 'Stygian Breastplate',
		id: 1102,
		enchantmentTier: 6,
		value: 5401200,
		tradeable: true,
		requiredLevel: {
			defense: 60,
		},
		itemImage: '/images/combat/equipment/stygian_platebody.png',
		class: 'equipment',
		visual: 'platebody',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.6,
				Magic: 0.2,
				Range: 0.2,
				Piercing: 0.6,
				Blunt: 0.6,
				Slashing: 0.6,
			},
			defensiveDamageAffinity: {
				Melee: 1.75,
				Magic: 1.6,
				Range: 1.75,
				Piercing: 1.13,
				Blunt: 0.94,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 143,
				resistance: -6,
				agility: -6,
				stamina: 13,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 7.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.7,
				},
			],
			itemSet: [10009, 10016],
			grantedAbility: [54, 65],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 90000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1103: {
		name: 'Stygian Chainmail',
		id: 1103,
		enchantmentTier: 6,
		value: 4320960,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_chainbody.png',
		class: 'equipment',
		visual: 'chainbody',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.95,
				Range: 1.05,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 88,
				resistance: -3,
				agility: 24,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.4,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.3,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 63,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1110: {
		name: 'Bronze Plate Leggings',
		id: 1110,
		enchantmentTier: 1,
		value: 1200,
		tradeable: true,
		itemImage: '/images/combat/equipment/bronze_platelegs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 5,
				resistance: -4,
				agility: -4,
				stamina: 4.75,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.3,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 3,
			experience: 200,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1111: {
		name: 'Iron Plate Leggings',
		id: 1111,
		enchantmentTier: 2,
		value: 22500,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			strength: 10,
		},
		itemImage: '/images/combat/equipment/iron_platelegs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 7,
				resistance: -4,
				agility: -4,
				stamina: 5,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.3,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 12,
			experience: 1500,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 10 to wield.',
		},
	},
	1112: {
		name: 'Obsidian Plate Leggings',
		id: 1112,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 92000,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			strength: 20,
		},
		itemImage: '/images/combat/equipment/black_platelegs.png',
		extraTooltipInfo: 'You feel hidden potential in these legs... The armor, not your legs.',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 18,
				resistance: -4,
				agility: -4,
				stamina: 6,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1113: {
		name: 'Mithril Plate Leggings',
		id: 1113,
		enchantmentTier: 3,
		value: 78000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			strength: 30,
		},
		itemImage: '/images/combat/equipment/mithril_platelegs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 31,
				resistance: -4,
				agility: -4,
				stamina: 7,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.6,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		craftingStats: {
			level: 32,
			experience: 9750,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 30 to wield.',
		},
	},
	1114: {
		name: 'Adamantite Plate Leggings',
		id: 1114,
		enchantmentTier: 4,
		value: 480000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			strength: 40,
		},
		itemImage: '/images/combat/equipment/adamantite_platelegs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 45,
				resistance: -4,
				agility: -4,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 42,
			experience: 30000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 40 to wield.',
		},
	},
	1115: {
		name: 'Runite Plate Leggings',
		id: 1115,
		enchantmentTier: 5,
		value: 2268000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			strength: 50,
		},
		itemImage: '/images/combat/equipment/rune_platelegs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 60,
				resistance: -4,
				agility: -4,
				stamina: 9,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 52,
			experience: 40000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 50 to wield.',
		},
	},
	1116: {
		name: 'Stygian Plate Leggings',
		id: 1116,
		enchantmentTier: 6,
		value: 3780840,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			strength: 60,
		},
		itemImage: '/images/combat/equipment/stygian_platelegs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Magic: 0.97,
				Range: 0.98,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 0.94,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 77,
				resistance: -4,
				agility: -4,
				stamina: 10,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3.9,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'rare',
		craftingStats: {
			level: 62,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1130: {
		name: 'Bronze Boots',
		id: 1130,
		enchantmentTier: 1,
		value: 1000,
		tradeable: false,
		itemImage: '/images/combat/equipment/bronze_boots.png',
		itemIcon: '/images/combat/equipment/bronze_boots_icon.png',
		extraTooltipInfo: 'These boots cannot be traded.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 6,
				resistance: 6,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.4,
				},
			],
		},
	},
	1131: {
		name: 'Iron Boots',
		id: 1131,
		enchantmentTier: 2,
		value: 8000,
		tradeable: false,
		itemImage: '/images/combat/equipment/iron_boots.png',
		itemIcon: '/images/combat/equipment/iron_boots_icon.png',
		extraTooltipInfo: 'These boots cannot be traded.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 8,
				resistance: 8,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.4,
				},
			],
		},
	},
	1132: {
		name: 'Obsidian Boots',
		id: 1132,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 68000,
		tradeable: true,
		itemImage: '/images/combat/equipment/black_boots.png',
		itemIcon: '/images/combat/equipment/black_boots_icon.png',
		extraTooltipInfo: 'You feel hidden potential in these boots...',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 10,
				resistance: 10,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.5,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1133: {
		name: 'Mithril Boots',
		id: 1133,
		enchantmentTier: 3,
		value: 60000,
		tradeable: false,
		itemImage: '/images/combat/equipment/mithril_boots.png',
		itemIcon: '/images/combat/equipment/mithril_boots_icon.png',
		extraTooltipInfo: 'These boots cannot be traded.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 12,
				resistance: 12,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.7,
				},
			],
		},
	},
	1134: {
		name: 'Adamantite Boots',
		id: 1134,
		enchantmentTier: 4,
		value: 480000,
		tradeable: false,
		itemImage: '/images/combat/equipment/adamantite_boots.png',
		itemIcon: '/images/combat/equipment/adamantite_boots_icon.png',
		extraTooltipInfo: 'These boots cannot be traded.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 14,
				resistance: 14,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.8,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.8,
				},
			],
		},
		rarity: 'uncommon',
	},
	1135: {
		name: 'Runite Boots',
		id: 1135,
		enchantmentTier: 5,
		value: 1800000,
		tradeable: false,
		itemImage: '/images/combat/equipment/rune_boots.png',
		itemIcon: '/images/combat/equipment/rune_boots_icon.png',
		extraTooltipInfo: 'These boots cannot be traded.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 16,
				resistance: 16,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.8,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.8,
				},
			],
		},
		rarity: 'uncommon',
	},
	1136: {
		name: 'Stygian Boots',
		id: 1136,
		enchantmentTier: 6,
		value: 2880000,
		tradeable: false,
		itemImage: '/images/combat/equipment/stygian_boots.png',
		itemIcon: '/images/combat/equipment/stygian_boots_icon.png',
		extraTooltipInfo: 'These boots cannot be traded.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor'],
		equipmentStats: {
			slot: 'boots',
			armorBonus: {
				protection: 18,
				resistance: 18,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.9,
				},
			],
		},
		rarity: 'rare',
	},
	1150: {
		name: 'Bronze Gloves',
		id: 1150,
		enchantmentTier: 1,
		value: 20,
		tradeable: false,
		itemImage: '/images/combat/equipment/bronze_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/bronze_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/bronze_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/bronze_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 2,
				intellect: 2,
				dexterity: 2,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 2,
				Magic: 2,
				Range: 2,
			},
			defensiveDamageAffinity: {
				Melee: 1.01,
				Magic: 1.01,
				Range: 1.01,
			},
			armorBonus: {
				protection: 4,
				resistance: 4,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.1,
				},
			],
		},
	},
	1151: {
		name: 'Iron Gloves',
		id: 1151,
		enchantmentTier: 2,
		value: 600,
		tradeable: false,
		itemImage: '/images/combat/equipment/iron_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/iron_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/iron_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/iron_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 3,
				intellect: 3,
				dexterity: 3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 3,
				Magic: 3,
				Range: 3,
			},
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			armorBonus: {
				protection: 6,
				resistance: 6,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.2,
				},
			],
		},
	},
	1152: {
		name: 'Obsidian Gloves',
		id: 1152,
		overrideItemTier: 3,
		enchantmentTier: 5,
		value: 8000,
		tradeable: false,
		itemImage: '/images/combat/equipment/black_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/black_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/black_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/black_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 5,
				intellect: 5,
				dexterity: 5,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 5,
				Magic: 5,
				Range: 5,
			},
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			armorBonus: {
				protection: 10,
				resistance: 10,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.3,
				},
			],
		},
		rarity: 'uncommon',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		scrappingFailItem: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1153: {
		name: 'Mithril Gloves',
		id: 1153,
		enchantmentTier: 3,
		value: 50000,
		tradeable: false,
		itemImage: '/images/combat/equipment/mithril_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/mithril_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/mithril_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/mithril_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 6,
				intellect: 6,
				dexterity: 6,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 6,
				Magic: 6,
				Range: 6,
			},
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			armorBonus: {
				protection: 12,
				resistance: 12,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.7,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.4,
				},
			],
		},
	},
	1154: {
		name: 'Adamantite Gloves',
		id: 1154,
		enchantmentTier: 4,
		value: 300000,
		tradeable: false,
		itemImage: '/images/combat/equipment/adamantite_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/adamantite_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/adamantite_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/adamantite_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 7,
				intellect: 7,
				dexterity: 7,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 7,
				Magic: 7,
				Range: 7,
			},
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			armorBonus: {
				protection: 14,
				resistance: 14,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.8,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.4,
				},
			],
		},
		rarity: 'uncommon',
	},
	1155: {
		name: 'Runite Gloves',
		id: 1155,
		enchantmentTier: 5,
		value: 1500000,
		tradeable: false,
		itemImage: '/images/combat/equipment/rune_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/rune_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/rune_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/rune_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 8,
				intellect: 8,
				dexterity: 8,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 8,
				Magic: 8,
				Range: 8,
			},
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			armorBonus: {
				protection: 16,
				resistance: 16,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.8,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.4,
				},
			],
		},
		rarity: 'uncommon',
	},
	1156: {
		name: 'Stygian Gloves',
		id: 1156,
		enchantmentTier: 6,
		value: 5000000,
		itemImage: '/images/combat/equipment/stygian_gloves.png',
		extraTooltipInfo: 'Gloves cannot be traded.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/stygian_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/stygian_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/stygian_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 9,
				intellect: 9,
				dexterity: 9,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 9,
				Magic: 9,
				Range: 9,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
			},
			armorBonus: {
				protection: 18,
				resistance: 18,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.5,
				},
			],
		},
		rarity: 'rare',
	},
	1157: {
		name: 'Hide Body',
		id: 1157,
		enchantmentTier: 1,
		value: 4800,
		tradeable: true,
		itemImage: '/images/combat/equipment/range/hide_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 3,
				resistance: 1,
				agility: 3,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.1,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 3,
			experience: 180,
			category: 'Range',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1158: {
		name: 'Fur Body',
		id: 1158,
		enchantmentTier: 1,
		value: 14400,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			range: 10,
		},
		itemImage: '/images/combat/equipment/range/fur_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 5,
				resistance: 2,
				agility: 4,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.3,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.1,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 14,
			experience: 500,
			category: 'Range',
			craftable: true,
		},
	},
	1159: {
		name: 'Thick Hide Body',
		id: 1159,
		enchantmentTier: 2,
		value: 22500,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			range: 20,
		},
		itemImage: '/images/combat/equipment/range/fur_body.png',
		itemIcon: '/images/combat/equipment/range/thick_hide_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 13,
				resistance: 5,
				agility: 9,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.2,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 24,
			experience: 750,
			category: 'Range',
			craftable: true,
		},
	},
	1160: {
		name: 'Leather Body',
		id: 1160,
		enchantmentTier: 3,
		value: 64800,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			range: 30,
		},
		itemImage: '/images/combat/equipment/range/fur_body.png',
		itemIcon: '/images/combat/equipment/range/leather_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 22,
				resistance: 8,
				agility: 14,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.1,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.3,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 34,
			experience: 1500,
			category: 'Range',
			craftable: true,
		},
	},
	1161: {
		name: 'Reinforced Leather Body',
		id: 1161,
		enchantmentTier: 4,
		value: 89910,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			range: 40,
		},
		itemImage: '/images/combat/equipment/range/fur_body.png',
		itemIcon: '/images/combat/equipment/range/reinforced_leather_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 32,
				resistance: 11,
				agility: 18,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.6,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.9,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.4,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 44,
			experience: 2500,
			category: 'Range',
			craftable: true,
		},
	},
	1162: {
		name: 'Chitin Body',
		id: 1162,
		enchantmentTier: 5,
		value: 607500,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			range: 50,
		},
		itemImage: '/images/combat/equipment/range/fur_body.png',
		itemIcon: '/images/combat/equipment/range/chitin_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 43,
				resistance: 15,
				agility: 23,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.5,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 54,
			experience: 4000,
			category: 'Range',
			craftable: true,
		},
	},
	1163: {
		name: 'Scale Body',
		id: 1163,
		enchantmentTier: 6,
		value: 363000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			range: 60,
		},
		itemImage: '/images/combat/equipment/range/fur_body.png',
		itemIcon: '/images/combat/equipment/range/scale_body.png',
		class: 'equipment',
		visual: 'rangebody',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.01,
				Piercing: 0.96,
				Blunt: 0.97,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 55,
				resistance: 19,
				agility: 27,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.8,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'rare',
		craftingStats: {
			level: 64,
			experience: 8000,
			category: 'Range',
			craftable: true,
		},
	},
	1164: {
		name: 'Hide Coif',
		id: 1164,
		enchantmentTier: 1,
		value: 1500,
		tradeable: true,
		itemImage: '/images/combat/equipment/range/hide_coif_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/hide_coif_1.png',
				css: 'range-med-helm-1',
			},
			{
				image: '/images/combat/equipment/range/hide_coif_2.png',
				css: 'range-med-helm-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 3,
				resistance: 1,
				agility: 2,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 5,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.3,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.1,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 1,
			experience: 90,
			category: 'Range',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1165: {
		name: 'Fur Coif',
		id: 1165,
		enchantmentTier: 1,
		value: 4500,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			range: 10,
		},
		itemImage: '/images/combat/equipment/range/fur_coif_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/fur_coif_1.png',
				css: 'range-med-helm-alt-1',
			},
			{
				image: '/images/combat/equipment/range/fur_coif_2.png',
				css: 'range-med-helm-alt-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 4,
				resistance: 1,
				agility: 2,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 6,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.4,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.1,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 10,
			experience: 250,
			category: 'Range',
			craftable: true,
		},
	},
	1166: {
		name: 'Thick Hide Coif',
		id: 1166,
		enchantmentTier: 2,
		value: 7800,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			range: 20,
		},
		itemImage: '/images/combat/equipment/range/thick_hide_coif.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/hide_coif_1.png',
				css: 'range-med-helm-1',
			},
			{
				image: '/images/combat/equipment/range/hide_coif_2.png',
				css: 'range-med-helm-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 10,
				resistance: 3,
				agility: 4,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 11,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 20,
			experience: 525,
			category: 'Range',
			craftable: true,
		},
	},
	1167: {
		name: 'Leather Coif',
		id: 1167,
		enchantmentTier: 3,
		value: 17280,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			range: 30,
		},
		itemImage: '/images/combat/equipment/range/leather_coif.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/hide_coif_1.png',
				css: 'range-med-helm-1',
			},
			{
				image: '/images/combat/equipment/range/hide_coif_2.png',
				css: 'range-med-helm-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 17,
				resistance: 6,
				agility: 6,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 16,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.8,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 30,
			experience: 1000,
			category: 'Range',
			craftable: true,
		},
	},
	1168: {
		name: 'Reinforced Leather Coif',
		id: 1168,
		enchantmentTier: 4,
		value: 25380,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			range: 40,
		},
		itemImage: '/images/combat/equipment/range/reinforced_leather_coif.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/hide_coif_1.png',
				css: 'range-med-helm-1',
			},
			{
				image: '/images/combat/equipment/range/hide_coif_2.png',
				css: 'range-med-helm-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 26,
				resistance: 9,
				agility: 8,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 22,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.1,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 40,
			experience: 1250,
			category: 'Range',
			craftable: true,
		},
	},
	1169: {
		name: 'Chitin Coif',
		id: 1169,
		enchantmentTier: 5,
		value: 193500,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			range: 50,
		},
		itemImage: '/images/combat/equipment/range/chitin_coif.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/hide_coif_1.png',
				css: 'range-med-helm-1',
			},
			{
				image: '/images/combat/equipment/range/hide_coif_2.png',
				css: 'range-med-helm-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 35,
				resistance: 11,
				agility: 10,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 28,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.8,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 2000,
			category: 'Range',
			craftable: true,
		},
	},
	1170: {
		name: 'Scale Coif',
		id: 1170,
		enchantmentTier: 6,
		value: 63000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			range: 60,
		},
		itemImage: '/images/combat/equipment/range/scale_coif.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/range/hide_coif_1.png',
				css: 'range-med-helm-1',
			},
			{
				image: '/images/combat/equipment/range/hide_coif_2.png',
				css: 'range-med-helm-2',
			},
		],
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
			},
			armorBonus: {
				protection: 44,
				resistance: 15,
				agility: 12,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 35,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.8,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 4000,
			category: 'Range',
			craftable: true,
		},
	},
	1171: {
		name: 'Hide Trousers',
		id: 1171,
		enchantmentTier: 1,
		value: 3000,
		tradeable: true,
		itemIcon: '/images/combat/equipment/range/hide_legs.png',
		itemImage: '/images/combat/equipment/range/hide_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 3,
				resistance: 1,
				agility: 2,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.1,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 2,
			experience: 180,
			category: 'Range',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1172: {
		name: 'Fur Trousers',
		id: 1172,
		enchantmentTier: 1,
		value: 9000,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			range: 10,
		},
		itemIcon: '/images/combat/equipment/range/fur_legs.png',
		itemImage: '/images/combat/equipment/range/fur_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 4,
				resistance: 1,
				agility: 3,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.1,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 11,
			experience: 500,
			category: 'Range',
			craftable: true,
		},
	},
	1173: {
		name: 'Thick Hide Trousers',
		id: 1173,
		enchantmentTier: 2,
		value: 12450,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			range: 20,
		},
		itemIcon: '/images/combat/equipment/range/thick_hide_legs.png',
		itemImage: '/images/combat/equipment/range/hide_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 10,
				resistance: 4,
				agility: 6,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.2,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 21,
			experience: 750,
			category: 'Range',
			craftable: true,
		},
	},
	1174: {
		name: 'Leather Trousers',
		id: 1174,
		enchantmentTier: 3,
		value: 38160,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			range: 30,
		},
		itemIcon: '/images/combat/equipment/range/leather_legs.png',
		itemImage: '/images/combat/equipment/range/hide_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 17,
				resistance: 6,
				agility: 9,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.3,
				},
			],
			itemSet: [10010, 10012],
		},
		craftingStats: {
			level: 31,
			experience: 1500,
			category: 'Range',
			craftable: true,
		},
	},
	1175: {
		name: 'Reinforced Leather Trousers',
		id: 1175,
		enchantmentTier: 4,
		value: 46440,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			range: 40,
		},
		itemIcon: '/images/combat/equipment/range/reinforced_leather_legs.png',
		itemImage: '/images/combat/equipment/range/hide_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 26,
				resistance: 9,
				agility: 12,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.4,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 41,
			experience: 2500,
			category: 'Range',
			craftable: true,
		},
	},
	1176: {
		name: 'Chitin Trousers',
		id: 1176,
		enchantmentTier: 5,
		value: 517500,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			range: 50,
		},
		itemIcon: '/images/combat/equipment/range/chitin_legs.png',
		itemImage: '/images/combat/equipment/range/hide_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 35,
				resistance: 12,
				agility: 15,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.8,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.5,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 51,
			experience: 4000,
			category: 'Range',
			craftable: true,
		},
	},
	1177: {
		name: 'Scale Trousers',
		id: 1177,
		enchantmentTier: 6,
		value: 208500,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			range: 60,
		},
		itemIcon: '/images/combat/equipment/range/scale_legs.png',
		itemImage: '/images/combat/equipment/range/hide_legs.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.04,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.99,
				Slashing: 0.99,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 44,
				resistance: 15,
				agility: 18,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.9,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
			],
			itemSet: [10010, 10012],
		},
		rarity: 'rare',
		craftingStats: {
			level: 61,
			experience: 8000,
			category: 'Range',
			craftable: true,
		},
	},
	1178: {
		name: 'Robe Top',
		id: 1178,
		enchantmentTier: 1,
		value: 1800,
		tradeable: true,
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 2,
				resistance: 7,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 3,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 3,
			experience: 600,
			category: 'Magic',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1179: {
		name: 'Practitioner Robe Top',
		id: 1179,
		enchantmentTier: 1,
		value: 7200,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			magic: 10,
		},
		itemIcon: '/images/combat/equipment/magic/practitioner_robe_top.png',
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 3,
				resistance: 11,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 4,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 14,
			experience: 2000,
			category: 'Magic',
			craftable: true,
		},
	},
	1180: {
		name: 'Academy Robe Top',
		id: 1180,
		enchantmentTier: 2,
		value: 22500,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			magic: 20,
		},
		itemIcon: '/images/combat/equipment/magic/academy_robe_top.png',
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 8,
				resistance: 28,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 6,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.4,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 24,
			experience: 4500,
			category: 'Magic',
			craftable: true,
		},
	},
	1181: {
		name: 'Imbued Robe Top',
		id: 1181,
		enchantmentTier: 3,
		value: 90000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemIcon: '/images/combat/equipment/magic/imbued_robe_top.png',
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 13,
				resistance: 48,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 9,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 34,
			experience: 12000,
			category: 'Magic',
			craftable: true,
		},
	},
	1182: {
		name: 'Major Imbued Robe Top',
		id: 1182,
		enchantmentTier: 4,
		value: 210000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			magic: 40,
		},
		itemIcon: '/images/combat/equipment/magic/major_imbued_robe_top.png',
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 19,
				resistance: 70,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 11,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 3.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.6,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 44,
			experience: 20000,
			category: 'Magic',
			craftable: true,
		},
	},
	1183: {
		name: 'Greater Imbued Robe Top',
		id: 1183,
		enchantmentTier: 5,
		value: 480000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			magic: 50,
		},
		itemIcon: '/images/combat/equipment/magic/greater_imbued_robe_top.png',
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 26,
				resistance: 95,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 15,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 4.8,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.8,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 54,
			experience: 30000,
			category: 'Magic',
			craftable: true,
		},
	},
	1184: {
		name: 'Elder Robe Top',
		id: 1184,
		enchantmentTier: 6,
		value: 1080000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			magic: 60,
		},
		itemIcon: '/images/combat/equipment/magic/elder_robe_top.png',
		itemImage: '/images/combat/equipment/magic/robe_top.png',
		class: 'equipment',
		visual: 'robetop',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 33,
				resistance: 121,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 19,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.7,
				},
				{
					stat: 'armorBonus.resistance',
					value: 6.1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'rare',
		craftingStats: {
			level: 64,
			experience: 61500,
			category: 'Magic',
			craftable: true,
		},
	},
	1185: {
		name: 'Robe Hood',
		id: 1185,
		enchantmentTier: 1,
		value: 684,
		tradeable: true,
		itemImage: '/images/combat/equipment/magic/robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 1,
				resistance: 3,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 3,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 1,
			experience: 300,
			category: 'Magic',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1186: {
		name: 'Practitioner Hood',
		id: 1186,
		enchantmentTier: 1,
		value: 2232,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			magic: 10,
		},
		itemImage: '/images/combat/equipment/magic/practitioner_robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 1,
				resistance: 5,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 4,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 10,
			experience: 1000,
			category: 'Magic',
			craftable: true,
		},
	},
	1187: {
		name: 'Academy Hood',
		id: 1187,
		enchantmentTier: 2,
		value: 7920,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			magic: 20,
		},
		itemImage: '/images/combat/equipment/magic/academy_robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 3,
				resistance: 13,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 5,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.7,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.3,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 20,
			experience: 2250,
			category: 'Magic',
			craftable: true,
		},
	},
	1188: {
		name: 'Imbued Hood',
		id: 1188,
		enchantmentTier: 3,
		value: 30000,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemImage: '/images/combat/equipment/magic/imbued_robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 4,
				resistance: 22,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 7,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.4,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 30,
			experience: 6000,
			category: 'Magic',
			craftable: true,
		},
	},
	1189: {
		name: 'Major Imbued Hood',
		id: 1189,
		enchantmentTier: 4,
		value: 75600,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			magic: 40,
		},
		itemImage: '/images/combat/equipment/magic/major_imbued_robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 6,
				resistance: 32,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 9,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.6,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 40,
			experience: 10000,
			category: 'Magic',
			craftable: true,
		},
	},
	1190: {
		name: 'Greater Imbued Hood',
		id: 1190,
		enchantmentTier: 5,
		value: 180000,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			magic: 50,
		},
		itemImage: '/images/combat/equipment/magic/greater_imbued_robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 9,
				resistance: 43,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 14,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.8,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 15000,
			category: 'Magic',
			craftable: true,
		},
	},
	1191: {
		name: 'Elder Hood',
		id: 1191,
		enchantmentTier: 6,
		value: 480000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			magic: 60,
		},
		itemImage: '/images/combat/equipment/magic/elder_robe_hood.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/magic/robe_hood_1.png',
				css: 'hood-1',
			},
			{
				image: '/images/combat/equipment/magic/robe_hood_2.png',
				css: 'hood-2',
			},
		],
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 11,
				resistance: 55,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 17,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.6,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2.8,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.9,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 31500,
			category: 'Magic',
			craftable: true,
		},
	},
	1192: {
		name: 'Robe Bottom',
		id: 1192,
		enchantmentTier: 1,
		value: 1584,
		tradeable: true,
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 1,
				resistance: 4,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 3,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 2,
			experience: 600,
			category: 'Magic',
			craftable: true,
			description: 'Requires Defense level of 1 to wield.',
		},
	},
	1193: {
		name: 'Practitioner Robe Bottom',
		id: 1193,
		enchantmentTier: 1,
		value: 4536,
		tradeable: true,
		requiredLevel: {
			defense: 10,
			magic: 10,
		},
		itemIcon: '/images/combat/equipment/magic/practitioner_robe_legs.png',
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 2,
				resistance: 6,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 4,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 11,
			experience: 2000,
			category: 'Magic',
			craftable: true,
		},
	},
	1194: {
		name: 'Academy Robe Bottom',
		id: 1194,
		enchantmentTier: 2,
		value: 15840,
		tradeable: true,
		requiredLevel: {
			defense: 20,
			magic: 20,
		},
		itemIcon: '/images/combat/equipment/magic/academy_robe_legs.png',
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 5,
				resistance: 15,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 5,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.3,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 21,
			experience: 4500,
			category: 'Magic',
			craftable: true,
		},
	},
	1195: {
		name: 'Imbued Robe Bottom',
		id: 1195,
		enchantmentTier: 3,
		value: 67800,
		tradeable: true,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemIcon: '/images/combat/equipment/magic/imbued_robe_legs.png',
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 9,
				resistance: 26,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 9,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 31,
			experience: 12000,
			category: 'Magic',
			craftable: true,
		},
	},
	1196: {
		name: 'Major Imbued Robe Bottom',
		id: 1196,
		enchantmentTier: 4,
		value: 165600,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			magic: 40,
		},
		itemIcon: '/images/combat/equipment/magic/major_imbued_robe_legs.png',
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 13,
				resistance: 38,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 12,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.7,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.9,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.7,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 41,
			experience: 20000,
			category: 'Magic',
			craftable: true,
		},
	},
	1197: {
		name: 'Greater Imbued Robe Bottom',
		id: 1197,
		enchantmentTier: 5,
		value: 391200,
		tradeable: true,
		requiredLevel: {
			defense: 50,
			magic: 50,
		},
		itemIcon: '/images/combat/equipment/magic/greater_imbued_robe_legs.png',
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 17,
				resistance: 52,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 16,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2.6,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.8,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 51,
			experience: 30000,
			category: 'Magic',
			craftable: true,
		},
	},
	1198: {
		name: 'Elder Robe Bottom',
		id: 1198,
		enchantmentTier: 6,
		value: 902400,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			magic: 60,
		},
		itemIcon: '/images/combat/equipment/magic/elder_robe_legs.png',
		itemImage: '/images/combat/equipment/magic/robe_legs.png',
		class: 'equipment',
		visual: 'robebottom',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.02,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			armorBonus: {
				protection: 22,
				resistance: 66,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 19,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 3.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'rare',
		craftingStats: {
			level: 61,
			experience: 61500,
			category: 'Magic',
			craftable: true,
		},
	},
	1199: {
		name: 'Flimsy Recurve Bow',
		id: 1199,
		tradeable: true,
		enchantmentTier: 1,
		value: 450,
		itemImage: '/images/combat/equipment/range/bow_wood_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_wood_recurve.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 4,
				Piercing: 5,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 9,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.5,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 500,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1200: {
		name: 'Sturdy Recurve Bow',
		id: 1200,
		tradeable: true,
		enchantmentTier: 1,
		value: 878,
		requiredLevel: {
			range: 10,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 6,
				Piercing: 8,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 11,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
			],
		},
		craftingStats: {
			level: 15,
			experience: 1000,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1201: {
		name: 'Oak Recurve Bow',
		id: 1201,
		tradeable: true,
		enchantmentTier: 2,
		value: 3276,
		requiredLevel: {
			range: 20,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 15,
				Piercing: 20,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 18,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 25,
			experience: 3000,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1202: {
		name: 'Willow Recurve Bow',
		id: 1202,
		tradeable: true,
		enchantmentTier: 3,
		value: 7200,
		requiredLevel: {
			range: 30,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_willow.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 26,
				Piercing: 35,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 27,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 1.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.5,
				},
			],
		},
		craftingStats: {
			level: 35,
			experience: 8000,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1203: {
		name: 'Maple Recurve Bow',
		id: 1203,
		tradeable: true,
		enchantmentTier: 4,
		value: 13704,
		requiredLevel: {
			range: 40,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_yew.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 38,
				Piercing: 51,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 37,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 45,
			experience: 12000,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1204: {
		name: 'Yew Recurve Bow',
		id: 1204,
		tradeable: true,
		enchantmentTier: 5,
		value: 32040,
		requiredLevel: {
			range: 50,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_yew.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 52,
				Piercing: 69,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 48,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 2.6,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2.5,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 55,
			experience: 16000,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1205: {
		name: 'Elder Recurve Bow',
		id: 1205,
		tradeable: true,
		enchantmentTier: 6,
		value: 90600,
		requiredLevel: {
			range: 60,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy_recurve.png',
		itemIcon: '/images/combat/equipment/range/bow_elder.png',
		class: 'equipment',
		visual: 'recurvebow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.4,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -16,
				Magic: -16,
				Range: 66,
				Piercing: 88,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1,
				Piercing: 1.25,
				Blunt: 0.25,
				Slashing: 0.25,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 59,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 20000,
			category: 'Range',
			craftable: true,
			description: 'Recurve Bow WIP',
		},
	},
	1206: {
		name: 'Flimsy Longbow',
		id: 1206,
		tradeable: true,
		enchantmentTier: 1,
		value: 497,
		itemImage: '/images/combat/equipment/range/bow_wood.png',
		itemIcon: '/images/combat/equipment/range/bow_wood.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 6,
				Piercing: 7,
				Blunt: 1,
				Slashing: 1,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 19,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 2,
			experience: 750,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1207: {
		name: 'Sturdy Longbow',
		id: 1207,
		tradeable: true,
		enchantmentTier: 1,
		value: 990,
		requiredLevel: {
			range: 10,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy.png',
		itemIcon: '/images/combat/equipment/range/bow_sturdy.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 9,
				Piercing: 10,
				Blunt: 2,
				Slashing: 2,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 26,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.3,
				},
			],
		},
		craftingStats: {
			level: 15,
			experience: 1500,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1208: {
		name: 'Oak Longbow',
		id: 1208,
		tradeable: true,
		enchantmentTier: 2,
		value: 3852,
		requiredLevel: {
			range: 20,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy.png',
		itemIcon: '/images/combat/equipment/range/bow_sturdy.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 23,
				Piercing: 25,
				Blunt: 5,
				Slashing: 5,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 40,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 1.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2,
				},
			],
		},
		craftingStats: {
			level: 25,
			experience: 4000,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1209: {
		name: 'Willow Longbow',
		id: 1209,
		tradeable: true,
		enchantmentTier: 3,
		value: 8976,
		requiredLevel: {
			range: 30,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy.png',
		itemIcon: '/images/combat/equipment/range/bow_willow.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 39,
				Piercing: 44,
				Blunt: 9,
				Slashing: 9,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 52,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2.6,
				},
			],
		},
		craftingStats: {
			level: 35,
			experience: 12000,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1210: {
		name: 'Maple Longbow',
		id: 1210,
		tradeable: true,
		enchantmentTier: 4,
		value: 19224,
		requiredLevel: {
			range: 40,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy.png',
		itemIcon: '/images/combat/equipment/range/bow_yew.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 58,
				Piercing: 64,
				Blunt: 13,
				Slashing: 13,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 64,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3.2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 45,
			experience: 18000,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1211: {
		name: 'Yew Longbow',
		id: 1211,
		tradeable: true,
		enchantmentTier: 5,
		value: 53040,
		requiredLevel: {
			range: 50,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy.png',
		itemIcon: '/images/combat/equipment/range/bow_yew.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 78,
				Piercing: 86,
				Blunt: 17,
				Slashing: 17,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 75,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3.9,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3.8,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 55,
			experience: 24000,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1212: {
		name: 'Elder Longbow',
		id: 1212,
		tradeable: true,
		enchantmentTier: 6,
		value: 171000,
		requiredLevel: {
			range: 60,
		},
		itemImage: '/images/combat/equipment/range/bow_sturdy.png',
		itemIcon: '/images/combat/equipment/range/bow_elder.png',
		class: 'equipment',
		visual: 'longbow',
		tags: ['range', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 99,
				Piercing: 110,
				Blunt: 22,
				Slashing: 22,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.3333333333333333,
				Blunt: 0.5,
				Slashing: 0.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 99,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 30000,
			category: 'Range',
			craftable: true,
			description: 'Longbow WIP',
		},
	},
	1213: {
		name: 'Flimsy Crossbow',
		id: 1213,
		tradeable: true,
		enchantmentTier: 1,
		value: 622,
		itemImage: '/images/combat/equipment/range/crossbow_wood.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_wood.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 16,
				Piercing: 10,
				Blunt: 3,
				Slashing: 3,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 17,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.9,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 3,
			experience: 325,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
	},
	1214: {
		name: 'Light Crossbow',
		id: 1214,
		tradeable: true,
		enchantmentTier: 1,
		value: 1097,
		requiredLevel: {
			range: 10,
		},
		itemImage: '/images/combat/equipment/range/crossbow_sturdy.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_sturdy.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 24,
				Piercing: 14,
				Blunt: 4,
				Slashing: 4,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 20,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 13,
			experience: 625,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
	},
	1215: {
		name: 'Bone Crossbow',
		id: 1215,
		tradeable: true,
		enchantmentTier: 2,
		value: 5412,
		requiredLevel: {
			range: 20,
		},
		itemImage: '/images/combat/equipment/range/crossbow_sturdy.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_bone.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 48,
				Piercing: 35,
				Blunt: 10,
				Slashing: 10,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 33,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 2.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.7,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 23,
			experience: 4120,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
	},
	1216: {
		name: 'Heavy Crossbow',
		id: 1216,
		tradeable: true,
		enchantmentTier: 3,
		value: 12432,
		requiredLevel: {
			range: 30,
		},
		itemImage: '/images/combat/equipment/range/crossbow_sturdy.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_heavy.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 70,
				Piercing: 61,
				Blunt: 17,
				Slashing: 17,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 49,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2.5,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 33,
			experience: 12525,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
	},
	1217: {
		name: 'Reinforced Crossbow',
		id: 1217,
		tradeable: true,
		enchantmentTier: 4,
		value: 38328,
		requiredLevel: {
			range: 40,
		},
		itemImage: '/images/combat/equipment/range/crossbow_sturdy.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_metal.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 100,
				Piercing: 90,
				Blunt: 26,
				Slashing: 26,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 67,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3.4,
				},
			],
			oneHanded: true,
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 43,
			experience: 19350,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
	},
	1218: {
		name: 'Arbalest',
		id: 1218,
		tradeable: true,
		enchantmentTier: 5,
		value: 127440,
		requiredLevel: {
			range: 50,
		},
		itemImage: '/images/combat/equipment/range/crossbow_sturdy.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_arbalest.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 136,
				Piercing: 121,
				Blunt: 35,
				Slashing: 35,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 86,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 6.8,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 4.3,
				},
			],
			oneHanded: true,
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 53,
			experience: 26000,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
	},
	1219: {
		name: 'Elder Crossbow',
		id: 1219,
		tradeable: true,
		enchantmentTier: 6,
		value: 263400,
		requiredLevel: {
			range: 60,
		},
		itemImage: '/images/combat/equipment/range/crossbow_sturdy.png',
		visual: 'crossbow',
		itemIcon: '/images/combat/equipment/range/crossbow_elder.png',
		class: 'equipment',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 168,
				Piercing: 154,
				Blunt: 44,
				Slashing: 44,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.3333333333333333,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 107,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 8.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 5.4,
				},
			],
			oneHanded: true,
		},
		rarity: 'rare',
		craftingStats: {
			level: 63,
			experience: 33600,
			category: 'Range',
			craftable: true,
			description: 'Crossbow WIP',
		},
		extraTooltipInfo: 'Perhaps researching this crossbow will lead to new technological developments?',
	},
	1220: {
		name: 'Rough Throwing Knife',
		id: 1220,
		tradeable: true,
		enchantmentTier: 1,
		value: 557,
		itemImage: '/images/combat/equipment/range/bronze_knife.png',
		itemIcon: '/images/combat/equipment/range/thrown_knife_rough.png',
		class: 'equipment',
		visual: 'throwingdagger',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 7,
				Piercing: 3,
				Blunt: 3,
				Slashing: 3,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 11,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 1,
			experience: 1025,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1221: {
		name: 'Throwing Knife',
		id: 1221,
		tradeable: true,
		enchantmentTier: 1,
		value: 982,
		requiredLevel: {
			range: 10,
		},
		itemImage: '/images/combat/equipment/range/iron_knife.png',
		itemIcon: '/images/combat/equipment/range/thrown_knife.png',
		class: 'equipment',
		visual: 'throwingdagger',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 10,
				Piercing: 4,
				Blunt: 4,
				Slashing: 4,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 13,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.7,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 12,
			experience: 1525,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1222: {
		name: 'Light Throwing Knife',
		id: 1222,
		tradeable: true,
		enchantmentTier: 2,
		value: 4848,
		requiredLevel: {
			range: 20,
		},
		itemImage: '/images/combat/equipment/range/obby_knife.png',
		itemIcon: '/images/combat/equipment/range/thrown_knife_2.png',
		class: 'equipment',
		visual: 'throwingdagger',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 25,
				Piercing: 10,
				Blunt: 10,
				Slashing: 10,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 22,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.1,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 22,
			experience: 3120,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1223: {
		name: 'Refined Throwing Knife',
		id: 1223,
		tradeable: true,
		enchantmentTier: 3,
		value: 10680,
		requiredLevel: {
			range: 30,
		},
		itemImage: '/images/combat/equipment/range/mithril_knife.png',
		itemIcon: '/images/combat/equipment/range/thrown_knife_refined.png',
		class: 'equipment',
		visual: 'throwingdagger',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 44,
				Piercing: 17,
				Blunt: 17,
				Slashing: 17,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 33,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1.7,
				},
			],
			oneHanded: true,
		},
		craftingStats: {
			level: 32,
			experience: 8525,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1224: {
		name: 'Throwing Axe',
		id: 1224,
		tradeable: true,
		enchantmentTier: 4,
		value: 33144,
		requiredLevel: {
			range: 40,
		},
		itemImage: '/images/combat/equipment/range/throwing_axe.png',
		class: 'equipment',
		visual: 'throwingaxe',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 64,
				Piercing: 26,
				Blunt: 26,
				Slashing: 26,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 44,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3.2,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2.2,
				},
			],
			oneHanded: true,
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 42,
			experience: 13350,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1225: {
		name: 'Throwing Javelin',
		id: 1225,
		tradeable: true,
		enchantmentTier: 5,
		value: 107760,
		requiredLevel: {
			range: 50,
		},
		itemImage: '/images/combat/equipment/range/throwing_jav.png',
		itemIcon: '/images/combat/equipment/range/thrown_jav.png',
		class: 'equipment',
		visual: 'throwingjavelin',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 86,
				Piercing: 35,
				Blunt: 35,
				Slashing: 35,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 57,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 4.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 2.9,
				},
			],
			oneHanded: true,
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 52,
			experience: 18000,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1226: {
		name: 'Throwing Spinner',
		id: 1226,
		tradeable: true,
		enchantmentTier: 6,
		value: 189000,
		requiredLevel: {
			range: 60,
		},
		itemImage: '/images/combat/equipment/range/thrown_spinner.png',
		class: 'equipment',
		visual: 'throwingspinner',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -5,
				Magic: -20,
				Range: 110,
				Piercing: 44,
				Blunt: 44,
				Slashing: 44,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.85,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 71,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 5.5,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3.6,
				},
			],
			oneHanded: true,
		},
		rarity: 'rare',
		craftingStats: {
			level: 62,
			experience: 23600,
			category: 'Range',
			craftable: true,
			description: 'Throwing WIP',
		},
	},
	1227: {
		name: 'Driftwood Wand',
		id: 1227,
		tradeable: true,
		enchantmentTier: 1,
		value: 202,
		itemImage: '/images/combat/equipment/magic/driftwood_wand.png',
		itemIcon: '/images/combat/equipment/magic/driftwood_wand_icon.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 7,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 11,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.6,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 75,
			category: 'Magic',
			craftable: true,
			description: 'Wand WIP',
		},
	},
	1228: {
		name: 'Sturdy Wand',
		id: 1228,
		tradeable: true,
		enchantmentTier: 1,
		value: 472,
		requiredLevel: {
			magic: 10,
		},
		itemImage: '/images/combat/equipment/magic/sturdy_wand.png',
		itemIcon: '/images/combat/equipment/magic/sturdy_wand_icon.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 10,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 13,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.7,
				},
			],
		},
		craftingStats: {
			level: 13,
			experience: 190,
			category: 'Magic',
			craftable: true,
			description: 'Wand WIP',
		},
	},
	1229: {
		name: 'Academy Wand',
		id: 1229,
		tradeable: true,
		enchantmentTier: 2,
		value: 2196,
		requiredLevel: {
			magic: 20,
		},
		itemImage: '/images/combat/equipment/magic/academy_wand.png',
		itemIcon: '/images/combat/equipment/magic/academy_wand_icon.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 25,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 22,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.1,
				},
			],
		},
		craftingStats: {
			level: 23,
			experience: 1590,
			category: 'Magic',
			craftable: true,
			description:
				'The instructional manuals you found DEFINITELY indicated you should hold it upside-down. You do know how to read though, right?',
		},
	},
	1230: {
		name: 'Imbued Wand',
		id: 1230,
		tradeable: true,
		enchantmentTier: 3,
		value: 3096,
		requiredLevel: {
			magic: 30,
		},
		itemImage: '/images/combat/equipment/magic/imbued_wand.png',
		itemIcon: '/images/combat/equipment/magic/imbued_wand_icon.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 44,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 32,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.6,
				},
			],
		},
		craftingStats: {
			level: 33,
			experience: 1645,
			category: 'Magic',
			craftable: true,
			description: 'Wand WIP',
		},
	},
	1231: {
		name: 'Major Imbued Wand',
		id: 1231,
		tradeable: true,
		enchantmentTier: 4,
		value: 12408,
		requiredLevel: {
			magic: 40,
		},
		itemImage: '/images/combat/equipment/magic/driftwood_wand.png',
		itemIcon: '/images/combat/equipment/magic/major_imbued_wand.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 64,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 44,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 2.2,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 43,
			experience: 23350,
			category: 'Magic',
			craftable: true,
			description: 'Wand WIP',
		},
	},
	1232: {
		name: 'Greater Imbued Wand',
		id: 1232,
		tradeable: true,
		enchantmentTier: 5,
		value: 17940,
		requiredLevel: {
			magic: 50,
		},
		itemImage: '/images/combat/equipment/magic/driftwood_wand.png',
		itemIcon: '/images/combat/equipment/magic/greater_imbued_wand.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 86,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 57,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 4.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 2.9,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 53,
			experience: 23900,
			category: 'Magic',
			craftable: true,
			description: 'Wand WIP',
		},
	},
	1233: {
		name: 'Elder Wand',
		id: 1233,
		tradeable: true,
		enchantmentTier: 6,
		value: 27000,
		requiredLevel: {
			magic: 60,
		},
		itemImage: '/images/combat/equipment/magic/driftwood_wand.png',
		itemIcon: '/images/combat/equipment/magic/elder_imbued_wand.png',
		class: 'equipment',
		visual: 'wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 110,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 71,
				dexterity: 0,
			},
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 5.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3.6,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 63,
			experience: 26750,
			category: 'Magic',
			craftable: true,
			description: 'Wand WIP',
		},
	},
	1234: {
		name: 'Driftwood Staff',
		id: 1234,
		tradeable: true,
		enchantmentTier: 1,
		value: 698,
		itemImage: '/images/combat/equipment/magic/driftwood_staff.png',
		itemIcon: '/images/combat/equipment/magic/driftwood_staff_icon.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 10,
				Range: -28,
				Piercing: -8,
				Blunt: 3,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 25,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.3,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 2,
			experience: 130,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1235: {
		name: 'Sturdy Staff',
		id: 1235,
		tradeable: true,
		enchantmentTier: 1,
		value: 698,
		requiredLevel: {
			magic: 10,
		},
		itemImage: '/images/combat/equipment/magic/sturdy_staff.png',
		itemIcon: '/images/combat/equipment/magic/sturdy_staff_icon.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 14,
				Range: -28,
				Piercing: -8,
				Blunt: 4,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 30,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.5,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 15,
			experience: 290,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1236: {
		name: 'Academy Staff',
		id: 1236,
		tradeable: true,
		enchantmentTier: 2,
		value: 3300,
		requiredLevel: {
			magic: 20,
		},
		itemImage: '/images/combat/equipment/magic/academy_staff.png',
		itemIcon: '/images/combat/equipment/magic/academy_staff_icon.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 35,
				Range: -28,
				Piercing: -8,
				Blunt: 10,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 46,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 1.8,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 2.4,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 25,
			experience: 1660,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1237: {
		name: 'Imbued Staff',
		id: 1237,
		tradeable: true,
		enchantmentTier: 3,
		value: 5112,
		requiredLevel: {
			magic: 30,
		},
		itemImage: '/images/combat/equipment/magic/imbued_staff.png',
		itemIcon: '/images/combat/equipment/magic/imbued_staff_icon.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 61,
				Range: -28,
				Piercing: -8,
				Blunt: 17,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 62,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3.1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3.1,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 35,
			experience: 1835,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1238: {
		name: 'Major Imbued Staff',
		id: 1238,
		tradeable: true,
		enchantmentTier: 4,
		value: 20424,
		requiredLevel: {
			magic: 40,
		},
		itemImage: '/images/combat/equipment/magic/imbued_staff.png',
		itemIcon: '/images/combat/equipment/magic/major_imbued_staff.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 90,
				Range: -28,
				Piercing: -8,
				Blunt: 26,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 80,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 4.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 4,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 45,
			experience: 24550,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1239: {
		name: 'Greater Imbued Staff',
		id: 1239,
		tradeable: true,
		enchantmentTier: 5,
		value: 33000,
		requiredLevel: {
			magic: 50,
		},
		itemImage: '/images/combat/equipment/magic/imbued_staff.png',
		itemIcon: '/images/combat/equipment/magic/greater_imbued_staff.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 121,
				Range: -28,
				Piercing: -8,
				Blunt: 35,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 100,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 6.1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 5,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 55,
			experience: 25500,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1240: {
		name: 'Elder Staff',
		id: 1240,
		tradeable: true,
		enchantmentTier: 6,
		value: 59640,
		requiredLevel: {
			magic: 60,
		},
		itemImage: '/images/combat/equipment/magic/imbued_staff.png',
		itemIcon: '/images/combat/equipment/magic/elder_imbued_staff.png',
		class: 'equipment',
		visual: 'staff',
		tags: ['magic', 'twohand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -14,
				Magic: 154,
				Range: -28,
				Piercing: -8,
				Blunt: 44,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.3333333333333333,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 134,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 7.7,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 6.7,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 28500,
			category: 'Magic',
			craftable: true,
			description: 'Staff WIP',
		},
	},
	1241: {
		name: 'Arcane Notes',
		id: 1241,
		tradeable: true,
		enchantmentTier: 1,
		value: 1764,
		itemImage: '/images/combat/equipment/magic/starter_focus.png',
		class: 'equipment',
		visual: 'focus',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 3,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 3,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 4,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.2,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 1,
			experience: 330,
			category: 'Magic',
			craftable: true,
			description:
				"A reference book on how to properly cast beginner's magic. Not inherently magical unless you consider your nearly illegible handwriting to be on par with some ancient text.",
		},
	},
	1242: {
		name: 'Simple Arcane Focus',
		id: 1242,
		tradeable: true,
		enchantmentTier: 1,
		value: 3348,
		requiredLevel: {
			defense: 10,
			magic: 10,
		},
		itemImage: '/images/combat/equipment/magic/practitioner_focus.png',
		itemIcon: '/images/combat/equipment/magic/practitioner_focus_icon.png',
		class: 'equipment',
		visual: 'focus-4',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 4,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 4,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 6,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 12,
			experience: 1060,
			category: 'Magic',
			craftable: true,
			description: 'Focus WIP',
		},
	},
	1243: {
		name: 'Academy Focus',
		id: 1243,
		tradeable: true,
		enchantmentTier: 2,
		value: 12120,
		requiredLevel: {
			defense: 20,
			magic: 20,
		},
		itemImage: '/images/combat/equipment/magic/academy_focus.png',
		itemIcon: '/images/combat/equipment/magic/academy_focus_icon.png',
		class: 'equipment',
		visual: 'focus-2',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 10,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 7,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 15,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 22,
			experience: 2460,
			category: 'Magic',
			craftable: true,
			description: 'Focus WIP',
		},
	},
	1244: {
		name: 'Imbued Focus',
		id: 1244,
		tradeable: true,
		enchantmentTier: 3,
		value: 54960,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemImage: '/images/combat/equipment/magic/imbued_focus.png',
		itemIcon: '/images/combat/equipment/magic/imbued_focus_icon.png',
		class: 'equipment',
		visual: 'focus-3',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 17,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 11,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 26,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 1.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.5,
				},
			],
			itemSet: [10011, 10013],
		},
		craftingStats: {
			level: 32,
			experience: 6235,
			category: 'Magic',
			craftable: true,
			description: 'Focus WIP',
		},
	},
	1245: {
		name: 'Major Imbued Focus',
		id: 1245,
		tradeable: true,
		enchantmentTier: 4,
		value: 157200,
		requiredLevel: {
			defense: 40,
			magic: 40,
		},
		itemImage: '/images/combat/equipment/magic/imbued_focus.png',
		itemIcon: '/images/combat/equipment/magic/major_imbued_focus.png',
		class: 'equipment',
		visual: 'focus-3',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 26,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 14,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 38,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 2,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 42,
			experience: 12350,
			category: 'Magic',
			craftable: true,
			description: 'Focus WIP',
		},
	},
	1246: {
		name: 'Greater Imbued Focus',
		id: 1246,
		tradeable: true,
		enchantmentTier: 5,
		value: 443880,
		requiredLevel: {
			defense: 50,
			magic: 50,
		},
		itemImage: '/images/combat/equipment/magic/imbued_focus.png',
		itemIcon: '/images/combat/equipment/magic/greater_imbued_focus.png',
		class: 'equipment',
		visual: 'focus-3',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 35,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 19,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 52,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 2.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 2.5,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 52,
			experience: 18160,
			category: 'Magic',
			craftable: true,
			description: 'Focus WIP',
		},
	},
	1247: {
		name: 'Elder Focus',
		id: 1247,
		tradeable: true,
		enchantmentTier: 6,
		value: 1253760,
		requiredLevel: {
			defense: 60,
			magic: 60,
		},
		itemImage: '/images/combat/equipment/magic/imbued_focus.png',
		itemIcon: '/images/combat/equipment/magic/elder_focus.png',
		class: 'equipment',
		visual: 'focus-3',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 44,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
			},
			weaponBonus: {
				strength: 0,
				intellect: 23,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 66,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3,
				},
			],
			itemSet: [10011, 10013],
		},
		rarity: 'rare',
		craftingStats: {
			level: 62,
			experience: 37050,
			category: 'Magic',
			craftable: true,
			description: 'Focus WIP',
		},
	},
	1300: {
		name: "Blademaster's Shroud",
		id: 1300,
		enchantmentTier: 2,
		value: 3120000,
		tradeable: true,
		requiredLevel: {
			constitution: 40,
			defense: 40,
			attack: 40,
			enchanting: 60,
		},
		itemImage: '/images/combat/equipment/decayed_cloak.png',
		itemIcon: '/images/combat/equipment/melee/blademaster.png',
		extraTooltipInfo: 'Ragged and torn, the cloth itself appears to remember every battle.',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [113, 114, 115, 116],
			slot: 'cape',
			offensiveAccuracyAffinityRating: {
				Melee: 20,
			},
			offensiveDamageAffinity: {
				Melee: 1.12,
				Magic: 1,
				Range: 1,
				Slashing: 1.08,
				Piercing: 1.08,
				Blunt: 1.08,
			},
			defensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 1,
			},
			weaponBonus: {
				strength: 20,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 4,
				resistance: 0,
				agility: 12,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.agility',
					value: 1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2,
				},
			],
			itemSet: [10018],
		},
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 69500,
			category: 'Melee',
			craftable: true,
		},
	},
	1301: {
		id: 1301,
		name: "Blademaster's Memory",
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/memory.png',
		extraTooltipInfo:
			'The distilled knowledge of an ancient warrior. Perhaps you can infuse this into a piece of equipment?',
		class: 'bar',
		tags: ['shard'],
		rarity: 'epic',
	},
	1302: {
		name: 'Royal Banner',
		id: 1302,
		enchantmentTier: 2,
		value: 3120000,
		tradeable: true,
		requiredLevel: {
			constitution: 40,
			defense: 40,
			attack: 40,
			enchanting: 60,
		},
		itemImage: '/images/combat/equipment/melee/banner.png',
		extraTooltipInfo:
			'A banner representing the will and might of humanity, if you believe the propaganda. Heavy and unwieldy to carry, reducing your defenses slightly, but sure to inspire your comrades.',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [117, 118, 119],
			slot: 'cape',
			offensiveDamageAffinity: {
				Melee: 0.9,
				Magic: 0.9,
				Range: 0.9,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 0.95,
				Range: 0.95,
			},
			armorBonus: {
				protection: 16,
				resistance: 16,
				agility: -64,
				stamina: 16,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.stamina',
					value: 16,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 56050,
			category: 'Melee',
			craftable: true,
		},
	},
	1303: {
		id: 1303,
		name: 'Torn Banner',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/torn_banner.png',
		extraTooltipInfo: 'A torn banner still humming with magical power. You should restore it to its former glory!',
		class: 'bar',
		tags: ['shard'],
		rarity: 'epic',
	},
	1304: {
		name: "Necromancer's Cowl",
		id: 1304,
		enchantmentTier: 7,
		tradeable: true,
		value: 8000000,
		requiredLevel: {
			defense: 75,
			magic: 75,
		},
		itemImage: '/images/combat/equipment/magic/necro_cowl.PNG',
		extraTooltipInfo: 'A magical cowl to hide your face. Somewhat helps with keeping out the stench of the dead.',
		class: 'equipment',
		visual: 'necro-cowl',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			grantedAbility: [120, 121, 122, 167],
			slot: 'helm',
			offensiveDamageAffinity: {
				Magic: 1.06,
				Poison: 1.03,
				Chaos: 1.03,
			},
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 1.01,
				Poison: 1.01,
				Chaos: 1.01,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			weaponBonus: {
				strength: 6,
				intellect: 12,
				dexterity: 6,
			},
			armorBonus: {
				protection: 8,
				resistance: 32,
				agility: 18,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 2.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.6,
				},
			],
			itemSet: [10011, 10013, 10020],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 40500,
			category: 'Magic',
			craftable: true,
		},
	},
	1305: {
		name: "Necromancer's Robe Top",
		id: 1305,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			magic: 75,
		},
		itemImage: '/images/combat/equipment/magic/necro_robe_top.PNG',
		class: 'equipment',
		visual: 'necro-top',
		extraTooltipInfo: 'The robes of a necromancer. Smells like death.',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			grantedAbility: [120, 121, 122],
			slot: 'body',
			offensiveDamageAffinity: {
				Magic: 1.06,
				Poison: 1.03,
				Chaos: 1.03,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 1.03,
				Poison: 1.03,
				Chaos: 1.01,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			weaponBonus: {
				strength: 0,
				intellect: 30,
				dexterity: 0,
			},
			armorBonus: {
				protection: 16,
				resistance: 54,
				agility: 8,
				stamina: 12,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 2.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2.7,
				},
			],
			itemSet: [10011, 10013, 10020],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 70500,
			category: 'Magic',
			craftable: true,
		},
	},
	1306: {
		name: "Necromancer's Robe Bottoms",
		id: 1306,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			magic: 75,
		},
		itemImage: '/images/combat/equipment/magic/necro_robe_legs.png',
		class: 'equipment',
		visual: 'necro-legs',
		extraTooltipInfo: 'The robe bottoms of a necromancer.',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			grantedAbility: [120, 121, 122],
			slot: 'legs',
			offensiveDamageAffinity: {
				Magic: 1.06,
				Poison: 1.03,
				Chaos: 1.03,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Magic: 1.03,
				Poison: 1.03,
				Chaos: 1.01,
				Range: 0.94,
				Piercing: 0.94,
				Blunt: 0.97,
				Slashing: 0.94,
			},
			weaponBonus: {
				strength: 0,
				intellect: 24,
				dexterity: 0,
			},
			armorBonus: {
				protection: 12,
				resistance: 48,
				agility: 12,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 2.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2.5,
				},
			],
			itemSet: [10011, 10013, 10020],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 70500,
			category: 'Magic',
			craftable: true,
		},
	},
	1307: {
		name: 'Book of Death',
		id: 1307,
		tradeable: true,
		enchantmentTier: 7,
		value: 6000000,
		itemImage: '/images/combat/equipment/magic/necro_book.png',
		class: 'equipment',
		visual: 'necro-focus',
		tags: ['magic', 'craftable'],
		requiredLevel: {
			magic: 75,
		},
		equipmentStats: {
			grantedAbility: [120, 121, 122, 138, 141],
			slot: 'shield',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 60,
			},
			offensiveDamageAffinity: {
				Magic: 1.15,
				Melee: 0.9,
				Range: 0.9,
				Poison: 1.03,
			},
			weaponBonus: {
				strength: 0,
				intellect: 40,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 88,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3,
				},
			],
			itemSet: [10011, 10013, 10020],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 45250,
			category: 'Magic',
			craftable: true,
			description: 'An unholy text. It will require all your willpower to not be corrupted by it.',
		},
	},
	1308: {
		name: "Necromancer's Wand",
		id: 1308,
		tradeable: true,
		enchantmentTier: 7,
		value: 6000000,
		requiredLevel: {
			magic: 75,
		},
		itemImage: '/images/combat/equipment/magic/necro_wand.png',
		class: 'equipment',
		visual: 'necro-wand',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			grantedAbility: [120, 121, 122, 131, 138, 141],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -20,
				Magic: 134,
				Range: -20,
				Piercing: -8,
				Blunt: -8,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.15,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.1,
				Slashing: 0.1,
				Poison: 1.03,
			},
			oneHanded: true,
			weaponBonus: {
				strength: 0,
				intellect: 90,
				dexterity: 0,
			},
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 6.7,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 4.5,
				},
			],
			itemSet: [10020],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 32750,
			category: 'Magic',
			craftable: true,
			description: 'A wand imbued with a dark power. You feel as if it is merely letting you control it.',
		},
	},
	1309: {
		name: "Necromancer's Remains",
		id: 1309,
		value: 100000,
		tradeable: true,
		extraTooltipInfo: 'The rotten belongings of a long dead necromancer. Can be restored through crafting.',
		itemImage: '/images/misc/necro_remains.png',
		class: 'bar',
		tags: ['crafting'],
		rarity: 'rare',
	},
	1310: {
		name: 'Royal Elven Remnants',
		id: 1310,
		value: 100000,
		tradeable: true,
		extraTooltipInfo: 'The remnants of a more civilized past. Can be restored through crafting.',
		itemImage: '/images/misc/royal_remnants.png',
		class: 'bar',
		tags: ['crafting'],
		rarity: 'rare',
	},
	1311: {
		name: 'Gargoyle Shards',
		id: 1311,
		value: 100000,
		tradeable: true,
		extraTooltipInfo: 'A burning shard taken from a failed experiment. Can be restored through crafting.',
		itemImage: '/images/misc/gargoyle_shards.png',
		class: 'bar',
		tags: ['crafting'],
		rarity: 'rare',
	},
	1312: {
		name: 'Mantis Shrimp Carapace',
		id: 1312,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
		},
		itemImage: '/images/combat/equipment/mantis_shrimp_armor.png',
		extraTooltipInfo: 'A reforged carapace modeled after the strongest shrimp.',
		class: 'equipment',
		visual: 'shrimp-armor',
		tags: ['armor', 'craftable'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
				Chaos: 1.03,
				Poison: 1.03,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.06,
				Blunt: 0.88,
				Slashing: 1.06,
			},
			armorBonus: {
				protection: 88,
				resistance: 88,
				agility: 26,
				stamina: 16,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 4.4,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.8,
				},
			],
			itemSet: [2001, 10034, 10029, 10033, 10032, 10031, 10030],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Rare',
			craftable: true,
			description: 'A carapace that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1313: {
		name: 'Mantis Shrimp Greaves',
		id: 1313,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
		},
		itemImage: '/images/combat/equipment/mantis_shrimp_legs.png',
		extraTooltipInfo: 'A reforged set of greaves modeled after the strongest shrimp.',
		class: 'equipment',
		visual: 'shrimp-legs',
		tags: ['armor', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
				Chaos: 1.03,
				Poison: 1.03,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.08,
				Blunt: 0.88,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 88,
				resistance: 88,
				agility: 26,
				stamina: 16,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 4.4,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.8,
				},
			],
			itemSet: [2001, 10034, 10029, 10033, 10032, 10031, 10030],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Rare',
			craftable: true,
			description: 'A pair of greaves that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1314: {
		name: 'Mantis Shrimp Helm',
		id: 1314,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
		},
		itemImage: '/images/combat/equipment/mantis_shrimp_helm.png',
		extraTooltipInfo: 'A reforged helm modeled after the strongest shrimp.',
		class: 'equipment',
		visual: 'shrimp-helm',
		tags: ['armor', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
				Chaos: 1.03,
				Poison: 1.03,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 80,
				resistance: 80,
				agility: 20,
				stamina: 12,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 4,
				},
				{
					stat: 'armorBonus.agility',
					value: 1,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.6,
				},
			],
			itemSet: [2001, 10034, 10029, 10033, 10032, 10031, 10030],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Rare',
			craftable: true,
			description: 'A helm that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1315: {
		name: 'Mantis Shrimp Shell',
		id: 1315,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
		},
		itemImage: '/images/combat/equipment/mantis_shrimp_shield.png',
		extraTooltipInfo:
			"The chitinous shell of the Aberrant Shrimp formed into a shield; it's tainted by some sort of demonic magic. Is this a blessing or a curse?",
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/mantis_shrimp_shield.png',
				css: 'shrimp-shield-1',
			},
			{
				image: '/images/combat/equipment/shrimp_shield_2.png',
				css: 'shrimp-shield-2',
			},
		],
		tags: ['armor', 'craftable'],
		equipmentStats: {
			grantedAbility: [54, 65],
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 0.8333333333333334,
				Magic: 0.8333333333333334,
				Range: 0.8333333333333334,
			},
			defensiveDamageAffinity: {
				Melee: 1.25,
				Magic: 1.25,
				Range: 1.25,
				Piercing: 1.33,
				Blunt: 1.33,
				Slashing: 1.33,
			},
			armorBonus: {
				protection: 88,
				resistance: 88,
				agility: 26,
				stamina: 32,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.4,
				},
				{
					stat: 'armorBonus.resistance',
					value: 4.4,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 1.6,
				},
			],
			itemSet: [2001, 10034, 10029, 10033],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Rare',
			craftable: true,
			description: 'A shield that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1316: {
		name: 'Mantis Shrimp Gauntlets',
		id: 1316,
		enchantmentTier: 7,
		value: 12000000,
		tradeable: true,
		itemImage: '/images/combat/equipment/mantis_shrimp_knuckles_icon.png',
		extraTooltipInfo:
			'A pair of gauntlets that infuse your punches with the speed and power of the strongest shrimp.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/mantis_shrimp_knuckles_1.png',
				css: 'mantis-gloves-1',
			},
			{
				image: '/images/combat/equipment/mantis_shrimp_knuckles_2.png',
				css: 'mantis-gloves-2',
			},
			{
				image: '/images/combat/equipment/mantis_shrimp_knuckles_3.png',
				css: 'mantis-gloves-3',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			grantedAbility: [155, 156, 157],
			slot: 'gloves',
			weaponBonus: {
				strength: 11,
				intellect: 11,
				dexterity: 11,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 11,
				Magic: 11,
				Range: 11,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
				Chaos: 1.06,
			},
			offensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
				Chaos: 1.06,
			},
			armorBonus: {
				protection: 24,
				resistance: 24,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
			],
			itemSet: [2001, 10034, 10029, 10033, 10032, 10031, 10030],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Rare',
			craftable: true,
			description: 'A pair of gauntlets that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1317: {
		id: 1317,
		name: 'Void-Blessed Shrimp',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/materials/void_shrimp.png',
		extraTooltipInfo:
			'It promises vast seas of knowledge if only you would free it. Perhaps it could teach you to use Shrimp Scraps to make equipment?',
		class: 'bar',
		tags: ['shard'],
		rarity: 'legendary',
	},
	1318: {
		name: 'Witchhunter Handgonne',
		id: 1318,
		tradeable: true,
		enchantmentTier: 7,
		value: 1263400,
		requiredLevel: {
			range: 75,
		},
		itemImage: '/images/combat/equipment/handgonne.png',
		visual: 'handgonne',
		class: 'equipment',
		tags: ['range'],
		equipmentStats: {
			grantedAbility: [163, 12, 158, 159, 160, 161],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.06666666666666667,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 179,
				Piercing: 208,
				Blunt: 156,
				Slashing: 22,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.05,
				Piercing: 1.35,
				Blunt: 1.35,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 142,
			},
			attackSpeed: 3.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 9,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 7.1,
				},
			],
			oneHanded: true,
		},
		rarity: 'epic',
		extraTooltipInfo:
			'A weapon that combines the ranged prowess of the elves with the technical ingenuity of the dwarves. A true breakthrough! Can technically be fired one handed.',
	},
	1319: {
		name: 'Personal Cannon',
		id: 1319,
		tradeable: true,
		enchantmentTier: 8,
		value: 3263400,
		requiredLevel: {
			range: 80,
		},
		itemImage: '/images/combat/equipment/cannon.png',
		visual: 'cannon',
		class: 'equipment',
		tags: ['range'],
		equipmentStats: {
			grantedAbility: [162, 12, 158, 159, 160, 161],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.030303030303030304,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -24,
				Magic: -24,
				Range: 228,
				Piercing: 266,
				Blunt: 266,
				Slashing: 22,
			},
			offensiveDamageAffinity: {
				Magic: 0.75,
				Melee: 0.75,
				Range: 1.1,
				Piercing: 1.45,
				Blunt: 1.45,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 188,
			},
			armorBonus: {
				protection: 32,
				resistance: 32,
				agility: -64,
				stamina: 0,
			},
			attackSpeed: 3.6,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 11.4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 9.4,
				},
				{
					stat: 'armorBonus.protection',
					value: 2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2,
				},
			],
			oneHanded: true,
		},
		rarity: 'epic',
		extraTooltipInfo:
			'The next logical step to a handgonne- a really big one! A little slower to operate, but packs a much larger punch. Unfortunately does not seem to help with your agility, but at least you can hide behind it!',
	},
	1450: {
		name: 'Shoddy Ammo',
		id: 1450,
		tradeable: true,
		value: 1,
		ammunitionMults: {
			damageMult: 1,
			accuracyMult: 1,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/arrow_0.png',
		extraTooltipInfo: 'A bunch of shoddily crafted ammunition for ranged weapons.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 1,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1451: {
		name: 'Amateur Ammo',
		id: 1451,
		tradeable: true,
		value: 2,
		requiredLevel: {
			range: 10,
		},
		ammunitionMults: {
			damageMult: 1.05,
			accuracyMult: 1.05,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/arrow_1.png',
		extraTooltipInfo: 'Ammo crafted by an amateur.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		craftingStats: {
			level: 10,
			experience: 15,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1452: {
		name: 'Apprentice Ammo',
		id: 1452,
		tradeable: true,
		value: 7,
		requiredLevel: {
			range: 20,
		},
		ammunitionMults: {
			damageMult: 1.1,
			accuracyMult: 1.1,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/arrow_2.png',
		extraTooltipInfo: 'Ammo that would be passable as being crafted by an apprentice crafter.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		craftingStats: {
			level: 20,
			experience: 40,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1453: {
		name: 'Journeyman Ammo',
		id: 1453,
		tradeable: true,
		value: 14,
		requiredLevel: {
			range: 30,
		},
		ammunitionMults: {
			damageMult: 1.15,
			accuracyMult: 1.15,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/arrow_3.png',
		extraTooltipInfo: 'Ammo that would be passable as being crafted by a journeyman crafter.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		craftingStats: {
			level: 30,
			experience: 155,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1454: {
		name: 'Professional Ammo',
		id: 1454,
		tradeable: true,
		value: 21,
		requiredLevel: {
			range: 40,
		},
		ammunitionMults: {
			damageMult: 1.2,
			accuracyMult: 1.2,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/arrow_4.png',
		extraTooltipInfo: 'Ammo that has clearly been crafted by a professional.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		craftingStats: {
			level: 40,
			experience: 195,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1455: {
		name: 'Master Ammo',
		id: 1455,
		tradeable: true,
		value: 28,
		requiredLevel: {
			range: 50,
		},
		ammunitionMults: {
			damageMult: 1.2,
			accuracyMult: 1.3,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/shot.png',
		extraTooltipInfo:
			'Ammo that has been crafted by a master and is clearly intended for more exotic weaponry. Has enhanced accuracy due to its lighter materials.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 310,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1456: {
		name: 'Experimental Ammo',
		id: 1456,
		tradeable: true,
		value: 35,
		requiredLevel: {
			range: 60,
		},
		ammunitionMults: {
			damageMult: 1.25,
			accuracyMult: 1.25,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/shot_void_0.png',
		extraTooltipInfo:
			'An experimental ammo that is on the edge of science and magic! Has enhanced damage due to its experimental nature.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 440,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1457: {
		name: 'Void Ammo',
		id: 1457,
		tradeable: true,
		value: 42,
		requiredLevel: {
			range: 70,
		},
		ammunitionMults: {
			damageMult: 1.3,
			accuracyMult: 1.3,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/shot_void_1.png',
		extraTooltipInfo:
			'The fully realized peak of ammunition technology made from the coalescence of science and magic.',
		class: 'ammo',
		tags: ['range', 'ammunition', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 70,
			experience: 400,
			category: 'Range',
			craftable: true,
			multiplier: 32,
			description: 'Each craft results in 32 projectiles.',
		},
	},
	1458: {
		name: 'Low-Grade Whetstone',
		id: 1458,
		tradeable: true,
		value: 20,
		requiredLevel: {
			strength: 20,
		},
		ammunitionMults: {
			damageMult: 1.1,
			accuracyMult: 1.1,
			style: 'Melee',
		},
		itemImage: '/images/combat/equipment/melee/low_whetstone.PNG',
		extraTooltipInfo: 'For basic weapon maintenance.',
		class: 'ammo',
		tags: ['melee', 'ammunition', 'craftable'],
		craftingStats: {
			level: 20,
			experience: 33,
			category: 'Melee',
			craftable: true,
			multiplier: 32,
		},
	},
	1459: {
		name: 'Mid-Grade Whetstone',
		id: 1459,
		tradeable: true,
		value: 40,
		requiredLevel: {
			strength: 40,
		},
		ammunitionMults: {
			damageMult: 1.2,
			accuracyMult: 1.2,
			style: 'Melee',
		},
		itemImage: '/images/combat/equipment/melee/mid_whetstone.png',
		extraTooltipInfo: 'For advanced weapon maintenance.',
		class: 'ammo',
		tags: ['melee', 'ammunition', 'craftable'],
		craftingStats: {
			level: 40,
			experience: 465,
			category: 'Melee',
			craftable: true,
			multiplier: 80,
		},
	},
	1460: {
		name: 'High-Grade Whetstone',
		id: 1460,
		tradeable: true,
		value: 60,
		requiredLevel: {
			strength: 60,
		},
		ammunitionMults: {
			damageMult: 1.3,
			accuracyMult: 1.3,
			style: 'Melee',
		},
		itemImage: '/images/combat/equipment/melee/high_whetstone.png',
		extraTooltipInfo: 'To make your weapon perform better than it ever has before.',
		class: 'ammo',
		tags: ['melee', 'ammunition', 'craftable'],
		craftingStats: {
			level: 60,
			experience: 1020,
			category: 'Melee',
			craftable: true,
			multiplier: 96,
		},
	},
	1461: {
		name: 'Lesser Imbued Charm',
		id: 1461,
		tradeable: true,
		value: 20,
		requiredLevel: {
			magic: 20,
		},
		ammunitionMults: {
			damageMult: 1.1,
			accuracyMult: 1.1,
			style: 'Magic',
		},
		itemImage: '/images/combat/equipment/magic/conc_rune_1.png',
		extraTooltipInfo: 'Imbued energy within this stone is released when casting spells to somewhat empower them.',
		class: 'ammo',
		tags: ['magic', 'ammunition', 'craftable'],
		craftingStats: {
			level: 20,
			experience: 33,
			category: 'Magic',
			craftable: true,
			multiplier: 72,
		},
	},
	1462: {
		name: 'Major Imbued Charm',
		id: 1462,
		tradeable: true,
		value: 40,
		requiredLevel: {
			magic: 40,
		},
		ammunitionMults: {
			damageMult: 1.2,
			accuracyMult: 1.2,
			style: 'Magic',
		},
		itemImage: '/images/combat/equipment/magic/conc_rune_2.png',
		extraTooltipInfo: 'Imbued energy within this stone is released when casting spells to empower them.',
		class: 'ammo',
		tags: ['magic', 'ammunition', 'craftable'],
		craftingStats: {
			level: 40,
			experience: 105,
			category: 'Magic',
			craftable: true,
			multiplier: 72,
		},
	},
	1463: {
		name: 'Greater Imbued Charm',
		id: 1463,
		tradeable: true,
		value: 60,
		requiredLevel: {
			magic: 60,
		},
		ammunitionMults: {
			damageMult: 1.3,
			accuracyMult: 1.3,
			style: 'Magic',
		},
		itemImage: '/images/combat/equipment/magic/conc_rune_3.png',
		extraTooltipInfo: 'Imbued energy within this stone is released when casting spells to greatly empower them.',
		class: 'ammo',
		tags: ['magic', 'ammunition', 'craftable'],
		craftingStats: {
			level: 60,
			experience: 275,
			category: 'Magic',
			craftable: true,
			multiplier: 72,
		},
	},
	1464: {
		name: 'Ancient Imbued Charm',
		id: 1464,
		tradeable: true,
		value: 200,
		requiredLevel: {
			magic: 80,
		},
		ammunitionMults: {
			damageMult: 1.4,
			accuracyMult: 1.4,
			style: 'Magic',
		},
		itemImage: '/images/combat/equipment/magic/conc_rune_4.PNG',
		extraTooltipInfo: 'Created by a technique lost to time. Massively empowers casted spells.',
		class: 'ammo',
		tags: ['magic', 'ammunition'],
		rarity: 'rare',
	},
	1465: {
		name: 'Dwarven Whetstone',
		id: 1465,
		tradeable: true,
		value: 200,
		requiredLevel: {
			strength: 80,
		},
		ammunitionMults: {
			damageMult: 1.4,
			accuracyMult: 1.4,
			style: 'Melee',
		},
		itemImage: '/images/combat/equipment/melee/dwarven_whetstone.png',
		extraTooltipInfo: 'Created by a technique lost to time. Massively improves your weapons performance.',
		class: 'ammo',
		tags: ['melee', 'ammunition'],
		rarity: 'rare',
	},
	1466: {
		name: 'Elven Ammo',
		id: 1466,
		tradeable: true,
		value: 200,
		requiredLevel: {
			range: 80,
		},
		ammunitionMults: {
			damageMult: 1.4,
			accuracyMult: 1.4,
			style: 'Range',
		},
		itemImage: '/images/combat/equipment/range/ammo_horn.PNG',
		extraTooltipInfo:
			'Created by a technique lost to time. Whatever magic empowers this ammo massively improves your weapons performance.',
		class: 'ammo',
		tags: ['melee', 'ammunition'],
		rarity: 'rare',
	},
	1500: {
		name: 'Santa Hat',
		id: 1500,
		enchantmentTier: 5,
		value: 42069,
		tradeable: true,
		itemImage: '/images/combat/equipment/santa_hat.png',
		extraTooltipInfo: 'Tis the season.',
		class: 'equipment',
		visual: 'santa-hat',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			slot: 'helm',
			isTransmog: true,
			cosmeticSlot: 'helm',
		},
		rarity: 'uncommon',
	},
	1501: {
		name: 'Snowman Head',
		id: 1501,
		enchantmentTier: 5,
		value: 42069,
		tradeable: true,
		itemImage: '/images/combat/equipment/snowman_head.png',
		extraTooltipInfo: "You'd be surprised how comfortable this feels.",
		class: 'equipment',
		visual: 'snowman-head',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			slot: 'helm',
			isTransmog: true,
			cosmeticSlot: 'helm',
		},
		rarity: 'uncommon',
	},
	1502: {
		name: 'Tophat',
		id: 1502,
		enchantmentTier: 5,
		value: 42069,
		tradeable: true,
		itemImage: '/images/combat/equipment/tophat.png',
		extraTooltipInfo: 'For the classy ones.',
		class: 'equipment',
		visual: 'tophat',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			slot: 'helm',
			isTransmog: true,
			cosmeticSlot: 'helm',
		},
		rarity: 'uncommon',
	},
	1503: {
		name: 'Lesser Ladle',
		id: 1503,
		enchantmentTier: 5,
		value: 20000,
		tradeable: true,
		enchantmentOverrideSlot: 'ladle',
		overrideItemTier: 3,
		itemImage: '/images/combat/equipment/lesser_ladle.png',
		extraTooltipInfo:
			"This could help you with cooking even if it doesn't look like much! Grants 2 hidden levels of haste while cooking.",
		class: 'equipment',
		tags: ['cooking'],
		equipmentStats: {
			slot: 'ladle',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 25,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 1.5,
				},
			],
		},
		rarity: 'uncommon',
	},
	1504: {
		name: 'Ladle',
		id: 1504,
		enchantmentTier: 5,
		value: 25000,
		tradeable: true,
		enchantmentOverrideSlot: 'ladle',
		overrideItemTier: 3,
		itemImage: '/images/combat/equipment/ladle.png',
		extraTooltipInfo: 'This could help you with cooking! Grants 2 hidden levels of haste while cooking.',
		class: 'equipment',
		tags: ['cooking'],
		equipmentStats: {
			slot: 'ladle',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 25,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 1.5,
				},
			],
		},
		rarity: 'uncommon',
	},
	1505: {
		name: 'Greater Ladle',
		id: 1505,
		enchantmentTier: 5,
		value: 30000,
		tradeable: true,
		enchantmentOverrideSlot: 'ladle',
		overrideItemTier: 3,
		itemImage: '/images/combat/equipment/greater_ladle.png',
		extraTooltipInfo:
			"For when you're allowed only a single spoonful. Grants 2 hidden levels of haste while cooking.",
		class: 'equipment',
		tags: ['cooking'],
		equipmentStats: {
			slot: 'ladle',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 25,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 1.5,
				},
			],
		},
		rarity: 'uncommon',
	},
	1506: {
		name: 'Moss Maul',
		id: 1506,
		enchantmentTier: 8,
		value: 6360000,
		tradeable: true,
		requiredLevel: {
			strength: 85,
		},
		itemImage: '/images/combat/equipment/moss_maul.png',
		itemIcon: '/images/combat/equipment/moss_maul_icon.png',
		extraTooltipInfo: "A hulking mass of moss covered material; requires a giant's strength to wield properly!",
		class: 'equipment',
		visual: 'moss-maul',
		tags: ['melee', 'twohand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [13, 45, 136, 137, 146],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.025,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 103,
				Magic: -20,
				Range: -20,
				Piercing: -4,
				Blunt: 256,
				Slashing: 44,
				Nature: 96,
			},
			offensiveDamageAffinity: {
				Melee: 1.3,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.125,
				Blunt: 1.5,
				Slashing: 0.25,
				Nature: 1.33,
			},
			weaponBonus: {
				strength: 196,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 36,
				resistance: 0,
				agility: -8,
				stamina: 0,
			},
			attackSpeed: 3.6,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 5.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 9.8,
				},
				{
					stat: 'armorBonus.protection',
					value: 1.8,
				},
			],
			itemSet: [10015],
		},
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 233500,
			category: 'Melee',
			craftable: true,
		},
	},
	1507: {
		name: 'Modified Diving Gloves',
		id: 1507,
		enchantmentTier: 6,
		value: 2120000,
		tradeable: true,
		requiredLevel: {
			strength: 55,
		},
		itemImage: '/images/combat/equipment/modified_diving_gloves.png',
		itemIcon: '/images/combat/equipment/modified_diving_gloves_icon.png',
		extraTooltipInfo: 'Ready to punch anything you might encounter underwater or above!',
		class: 'equipment',
		visual: 'modified-diving-gloves',
		tags: ['melee', 'twohand', 'ability'],
		equipmentStats: {
			grantedAbility: [44, 51, 148],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 88,
				Magic: -10,
				Range: -10,
				Piercing: 55,
				Blunt: -8,
				Slashing: 55,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.05,
				Blunt: 0.75,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 41,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 6,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.1,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.4,
				},
			],
		},
		rarity: 'rare',
	},
	1508: {
		name: "King's Crown",
		id: 1508,
		enchantmentTier: 8,
		value: 6360000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
			strength: 85,
		},
		itemImage: '/images/combat/equipment/kings_crown.png',
		extraTooltipInfo: "The crown of a Valley's true forever king.",
		class: 'equipment',
		visual: 'kings-crown',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [19],
			slot: 'helm',
			defensiveCritical: {
				chance: 0.25,
				damageMultiplier: 0.75,
			},
			offensiveDamageAffinity: {
				Melee: 0.95,
				Range: 0.95,
				Magic: 0.95,
				Fire: 0.7,
				Ice: 1.33,
			},
			defensiveDamageAffinity: {
				Fire: 1.1,
				Ice: 1.33,
				Melee: 1.33,
				Piercing: 1.33,
				Slashing: 1.33,
				Blunt: 1.33,
				Magic: 1.33,
				Range: 1.33,
			},
			weaponBonus: {
				strength: 0,
				intellect: -24,
				dexterity: -24,
			},
			armorBonus: {
				protection: 120,
				resistance: 64,
				agility: -24,
				stamina: 32,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 6,
				},
				{
					stat: 'armorBonus.resistance',
					value: 3.2,
				},
				{
					stat: 'armorBonus.stamina',
					value: 1.6,
				},
			],
			itemSet: [10009, 10016, 10015],
		},
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 378000,
			category: 'Melee',
			craftable: true,
		},
	},
	1509: {
		name: 'Fire Orb',
		id: 1509,
		enchantmentTier: 8,
		value: 6360000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
			magic: 85,
		},
		itemImage: '/images/combat/equipment/fire_orb.png',
		extraTooltipInfo: 'This orb of living flame sharpens the senses to an inhuman degree.',
		class: 'equipment',
		visual: 'fire-orb',
		tags: ['armor', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [7, 140],
			slot: 'shield',
			defensiveCritical: {
				chance: 0.01,
				damageMultiplier: 0.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 12,
				Fire: 24,
				Magic: 36,
				Range: 0,
			},
			offensiveDamageAffinity: {
				Fire: 1.25,
				Melee: 1,
				Magic: 1.15,
			},
			defensiveDamageAffinity: {
				Fire: 1.06,
				Melee: 1.02,
				Magic: 1.04,
				Range: 1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 24,
				dexterity: 0,
			},
			armorBonus: {
				protection: 48,
				resistance: 88,
				agility: -4,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 4.4,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.3,
				},
			],
			itemSet: [10013, 10015, 10036],
		},
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 57500,
			category: 'Magic',
			craftable: true,
		},
	},
	1510: {
		id: 1510,
		name: 'Kalanahmatti',
		value: 3360000,
		tradeable: true,
		itemImage: '/images/combat/equipment/kalanahmatti.png',
		extraTooltipInfo:
			"A mere fragment of the legendary spear 'Kalanahmatti' can still be fashioned into a powerful weapon!",
		class: 'bar',
		tags: ['crafting', 'shard'],
		rarity: 'epic',
	},
	1511: {
		name: 'Shard of Kalanahmatti',
		id: 1511,
		enchantmentTier: 8,
		value: 6360000,
		tradeable: true,
		requiredLevel: {
			strength: 85,
		},
		itemImage: '/images/combat/equipment/shard_of_kalanahmatti.png',
		itemIcon: '/images/combat/equipment/shard_of_kalanahmatti_icon.png',
		class: 'equipment',
		visual: 'shard-of-kalanahmatti',
		extraTooltipInfo: "This powerful blade is fashioned from a shard of the legendary spear 'Kalanahmatti.'",
		tags: ['melee', 'onehand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [8, 68, 145],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 94,
				Slashing: 123,
				Ice: 32,
				Magic: 14,
				Range: -14,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Slashing: 1.33,
				Blunt: 0.25,
				Piercing: 0.25,
				Magic: 0.85,
				Ice: 1.25,
				Range: 0.75,
			},
			weaponBonus: {
				strength: 109,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 4.7,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Ice',
					value: 1.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 5.5,
				},
			],
			itemSet: [10015],
		},
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 106000,
			category: 'Melee',
			craftable: true,
			description: "Powerful weapon made out of the legendary spear 'Kalanahmatti'.",
		},
	},
	1512: {
		name: 'Zero Edge',
		id: 1512,
		enchantmentTier: 6,
		value: 3360000,
		tradeable: true,
		requiredLevel: {
			attack: 65,
			strength: 65,
		},
		itemImage: '/images/combat/equipment/zero_edge.png',
		itemIcon: '/images/combat/equipment/zero_edge_icon.png',
		class: 'equipment',
		extraTooltipInfo: 'Lightning never strikes twice, so surely this is an anomaly.',
		visual: 'zero',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [16, 50, 98, 97],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 68,
				Magic: 14,
				Range: -14,
				Slashing: 78,
				Lightning: 44,
			},
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
				Lightning: 1.5,
			},
			defensiveDamageAffinity: {
				Lightning: 1.33,
			},
			weaponBonus: {
				strength: 60,
				intellect: 20,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.2,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.4,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Slashing',
					value: 3.9,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Lightning',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
			],
		},
		rarity: 'epic',
	},
	1513: {
		name: 'Chaos Crest',
		id: 1513,
		enchantmentTier: 3,
		value: 68000,
		tradeable: true,
		requiredLevel: {
			defense: 20,
		},
		enchantmentOverrideSlot: 'smithingset',
		itemImage: '/images/combat/equipment/crest_of_chaos.png',
		itemIcon: '/images/combat/equipment/crest_of_chaos_icon.png',
		extraTooltipInfo: 'The heat of this helm could empower your smithing abilities!',
		class: 'equipment',
		visual: 'crest-of-chaos',
		tags: ['armor', 'smithing', 'ability'],
		equipmentStats: {
			grantedAbility: [20],
			slot: 'helm',
			offensiveAccuracyAffinityRating: {
				Fire: 64,
			},
			offensiveDamageAffinity: {
				Fire: 1.15,
			},
			defensiveDamageAffinity: {
				Fire: 1.15,
			},
			weaponBonus: {
				strength: 0,
				intellect: 12,
				dexterity: 0,
			},
			armorBonus: {
				protection: 12,
				resistance: 18,
				agility: 0,
				stamina: 0,
			},
			toolBoost: [
				{
					skill: 'smithing',
					boost: 5,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1.5,
				},
			],
			itemSet: [10023, 10024],
		},
		rarity: 'rare',
	},
	1514: {
		name: 'Forgotten Sadness',
		id: 1514,
		enchantmentTier: 7,
		value: 3360000,
		tradeable: true,
		requiredLevel: {
			strength: 70,
			range: 70,
		},
		itemImage: '/images/combat/equipment/forgotten-soul-axe.png',
		itemIcon: '/images/combat/equipment/forgotten-soul-sad-icon.png',
		extraTooltipInfo: 'Long forgotten in the waves. Can you throw it?',
		class: 'equipment',
		visual: 'forgotten-soul-axe',
		tags: ['melee', 'onehand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [35],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 24,
				Magic: -12,
				Range: 55,
				Piercing: -4,
				Blunt: 132,
				Slashing: 88,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 1,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 72,
				intellect: 0,
				dexterity: 107,
			},
			oneHanded: true,
			attackSpeed: 2.7,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3.6,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 2.8,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 5.4,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 70,
			experience: 300,
			category: 'Range',
			craftable: true,
			description: 'Emotionally unstable.',
		},
	},
	1515: {
		name: 'Forgotten Madness',
		id: 1515,
		enchantmentTier: 7,
		value: 3360000,
		tradeable: true,
		requiredLevel: {
			strength: 70,
			magic: 70,
		},
		itemImage: '/images/combat/equipment/forgotten-soul-scimitar.png',
		itemIcon: '/images/combat/equipment/forgotten-soul-crazy-icon.png',
		extraTooltipInfo: 'Driven mad by its endless drifting. Appears to channel magical energy.',
		class: 'equipment',
		visual: 'forgotten-soul-scim',
		tags: ['melee', 'onehand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [35],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 48,
				Magic: 66,
				Range: -14,
				Slashing: 77,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 1,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 64,
				intellect: 71,
				dexterity: 0,
			},
			oneHanded: true,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3.2,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3.6,
				},
			],
			attackSpeed: 2.3,
		},
		rarity: 'epic',
		craftingStats: {
			level: 70,
			experience: 300,
			category: 'Magic',
			craftable: true,
			description: 'Emotionally unstable.',
		},
	},
	1516: {
		name: 'Forgotten Anger',
		id: 1516,
		enchantmentTier: 7,
		value: 3360000,
		tradeable: true,
		requiredLevel: {
			strength: 70,
		},
		itemImage: '/images/combat/equipment/forgotten-soul-two-handed.png',
		itemIcon: '/images/combat/equipment/forgotten-soul-mad-icon.png',
		class: 'equipment',
		extraTooltipInfo: 'Perhaps forgotten, absolutely enraged. Only good for melee!',
		visual: 'forgotten-soul-two',
		tags: ['melee', 'twohand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [35],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.04,
				damageMultiplier: 1.15,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 77,
				Magic: -20,
				Range: -20,
				Piercing: 110,
				Blunt: 44,
				Slashing: 132,
			},
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.66,
				Blunt: 0.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 131,
				intellect: 0,
				dexterity: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 6.6,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 70,
			experience: 300,
			category: 'Melee',
			craftable: true,
			description: 'Emotionally unstable.',
		},
	},
	1517: {
		name: 'Gargoyle Chainmail',
		id: 1517,
		enchantmentTier: 6,
		value: 1232000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			strength: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_chain.png',
		class: 'equipment',
		visual: 'chainbody-gargoyle',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.075,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.9,
				Range: 1,
				Piercing: 0.97,
				Blunt: 0.99,
				Slashing: 1.04,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 80,
				resistance: -3,
				agility: 20,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4,
				},
				{
					stat: 'armorBonus.agility',
					value: 1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1518: {
		name: 'Gargoyle Legs',
		id: 1518,
		enchantmentTier: 6,
		value: 1184000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_pants.png',
		class: 'equipment',
		visual: 'platelegs',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Melee: 1.05,
				Piercing: 1.075,
			},
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.9,
				Range: 1,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 70,
				resistance: -4,
				agility: -4,
				stamina: 9,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1519: {
		name: 'Gargoyle Boots',
		id: 1519,
		enchantmentTier: 6,
		value: 1136000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_boots.png',
		itemIcon: '/images/combat/equipment/gargoyle_boots_icon.png',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'boots',
			offensiveDamageAffinity: {
				Melee: 1.05,
				Piercing: 1.075,
			},
			armorBonus: {
				protection: 16,
				resistance: 16,
				agility: 0,
				stamina: 0,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.8,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
	},
	1520: {
		name: 'Gargoyle Helm',
		id: 1520,
		enchantmentTier: 6,
		value: 1136000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_helm_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/gargoyle_helm_1.png',
				css: 'gargoyle-helm-1',
			},
			{
				image: '/images/combat/equipment/gargoyle_helm_2.png',
				css: 'gargoyle-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.075,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.9,
				Range: 1,
				Piercing: 1,
				Blunt: 1,
				Slashing: 1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 30,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1521: {
		name: 'Gargoyle Shield',
		id: 1521,
		enchantmentTier: 6,
		value: 1232000,
		tradeable: true,
		requiredLevel: {
			defense: 60,
			strength: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_shield_1.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/gargoyle_shield_1.png',
				css: 'gargoyle-shield-1',
			},
			{
				image: '/images/combat/equipment/gargoyle_shield_2.png',
				css: 'gargoyle-shield-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 1.05,
				Piercing: 1.075,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.9,
				Range: 1,
				Piercing: 1.13,
				Blunt: 1.06,
				Slashing: 1.13,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 60,
				resistance: -1,
				agility: -1,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1522: {
		name: 'Gargoyle Falchion',
		id: 1522,
		enchantmentTier: 6,
		value: 1184000,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_falchion.png',
		itemIcon: '/images/combat/equipment/gargoyle_falcion_icon.png',
		class: 'equipment',
		visual: 'gargoyle-falchion',
		tags: ['melee', 'onehand', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 62,
				Magic: -14,
				Range: -14,
				Slashing: 70,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 68,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 4,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1523: {
		name: 'Gargoyle Daggers',
		id: 1523,
		enchantmentTier: 6,
		value: 1136000,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/dagger_gargoyle_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/dagger_gargoyle_1.png',
				css: 'gargoyle-daggers-1',
			},
			{
				image: '/images/combat/equipment/dagger_gargoyle_2.png',
				css: 'gargoyle-daggers-2',
			},
		],
		tags: ['melee', 'twohand', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 2,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 72,
				Magic: -10,
				Range: -10,
				Piercing: 43,
				Blunt: -8,
				Slashing: 43,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.33,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 38,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 10,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.9,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1524: {
		name: 'Gargoyle Glaive',
		id: 1524,
		enchantmentTier: 6,
		value: 980000,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/gargoyle_glaive.png',
		itemIcon: '/images/combat/equipment/gargoyle_glaive_icon.png',
		extraTooltipInfo: 'You feel like there is more potential to be unlocked here...',
		class: 'equipment',
		visual: 'spear',
		tags: ['melee', 'twohand', 'melee', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [22],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.35,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 78,
				Piercing: 138,
				Blunt: 52,
				Magic: -24,
				Range: -24,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.5,
				Blunt: 0.75,
				Slashing: 1.05,
				Magic: 0.75,
				Range: 0.75,
			},
			weaponBonus: {
				strength: 108,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 28,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			attackSpeed: 3,
			hitMults: {
				minimum: 0.33,
				maximum: 1.33,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 5.4,
				},
				{
					stat: 'armorBonus.protection',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
			description: 'Requires Defense level of 60 to wield.',
		},
	},
	1525: {
		name: 'Infernal Lance',
		id: 1525,
		enchantmentTier: 8,
		value: 4420000,
		tradeable: true,
		requiredLevel: {
			strength: 85,
		},
		itemImage: '/images/combat/equipment/infernal_lance.png',
		itemIcon: '/images/combat/equipment/infernal_lance_icon.png',
		extraTooltipInfo: 'A legendary weapon of great prestige and power.',
		class: 'equipment',
		visual: 'spear',
		tags: ['melee', 'twohand', 'ability', 'cosmetic', 'craftable'],
		equipmentStats: {
			grantedAbility: [22, 36, 61, 135],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.35,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 146,
				Piercing: 278,
				Slashing: 128,
				Blunt: 97,
				Fire: 60,
				Magic: -24,
				Range: -24,
			},
			offensiveDamageAffinity: {
				Melee: 1.175,
				Piercing: 1.75,
				Slashing: 1.1,
				Blunt: 0.75,
				Magic: 0.9,
				Fire: 1.33,
				Range: 0.75,
			},
			weaponBonus: {
				strength: 188,
				intellect: 44,
				dexterity: 0,
			},
			armorBonus: {
				protection: 44,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			attackSpeed: 3,
			hitMults: {
				minimum: 0.33,
				maximum: 1.33,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 7.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 9.4,
				},
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
			],
			isTransmog: true,
			itemSet: [10018],
		},
		rarity: 'legendary',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 75,
			experience: 643250,
			category: 'Melee',
			craftable: true,
			description: 'Trying to craft this would be madness.',
		},
	},
	1526: {
		name: 'Black Knight Great Helm',
		id: 1526,
		enchantmentTier: 6,
		tradeable: true,
		value: 192000,
		requiredLevel: {
			defense: 40,
			strength: 40,
		},
		itemImage: '/images/combat/equipment/black_black_knight_great_helm.png',
		itemIcon: '/images/combat/equipment/black_black_knight_great_helm_icon.png',
		extraTooltipInfo: 'A helm of unknown make that grants superhuman strength to its wearer!',
		class: 'equipment',
		visual: 'black-knight-full-helm',
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 1.33,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 0.81,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			weaponBonus: {
				strength: 32,
				intellect: -24,
				dexterity: -24,
			},
			armorBonus: {
				protection: 24,
				resistance: -6,
				agility: -12,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: -0.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.25,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
	},
	1527: {
		name: 'Chorus of Souls',
		id: 1527,
		enchantmentTier: 6,
		value: 2000000,
		tradeable: true,
		requiredLevel: {
			strength: 65,
		},
		itemImage: '/images/combat/equipment/chorus_of_souls.png',
		itemIcon: '/images/combat/equipment/chorus_of_souls_icon.png',
		extraTooltipInfo: 'It wails with every swing. Unnerving.',
		class: 'equipment',
		visual: 'chorus-of-souls',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [37, 100, 101],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 62,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 148,
				Slashing: 98,
			},
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 120,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 6,
				},
			],
		},
		rarity: 'rare',
	},
	1528: {
		name: 'Decayed Cloak',
		id: 1528,
		enchantmentTier: 2,
		value: 2560000,
		requiredLevel: {
			defense: 40,
			enchanting: 40,
		},
		tradeable: true,
		itemImage: '/images/combat/equipment/decayed_cloak.png',
		itemIcon: '/images/combat/equipment/decayed_cloak_hood.png',
		extraTooltipInfo: 'Despite its ragged appearance it is immensely durable. What could have caused this damage?',
		class: 'equipment',
		visual: 'cape',
		tags: ['magic', 'armor'],
		equipmentStats: {
			slot: 'cape',
			offensiveDamageAffinity: {
				Poison: 1.2,
			},
			defensiveDamageAffinity: {
				Poison: 1.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 9,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.resistance',
					value: 1.5,
				},
			],
		},
		rarity: 'epic',
	},
	1529: {
		name: 'Reinforced Cloak',
		id: 1529,
		enchantmentTier: 2,
		value: 560000,
		requiredLevel: {
			defense: 30,
			enchanting: 30,
		},
		tradeable: true,
		itemImage: '/images/combat/equipment/bestial_shawl.png',
		extraTooltipInfo: 'A standard cloak that has been reinforced to provide some measure of protection in combat.',
		class: 'equipment',
		visual: 'cape',
		tags: ['melee', 'armor', 'craftable'],
		equipmentStats: {
			slot: 'cape',
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 4,
				resistance: 4,
				agility: 4,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 25,
			experience: 10000,
			category: 'General',
			craftable: true,
		},
	},
	1530: {
		name: 'Mysterious Man',
		id: 1530,
		enchantmentTier: 5,
		value: 0,
		tradeable: true,
		itemImage: '/images/combat/equipment/hatcontest_mysterious_man.png',
		itemIcon: '/images/combat/equipment/hatcontest_mysterious_man_icon.png',
		extraTooltipInfo: 'Who are they? What are they? How did they get here?',
		class: 'equipment',
		visual: 'mysteriousman',
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			slot: 'helm',
			isTransmog: true,
			cosmeticSlot: 'helm',
		},
		rarity: 'uncommon',
	},
	1531: {
		name: 'Corny Joke',
		id: 1531,
		enchantmentTier: 5,
		value: 0,
		tradeable: true,
		itemImage: '/images/combat/equipment/hatcontest_cornyjoke.png',
		itemIcon: '/images/combat/equipment/hatcontest_cornyjoke_icon.png',
		extraTooltipInfo: 'You ever heard of this joke? Me neither',
		class: 'equipment',
		visual: 'cornyjoke',
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			slot: 'helm',
			isTransmog: true,
			cosmeticSlot: 'helm',
		},
		rarity: 'uncommon',
	},
	1532: {
		name: 'Death Metal Wig',
		id: 1532,
		enchantmentTier: 5,
		value: 0,
		tradeable: true,
		itemImage: '/images/combat/equipment/hatcontest_death_metal_wig_icon.png',
		extraTooltipInfo: 'Who said Death metal is all evil?',
		specialVisuals: [
			{
				image: '/images/combat/equipment/hatcontest_death_metal_wig_1.png',
				css: 'deathmetalwig-1',
			},
			{
				image: '/images/combat/equipment/hatcontest_death_metal_wig_2.png',
				css: 'deathmetalwig-2',
			},
		],
		class: 'equipment',
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			slot: 'helm',
			isTransmog: true,
			cosmeticSlot: 'helm',
		},
		rarity: 'uncommon',
	},
	1540: {
		name: 'Shrimp Carapace',
		id: 1540,
		enchantmentTier: 0,
		value: 10000,
		tradeable: false,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemImage: '/images/combat/equipment/shrimp_armor.png',
		itemIcon: '/images/combat/equipment/shrimp_armor_icon.png',
		extraTooltipInfo:
			"The chitinous shell of the Aberrant Shrimp formed into armor; it's tainted by some sort of demonic magic. Is this a blessing or a curse?",
		class: 'equipment',
		visual: 'shrimp-armor',
		tags: ['armor'],
		equipmentStats: {
			slot: 'body',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.06,
				Blunt: 0.88,
				Slashing: 1.06,
			},
			armorBonus: {
				protection: 44,
				resistance: 24,
				agility: -6,
				stamina: 12,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.7,
				},
			],
			itemSet: [2001, 10029],
		},
	},
	1541: {
		name: 'Shrimp Greaves',
		id: 1541,
		enchantmentTier: 0,
		value: 10000,
		tradeable: false,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemImage: '/images/combat/equipment/shrimp_pants.png',
		extraTooltipInfo:
			"The chitinous shell of the Aberrant Shrimp formed into greaves; it's tainted by some sort of demonic magic. Is this a blessing or a curse?",
		class: 'equipment',
		visual: 'shrimp-legs',
		tags: ['armor'],
		equipmentStats: {
			slot: 'legs',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.08,
				Blunt: 0.88,
				Slashing: 1.04,
			},
			armorBonus: {
				protection: 31,
				resistance: 11,
				agility: -4,
				stamina: 7,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.6,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.75,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [2001, 10029],
		},
	},
	1542: {
		name: 'Shrimp Helm',
		id: 1542,
		enchantmentTier: 0,
		value: 10000,
		tradeable: false,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemImage: '/images/combat/equipment/shrimp_helm.png',
		itemIcon: '/images/combat/equipment/shrimp_helm_icon.png',
		extraTooltipInfo:
			"The chitinous shell of the Aberrant Shrimp formed into a helmet; it's tainted by some sort of demonic magic. Is this a blessing or a curse?",
		class: 'equipment',
		visual: 'shrimp-helm',
		tags: ['armor'],
		equipmentStats: {
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			armorBonus: {
				protection: 22,
				resistance: 4,
				agility: -1,
				stamina: 6,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.75,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [2001, 10029],
		},
	},
	1543: {
		name: 'Shrimp Shell',
		id: 1543,
		enchantmentTier: 0,
		value: 10000,
		tradeable: false,
		requiredLevel: {
			defense: 30,
			magic: 30,
		},
		itemImage: '/images/combat/equipment/shrimp_shield_icon.png',
		extraTooltipInfo:
			"The chitinous shell of the Aberrant Shrimp formed into a shield; it's tainted by some sort of demonic magic. Is this a blessing or a curse?",
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/shrimp_shield_1.png',
				css: 'shrimp-shield-1',
			},
			{
				image: '/images/combat/equipment/shrimp_shield_2.png',
				css: 'shrimp-shield-2',
			},
		],
		tags: ['armor'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			defensiveDamageAffinity: {
				Melee: 0.99,
				Magic: 0.99,
				Range: 0.99,
				Piercing: 1.13,
				Blunt: 1.06,
				Slashing: 1.13,
			},
			armorBonus: {
				protection: 26,
				resistance: 12,
				agility: -1,
				stamina: 6,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.75,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.4,
				},
			],
			itemSet: [2001, 10029],
		},
	},
	1544: {
		name: "Naga's Bracelet",
		id: 1544,
		enchantmentTier: 0,
		overrideItemTier: 1,
		value: 75000,
		tradeable: true,
		itemImage: '/images/combat/equipment/nagas_bracelet.png',
		itemIcon: '/images/combat/equipment/nagas_bracelet.png',
		extraTooltipInfo:
			'The liquid inlaid into the carvings seems far too familiar... Wearing the bracelet gives you a craving you cannot describe; if used to create an enchanted piece of jewelry perhaps it will become more apparent to you, but you may run the risk of corrupting its original power...',
		class: 'equipment',
		visual: '',
		equipmentStats: {
			slot: 'gloves',
		},
		rarity: 'rare',
	},
	1545: {
		name: 'Titanic Gauntlet',
		id: 1545,
		enchantmentTier: 0,
		overrideItemTier: 7,
		value: 24000000,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		itemImage: '/images/combat/equipment/titanic_gauntlet.png',
		extraTooltipInfo: 'This gauntlet could become so much more if wielded by one with a strong will.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/titanic_gauntlet_2.png',
				css: 'awakened-titanic-gauntlet-gloves-2',
			},
			{
				image: '/images/combat/equipment/titanic_gauntlet_3.png',
				css: 'awakened-titanic-gauntlet-gloves-3',
			},
		],
		tags: ['armor', 'melee', 'range', 'magic', 'hybrid', 'craftable'],
		equipmentStats: {
			slot: 'gloves',
			weaponBonus: {
				strength: 11,
				intellect: 0,
				dexterity: 0,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 11,
				Magic: 11,
				Range: 11,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
			},
			armorBonus: {
				protection: 22,
				resistance: 22,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.1,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 80000,
			category: 'Rare',
			craftable: true,
			description: 'This gauntlet could become so much more if wielded by one with a strong will.',
		},
	},
	1546: {
		name: 'Awakened World Shaper',
		id: 1546,
		enchantmentTier: 7,
		value: 10000000,
		itemImage: '/images/combat/equipment/awakened_world_shaper.png',
		extraTooltipInfo: 'You feel as if infinite power lies within your grasp.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/awakened_world_shaper_2.png',
				css: 'awakened-titanic-gauntlet-gloves-2',
			},
			{
				image: '/images/combat/equipment/awakened_world_shaper_3.png',
				css: 'awakened-titanic-gauntlet-gloves-3',
			},
		],
		tags: ['armor', 'melee', 'range', 'magic', 'hybrid', 'ability'],
		equipmentStats: {
			grantedAbility: [72, 73, 74],
			slot: 'gloves',
			weaponBonus: {
				strength: 11,
				intellect: 11,
				dexterity: 11,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 11,
				Magic: 11,
				Range: 11,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 1.03,
				Range: 1.03,
			},
			armorBonus: {
				protection: 24,
				resistance: 24,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 0.6,
				},
			],
		},
		rarity: 'epic',
	},
	1547: {
		name: 'Shapeless Scythe',
		id: 1547,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			strength: 85,
		},
		itemImage: '/images/combat/equipment/Shapeless_Scythe.png',
		itemIcon: '/images/combat/equipment/Shapeless_Scythe_Icon.png',
		class: 'equipment',
		extraTooltipInfo: 'Its vorpal edge seems to shimmer and distort as you examine it.',
		visual: 'scythe',
		tags: ['melee', 'twohand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [22, 38, 11, 129],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 166,
				Slashing: 256,
				Piercing: 80,
				Blunt: 80,
				Magic: -22,
				Chaos: 88,
				Range: -22,
			},
			offensiveDamageAffinity: {
				Melee: 1.2,
				Slashing: 1.75,
				Piercing: 0.66,
				Blunt: 0.66,
				Magic: 0.9,
				Chaos: 1.5,
				Range: 0.75,
			},
			weaponBonus: {
				strength: 196,
				intellect: 96,
				dexterity: 0,
			},
			armorBonus: {
				protection: 24,
				resistance: 12,
				agility: 0,
				stamina: 0,
			},
			attackSpeed: 3,
			hitMults: {
				minimum: 0.4,
				maximum: 1.33,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 8.3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 9.8,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 4.4,
				},
			],
			itemSet: [10032],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 186000,
			category: 'Melee',
			craftable: true,
		},
	},
	1548: {
		name: "Giant King's Jewel",
		id: 1548,
		value: 2500000,
		tradeable: true,
		itemImage: '/images/magic/giants_gem.png',
		class: 'gem',
		extraTooltipInfo: 'You feel limitless energy pulsing from the jewel.',
		tags: ['crafting', 'craftable'],
		rarity: 'legendary',
		craftingStats: {
			level: 85,
			experience: 50000,
			category: 'Rare',
			craftable: true,
			description: 'Surely a jewel can be scavenged from these scraps.',
		},
	},
	1549: {
		name: 'Giant Scraps',
		id: 1549,
		value: 50000,
		tradeable: true,
		itemImage: '/images/misc/Giant_Scrap.png',
		class: 'bar',
		extraTooltipInfo: 'It would take a master craftsman to reshape this into something usable.',
		tags: ['shard', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 85,
			experience: 0,
			category: 'Rare',
			craftable: true,
			multiplier: 125,
			description: 'A crushed jewel counts as scrap, right?',
		},
	},
	1550: {
		name: 'World Walkers',
		id: 1550,
		enchantmentTier: 7,
		value: 24000000,
		tradeable: true,
		requiredLevel: {
			defense: 85,
			strength: 85,
		},
		itemImage: '/images/combat/equipment/World_Walkers_Silver_Version.png',
		itemIcon: '/images/combat/equipment/World_Walkers_Silver_Version_Icon.png',
		extraTooltipInfo: 'No terrain is too difficult to traverse with these on.',
		class: 'equipment',
		visual: 'winged-boots',
		tags: ['armor', 'melee', 'range', 'magic', 'hybrid', 'craftable'],
		equipmentStats: {
			slot: 'boots',
			defensiveDamageAffinity: {
				Melee: 1.04,
				Magic: 1.04,
				Range: 1.04,
			},
			weaponBonus: {
				strength: 4,
				intellect: 4,
				dexterity: 4,
			},
			armorBonus: {
				protection: 24,
				resistance: 24,
				agility: 12,
				stamina: 24,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.3,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 80000,
			category: 'Rare',
			craftable: true,
			description: 'No terrain is too difficult to traverse with these on.',
		},
	},
	1551: {
		name: "Giant King's Nails",
		id: 1551,
		value: 20,
		tradeable: false,
		itemImage: '/images/cooking/giants_toe_nail.png',
		extraTooltipInfo: '... You could try cooking it?',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
	},
	1552: {
		name: "Cooked Giant King's Nails",
		id: 1552,
		value: 20,
		tradeable: false,
		itemImage: '/images/cooking/Crispy_toe_nail.png',
		extraTooltipInfo: "Why would you eat this? That's disgusting.",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 10,
			cooldown: 10000,
			perTick: 12,
			totalTicks: 50,
			tickDelay: 10000,
			ignoreCooldown: false,
		},
	},
	1553: {
		name: 'Crystal Mace',
		id: 1553,
		enchantmentTier: 8,
		value: 6360000,
		tradeable: true,
		requiredLevel: {
			strength: 85,
			magic: 60,
		},
		itemImage: '/images/combat/equipment/crystal_mace.png',
		itemIcon: '/images/combat/equipment/crystal_mace_icon.png',
		extraTooltipInfo: "A manifested shard of chaos. It's able to channel magic fairly well, but seems unstable.",
		class: 'equipment',
		visual: 'crystal-mace',
		tags: ['melee', 'magic', 'hybrid', 'onehand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [13, 14, 149],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 98,
				Magic: 48,
				Range: -12,
				Piercing: 64,
				Blunt: 211,
				Slashing: 64,
				Chaos: 99,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 1.05,
				Range: 0.75,
				Piercing: 0.75,
				Blunt: 1.33,
				Slashing: 0.75,
				Chaos: 1.5,
			},
			weaponBonus: {
				strength: 164,
				intellect: 28,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 4.9,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 8.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3.5,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 117500,
			category: 'Melee',
			craftable: true,
		},
	},
	1554: {
		id: 1554,
		name: 'Signet Ring',
		value: 450000,
		tradeable: false,
		enchantmentTier: 3,
		forcedEnchant: 47,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/signet_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'Carries the signature of some unknown entity.',
		tags: ['ability'],
		requiredLevel: {
			defense: 60,
			strength: 60,
			magic: 60,
		},
		equipmentStats: {
			grantedAbility: [17, 150],
			slot: 'ring',
			offensiveAccuracyAffinityRating: {
				Melee: 5,
				Chaos: 5,
			},
			offensiveDamageAffinity: {
				Chaos: 1.5,
			},
			defensiveDamageAffinity: {
				Chaos: 1.13,
			},
			weaponBonus: {
				strength: 5,
				intellect: 5,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 1,
				},
			],
		},
		rarity: 'epic',
	},
	1555: {
		name: 'Mantle of Flame',
		id: 1555,
		enchantmentTier: 2,
		value: 3120000,
		tradeable: true,
		requiredLevel: {
			constitution: 40,
			defense: 40,
			magic: 40,
			enchanting: 60,
		},
		itemImage: '/images/combat/equipment/mantle_of_flame.gif',
		itemIcon: '/images/combat/equipment/mantle_of_flame.png',
		extraTooltipInfo: 'Wrap yourself in the cozy flames of a dying star and be reborn.',
		class: 'equipment',
		visual: 'phoenix-cape',
		tags: ['armor', 'magic', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [7, 18, 109, 110, 164],
			slot: 'cape',
			offensiveAccuracyAffinityRating: {
				Melee: 3,
				Magic: 15,
				Fire: 15,
				Range: 3,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 1.15,
				Range: 1,
				Fire: 1.35,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 1.04,
				Range: 1,
				Fire: 1.13,
			},
			weaponBonus: {
				strength: 0,
				intellect: 20,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 10,
				agility: 3,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.resistance',
					value: 1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Fire',
					value: 1,
				},
			],
			itemSet: [10004, 10036],
		},
		rarity: 'epic',
		craftingStats: {
			level: 60,
			experience: 36000,
			category: 'Magic',
			craftable: true,
		},
	},
	1556: {
		name: 'Bestial Shawl',
		id: 1556,
		enchantmentTier: 2,
		value: 2620000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			range: 40,
			enchanting: 50,
		},
		itemImage: '/images/combat/equipment/bestial_shawl.png',
		extraTooltipInfo:
			"A hunter's shawl designed to give its wearer the ability to easily handle their prey at extreme range.",
		class: 'equipment',
		visual: 'cape',
		tags: ['range', 'armor'],
		equipmentStats: {
			slot: 'cape',
			offensiveAccuracyAffinityRating: {
				Range: 15,
			},
			offensiveDamageAffinity: {
				Range: 1.1,
			},
			defensiveDamageAffinity: {
				Range: 1.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 10,
			},
			armorBonus: {
				protection: -12,
				resistance: -12,
				agility: 6,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.agility',
					value: 1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 1,
				},
			],
			itemSet: [10017],
		},
		rarity: 'epic',
	},
	1557: {
		name: 'Hands of Khaast',
		id: 1557,
		enchantmentTier: 7,
		value: 4120000,
		tradeable: true,
		requiredLevel: {
			strength: 75,
		},
		itemImage: '/images/combat/equipment/hands_of_khaast.png',
		itemIcon: '/images/combat/equipment/hands_of_khaast_icon.png',
		extraTooltipInfo:
			'You feel as if you could break the world in two with your bare hands and then burn it all down.',
		class: 'equipment',
		visual: 'modified-diving-gloves',
		tags: ['melee', 'twohand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [69, 70, 71, 142],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 118,
				Magic: -10,
				Range: -10,
				Piercing: 77,
				Blunt: 77,
				Slashing: -8,
				Fire: 48,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 1.05,
				Range: 0.75,
				Piercing: 1.05,
				Blunt: 1.05,
				Slashing: 0.9,
				Fire: 1.2,
			},
			weaponBonus: {
				strength: 99,
				intellect: 44,
				dexterity: 0,
			},
			armorBonus: {
				protection: 4,
				resistance: 4,
				agility: -4,
				stamina: 12,
			},
			attackSpeed: 2.2,
			hitMults: {
				minimum: 0.33,
				maximum: 1.2,
			},
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'weaponBonus.strength',
					value: 5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.7,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 69000,
			category: 'Melee',
			craftable: true,
		},
	},
	1558: {
		name: 'Shrimp Scraps',
		id: 1558,
		value: 100000,
		tradeable: true,
		itemImage: '/images/misc/shrimp_fragment.png',
		class: 'bar',
		extraTooltipInfo: 'It would take a master craftsman to reshape this into something usable.',
		tags: [],
		rarity: 'rare',
	},
	1559: {
		name: "Black Knight Titan's Helm",
		id: 1559,
		enchantmentTier: 6,
		tradeable: true,
		value: 4880000,
		requiredLevel: {
			defense: 70,
			strength: 70,
		},
		itemImage: '/images/combat/equipment/black_knight_titan_helm.png',
		itemIcon: '/images/combat/equipment/black_knight_titan_helm_icon.png',
		extraTooltipInfo: 'A helm that grants the strength of titans to its wearer!',
		class: 'equipment',
		visual: 'black-knight-full-helm',
		tags: ['armor', 'melee', 'ability'],
		equipmentStats: {
			grantedAbility: [15],
			slot: 'helm',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 1.33,
			},
			offensiveDamageAffinity: {
				Melee: 1.25,
				Blunt: 1.33,
				Magic: 0.1,
				Range: 0.1,
			},
			defensiveDamageAffinity: {
				Melee: 1.06,
				Magic: 0.81,
				Range: 1.06,
				Piercing: 1.08,
				Blunt: 0.94,
				Slashing: 1.08,
			},
			weaponBonus: {
				strength: 56,
				intellect: -32,
				dexterity: -32,
			},
			armorBonus: {
				protection: 48,
				resistance: -18,
				agility: -12,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: -0.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
			],
			itemSet: [10018],
		},
		rarity: 'epic',
	},
	1560: {
		name: 'Elven Hood',
		id: 1560,
		enchantmentTier: 6,
		tradeable: true,
		value: 5000000,
		requiredLevel: {
			defense: 65,
			magic: 65,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_helm.png',
		extraTooltipInfo: 'A magical hood of elven make.',
		class: 'equipment',
		visual: 'elven-helm',
		tags: ['armor', 'magic', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			defensiveDamageAffinity: {
				Magic: 1.08,
				Nature: 1.06,
			},
			weaponBonus: {
				strength: 6,
				intellect: 12,
				dexterity: 6,
			},
			armorBonus: {
				protection: 32,
				resistance: 12,
				agility: 18,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 1.5,
				},
				{
					stat: 'armorBonus.protection',
					value: 1.6,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.9,
				},
			],
			itemSet: [10011, 10012, 10014],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 40500,
			category: 'Magic',
			craftable: true,
		},
		scrappingFailItem: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
	},
	1561: {
		name: 'Elven Heavy Armor',
		id: 1561,
		enchantmentTier: 6,
		value: 5000000,
		tradeable: true,
		requiredLevel: {
			defense: 65,
			strength: 65,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_chest.png',
		class: 'equipment',
		visual: 'elven-chest',
		itemIcon: '/images/combat/equipment/range/elven_heavy.png',
		extraTooltipInfo: 'An unnaturally light platebody of elven make.',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1.08,
				Nature: 1.06,
			},
			weaponBonus: {
				strength: 12,
				intellect: 6,
				dexterity: 6,
			},
			armorBonus: {
				protection: 56,
				resistance: 6,
				agility: 28,
				stamina: 32,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 1.5,
				},
				{
					stat: 'armorBonus.protection',
					value: 2.9,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.5,
				},
			],
			itemSet: [10009, 10016, 10012, 10014],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 101500,
			category: 'Melee',
			craftable: true,
		},
		scrappingFailItem: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
	},
	1562: {
		name: 'Elven Leggings',
		id: 1562,
		enchantmentTier: 6,
		value: 5000000,
		tradeable: true,
		requiredLevel: {
			defense: 65,
			range: 65,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_legs.png',
		class: 'equipment',
		visual: 'elven-legs',
		extraTooltipInfo: 'A pair of comfortably fitting leggings of elven make.',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			defensiveDamageAffinity: {
				Range: 1.08,
				Nature: 1.06,
			},
			weaponBonus: {
				strength: 6,
				intellect: 6,
				dexterity: 16,
			},
			armorBonus: {
				protection: 32,
				resistance: 12,
				agility: 48,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 1.5,
				},
				{
					stat: 'armorBonus.protection',
					value: 1.6,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.7,
				},
				{
					stat: 'armorBonus.agility',
					value: 2.5,
				},
			],
			itemSet: [10010, 10012, 10014],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 34000,
			category: 'Range',
			craftable: true,
		},
		scrappingFailItem: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
	},
	1563: {
		name: 'Elven Boots',
		id: 1563,
		enchantmentTier: 6,
		value: 5000000,
		tradeable: true,
		requiredLevel: {
			defense: 65,
		},
		itemImage: '/images/combat/equipment/adamantite_boots.png',
		itemIcon: '/images/combat/equipment/range/elven_boots.png',
		class: 'equipment',
		visual: 'boots',
		extraTooltipInfo: 'A set of light cavalry boots of elven make.',
		tags: ['armor', 'craftable'],
		equipmentStats: {
			slot: 'boots',
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
				Nature: 1.05,
			},
			weaponBonus: {
				strength: 2,
				intellect: 2,
				dexterity: 2,
			},
			armorBonus: {
				protection: 12,
				resistance: 12,
				agility: 24,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.agility',
					value: 1.3,
				},
			],
			itemSet: [10010, 10012, 10014],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 48000,
			category: 'Range',
			craftable: true,
		},
		scrappingFailItem: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
	},
	1564: {
		name: 'Searing Ladle',
		id: 1564,
		enchantmentTier: 6,
		requiredLevel: {
			cooking: 50,
		},
		value: 25000,
		tradeable: true,
		enchantmentOverrideSlot: 'ladle',
		itemImage: '/images/combat/equipment/searing_ladle.png',
		extraTooltipInfo:
			'Almost too hot to handle! Burned food rewards full experience. Grants 3 hidden levels of haste while cooking.',
		class: 'equipment',
		tags: ['cooking', 'ability'],
		equipmentStats: {
			grantedAbility: [39, 53],
			slot: 'ladle',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 40,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 2,
				},
			],
		},
		rarity: 'epic',
	},
	1565: {
		name: "Black Knight's Gauntlets",
		id: 1565,
		enchantmentTier: 0,
		overrideItemTier: 6,
		value: 128000,
		tradeable: true,
		itemImage: '/images/combat/equipment/black_gloves.png',
		extraTooltipInfo: 'You feel like you could bend steel with these gauntlets on... but what is steel?',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/black_gloves_1.png',
				css: 'gloves-1',
			},
			{
				image: '/images/combat/equipment/black_gloves_2.png',
				css: 'gloves-2',
			},
			{
				image: '/images/combat/equipment/black_gloves_3.png',
				css: 'gloves-3',
			},
		],
		tags: ['armor', 'melee'],
		equipmentStats: {
			grantedAbility: [15],
			slot: 'gloves',
			weaponBonus: {
				strength: 12,
				intellect: 0,
				dexterity: 0,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 11,
				Blunt: 8,
				Slashing: 8,
				Piercing: 8,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.5,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Blunt',
					value: 1.5,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Slashing',
					value: 1.5,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Piercing',
					value: 1.5,
				},
			],
			itemSet: [10018],
		},
		rarity: 'epic',
	},
	1566: {
		name: 'Bestial Dagger',
		id: 1566,
		enchantmentTier: 5,
		value: 250000,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/beast_dagger_main.png',
		extraTooltipInfo:
			"A light and deadly dagger able to be wielded in one hand to great effect. Channel your inner beast! Other people won't judge you for it, probably...",
		class: 'equipment',
		visual: 'dagger-1h',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [40],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 2,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 69,
				Piercing: 49,
				Slashing: 49,
				Blunt: -8,
				Magic: -12,
				Range: -12,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Piercing: 1.1,
				Slashing: 1.1,
				Blunt: 0.1,
				Magic: 0.75,
				Range: 0.75,
			},
			weaponBonus: {
				strength: 22,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 8,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.1,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
			],
			oneHanded: true,
			attackSpeed: 2.2,
			itemSet: [10017],
		},
		rarity: 'rare',
	},
	1567: {
		name: 'Empowered Bestial Dagger',
		id: 1567,
		enchantmentTier: 7,
		value: 2500000,
		tradeable: true,
		requiredLevel: {
			strength: 70,
		},
		itemImage: '/images/combat/equipment/beast_dagger_main_e.png',
		extraTooltipInfo:
			"Empowered through magic and sheer rage. A light and deadly dagger able to be wielded in one hand to great effect. Channel your inner beast! Other people won't judge you for it, probably...",
		class: 'equipment',
		visual: 'dagger-1h',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [40, 41],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 2,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 104,
				Piercing: 74,
				Slashing: 74,
				Blunt: -8,
				Magic: -12,
				Range: -12,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.1,
				Slashing: 1.1,
				Blunt: 0.1,
				Magic: 0.75,
				Range: 0.75,
			},
			weaponBonus: {
				strength: 42,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 12,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 5.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2.1,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
			],
			oneHanded: true,
			attackSpeed: 2.1,
			itemSet: [10017],
		},
		rarity: 'epic',
	},
	1568: {
		name: 'Bestial Defender',
		id: 1568,
		enchantmentTier: 5,
		value: 250000,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/beast_dagger_off.png',
		extraTooltipInfo:
			'A light, yet strong, dagger able to be used in the offhand to slightly increase your offensive potential while remaining potent defensively.',
		class: 'equipment',
		visual: 'defender',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [40],
			slot: 'shield',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 1.5,
			},
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.5,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 35,
				Piercing: 25,
				Slashing: 25,
				Blunt: -8,
				Magic: -12,
				Range: -12,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Piercing: 1.05,
				Slashing: 1.05,
			},
			weaponBonus: {
				strength: 11,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 13,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.6,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.7,
				},
			],
			itemSet: [10017],
		},
		rarity: 'rare',
	},
	1569: {
		name: 'Empowered Bestial Defender',
		id: 1569,
		enchantmentTier: 7,
		value: 2500000,
		tradeable: true,
		requiredLevel: {
			strength: 70,
		},
		itemImage: '/images/combat/equipment/beast_dagger_off_e.png',
		extraTooltipInfo:
			'Empowered through magic and sheer rage. A light, yet strong, dagger able to be used in the offhand to slightly increase your offensive potential while remaining potent defensively.',
		class: 'equipment',
		visual: 'defender',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [40, 99],
			slot: 'shield',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 1.5,
			},
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.5,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 52,
				Piercing: 37,
				Slashing: 37,
				Blunt: -8,
				Magic: -12,
				Range: -12,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Piercing: 1.05,
				Slashing: 1.05,
			},
			weaponBonus: {
				strength: 16,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 18,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 0.8,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.9,
				},
			],
			itemSet: [10017],
		},
		rarity: 'epic',
	},
	1570: {
		name: "Elven King's Longbow",
		id: 1570,
		tradeable: true,
		enchantmentTier: 8,
		value: 14495000,
		requiredLevel: {
			range: 85,
		},
		itemImage: '/images/combat/equipment/range/bow_king.png',
		itemIcon: '/images/combat/equipment/range/bow_king.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo:
			'A weapon fit for royalty. Its craftsmanship is unmatched and the method to create weapons such as this is lost to time.',
		tags: ['range', 'twohand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [23, 42, 134],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -18,
				Magic: -18,
				Range: 164,
				Piercing: 192,
				Blunt: 35,
				Slashing: 35,
				Nature: 70,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 0.9,
				Range: 1.3,
				Piercing: 1.75,
				Blunt: 0.5,
				Slashing: 0.5,
				Nature: 1.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 160,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 8.3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 8,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Nature',
					value: 3.5,
				},
			],
			itemSet: [10014],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 246000,
			category: 'Range',
			craftable: true,
		},
		scrappingFailItem: {
			itemID: 4011,
			minimum: 24,
			maximum: 40,
		},
	},
	1571: {
		name: 'Ancient Trident',
		id: 1571,
		tradeable: true,
		enchantmentTier: 8,
		value: 24495000,
		requiredLevel: {
			magic: 85,
		},
		itemImage: '/images/combat/equipment/melee/trident.png',
		itemIcon: '/images/combat/equipment/melee/trident.png',
		class: 'equipment',
		visual: 'scimitar',
		extraTooltipInfo: "A weapon whose origins are lost to time; don't try using it as a fishing harpoon!",
		tags: ['magic', 'twohand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [43, 92, 93, 130],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 166,
				Ice: 256,
				Piercing: 80,
				Chaos: 22,
				Range: -22,
			},
			offensiveDamageAffinity: {
				Magic: 1.35,
				Melee: 0.9,
				Piercing: 1.5,
				Ice: 1.5,
				Chaos: 1.33,
				Range: 0.1,
			},
			weaponBonus: {
				strength: 96,
				intellect: 196,
				dexterity: 0,
			},
			armorBonus: {
				protection: 12,
				resistance: 24,
				agility: 0,
				stamina: 0,
			},
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 8.3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 9.8,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Ice',
					value: 12.8,
				},
			],
			itemSet: [10011, 10013, 10035],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 210000,
			category: 'Magic',
			craftable: true,
		},
	},
	1572: {
		name: 'Fishtail',
		id: 1572,
		enchantmentTier: 6,
		value: 2500000,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		itemImage: '/images/combat/equipment/fishtail.png',
		itemIcon: '/images/combat/equipment/fishtail.png',
		class: 'equipment',
		visual: 'fishtail',
		extraTooltipInfo: 'A large scimitar taken from a fish! You could probably use it as a paddle!',
		tags: ['melee', 'onehand'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 52,
				Magic: -14,
				Range: -14,
				Slashing: 66,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 60,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 2.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
			],
		},
		rarity: 'rare',
	},
	1573: {
		name: 'Razorfin',
		id: 1573,
		enchantmentTier: 7,
		value: 4495000,
		tradeable: true,
		requiredLevel: {
			strength: 70,
		},
		itemImage: '/images/combat/equipment/razorfin.png',
		itemIcon: '/images/combat/equipment/razorfin.png',
		class: 'equipment',
		visual: 'fishtail',
		extraTooltipInfo:
			'A large scimitar taken from a fish! You could probably use it as a paddle! Seems mildly magical, almost...',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [95, 94, 144],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 76,
				Magic: 4,
				Range: -14,
				Slashing: 84,
			},
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 0.25,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 80,
				intellect: 30,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.5,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3.8,
				},
				{
					stat: 'weaponBonus.strength',
					value: 4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1.5,
				},
			],
		},
		rarity: 'epic',
	},
	1574: {
		name: 'Elven Quiver',
		id: 1574,
		enchantmentTier: 5,
		value: 4495000,
		tradeable: true,
		requiredLevel: {
			range: 70,
			crafting: 70,
		},
		forcedEnchant: 63,
		forcedEnchantAmount: 5,
		itemImage: '/images/combat/equipment/range/quiver_elven_alt.png',
		class: 'equipment',
		visual: 'quiver-1',
		extraTooltipInfo:
			'A quiver blessed by Elven magic! It appears to retrieve your expended ammunition automatically!',
		tags: ['range'],
		equipmentStats: {
			slot: 'arrows',
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 0,
				Range: 15,
				Slashing: 0,
			},
			offensiveDamageAffinity: {
				Range: 1.15,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 20,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3,
				},
			],
		},
		rarity: 'epic',
		scrappingFailItem: {
			itemID: 4011,
			minimum: 24,
			maximum: 40,
		},
	},
	1575: {
		name: 'Magic Quiver',
		id: 1575,
		enchantmentTier: 3,
		value: 2495000,
		tradeable: true,
		requiredLevel: {
			range: 35,
			crafting: 35,
		},
		forcedEnchant: 63,
		forcedEnchantAmount: 3,
		itemImage: '/images/combat/equipment/range/quiver_elven.png',
		class: 'equipment',
		visual: 'quiver-2',
		extraTooltipInfo:
			'A slightly magical quiver that seems to retrieve some of your expended ammunition automatically!',
		tags: ['range'],
		equipmentStats: {
			slot: 'arrows',
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 0,
				Range: 5,
				Slashing: 0,
			},
			offensiveDamageAffinity: {
				Range: 1.05,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 10,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.5,
				},
			],
		},
		rarity: 'rare',
	},
	1576: {
		id: 1576,
		name: "Defender's Band",
		value: 500000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 19,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/defenders_band.png',
		class: 'equipment',
		extraTooltipInfo: 'A ring fashioned from the cornerstone of a great castle.',
		tags: ['ability'],
		requiredLevel: {
			defense: 60,
		},
		equipmentStats: {
			grantedAbility: [26],
			slot: 'ring',
			armorBonus: {
				protection: 1,
				resistance: 0,
				agility: -32,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'epic',
	},
	1577: {
		id: 1577,
		name: "Defender's Pendant",
		value: 500000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 65,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/defenders_pendant.png',
		class: 'equipment',
		extraTooltipInfo: 'A pendant fashioned from the cornerstone of a great castle.',
		tags: ['ability'],
		requiredLevel: {
			defense: 60,
			crafting: 60,
		},
		equipmentStats: {
			grantedAbility: [26],
			slot: 'arrows',
			armorBonus: {
				protection: 16,
				resistance: 16,
				agility: -32,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
			itemSet: [10009, 10016],
		},
		rarity: 'epic',
	},
	1578: {
		id: 1578,
		name: 'Scaled Circuit',
		value: 3450000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 19,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/scale_ring.png',
		class: 'equipment',
		extraTooltipInfo: "Some long forgotten beast's scales wreathe an ancient gemstone.",
		requiredLevel: {
			defense: 50,
		},
		equipmentStats: {
			slot: 'ring',
			armorBonus: {
				protection: 8,
				resistance: 8,
				agility: 0,
				stamina: 4,
			},
			defensiveDamageAffinity: {
				Range: 1.05,
				Piercing: 1.05,
				Slashing: 1.05,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2,
				},
				{
					stat: 'armorBonus.resistance',
					value: 2,
				},
			],
		},
		rarity: 'epic',
	},
	1579: {
		id: 1579,
		name: "Devourer's Band",
		value: 26450000,
		tradeable: true,
		enchantmentTier: 5,
		forcedEnchant: 38,
		forcedEnchantAmount: 5,
		itemImage: '/images/jewellery/ouroboros_ring.png',
		class: 'equipment',
		extraTooltipInfo: 'What was, will be. What will be, was.',
		requiredLevel: {
			defense: 92,
			attack: 92,
			constitution: 92,
		},
		equipmentStats: {
			slot: 'ring',
			offensiveDamageAffinity: {
				Melee: 1.25,
				Magic: 1.25,
				Range: 1.25,
			},
			defensiveDamageAffinity: {
				Melee: 0.5,
				Magic: 0.5,
				Range: 0.5,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 0,
				stamina: 66,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.stamina',
					value: 4,
				},
			],
		},
		rarity: 'legendary',
	},
	1580: {
		id: 1580,
		name: "Slayer's Pendant",
		value: 6450000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 38,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/slayer_pendant.png',
		class: 'equipment',
		extraTooltipInfo: 'A pendant depicting a sword through a skull. Small enough to fit in the palm of your hand!',
		tags: ['ability'],
		requiredLevel: {
			defense: 75,
			attack: 75,
			crafting: 75,
		},
		equipmentStats: {
			grantedAbility: [52],
			slot: 'arrows',
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1.05,
				Range: 1.05,
			},
			weaponBonus: {
				strength: 4,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 4,
				resistance: 0,
				agility: -4,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 1.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
			],
		},
		rarity: 'epic',
	},
	1581: {
		id: 1581,
		name: 'Alacrity Pendant',
		value: 8000000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 64,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/speed_pendant.png',
		class: 'equipment',
		extraTooltipInfo:
			'A pendant with a lightning bolt engraved on it. Though it greatly increases your speed, it also makes it harder to be accurate.',
		tags: ['ability'],
		requiredLevel: {
			defense: 90,
			crafting: 90,
		},
		equipmentStats: {
			slot: 'arrows',
			offensiveDamageAffinity: {
				Lightning: 1.15,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 15,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.agility',
					value: 1.5,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Lightning',
					value: 1,
				},
			],
		},
		rarity: 'legendary',
	},
	1582: {
		id: 1582,
		name: 'Ring of Taxes',
		value: 10000000,
		tradeable: true,
		enchantmentTier: 4,
		forcedEnchant: 66,
		forcedEnchantAmount: 4,
		itemImage: '/images/jewellery/habs_ring.png',
		class: 'equipment',
		extraTooltipInfo:
			'Allows you to collect taxes from defeated enemies in combat at the expense of your experience. Not even the dead can escape taxes.',
		requiredLevel: {
			constitution: 92,
		},
		equipmentStats: {
			slot: 'ring',
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 6,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 6,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 6,
				},
			],
		},
		rarity: 'legendary',
	},
	1583: {
		id: 1583,
		name: 'Wrath and War',
		extraTooltipInfo: "The demonic embodiment of Wrath and War. 'Warth'? Quite mad.",
		rarity: 'legendary',
		enchantmentTier: 8,
		value: 3440000,
		tradeable: true,
		requiredLevel: {
			strength: 80,
		},
		itemImage: '/images/combat/equipment/wrath_axe.png',
		itemIcon: '/images/combat/equipment/wrath_axe_icon.png',
		class: 'equipment',
		visual: 'wrath-axe',
		tags: ['melee', 'twohand'],
		equipmentStats: {
			grantedAbility: [111, 112, 147],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.025,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 88,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 196,
				Slashing: 174,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.33,
				Slashing: 1.33,
			},
			weaponBonus: {
				strength: 165,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 8,
				resistance: 0,
				agility: -8,
				stamina: 0,
			},
			attackSpeed: 3.1,
			hitMults: {
				minimum: 0.4,
				maximum: 1.3,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 4.4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 8.3,
				},
			],
			oneHanded: true,
		},
	},
	1584: {
		name: 'Shrimp Scepter',
		id: 1584,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			magic: 85,
		},
		itemImage: '/images/combat/equipment/shrimp_staff.png',
		itemIcon: '/images/combat/equipment/shrimp_staff_icon.png',
		class: 'equipment',
		visual: 'staff',
		extraTooltipInfo: 'A scepter formed from the void given shape: the Shrimp God.',
		tags: ['magic', 'onehand', 'craftable'],
		equipmentStats: {
			grantedAbility: [85, 133],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.33,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 228,
				Chaos: 356,
				Ice: 56,
				Melee: -48,
				Range: -48,
			},
			offensiveDamageAffinity: {
				Magic: 1.66,
				Melee: 0.95,
				Range: 0.95,
				Chaos: 1.5,
				Poison: 1.5,
			},
			weaponBonus: {
				strength: 0,
				intellect: 142,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 8,
				agility: 0,
				stamina: 0,
			},
			attackSpeed: 2.3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 11.4,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 7.5,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Chaos',
					value: 17.8,
				},
			],
			oneHanded: true,
			itemSet: [2001, 10029, 10030],
		},
		scrappingFailItem: {
			itemID: 1558,
			minimum: 80,
			maximum: 120,
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Magic',
			craftable: true,
			description: 'A staff that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1585: {
		name: 'Shrimp Stinger',
		id: 1585,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			range: 85,
		},
		itemImage: '/images/combat/equipment/shrimp_dagger.png',
		itemIcon: '/images/combat/equipment/shrimp_dagger_icon.png',
		class: 'equipment',
		visual: 'defender',
		extraTooltipInfo: "An off-hand dagger fashioned from one of the Shrimp God's many stingers.",
		tags: ['range', 'onehand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [83],
			slot: 'shield',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 1.5,
			},
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.5,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 48,
				Range: 64,
				Piercing: 36,
				Slashing: 36,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.1,
				Slashing: 1.1,
				Range: 1.5,
				Poison: 1.5,
				Chaos: 1.5,
			},
			weaponBonus: {
				strength: 22,
				intellect: 0,
				dexterity: 66,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 22,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 1.8,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 4.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1.1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3.3,
				},
				{
					stat: 'armorBonus.agility',
					value: 2,
				},
			],
			itemSet: [2001, 10029, 10031],
		},
		scrappingFailItem: {
			itemID: 1558,
			minimum: 80,
			maximum: 120,
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Range',
			craftable: true,
			description: 'A dagger that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1586: {
		name: 'Shrimp Tail',
		id: 1586,
		enchantmentTier: 8,
		value: 12000000,
		tradeable: true,
		requiredLevel: {
			strength: 85,
		},
		itemImage: '/images/combat/equipment/shrimp_whip.png',
		itemIcon: '/images/combat/equipment/shrimp_whip_icon.png',
		class: 'equipment',
		visual: 'shrimp-whip',
		extraTooltipInfo: "A whip designed to mimic the devastating power of a Shrimp's tail.",
		tags: ['melee', 'onehand', 'ability', 'craftable'],
		equipmentStats: {
			grantedAbility: [84, 132],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.5,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 120,
				Slashing: 156,
				Chaos: 48,
				Magic: 24,
				Range: 24,
			},
			offensiveDamageAffinity: {
				Melee: 1.33,
				Slashing: 1.5,
				Blunt: 0.9,
				Piercing: 0.9,
				Magic: 0.95,
				Range: 0.95,
				Chaos: 1.5,
				Poison: 1.5,
			},
			weaponBonus: {
				strength: 120,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 8,
				stamina: 0,
			},
			attackSpeed: 2.3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 6,
				},
			],
			itemSet: [2001, 10029, 10032],
		},
		scrappingFailItem: {
			itemID: 1558,
			minimum: 80,
			maximum: 120,
		},
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 120000,
			category: 'Melee',
			craftable: true,
			description: 'A whip that could only be fashioned through the help of a Voidblessed Shrimp.',
		},
	},
	1587: {
		name: 'Shrimp Eye',
		id: 1587,
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/shrimp_eye_sleep.png',
		class: 'bar',
		extraTooltipInfo: "The Eye's power sleeps.",
		tags: [],
		rarity: 'epic',
	},
	1588: {
		id: 1588,
		name: "Hunter's Band",
		value: 3450000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 13,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/hunter_band.png',
		class: 'equipment',
		extraTooltipInfo: "To mark a true hunter's finest kill.",
		tags: ['ability'],
		requiredLevel: {
			defense: 60,
			range: 60,
		},
		equipmentStats: {
			grantedAbility: [87],
			slot: 'ring',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 2,
			},
			offensiveDamageAffinity: {
				Range: 1.15,
				Piercing: 1.05,
			},
			offensiveAccuracyAffinityRating: {
				Range: 4,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 4,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
			],
		},
		rarity: 'epic',
	},
	1589: {
		id: 1589,
		name: 'Dwarven Hammer Necklace',
		value: 3450000,
		tradeable: true,
		enchantmentTier: 5,
		forcedEnchant: 74,
		forcedEnchantAmount: 5,
		itemImage: '/images/jewellery/hammer_necklace.png',
		class: 'equipment',
		extraTooltipInfo: "When all you've got is a hammer everything looks like a nail.",
		tags: ['ability'],
		requiredLevel: {
			defense: 55,
		},
		equipmentStats: {
			grantedAbility: [88],
			slot: 'necklace',
			offensiveAccuracyAffinityRating: {
				Blunt: 16,
			},
			toolBoost: [
				{
					skill: 'mining',
					boost: 1,
				},
			],
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: -12,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Blunt',
					value: 3,
				},
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
			],
		},
		rarity: 'epic',
	},
	1590: {
		id: 1590,
		name: 'Ancient Token',
		value: 13000000,
		tradeable: true,
		enchantmentTier: 2,
		forcedEnchant: 8016,
		forcedEnchantAmount: 2,
		itemImage: '/images/jewellery/ancient_token.png',
		class: 'equipment',
		extraTooltipInfo: 'An ancient token that freezes anything it touches.',
		tags: ['ability', 'ability'],
		requiredLevel: {
			defense: 85,
			magic: 85,
			crafting: 85,
		},
		equipmentStats: {
			grantedAbility: [90, 92, 165],
			slot: 'arrows',
			armorBonus: {
				protection: 4,
				resistance: 24,
				agility: 0,
				stamina: 0,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 12,
				Range: 0,
			},
			offensiveDamageAffinity: {
				Ice: 1.2,
			},
			weaponBonus: {
				strength: 0,
				intellect: 12,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Ice',
					value: 5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 2,
				},
			],
			itemSet: [10011, 10013, 10035],
		},
		rarity: 'legendary',
	},
	1591: {
		id: 1591,
		name: 'Ancient Ring',
		value: 13000000,
		tradeable: true,
		enchantmentTier: 5,
		forcedEnchant: 70,
		forcedEnchantAmount: 5,
		itemImage: '/images/jewellery/ancient_ring.png',
		class: 'equipment',
		extraTooltipInfo:
			'An ancient ring that allows you to control ice. Maybe you should take up ice sculpting and leave the adventuring life behind?',
		tags: ['ability'],
		requiredLevel: {
			defense: 85,
			magic: 85,
		},
		equipmentStats: {
			grantedAbility: [91, 92, 165],
			slot: 'ring',
			armorBonus: {
				protection: 0,
				resistance: 4,
				agility: 0,
				stamina: 0,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 30,
				Range: 0,
			},
			offensiveDamageAffinity: {
				Ice: 1.35,
			},
			weaponBonus: {
				strength: 0,
				intellect: 20,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Ice',
					value: 5,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3,
				},
			],
			itemSet: [10011, 10013, 10035],
		},
		rarity: 'legendary',
	},
	1592: {
		id: 1592,
		name: 'Golem Ring',
		value: 450000,
		tradeable: true,
		enchantmentTier: 5,
		forcedEnchant: 75,
		forcedEnchantAmount: 5,
		itemImage: '/images/jewellery/golem_ring.png',
		class: 'equipment',
		extraTooltipInfo:
			"A ring fashioned from an artificial golem's internals. The magic infused within is said to stabilize the effects of negative affinities, albeit imperfectly.",
		requiredLevel: {
			defense: 50,
		},
		equipmentStats: {
			slot: 'ring',
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 0,
				Range: 0,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 1,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 1,
				},
			],
		},
		rarity: 'epic',
	},
	1593: {
		id: 1593,
		name: 'Phroglin Pheromones',
		value: 1450000,
		tradeable: false,
		enchantmentTier: 1,
		forcedEnchant: 73,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/phroglin_attractor.png',
		class: 'equipment',
		extraTooltipInfo:
			"A foul smelling jar filled with a substance that apparently attracts creatures called 'Phroglins.' If used in group combat only the leader's Phroglin Pheromones will be active.",
		requiredLevel: {
			crafting: 10,
		},
		equipmentStats: {
			slot: 'arrows',
		},
		rarity: 'epic',
	},
	1594: {
		name: 'Elven Light Armor',
		id: 1594,
		enchantmentTier: 6,
		value: 5000000,
		tradeable: true,
		requiredLevel: {
			defense: 65,
			range: 65,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_chest.png',
		class: 'equipment',
		visual: 'elven-chest',
		extraTooltipInfo: 'An unnaturally light chainmail of elven make.',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.08,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.05,
				Piercing: 0.96,
				Blunt: 0.97,
				Nature: 1.06,
			},
			weaponBonus: {
				strength: 6,
				intellect: 6,
				dexterity: 18,
			},
			armorBonus: {
				protection: 18,
				resistance: 6,
				agility: 44,
				stamina: 8,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 1.5,
				},
				{
					stat: 'armorBonus.protection',
					value: 0.9,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1,
				},
				{
					stat: 'armorBonus.agility',
					value: 2.2,
				},
			],
			itemSet: [10010, 10012, 10014],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 24000,
			category: 'Range',
			craftable: true,
		},
	},
	1595: {
		name: 'Elven Hat',
		id: 1595,
		enchantmentTier: 6,
		tradeable: true,
		value: 5000000,
		requiredLevel: {
			defense: 65,
			range: 65,
		},
		extraTooltipInfo: 'A magical hat of elven make.',
		itemImage: '/images/combat/equipment/range/royal_elven_helm.png',
		class: 'equipment',
		visual: 'elven-helm',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.08,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
				Nature: 1.06,
			},
			weaponBonus: {
				strength: 6,
				intellect: 6,
				dexterity: 18,
			},
			armorBonus: {
				protection: 20,
				resistance: 12,
				agility: 24,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 1.5,
				},
				{
					stat: 'armorBonus.protection',
					value: 1,
				},
				{
					stat: 'armorBonus.resistance',
					value: 1,
				},
				{
					stat: 'armorBonus.agility',
					value: 1.5,
				},
			],
			itemSet: [10010, 10012, 10014],
		},
		rarity: 'rare',
		craftingStats: {
			level: 65,
			experience: 24000,
			category: 'Range',
			craftable: true,
		},
	},
	1596: {
		name: 'Sylvan Staff',
		id: 1596,
		tradeable: true,
		enchantmentTier: 7,
		value: 4660000,
		requiredLevel: {
			magic: 70,
		},
		itemImage: '/images/combat/equipment/sylvan_staff.png',
		itemIcon: '/images/combat/equipment/sylvan_staff_icon.png',
		class: 'equipment',
		visual: 'sylvan-staff',
		extraTooltipInfo:
			'A staff stolen from a Spriggan. Requires some druidic aptitude in order to communicate your will to the staff as it is actually a living embodiment of nature.',
		tags: ['magic', 'twohand', 'ability'],
		equipmentStats: {
			grantedAbility: [9, 89, 102, 103, 104, 107],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.25,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 180,
				Nature: 180,
				Melee: -14,
				Range: -28,
				Piercing: -8,
				Blunt: 60,
				Slashing: -8,
			},
			offensiveDamageAffinity: {
				Melee: 0.75,
				Magic: 1.25,
				Nature: 1.75,
				Range: 0.75,
				Piercing: 0.1,
				Blunt: 0.3333333333333333,
				Slashing: 0.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 156,
				dexterity: 0,
			},
			armorBonus: {
				agility: -12,
				protection: 12,
				resistance: 12,
				stamina: 0,
			},
			attackSpeed: 2.8,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 9,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 7.8,
				},
			],
			itemSet: [10011, 10013, 10019],
		},
		rarity: 'epic',
	},
	1597: {
		id: 1597,
		name: 'Master Parrying Gauntlet',
		value: 6000000,
		tradeable: true,
		enchantmentTier: 5,
		forcedEnchant: 20,
		forcedEnchantAmount: 5,
		itemImage: '/images/jewellery/parrying_gauntlet_master.png',
		class: 'equipment',
		extraTooltipInfo:
			'A parrying gauntlet for a true master, designed to give you the best chance to parry enemy attacks.',
		requiredLevel: {
			constitution: 75,
			defense: 75,
		},
		equipmentStats: {
			grantedAbility: [46],
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
				{
					stat: 'armorBonus.protection',
					value: 3,
				},
			],
			weaponBonus: {
				strength: 0,
				dexterity: 0,
				intellect: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			offensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 0.25,
				Range: 0.25,
			},
		},
		tags: ['melee', 'craftable'],
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 132500,
			category: 'Melee',
			craftable: true,
			description:
				'A parrying gauntlet for a true master, designed to give you the best chance to parry enemy attacks. Remember to disenchant the Defender piece before crafting this.',
		},
	},
	1598: {
		id: 1598,
		name: 'Expert Parrying Gauntlet',
		value: 1200000,
		tradeable: true,
		enchantmentTier: 3,
		forcedEnchant: 20,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/parrying_gauntlet_expert.png',
		class: 'equipment',
		extraTooltipInfo:
			"An expert's tool to more reliably parry enemy attacks. Much more effective than its beginner counterpart.",
		requiredLevel: {
			constitution: 50,
			defense: 50,
		},
		equipmentStats: {
			grantedAbility: [46],
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
				{
					stat: 'armorBonus.protection',
					value: 2,
				},
			],
			weaponBonus: {
				strength: 0,
				dexterity: 0,
				intellect: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			offensiveDamageAffinity: {
				Melee: 1.01,
				Magic: 0.25,
				Range: 0.25,
			},
		},
		rarity: 'rare',
		tags: ['melee', 'craftable'],
		craftingStats: {
			level: 50,
			experience: 48000,
			category: 'Melee',
			craftable: true,
		},
	},
	1599: {
		id: 1599,
		name: 'Beginner Parrying Gauntlet',
		value: 600000,
		tradeable: true,
		enchantmentTier: 1,
		forcedEnchant: 20,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/parrying_gauntlet_beginner.png',
		class: 'equipment',
		extraTooltipInfo:
			"A beginner's tool to parry and immediately strike an opponent's attack. While it may work sometimes, it's not very reliable.",
		requiredLevel: {
			constitution: 25,
			defense: 25,
		},
		equipmentStats: {
			grantedAbility: [46],
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'weaponBonus.strength',
					value: 1,
				},
				{
					stat: 'armorBonus.protection',
					value: 1,
				},
			],
			weaponBonus: {
				strength: 0,
				dexterity: 0,
				intellect: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.25,
				Range: 0.25,
			},
		},
		rarity: 'uncommon',
		tags: ['melee', 'craftable'],
		craftingStats: {
			level: 25,
			experience: 7500,
			category: 'Melee',
			craftable: true,
		},
	},
	1600: {
		name: 'Scroll',
		id: 1600,
		tradeable: true,
		value: 900,
		extraTooltipInfo: 'Used in magic to store magical properties: enchantments.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		itemImage: '/images/enchanting/scroll.png',
	},
	1601: {
		name: 'Scroll of Embers',
		activeMessage: 'Making Scrolls of Embers',
		enchant: true,
		enchantmentID: 1,
		categories: ['hatchet', 'foragingset'],
		time: 15000,
		id: 1601,
		level: 61,
		experience: 4200,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_embers.png',
		extraTooltipInfo: 'Foraging Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'foraging'],
		rarity: 'rare',
	},
	1602: {
		name: 'Scroll of the Prospector',
		activeMessage: 'Making Scrolls of the Prospector',
		enchant: true,
		enchantmentID: 2,
		categories: ['pickaxe', 'miningset'],
		time: 15000,
		id: 1602,
		level: 25,
		experience: 1500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_prospector.png',
		extraTooltipInfo: 'Mining Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining'],
	},
	1603: {
		name: 'Scroll of the Scholar',
		activeMessage: 'Making Scrolls of the Scholar',
		enchant: true,
		enchantmentID: 3,
		categories: ['ring', 'necklace'],
		time: 15000,
		id: 1603,
		level: 88,
		experience: 6500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_scholar.png',
		extraTooltipInfo: 'Skilling Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining', 'foraging', 'fishing', 'smithing', 'cooking'],
		rarity: 'epic',
	},
	1604: {
		name: 'Scroll of Gathering',
		activeMessage: 'Making Scrolls of Gathering',
		enchant: true,
		enchantmentID: 4,
		categories: ['ring', 'necklace'],
		time: 15000,
		id: 1604,
		level: 64,
		experience: 4300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_gathering.png',
		extraTooltipInfo: 'Gathering Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining', 'foraging', 'fishing'],
		rarity: 'rare',
	},
	1605: {
		name: 'Scroll of Superheating',
		activeMessage: 'Making Scrolls of Superheating',
		enchant: true,
		enchantmentID: 5,
		categories: ['pickaxe', 'miningset'],
		time: 15000,
		id: 1605,
		level: 76,
		experience: 5400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_superheating.png',
		extraTooltipInfo: 'Mining Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining'],
		rarity: 'rare',
	},
	1606: {
		name: 'Scroll of Pyromancy',
		activeMessage: 'Making Scrolls of Pyromancy',
		enchant: true,
		enchantmentID: 6,
		categories: ['ring', 'necklace'],
		time: 15000,
		id: 1606,
		level: 7,
		experience: 600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_pyromancy.png',
		extraTooltipInfo: 'Skilling Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'smithing', 'cooking'],
	},
	1607: {
		name: 'Scroll of Wealth',
		activeMessage: 'Making scrolls of wealth',
		enchant: true,
		enchantmentID: 7,
		categories: ['ring', 'necklace'],
		time: 15000,
		id: 1607,
		level: 37,
		experience: 2300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_wealth.png',
		extraTooltipInfo: 'Skilling Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining', 'foraging', 'fishing', 'smithing', 'cooking'],
	},
	1608: {
		name: 'Scroll of Haste',
		activeMessage: 'Making scrolls of haste',
		enchant: true,
		enchantmentID: 8,
		categories: ['ring', 'necklace'],
		time: 15000,
		id: 1608,
		level: 94,
		experience: 7500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_haste.png',
		extraTooltipInfo: 'Skilling Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining', 'foraging', 'fishing', 'runecrafting', 'smithing', 'cooking'],
		rarity: 'epic',
	},
	1609: {
		name: 'Scroll of Naturalist',
		activeMessage: 'Making scrolls of naturalist',
		enchant: true,
		enchantmentID: 9,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1609,
		level: 49,
		experience: 3200,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_naturalist_v2.png',
		extraTooltipInfo: 'Gathering Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining', 'foraging', 'fishing'],
	},
	1610: {
		name: 'Scroll of Acrobatics',
		activeMessage: 'Making scrolls of acrobatics',
		enchant: true,
		hidden: true,
		enchantmentID: 10,
		categories: ['weapon'],
		time: 15000,
		id: 1610,
		level: 100,
		experience: 1,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_naturalist_v2.png',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'epic',
	},
	1611: {
		name: 'Scroll of Critical Strike',
		activeMessage: 'Making scrolls of critical strike',
		enchant: true,
		enchantmentID: 11,
		categories: ['weapon'],
		time: 15000,
		id: 1611,
		level: 52,
		experience: 3300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_critical_strike.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'uncommon',
	},
	1612: {
		name: 'Scroll of Poisoning',
		activeMessage: 'Making scrolls of poisoning',
		enchant: true,
		hidden: true,
		enchantmentID: 12,
		categories: ['weapon'],
		time: 15000,
		id: 1612,
		level: 100,
		experience: 1,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_naturalist_v2.png',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'epic',
	},
	1613: {
		name: 'Scroll of Accuracy',
		activeMessage: 'Making scrolls of accuracy',
		enchant: true,
		enchantmentID: 13,
		categories: ['weapon'],
		time: 15000,
		id: 1613,
		level: 28,
		experience: 1600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_accuracy.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
	},
	1614: {
		name: 'Scroll of Deflect',
		activeMessage: 'Making scrolls of deflect',
		enchant: true,
		hidden: true,
		enchantmentID: 14,
		categories: ['shield'],
		time: 15000,
		id: 1614,
		level: 100,
		experience: 1,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_naturalist_v2.png',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'epic',
	},
	1615: {
		name: 'Scroll of Force',
		activeMessage: 'Making scrolls of force',
		enchant: true,
		enchantmentID: 15,
		categories: ['shield'],
		time: 15000,
		id: 1615,
		level: 79,
		experience: 5500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1616: {
		name: 'Scroll of Healing',
		activeMessage: 'Making scrolls of healing',
		enchant: true,
		enchantmentID: 16,
		categories: ['shield', 'combat-talisman'],
		time: 15000,
		id: 1616,
		level: 10,
		experience: 700,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_healing.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
	},
	1617: {
		name: 'Scroll of Weakening',
		activeMessage: 'Making scrolls of weakening',
		enchant: true,
		enchantmentID: 17,
		categories: ['helm', 'body', 'legs', 'shield', 'weapon'],
		time: 15000,
		id: 1617,
		level: 4,
		experience: 300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_weakening.png',
		extraTooltipInfo: 'General Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
	},
	1618: {
		name: 'Scroll of Reinforcement',
		activeMessage: 'Making scrolls of reinforcement',
		enchant: true,
		enchantmentID: 18,
		categories: ['helm', 'body', 'legs'],
		time: 15000,
		id: 1618,
		level: 13,
		experience: 800,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_reinforcement.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
	},
	1619: {
		name: 'Scroll of Protection',
		activeMessage: 'Making scrolls of protection',
		enchant: true,
		enchantmentID: 19,
		categories: ['helm', 'body', 'legs'],
		time: 15000,
		id: 1619,
		level: 31,
		experience: 1700,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_protection.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
	},
	1620: {
		name: 'Scroll of Parry',
		activeMessage: 'Making scrolls of parry',
		enchant: true,
		hidden: true,
		enchantmentID: 20,
		categories: ['body', 'legs'],
		time: 15000,
		id: 1620,
		level: 100,
		experience: 1,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_naturalist_v2.png',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
		rarity: 'epic',
	},
	1621: {
		name: 'Scroll of False Equilibrium',
		activeMessage: 'Making scrolls of false equilibrium',
		enchant: true,
		enchantmentID: 21,
		categories: ['helm', 'body', 'legs'],
		time: 15000,
		id: 1621,
		level: 81,
		experience: 5600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_false_equilibrium.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
		rarity: 'epic',
	},
	1622: {
		name: 'Scroll of Efficiency',
		activeMessage: 'Making scrolls of efficiency',
		enchant: true,
		enchantmentID: 22,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1622,
		level: 91,
		experience: 6600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_efficiency.png',
		extraTooltipInfo: 'Skilling Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'cooking', 'smithing', 'runecrafting'],
		rarity: 'epic',
	},
	1623: {
		name: 'Scroll of Fishing',
		activeMessage: 'Making scrolls of fishing',
		enchant: true,
		enchantmentID: 23,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1623,
		level: 55,
		experience: 3400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_fishing.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
		rarity: 'uncommon',
	},
	1624: {
		name: 'Scroll of Cooking',
		activeMessage: 'Making scrolls of cooking',
		enchant: true,
		enchantmentID: 24,
		categories: ['gloves', 'boots', 'ladle', 'cookingset'],
		time: 15000,
		id: 1624,
		level: 40,
		experience: 2400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_cooking.png',
		extraTooltipInfo: 'Cooking Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'cooking'],
	},
	1625: {
		name: 'Scroll of Crafting',
		activeMessage: 'Making scrolls of crafting',
		enchant: true,
		enchantmentID: 25,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1625,
		level: 67,
		experience: 4400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_crafting.png',
		extraTooltipInfo: 'Crafting Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1626: {
		name: 'Scroll of Refining',
		activeMessage: 'Making scrolls of refining',
		enchant: true,
		enchantmentID: 26,
		categories: ['gloves', 'boots', 'tongs', 'smithingset'],
		time: 15000,
		id: 1626,
		level: 34,
		experience: 1800,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_refining.png',
		extraTooltipInfo: 'Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'smithing'],
	},
	1627: {
		name: 'Scroll of Runecrafting',
		activeMessage: 'Making scrolls of runecrafting',
		enchant: true,
		enchantmentID: 27,
		categories: ['gloves', 'boots', 'chisel', 'runecraftingset'],
		time: 15000,
		id: 1627,
		level: 43,
		experience: 2500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_runecrafting.png',
		extraTooltipInfo: 'Runecrafting Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'runecrafting'],
	},
	1628: {
		name: 'Scroll of Chances',
		activeMessage: 'Making scrolls of chances',
		enchant: true,
		enchantmentID: 28,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1628,
		level: 70,
		experience: 4500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_chances_v2.png',
		extraTooltipInfo: 'Augmentation Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1629: {
		name: 'Scroll of the Shrimp Lord',
		activeMessage: 'Making scrolls of the Shrimp Lord',
		enchant: true,
		enchantmentID: 29,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1629,
		level: 1,
		experience: 80,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_shrimp_lord.png',
		extraTooltipInfo: 'Cooking Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'cooking'],
	},
	1630: {
		name: 'Scroll of Enlightenment',
		activeMessage: 'Making scrolls of enlightment',
		enchant: true,
		enchantmentID: 30,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1630,
		level: 73,
		experience: 4600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_enlightment.png',
		extraTooltipInfo: 'General Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'runecrafting'],
		rarity: 'rare',
	},
	1631: {
		name: 'Scroll of Prolonging',
		activeMessage: 'Making scrolls of prolonging',
		enchant: true,
		enchantmentID: 31,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1631,
		level: 58,
		experience: 3500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_boosting.png',
		extraTooltipInfo: 'General Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'uncommon',
	},
	1632: {
		name: 'Scroll of the Treasure Hunter',
		activeMessage: 'Making scrolls of treasure hunter',
		enchant: true,
		enchantmentID: 32,
		categories: ['gloves', 'boots', 'combat-boots', 'combat-gloves'],
		time: 15000,
		id: 1632,
		level: 85,
		experience: 5700,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_treasure_hunter.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'epic',
	},
	1633: {
		name: 'Scroll of Inferno',
		activeMessage: 'Making scrolls of inferno',
		enchant: true,
		enchantmentID: 33,
		categories: ['boots', 'gloves'],
		time: 15000,
		id: 1633,
		level: 46,
		experience: 2600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_inferno.png',
		extraTooltipInfo: 'Cooking/Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'cooking', 'smithing'],
	},
	1634: {
		name: 'Scroll of Destructive Testing',
		activeMessage: 'Making scrolls of destructive testing',
		enchant: true,
		enchantmentID: 34,
		categories: ['pickaxe', 'miningset'],
		time: 15000,
		id: 1634,
		level: 16,
		experience: 900,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_destructive_testing.png',
		extraTooltipInfo: 'Mining Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining'],
	},
	1635: {
		name: 'Scroll of Nature',
		activeMessage: 'Making scrolls of the nature',
		enchant: true,
		enchantmentID: 35,
		categories: ['hatchet', 'foragingset'],
		time: 15000,
		id: 1635,
		level: 19,
		experience: 1000,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_nature.png',
		extraTooltipInfo: 'Foraging Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'foraging'],
	},
	1636: {
		name: 'Scroll of Root Digging',
		activeMessage: 'Making scrolls of root digging',
		enchant: true,
		enchantmentID: 36,
		categories: ['hatchet', 'foragingset'],
		time: 15000,
		id: 1636,
		level: 22,
		experience: 1000,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_root_digging.png',
		extraTooltipInfo: 'Foraging Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'foraging'],
	},
	1637: {
		name: 'Scroll of Patience',
		activeMessage: 'Making scrolls of patience',
		enchant: true,
		enchantmentID: 37,
		categories: ['body', 'legs'],
		time: 15000,
		id: 1637,
		level: 82,
		experience: 5600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_patience.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
		rarity: 'epic',
	},
	1638: {
		name: 'Scroll of Overhealing',
		activeMessage: 'Making scrolls of overhealing',
		enchant: true,
		enchantmentID: 38,
		categories: ['weapon'],
		time: 15000,
		id: 1638,
		level: 11,
		experience: 700,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_overhealing.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
	},
	1639: {
		name: 'Scroll of Farming',
		activeMessage: 'Making scrolls of farming',
		enchant: true,
		enchantmentID: 39,
		categories: ['hoe'],
		time: 15000,
		id: 1639,
		level: 72,
		experience: 4600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_farming.png',
		extraTooltipInfo: 'Farming Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'farming'],
		rarity: 'rare',
	},
	1640: {
		name: 'Scroll of the Gold Digger',
		activeMessage: 'Making scrolls of gold digger',
		enchant: true,
		enchantmentID: 40,
		categories: ['hoe'],
		time: 15000,
		id: 1640,
		level: 36,
		experience: 2300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_gold_digger.png',
		extraTooltipInfo: 'Farming Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'farming'],
	},
	1641: {
		name: 'Scroll of Fertilizing',
		activeMessage: 'Making scrolls of fertilizing',
		enchant: true,
		enchantmentID: 41,
		categories: ['hoe'],
		time: 15000,
		id: 1641,
		level: 90,
		experience: 6600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_fertilizing.png',
		extraTooltipInfo: 'Farming Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'farming'],
		rarity: 'epic',
	},
	1642: {
		name: 'Scroll of Cultivation',
		activeMessage: 'Making scrolls of cultivation',
		enchant: true,
		enchantmentID: 42,
		categories: ['hoe'],
		time: 15000,
		id: 1642,
		level: 12,
		experience: 800,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_cultivation.png',
		extraTooltipInfo: 'Farming Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'farming'],
	},
	1643: {
		name: 'Scroll of Fortitude',
		activeMessage: 'Making scrolls of fortitude',
		enchant: true,
		enchantmentID: 44,
		categories: ['shield', 'combat-talisman'],
		id: 1643,
		value: 250000,
		time: 15000,
		level: 68,
		experience: 4400,
		tradeable: true,
		itemImage: '/images/enchanting/scroll_of_fortitude.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'uncommon',
	},
	1644: {
		name: 'Scroll of Pungent Bait',
		activeMessage: 'Making scrolls of pungent bait',
		enchant: true,
		enchantmentID: 49,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1644,
		level: 14,
		experience: 850,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_pungentbait.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
	},
	1645: {
		name: 'Scroll of Reinforced Line',
		activeMessage: 'Making scrolls of reinforced line',
		enchant: true,
		enchantmentID: 50,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1645,
		level: 23,
		experience: 1250,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_reinforcedline.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
	},
	1646: {
		name: 'Scroll of Fishing Magnetism',
		activeMessage: 'Making scrolls of fishing magnetism',
		enchant: true,
		enchantmentID: 48,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1646,
		level: 33,
		experience: 1750,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_fishingmagnetism.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
	},
	1647: {
		name: 'Scroll of Bait Preservation',
		activeMessage: 'Making scrolls of bait preservation',
		enchant: true,
		enchantmentID: 51,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1647,
		level: 47,
		experience: 2800,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_baitpreservation.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
	},
	1648: {
		name: 'Scroll of Oil Spill',
		activeMessage: 'Making scrolls of oil spill',
		enchant: true,
		enchantmentID: 52,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1648,
		level: 69,
		experience: 4450,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_oilspill.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
		rarity: 'rare',
	},
	1649: {
		name: 'Scroll of Deadliest Catch',
		activeMessage: 'Making scrolls of deadliest catch',
		enchant: true,
		enchantmentID: 53,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1649,
		level: 71,
		experience: 4550,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_deadliestcatch.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
		rarity: 'rare',
	},
	1650: {
		name: 'Scroll of the Master Chef',
		activeMessage: 'Making scrolls of master chef',
		enchant: true,
		enchantmentID: 54,
		categories: ['ladle', 'cookingset'],
		time: 15000,
		id: 1650,
		level: 84,
		experience: 5650,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_masterchef.png',
		extraTooltipInfo: 'Cooking Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'cooking'],
		rarity: 'epic',
	},
	1651: {
		name: 'Scroll of the Herbalist',
		activeMessage: 'Making scrolls of herbalist',
		enchant: true,
		enchantmentID: 55,
		categories: ['hatchet', 'foragingset'],
		time: 15000,
		id: 1651,
		level: 42,
		experience: 2450,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_herbalist.png',
		extraTooltipInfo: 'Foraging Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'foraging'],
	},
	1652: {
		name: 'Scroll of Seed Harvesting',
		activeMessage: 'Making scrolls of seed harvesting',
		enchant: true,
		enchantmentID: 56,
		categories: ['hatchet', 'foragingset'],
		time: 15000,
		id: 1652,
		level: 30,
		experience: 1650,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_herbalist.png',
		extraTooltipInfo: 'Foraging Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'foraging'],
	},
	1653: {
		name: 'Scroll of Metallurgy',
		activeMessage: 'Making scrolls of metallurgy',
		enchant: true,
		enchantmentID: 57,
		categories: ['tongs', 'smithingset'],
		time: 15000,
		id: 1653,
		level: 38,
		experience: 2350,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_inferno.png',
		extraTooltipInfo: 'Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'smithing'],
	},
	1654: {
		name: 'Scroll of Pure Metals',
		activeMessage: 'Making scrolls of pure metals',
		enchant: true,
		enchantmentID: 58,
		categories: ['tongs', 'smithingset'],
		time: 15000,
		id: 1654,
		level: 87,
		experience: 6400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_pure_metal.png',
		extraTooltipInfo: 'Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'smithing'],
		rarity: 'epic',
	},
	1655: {
		name: 'Scroll of Essence Concentration',
		activeMessage: 'Making scrolls of essence concentration',
		enchant: true,
		enchantmentID: 59,
		categories: ['gloves', 'boots', 'chisel', 'runecraftingset'],
		time: 15000,
		id: 1655,
		level: 59,
		experience: 4000,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_runecrafting.png',
		extraTooltipInfo: 'Runecrafting Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'runecrafting'],
		rarity: 'epic',
	},
	1656: {
		name: 'Scroll of the Fiber Finder',
		activeMessage: 'Making scrolls of fiber finder',
		enchant: true,
		enchantmentID: 62,
		categories: ['tacklebox', 'fishingset'],
		time: 15000,
		id: 1656,
		level: 50,
		experience: 3300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_fishing.png',
		extraTooltipInfo: 'Fishing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'fishing'],
		rarity: 'uncommon',
	},
	1657: {
		name: 'Scroll of Archaeology',
		activeMessage: 'Making scrolls of archaeology',
		enchant: true,
		enchantmentID: 67,
		categories: ['tacklebox', 'fishingset', 'pickaxe', 'hatchet', 'miningset', 'foragingset'],
		time: 15000,
		id: 1657,
		level: 41,
		experience: 2400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_chances_v1.png',
		extraTooltipInfo: 'Gathering Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining', 'foraging', 'fishing'],
	},
	1658: {
		name: 'Scroll of Endless Ammo',
		activeMessage: 'Making scrolls of endless ammo',
		enchant: true,
		enchantmentID: 60,
		categories: ['cape', 'weapon'],
		time: 15000,
		id: 1658,
		level: 29,
		experience: 1650,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_chances_v2.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
	},
	1659: {
		name: 'Scroll of Rune Reserves',
		activeMessage: 'Making scrolls of rune reserves',
		enchant: true,
		enchantmentID: 61,
		categories: ['cape', 'weapon'],
		time: 15000,
		id: 1659,
		level: 35,
		experience: 2100,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_chances_v2.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
	},
	1660: {
		name: 'Scroll of the Soul Wisdom',
		activeMessage: 'Making Scrolls of Soul Wisdom',
		enchant: true,
		hidden: true,
		enchantmentID: 71,
		categories: [
			'ring',
			'necklace',
			'weapon',
			'shield',
			'helm',
			'body',
			'legs',
			'gloves',
			'boots',
			'cape',
			'arrows',
			'pickaxe',
			'hatchet',
			'hoe',
			'tacklebox',
			'tongs',
			'tome',
			'ladle',
			'combat-talisman',
			'chisel',
			'miningset',
			'foragingset',
			'fishingset',
			'cookingset',
			'smithingset',
			'runecraftingset',
		],
		time: 15000,
		id: 1660,
		level: 48,
		experience: 3000,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_the_scholar.png',
		extraTooltipInfo: 'General Enchantment. Deprecated.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'epic',
	},
	1661: {
		name: 'Scroll of Overly Well Done',
		activeMessage: 'Making scrolls of overly well done',
		enchant: true,
		enchantmentID: 72,
		categories: ['ladle', 'cookingset'],
		time: 15000,
		id: 1661,
		level: 2,
		experience: 120,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_masterchef.png',
		extraTooltipInfo: 'Cooking Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'cooking'],
	},
	1662: {
		name: 'Scroll of Recklessness',
		activeMessage: 'Making scrolls of recklessness',
		enchant: true,
		enchantmentID: 74,
		categories: ['helm', 'body', 'legs'],
		time: 15000,
		id: 1662,
		level: 80,
		experience: 5550,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_recklessness.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
		rarity: 'epic',
	},
	1663: {
		name: 'Scroll of Engraving',
		activeMessage: 'Making scrolls of engraving',
		enchant: true,
		enchantmentID: 81,
		categories: ['chisel', 'runecraftingset'],
		time: 15000,
		id: 1663,
		level: 39,
		experience: 2400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_runecrafting.png',
		extraTooltipInfo: 'Runecrafting Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'runecrafting'],
	},
	1664: {
		name: 'Scroll of Resistance',
		activeMessage: 'Making scrolls of resistance',
		enchant: true,
		enchantmentID: 86,
		categories: ['helm', 'body', 'legs'],
		time: 15000,
		id: 1664,
		level: 32,
		experience: 1750,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_protection.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
	},
	1665: {
		name: 'Scroll of Supercooling',
		activeMessage: 'Making Scrolls of Supercooling',
		enchant: true,
		enchantmentID: 82,
		categories: ['pickaxe', 'miningset'],
		time: 15000,
		id: 1665,
		level: 75,
		experience: 5000,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_supercooling.png',
		extraTooltipInfo: 'Mining Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining'],
		rarity: 'rare',
	},
	1666: {
		name: 'Scroll of Dwarven Engineering',
		activeMessage: 'Making Scrolls of Dwarven Engineering',
		enchant: true,
		enchantmentID: 83,
		categories: ['pickaxe', 'miningset'],
		time: 15000,
		id: 1666,
		level: 83,
		experience: 5600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_superheating.png',
		extraTooltipInfo: 'Mining Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'mining'],
		rarity: 'rare',
	},
	1667: {
		name: 'Scroll of Taunting',
		activeMessage: 'Making Scrolls of Taunting',
		enchant: true,
		enchantmentID: 8013,
		categories: ['helm', 'body', 'legs', 'shield', 'combat-talisman'],
		time: 15000,
		id: 1667,
		level: 26,
		experience: 1600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_taunting.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
	},
	1668: {
		name: 'Scroll of Convergence',
		activeMessage: 'Making scrolls of convergence',
		enchant: true,
		enchantmentID: 87,
		categories: ['shield'],
		time: 15000,
		id: 1668,
		level: 79,
		experience: 5500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1669: {
		name: 'Scroll of Finesse',
		activeMessage: 'Making scrolls of finesse',
		enchant: true,
		enchantmentID: 88,
		categories: ['shield'],
		time: 15000,
		id: 1669,
		level: 79,
		experience: 5500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1670: {
		name: 'Scroll of Fibrous Fabrication',
		activeMessage: 'Making scrolls of fibrous fabrication',
		enchant: true,
		enchantmentID: 92,
		categories: ['chisel', 'runecraftingset'],
		time: 15000,
		id: 1670,
		level: 66,
		experience: 4400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Runecrafting Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1671: {
		name: 'Scroll of Crucible',
		activeMessage: 'Making scrolls of crucible',
		enchant: true,
		enchantmentID: 89,
		categories: ['tongs', 'smithingset'],
		time: 15000,
		id: 1671,
		level: 62,
		experience: 4200,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1672: {
		name: 'Scroll of Forge Enhancement',
		activeMessage: 'Making scrolls of forge enhancement',
		enchant: true,
		enchantmentID: 90,
		categories: ['tongs', 'smithingset'],
		time: 15000,
		id: 1672,
		level: 78,
		experience: 5400,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1673: {
		name: 'Scroll of Forge Maintenance',
		activeMessage: 'Making scrolls of forge maintenance',
		enchant: true,
		enchantmentID: 91,
		categories: ['tongs', 'smithingset'],
		time: 15000,
		id: 1673,
		level: 77,
		experience: 5500,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_force.png',
		extraTooltipInfo: 'Smithing Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1674: {
		name: 'Scroll of Leftovers',
		activeMessage: 'Making scrolls of leftovers',
		enchant: true,
		enchantmentID: 96,
		categories: ['ladle', 'cookingset'],
		time: 15000,
		id: 1674,
		level: 74,
		experience: 4800,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_root_digging.png',
		extraTooltipInfo: 'Cooking Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1675: {
		name: 'Scroll of Blitzing',
		activeMessage: 'Making scrolls of blitzing',
		enchant: true,
		enchantmentID: 97,
		categories: ['ladle', 'cookingset'],
		time: 15000,
		id: 1675,
		level: 27,
		experience: 1600,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_cooking.png',
		extraTooltipInfo: 'Cooking Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1676: {
		name: 'Scroll of Duster',
		activeMessage: 'Making scrolls of duster',
		enchant: true,
		enchantmentID: 99,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1676,
		level: 80,
		experience: 5550,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_chances_v2.png',
		extraTooltipInfo: 'Augmentation Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1677: {
		name: 'Scroll of Scrapper',
		activeMessage: 'Making scrolls of scrapper',
		enchant: true,
		enchantmentID: 100,
		categories: ['gloves', 'boots'],
		time: 15000,
		id: 1677,
		level: 51,
		experience: 3300,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_chances_v2.png',
		extraTooltipInfo: 'Augmentation Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	1678: {
		name: 'Scroll of Immunity',
		activeMessage: 'Making scrolls of immunity',
		enchant: true,
		enchantmentID: 101,
		categories: ['cape'],
		time: 15000,
		id: 1678,
		level: 56,
		experience: 3450,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_protection.png',
		extraTooltipInfo: 'Combat Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'armor'],
	},
	1679: {
		name: 'Scroll of Quick Study',
		activeMessage: 'Making scrolls of quick study',
		enchant: true,
		enchantmentID: 103,
		categories: ['boots', 'gloves'],
		time: 15000,
		id: 1679,
		level: 92,
		experience: 6800,
		tradeable: true,
		value: 2000,
		itemImage: '/images/enchanting/scroll_of_inferno.png',
		extraTooltipInfo: 'Enchanting Enchantment.',
		class: 'enchanted-scroll',
		tags: ['enchanting', 'enchanting'],
	},
	1800: {
		name: 'Elven Royal Leggings',
		id: 1800,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			range: 75,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_legs.png',
		class: 'equipment',
		visual: 'elven-legs',
		extraTooltipInfo: 'A pair of royal leggings that offer an incredible amount of flexibility.',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Range: 1.1,
				Nature: 1.03,
			},
			defensiveDamageAffinity: {
				Range: 1.1,
				Nature: 1.1,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 24,
			},
			armorBonus: {
				protection: 40,
				resistance: 18,
				agility: 88,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 2,
				},
				{
					stat: 'armorBonus.agility',
					value: 4.4,
				},
			],
			itemSet: [10010, 10012, 10014, 10021],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 217000,
			category: 'Range',
			craftable: true,
		},
	},
	1801: {
		name: 'Elven Royal Tunic',
		id: 1801,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			range: 75,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_chest.png',
		class: 'equipment',
		visual: 'elven-chest',
		extraTooltipInfo:
			'A tunic fashioned from sort magical fiber you have never encountered. Not the most protective, but very light.',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Range: 1.16,
				Nature: 1.08,
			},
			defensiveDamageAffinity: {
				Melee: 0.94,
				Magic: 1.08,
				Range: 1.05,
				Piercing: 0.96,
				Blunt: 0.97,
				Nature: 1.08,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 24,
			},
			armorBonus: {
				protection: 32,
				resistance: 12,
				agility: 64,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 2,
				},
				{
					stat: 'armorBonus.agility',
					value: 3.2,
				},
			],
			itemSet: [10010, 10012, 10014, 10021],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 227500,
			category: 'Range',
			craftable: true,
		},
	},
	1802: {
		name: 'Elven Royal Hat',
		id: 1802,
		enchantmentTier: 7,
		tradeable: true,
		value: 8000000,
		requiredLevel: {
			defense: 75,
			range: 75,
		},
		itemImage: '/images/combat/equipment/range/royal_elven_helm.png',
		extraTooltipInfo: 'Somehow lets you see better while you are wearing it!',
		class: 'equipment',
		visual: 'elven-helm',
		tags: ['armor', 'range', 'craftable'],
		equipmentStats: {
			slot: 'helm',
			offensiveDamageAffinity: {
				Range: 1.16,
			},
			defensiveDamageAffinity: {
				Melee: 0.95,
				Magic: 1.08,
				Range: 1,
				Piercing: 0.95,
				Blunt: 0.97,
				Slashing: 0.97,
				Nature: 1.08,
			},
			offensiveAccuracyAffinityRating: {
				Range: 90,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 24,
			},
			armorBonus: {
				protection: 32,
				resistance: 18,
				agility: 44,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.dexterity',
					value: 2,
				},
				{
					stat: 'armorBonus.agility',
					value: 3,
				},
			],
			itemSet: [10010, 10012, 10014, 10021],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 211000,
			category: 'Range',
			craftable: true,
		},
	},
	1803: {
		name: 'Infernal Chainmail',
		id: 1803,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			strength: 75,
		},
		itemImage: '/images/combat/equipment/infernal_chain.png',
		class: 'equipment',
		visual: 'chainbody-gargoyle',
		tags: ['armor', 'melee', 'craftable'],
		extraTooltipInfo: 'Chain armor supposedly fashioned from the infernal metal of a gargoyle.',
		equipmentStats: {
			slot: 'body',
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.075,
				Fire: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 0.9,
				Range: 1.03,
				Piercing: 1.01,
				Blunt: 1.01,
				Slashing: 1.05,
				Fire: 1.04,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 8,
			},
			armorBonus: {
				protection: 96,
				resistance: -3,
				agility: 20,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.9,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 212500,
			category: 'Melee',
			craftable: true,
		},
	},
	1804: {
		name: 'Infernal Legs',
		id: 1804,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			strength: 75,
		},
		itemImage: '/images/combat/equipment/infernal_pants.png',
		class: 'equipment',
		visual: 'platelegs',
		extraTooltipInfo: 'Liar, liar, pants on fire!',
		tags: ['armor', 'melee', 'craftable'],
		equipmentStats: {
			slot: 'legs',
			offensiveDamageAffinity: {
				Melee: 1.05,
				Piercing: 1.075,
				Fire: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 0.9,
				Range: 1.03,
				Piercing: 1.08,
				Blunt: 1.01,
				Slashing: 1.08,
				Fire: 1.04,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 8,
			},
			armorBonus: {
				protection: 88,
				resistance: -4,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 4.4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 173000,
			category: 'Melee',
			craftable: true,
		},
	},
	1805: {
		name: 'Infernal Helm',
		id: 1805,
		enchantmentTier: 7,
		value: 8000000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			strength: 75,
		},
		itemImage: '/images/combat/equipment/infernal_helm_1.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/infernal_helm_1.png',
				css: 'gargoyle-helm-1',
			},
			{
				image: '/images/combat/equipment/gargoyle_helm_2.png',
				css: 'gargoyle-helm-2',
			},
		],
		tags: ['armor', 'melee', 'craftable'],
		extraTooltipInfo: 'A fiery countenance that will strike fear into the hearts of your enemies.',
		equipmentStats: {
			slot: 'helm',
			offensiveAccuracyAffinityRating: {
				Melee: 64,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Piercing: 1.15,
				Slashing: 1.15,
				Fire: 1.1,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 0.9,
				Range: 1.03,
				Piercing: 1.01,
				Blunt: 1.01,
				Slashing: 1.01,
				Fire: 1.04,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 24,
			},
			armorBonus: {
				protection: 44,
				resistance: -1,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 2.2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 2,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		craftingStats: {
			level: 75,
			experience: 133500,
			category: 'Melee',
			craftable: true,
		},
	},
	1806: {
		name: 'Symphony of Spirits',
		id: 1806,
		enchantmentTier: 7,
		value: 4000000,
		tradeable: true,
		requiredLevel: {
			strength: 75,
		},
		itemImage: '/images/combat/equipment/chorus_of_souls.png',
		itemIcon: '/images/combat/equipment/chorus_of_souls_icon.png',
		extraTooltipInfo: 'It sings with every swing. You find it almost soothing.',
		class: 'equipment',
		visual: 'chorus-of-souls',
		tags: ['melee', 'onehand', 'ability'],
		equipmentStats: {
			grantedAbility: [37, 100, 101],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 90,
				Magic: 38,
				Range: -12,
				Piercing: -4,
				Blunt: 178,
				Slashing: 118,
				Poison: 99,
			},
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.33,
				Slashing: 0.75,
				Poison: 1.2,
			},
			weaponBonus: {
				strength: 142,
				intellect: 64,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 4.5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 7.1,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3.2,
				},
			],
		},
		rarity: 'epic',
	},
	1807: {
		id: 1807,
		name: 'Crown of Spikes',
		value: 800000,
		tradeable: true,
		enchantmentTier: 1,
		forcedEnchant: 14,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/spiked_crown.png',
		class: 'equipment',
		extraTooltipInfo:
			"A relic stolen from a collector's hoard. It bears a powerful enchantment that heavily limits your mobility.",
		requiredLevel: {
			defense: 60,
		},
		equipmentStats: {
			slot: 'arrows',
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: -256,
				stamina: 0,
			},
			offensiveAccuracyAffinityRating: {
				Melee: -128,
				Magic: -128,
				Range: -128,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 3,
				},
				{
					stat: 'armorBonus.resistance',
					value: 3,
				},
				{
					stat: 'armorBonus.stamina',
					value: 3,
				},
			],
		},
		rarity: 'epic',
	},
	1808: {
		id: 1808,
		name: 'Dark Ring',
		value: 500000,
		tradeable: true,
		itemImage: '/images/jewellery/dark_ring.png',
		class: 'equipment',
		extraTooltipInfo:
			'A ring stolen from the Dark Vault; it enhances your combat prowess at the cost of your survivability. It appears to be a crude reconstruction of legendary rings of old.',
		requiredLevel: {
			defense: 60,
		},
		equipmentStats: {
			slot: 'ring',
			offensiveDamageAffinity: {
				Melee: 1.05,
				Magic: 1.05,
				Range: 1.05,
			},
			defensiveDamageAffinity: {
				Melee: 0.9,
				Magic: 0.9,
				Range: 0.9,
			},
			armorBonus: {
				protection: -16,
				resistance: -16,
				agility: -24,
				stamina: -16,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 16,
				Magic: 16,
				Range: 16,
			},
			weaponBonus: {
				strength: 8,
				intellect: 8,
				dexterity: 8,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: -1,
				},
				{
					stat: 'armorBonus.resistance',
					value: -1,
				},
				{
					stat: 'armorBonus.stamina',
					value: -1,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
				{
					stat: 'weaponBonus.intellect',
					value: 3,
				},
				{
					stat: 'weaponBonus.dexterity',
					value: 3,
				},
			],
		},
		rarity: 'epic',
	},
	1809: {
		id: 1809,
		name: 'Pendant of Virulence',
		value: 500000,
		tradeable: true,
		itemImage: '/images/jewellery/necro_2.png',
		enchantmentTier: 1,
		forcedEnchant: 98,
		forcedEnchantAmount: 1,
		class: 'equipment',
		extraTooltipInfo: 'This pendant is a magical channel for poison, death, and altogether unpleasant things.',
		requiredLevel: {
			defense: 60,
		},
		equipmentStats: {
			grantedAbility: [168],
			slot: 'arrows',
			offensiveDamageAffinity: {
				Poison: 1.1,
			},
			offensiveAccuracyAffinityRating: {
				Magic: 24,
				Poison: 32,
			},
			weaponBonus: {
				strength: 0,
				intellect: 16,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 3,
				},
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 3,
				},
			],
			itemSet: [10020],
		},
		rarity: 'epic',
	},
	1810: {
		name: 'Gargoyle Battleaxe',
		id: 1810,
		enchantmentTier: 6,
		value: 1184000,
		tradeable: true,
		requiredLevel: {
			strength: 60,
		},
		itemImage: '/images/combat/equipment/battleaxe_gargoyle.png',
		itemIcon: '/images/combat/equipment/battleaxe_gargoyle_icon.png',
		class: 'equipment',
		visual: 'stygian-battleaxe',
		extraTooltipInfo: 'A rather heavy gargoyle axe. Will you stop using the blunt end already?',
		tags: ['melee', 'onehand', 'craftable'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 66,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 124,
				Slashing: 80,
			},
			offensiveDamageAffinity: {
				Melee: 1.1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.25,
				Slashing: 1.15,
			},
			weaponBonus: {
				strength: 102,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.25,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 3,
				},
				{
					stat: 'weaponBonus.strength',
					value: 6,
				},
			],
			itemSet: [10018],
		},
		rarity: 'rare',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 60,
			experience: 60000,
			category: 'Melee',
			craftable: true,
		},
	},
	1811: {
		name: 'Infernal Battleaxe',
		id: 1811,
		enchantmentTier: 7,
		value: 1136000,
		tradeable: true,
		requiredLevel: {
			strength: 75,
		},
		itemImage: '/images/combat/equipment/battleaxe_infernal.png',
		itemIcon: '/images/combat/equipment/battleaxe_infernal_icon.png',
		class: 'equipment',
		visual: 'stygian-battleaxe',
		tags: ['melee', 'onehand'],
		equipmentStats: {
			grantedAbility: [22, 36],
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.035,
				damageMultiplier: 1.13,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 74,
				Magic: -12,
				Range: -12,
				Piercing: -4,
				Blunt: 179,
				Slashing: 120,
				Fire: 60,
			},
			offensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 0.25,
				Blunt: 1.3,
				Slashing: 1.3,
				Fire: 1.33,
			},
			weaponBonus: {
				strength: 141,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 3,
			hitMults: {
				minimum: 0.28,
				maximum: 1.28,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 4,
				},
				{
					stat: 'weaponBonus.strength',
					value: 7.5,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 75,
			experience: 200000,
			category: 'Melee',
			craftable: false,
		},
	},
	1812: {
		name: 'Infernal Daggers',
		id: 1812,
		enchantmentTier: 7,
		value: 1136000,
		tradeable: true,
		requiredLevel: {
			strength: 75,
		},
		itemImage: '/images/combat/equipment/dagger_infernal_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/dagger_infernal_1.png',
				css: 'gargoyle-daggers-1',
			},
			{
				image: '/images/combat/equipment/dagger_infernal_2.png',
				css: 'gargoyle-daggers-2',
			},
		],
		tags: ['melee', 'twohand', 'melee'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.1,
				damageMultiplier: 2,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 119,
				Magic: -10,
				Range: -10,
				Piercing: 75,
				Blunt: -8,
				Slashing: 75,
				Fire: 60,
			},
			offensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.35,
				Blunt: 0.33,
				Slashing: 0.75,
				Fire: 1.33,
			},
			weaponBonus: {
				strength: 47,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 30,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 2,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 75,
			experience: 200000,
			category: 'Melee',
			craftable: false,
		},
	},
	1813: {
		name: 'Infernal Falchion',
		id: 1813,
		enchantmentTier: 7,
		value: 1184000,
		tradeable: true,
		requiredLevel: {
			strength: 75,
		},
		itemImage: '/images/combat/equipment/infernal_falchion.png',
		itemIcon: '/images/combat/equipment/infernal_falcion_icon.png',
		class: 'equipment',
		visual: 'gargoyle-falchion',
		tags: ['melee', 'onehand', 'melee'],
		equipmentStats: {
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.05,
				damageMultiplier: 1.3,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 89,
				Magic: -14,
				Range: -14,
				Slashing: 104,
				Fire: 60,
			},
			offensiveDamageAffinity: {
				Melee: 1.15,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1,
				Blunt: 0.25,
				Slashing: 1.33,
				Fire: 1.33,
			},
			weaponBonus: {
				strength: 94,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Melee',
					value: 5,
				},
				{
					stat: 'weaponBonus.strength',
					value: 5,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 75,
			experience: 200000,
			category: 'Melee',
			craftable: false,
		},
	},
	1814: {
		name: 'Infernal Shield',
		id: 1814,
		enchantmentTier: 7,
		value: 1232000,
		tradeable: true,
		requiredLevel: {
			defense: 75,
			strength: 75,
		},
		itemImage: '/images/combat/equipment/infernal_shield_1.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/combat/equipment/infernal_shield_1.png',
				css: 'gargoyle-shield-1',
			},
			{
				image: '/images/combat/equipment/infernal_shield_2.png',
				css: 'gargoyle-shield-2',
			},
		],
		tags: ['armor', 'melee'],
		equipmentStats: {
			slot: 'shield',
			defensiveCritical: {
				chance: 0.1,
				damageMultiplier: 0.8,
			},
			offensiveDamageAffinity: {
				Melee: 1.075,
				Piercing: 1.075,
			},
			defensiveDamageAffinity: {
				Melee: 1.03,
				Magic: 0.9,
				Range: 1.03,
				Piercing: 1.15,
				Blunt: 1.1,
				Slashing: 1.15,
				Fire: 1.1,
			},
			weaponBonus: {
				dexterity: 0,
				intellect: 0,
				strength: 0,
			},
			armorBonus: {
				protection: 89,
				resistance: -1,
				agility: 2,
				stamina: 11,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.6,
				},
				{
					stat: 'weaponBonus.strength',
					value: 3,
				},
			],
			itemSet: [10018, 10022],
		},
		rarity: 'epic',
		scrappingSuccessItem: {
			itemID: 12014,
		},
		craftingStats: {
			level: 75,
			experience: 200000,
			category: 'Melee',
			craftable: false,
		},
	},
	1815: {
		name: 'Militia Quiver',
		id: 1815,
		enchantmentTier: 1,
		value: 15000,
		tradeable: true,
		requiredLevel: {
			range: 10,
			crafting: 10,
		},
		forcedEnchant: 63,
		forcedEnchantAmount: 1,
		itemImage: '/images/combat/equipment/range/quiver_leather.png',
		class: 'equipment',
		visual: 'quiver-2',
		extraTooltipInfo: 'A basic quiver to help with your ammo management.',
		tags: ['range', 'craftable'],
		equipmentStats: {
			slot: 'arrows',
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 0,
				Range: 5,
				Slashing: 0,
			},
			offensiveDamageAffinity: {
				Range: 1.025,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 5,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Range',
					value: 0.5,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 15,
			experience: 5000,
			category: 'Range',
			craftable: true,
		},
	},
	1816: {
		name: 'Rune Bag',
		id: 1816,
		enchantmentTier: 1,
		value: 15000,
		tradeable: true,
		requiredLevel: {
			magic: 10,
			crafting: 10,
		},
		forcedEnchant: 61,
		forcedEnchantAmount: 1,
		itemImage: '/images/combat/equipment/magic/rune_bag.png',
		class: 'equipment',
		extraTooltipInfo: 'A basic bag for managing your runes better.',
		tags: ['magic', 'craftable'],
		equipmentStats: {
			slot: 'arrows',
			offensiveAccuracyAffinityRating: {
				Melee: 0,
				Magic: 5,
				Range: 0,
				Slashing: 0,
			},
			offensiveDamageAffinity: {
				Magic: 1.025,
			},
			weaponBonus: {
				strength: 0,
				intellect: 5,
				dexterity: 0,
			},
			augmentationBonus: [
				{
					stat: 'offensiveAccuracyAffinityRating.Magic',
					value: 0.5,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 15,
			experience: 5000,
			category: 'Magic',
			craftable: true,
		},
	},
	1998: {
		name: 'All Potions',
		id: 1998,
		value: 0,
		tradeable: true,
		dummy: 'potion',
		itemImage: '/images/cooking/alchemy/rainbow_pot.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'alchemy', 'food', 'cooking'],
	},
	1999: {
		name: 'All Foods',
		id: 1999,
		value: 0,
		tradeable: true,
		dummy: 'food',
		itemImage: '/images/cooking/monster_curry.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
	},
	2000: {
		name: 'Raw Chicken',
		id: 2000,
		value: 25,
		tradeable: true,
		itemImage: '/images/cooking/raw_chicken.png',
		extraTooltipInfo: 'Cooking Ingredient. Clucked its last cluck.',
		class: 'cooking-ingredient',
		tags: ['cooking'],
	},
	2001: {
		name: 'Raw Beef',
		id: 2001,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/raw_beef.png',
		extraTooltipInfo: 'Cooking Ingredient. Fresh, never frozen!',
		class: 'cooking-ingredient',
		tags: ['cooking'],
	},
	2009: {
		name: 'Spider Legs',
		id: 2009,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/spider_legs.png',
		extraTooltipInfo: 'Cooking Ingredient. It is somehow difficult to thread on a skewer.',
		class: 'cooking-ingredient',
		tags: ['cooking'],
	},
	2010: {
		name: 'Goblin Brain',
		id: 2010,
		value: 25,
		tradeable: true,
		itemImage: '/images/cooking/goblin_brain.png',
		extraTooltipInfo:
			'Cooking Ingredient. They are dumb. They are sad. Their brain run by Runecrafting. Beat them.',
		class: 'cooking-ingredient',
		tags: ['cooking'],
	},
	2012: {
		name: 'Pepper',
		id: 2012,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/pepper.png',
		extraTooltipInfo: "Cooking Ingredient. Don't sneeze or you'll dump too much ore into the forge! Achoo!",
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2013: {
		name: 'Salt',
		id: 2013,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/salt.png',
		extraTooltipInfo: 'Cooking Ingredient. For some reason it reminds you of yourself. A great chef! ... Probably.',
		class: 'cooking-ingredient',
		tags: ['cooking'],
	},
	2014: {
		name: 'Ashes',
		id: 2014,
		value: 5,
		tradeable: true,
		itemImage: '/images/cooking/ashes.png',
		extraTooltipInfo: 'Cooking Ingredient. The remnants of life.',
		class: 'cooking-ingredient',
		tags: ['cooking', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 65,
			category: 'General',
			craftable: true,
			multiplier: 5,
			description: 'Cooking Ingredient. The remnants of life.',
		},
	},
	2015: {
		name: 'Sugar',
		id: 2015,
		value: 10,
		tradeable: true,
		itemImage: '/images/cooking/sugar.png',
		extraTooltipInfo: 'Cooking Ingredient. A sugar rush would definitely make you more efficient!',
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2016: {
		name: 'Honey',
		id: 2016,
		value: 20,
		tradeable: true,
		experience: 15,
		itemImage: '/images/cooking/honey.png',
		extraTooltipInfo: 'Cooking Ingredient. Why do fish like this so much?',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 6,
			cooldown: 7500,
			perTick: 6,
			totalTicks: 5,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2017: {
		name: 'Milk',
		id: 2017,
		value: 15,
		tradeable: true,
		itemImage: '/images/cooking/milk.png',
		extraTooltipInfo: 'Cooking Ingredient. mooooooooo',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 9,
			cooldown: 10000,
			perTick: 9,
			totalTicks: 3,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2018: {
		name: 'Butter',
		id: 2018,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/butter.png',
		extraTooltipInfo:
			'Cooking Ingredient. This really didnt take a lot of effort to make like everyone is saying...',
		class: 'cooking-ingredient',
		tags: ['cooking', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 25,
			category: 'General',
			craftable: true,
			description:
				'Cooking Ingredient. This really didnt take a lot of effort to make like everyone is saying...',
		},
	},
	2019: {
		name: 'Egg',
		id: 2019,
		value: 15,
		tradeable: true,
		itemImage: '/images/cooking/egg.png',
		extraTooltipInfo: 'GEG, scrambled egg.',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
	},
	2020: {
		name: 'Mushroom',
		id: 2020,
		value: 10,
		tradeable: true,
		experience: 15,
		itemImage: '/images/cooking/mushroom.png',
		extraTooltipInfo: 'Cooking Ingredient. Kinda makes you feel like checking the roots of things.',
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2021: {
		name: 'Carrot',
		id: 2021,
		value: 15,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/carrot.png',
		extraTooltipInfo: 'Cooking Ingredient. Build your own snowman kit. Just add water and coal!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 9,
			cooldown: 10000,
			perTick: 9,
			totalTicks: 4,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2022: {
		name: 'Chili Pepper',
		id: 2022,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/chili_pepper.png',
		extraTooltipInfo: 'Cooking Ingredient. So hot you feel like you could almost smelt ores with it!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 6,
			cooldown: 7500,
			perTick: 6,
			totalTicks: 5,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2023: {
		name: 'Potato',
		id: 2023,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/potato.png',
		extraTooltipInfo: "Cooking Ingredient. What's 'taters, eh?",
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2024: {
		name: 'Pumpkin',
		id: 2024,
		value: 10,
		tradeable: true,
		experience: 20,
		itemImage: '/images/cooking/pumpkin.png',
		extraTooltipInfo: 'Cooking Ingredient. Such a weird melon!',
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2025: {
		name: 'Tomato',
		id: 2025,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/tomato.png',
		extraTooltipInfo: 'Cooking Ingredient. Definitely a fruit.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 3,
			cooldown: 5000,
			perTick: 3,
			totalTicks: 10,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2026: {
		name: 'Apple',
		id: 2026,
		value: 20,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/apple.png',
		extraTooltipInfo: "Cooking Ingredient. Didn't fall far from the tree.",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 9,
			cooldown: 10000,
			perTick: 9,
			totalTicks: 4,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2027: {
		name: 'Wildberry',
		id: 2027,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/wildberry.png',
		extraTooltipInfo: 'Cooking Ingredient. Like a blueberry, but wild.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 3,
			cooldown: 5000,
			perTick: 3,
			totalTicks: 10,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2028: {
		name: 'Banana',
		id: 2028,
		value: 20,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/banana.png',
		extraTooltipInfo: "Cooking Ingredient. Gather 'em up in bunches!",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking', 'foraging'],
		healing: {
			hp: 9,
			cooldown: 10000,
			perTick: 9,
			totalTicks: 4,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2029: {
		name: 'Wheat',
		id: 2029,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/wheat.png',
		extraTooltipInfo: 'Cooking Ingredient. How many of these do I need to make a Straw Hat?',
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2030: {
		name: 'Rice',
		id: 2030,
		value: 10,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/rice.png',
		extraTooltipInfo: "Cooking Ingredient. You're telling me a Limpe fried this rice?",
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2031: {
		name: 'Sageberry',
		id: 2031,
		value: 150,
		tradeable: true,
		experience: 1,
		itemImage: '/images/farming/sage_berry.png',
		extraTooltipInfo: 'Cooking Ingredient. The ultimate big brain berry.',
		class: 'cooking-ingredient',
		tags: ['cooking', 'foraging'],
	},
	2032: {
		name: 'Choccy Milk',
		id: 2032,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/chocolate_milk.png',
		extraTooltipInfo: "Cooking Ingredient. Do you even know what chocolate is? ... Well, it's brown.",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 24,
			cooldown: 10000,
			perTick: 20,
			totalTicks: 6,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2034: {
		name: 'Filleted Fish',
		id: 2034,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/fillet.png',
		extraTooltipInfo: 'Sashimi! You should probably cook these.',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 3,
			cooldown: 3000,
			perTick: 5,
			totalTicks: 5,
			tickDelay: 3000,
			ignoreCooldown: false,
		},
	},
	2036: {
		name: 'Ground Flour',
		id: 2036,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/starch.png',
		extraTooltipInfo: 'Definitely better cooked!',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 6,
			cooldown: 5000,
			perTick: 5,
			totalTicks: 4,
			tickDelay: 4000,
			ignoreCooldown: false,
		},
	},
	2037: {
		name: 'Churned Dairy',
		id: 2037,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/dairy.png',
		extraTooltipInfo: 'Tide you over till you cook real food.',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 10,
			cooldown: 4000,
			perTick: 6,
			totalTicks: 3,
			tickDelay: 4000,
			ignoreCooldown: false,
		},
	},
	2039: {
		name: 'Diced Fruit',
		id: 2039,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/fruit.png',
		extraTooltipInfo: 'Fruit salad. Cook these for more nutrition!',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 11,
			cooldown: 3000,
			perTick: 7,
			totalTicks: 2,
			tickDelay: 3000,
			ignoreCooldown: false,
		},
	},
	2040: {
		name: 'Chopped Vegetables',
		id: 2040,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/vegetable.png',
		extraTooltipInfo: "You're not sure if that tomato belongs here. Cook these for a hearty meal.",
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 4,
			cooldown: 6000,
			perTick: 5,
			totalTicks: 4,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2043: {
		name: 'Minced Meat',
		id: 2043,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/meat.png',
		extraTooltipInfo: 'Cook them you monster!',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 3,
			cooldown: 8000,
			perTick: 4,
			totalTicks: 6,
			tickDelay: 7000,
			ignoreCooldown: false,
		},
	},
	2044: {
		name: 'Butchered Monster',
		id: 2044,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/monster.png',
		extraTooltipInfo: 'Really!? Is it better cooked?',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
		healing: {
			hp: 0,
			cooldown: 10000,
			perTick: 4,
			totalTicks: 8,
			tickDelay: 10000,
			ignoreCooldown: false,
		},
	},
	2045: {
		name: 'Sour Essence',
		id: 2045,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/sour.png',
		extraTooltipInfo: '',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
	},
	2046: {
		name: 'Bitter Essence',
		id: 2046,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/bitter.png',
		extraTooltipInfo: '',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
	},
	2047: {
		name: 'Sweet Essence',
		id: 2047,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/sweet.png',
		extraTooltipInfo: '',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
	},
	2048: {
		name: 'Spicy Essence',
		id: 2048,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/prepared/spicy.png',
		extraTooltipInfo: '',
		class: 'cooking-ingredient',
		tags: ['prepared', 'cooking'],
	},
	2100: {
		name: 'Questionable Food',
		id: 2100,
		value: 5,
		tradeable: true,
		itemImage: '/images/cooking/questionable_food.png',
		extraTooltipInfo: "You're not quite sure what this is supposed to be. Are you really going to eat it?",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 3,
			cooldown: 5000,
			perTick: 1,
			totalTicks: 5,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2101: {
		name: 'Monster Surprise',
		id: 2101,
		value: 5,
		tradeable: true,
		itemImage: '/images/cooking/monster_surprise.png',
		extraTooltipInfo: '... Probably edible!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 0,
			cooldown: 10000,
			perTick: 7,
			totalTicks: 15,
			tickDelay: 8000,
			ignoreCooldown: false,
		},
	},
	2102: {
		name: 'Seared Steak',
		id: 2102,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/seared_steak.png',
		extraTooltipInfo: 'A nice juicy steak.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 4,
			cooldown: 8000,
			perTick: 6,
			totalTicks: 13,
			tickDelay: 7000,
			ignoreCooldown: false,
		},
	},
	2103: {
		name: 'Seared Fish',
		id: 2103,
		value: 30,
		tradeable: true,
		itemImage: '/images/cooking/seared_fish.png',
		extraTooltipInfo: "You're not entirely sure which fish this is, but it's definitely seared!",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 5,
			cooldown: 6000,
			perTick: 9,
			totalTicks: 9,
			tickDelay: 6000,
			ignoreCooldown: false,
		},
	},
	2104: {
		name: 'Seared Poultry',
		id: 2104,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/seared_bird.png',
		extraTooltipInfo: "It's not fried but it'll do.",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 3,
			cooldown: 5000,
			perTick: 3,
			totalTicks: 12,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2105: {
		name: 'Birdie Pasta',
		id: 2105,
		value: 75,
		tradeable: true,
		itemImage: '/images/cooking/birdie_pasta.png',
		extraTooltipInfo: 'The invention of a true madman.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 15,
			cooldown: 8000,
			perTick: 15,
			totalTicks: 8,
			tickDelay: 4000,
			ignoreCooldown: false,
		},
	},
	2106: {
		name: 'Spaghetti & Meat',
		id: 2106,
		value: 75,
		tradeable: true,
		itemImage: '/images/cooking/spaghetti_meat.png',
		extraTooltipInfo: 'Classic.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 18,
			cooldown: 13000,
			perTick: 16,
			totalTicks: 10,
			tickDelay: 5500,
			ignoreCooldown: false,
		},
	},
	2107: {
		name: 'Sushi',
		id: 2107,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/sushi.png',
		extraTooltipInfo: 'Wait, is the fish raw or not?',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 22,
			cooldown: 11000,
			perTick: 21,
			totalTicks: 8,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2108: {
		name: 'Birdie Pie',
		id: 2108,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/birdie_pie.png',
		extraTooltipInfo: 'Could almost be called a chicken pot pie!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 24,
			cooldown: 15000,
			perTick: 40,
			totalTicks: 3,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2109: {
		name: 'Cottage Pie',
		id: 2109,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/cottage_pie.png',
		extraTooltipInfo: 'Maybe you should have cut the meat up first.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 63,
			cooldown: 17000,
			perTick: 39,
			totalTicks: 8,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2110: {
		name: "Fisherman's Pie",
		id: 2110,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/fisherman_pie.png',
		extraTooltipInfo: '... is that a whole fin sticking out of the crust?',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 61,
			cooldown: 15000,
			perTick: 42,
			totalTicks: 7,
			tickDelay: 4666,
			ignoreCooldown: false,
		},
	},
	2111: {
		name: 'Fruit Pie',
		id: 2111,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/fruit_pie.png',
		extraTooltipInfo: "I don't think that's what many people have in mind when describing a fruit pie.",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 90,
			cooldown: 12000,
			perTick: 49,
			totalTicks: 5,
			tickDelay: 3666,
			ignoreCooldown: false,
		},
	},
	2112: {
		name: 'Demon Cake',
		id: 2112,
		value: 175,
		tradeable: true,
		itemImage: '/images/cooking/demon_cake.png',
		extraTooltipInfo: 'Probably not inherently evil! Probably.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 16,
			cooldown: 15000,
			perTick: 19,
			totalTicks: 11,
			tickDelay: 6000,
			ignoreCooldown: false,
		},
	},
	2113: {
		name: 'Cake',
		id: 2113,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/cake.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		extraTooltipInfo: 'A lie.',
		healing: {
			hp: 28,
			cooldown: 12000,
			perTick: 17,
			totalTicks: 7,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2114: {
		name: 'Fruit Cake',
		id: 2114,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/fruit_cake.png',
		extraTooltipInfo: 'Pretty please with a cherry on top?',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 45,
			cooldown: 8000,
			perTick: 27,
			totalTicks: 5,
			tickDelay: 3500,
			ignoreCooldown: false,
		},
	},
	2115: {
		name: 'Grain Cake',
		id: 2115,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/grain_cake.png',
		extraTooltipInfo: 'A little dry.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 33,
			cooldown: 9000,
			perTick: 22,
			totalTicks: 6,
			tickDelay: 4000,
			ignoreCooldown: false,
		},
	},
	2116: {
		name: 'Omelette',
		id: 2116,
		value: 75,
		tradeable: true,
		itemImage: '/images/cooking/omelette.png',
		extraTooltipInfo: 'Sometimes you just gotta break a few eggs.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 10,
			cooldown: 7000,
			perTick: 7,
			totalTicks: 8,
			tickDelay: 6000,
			ignoreCooldown: false,
		},
	},
	2117: {
		name: 'Monster Omelette',
		id: 2117,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/monster_omelette.png',
		extraTooltipInfo: 'Is it alive?',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 15,
			cooldown: 17000,
			perTick: 16,
			totalTicks: 11,
			tickDelay: 7000,
			ignoreCooldown: false,
		},
	},
	2118: {
		name: 'Meaty Omelette',
		id: 2118,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/meat_omelette.png',
		extraTooltipInfo: 'Rich and savoury!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 21,
			cooldown: 15000,
			perTick: 16,
			totalTicks: 10,
			tickDelay: 6500,
			ignoreCooldown: false,
		},
	},
	2119: {
		name: 'Veggie Crepe',
		id: 2119,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/veggie_crepe.png',
		extraTooltipInfo: "Vegetables are crepe'y! Get it? No? Okay...",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 24,
			cooldown: 13000,
			perTick: 17,
			totalTicks: 7,
			tickDelay: 5500,
			ignoreCooldown: false,
		},
	},
	2120: {
		name: 'Fruity Crepe',
		id: 2120,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/fruit_crepe.png',
		extraTooltipInfo: 'Should probably only be eaten as a dessert!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 42,
			cooldown: 10000,
			perTick: 22,
			totalTicks: 5,
			tickDelay: 4500,
			ignoreCooldown: false,
		},
	},
	2121: {
		name: 'Eggnog',
		id: 2121,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/egg_nog.png',
		extraTooltipInfo: 'Good all year round!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 37,
			cooldown: 11000,
			perTick: 21,
			totalTicks: 6,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2122: {
		name: 'Meat Roast',
		id: 2122,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/meat_roast.png',
		extraTooltipInfo: 'PROTEIN!!!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 18,
			cooldown: 24000,
			perTick: 32,
			totalTicks: 12,
			tickDelay: 7000,
			ignoreCooldown: false,
		},
	},
	2123: {
		name: 'Fried Fruit',
		id: 2123,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/fried_fruit.png',
		extraTooltipInfo: 'Basically candy at this point.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 22,
			cooldown: 3000,
			perTick: 14,
			totalTicks: 3,
			tickDelay: 3000,
			ignoreCooldown: false,
		},
	},
	2124: {
		name: 'Grilled Veggies',
		id: 2124,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/grilled_veggies.png',
		extraTooltipInfo: 'Grilled to perfection!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 8,
			cooldown: 6000,
			perTick: 9,
			totalTicks: 8,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2125: {
		name: 'Kebabs',
		id: 2125,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/kababs.png',
		extraTooltipInfo: "The action man's food!",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 60,
			cooldown: 17000,
			perTick: 42,
			totalTicks: 7,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2126: {
		name: 'Candied Fruit',
		id: 2126,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/candied_fruit.png',
		extraTooltipInfo: 'Almost too sweet to handle!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 6,
			cooldown: 7500,
			perTick: 6,
			totalTicks: 10,
			tickDelay: 5000,
			ignoreCooldown: true,
		},
	},
	2127: {
		name: 'Milkshake',
		id: 2127,
		value: 75,
		tradeable: true,
		itemImage: '/images/cooking/milkshake.png',
		extraTooltipInfo: 'Brings all the boys to the raid!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 56,
			cooldown: 7000,
			perTick: 31,
			totalTicks: 4,
			tickDelay: 3500,
			ignoreCooldown: false,
		},
	},
	2128: {
		name: 'Vegetable Stew',
		id: 2128,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/veggie_stew.png',
		extraTooltipInfo: 'Reminds you of home.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 28,
			cooldown: 10000,
			perTick: 22,
			totalTicks: 6,
			tickDelay: 4500,
			ignoreCooldown: false,
		},
	},
	2129: {
		name: 'Meaty Stew',
		id: 2129,
		value: 125,
		tradeable: true,
		itemImage: '/images/cooking/meaty_stew.png',
		extraTooltipInfo: 'Definitely not just fried chicken in a bowl of sauce!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 34,
			cooldown: 12000,
			perTick: 22,
			totalTicks: 8,
			tickDelay: 5500,
			ignoreCooldown: false,
		},
	},
	2130: {
		name: 'Monster Stew',
		id: 2130,
		value: 200,
		tradeable: true,
		itemImage: '/images/cooking/monster_stew.png',
		extraTooltipInfo: 'Parts of it are definitely still moving.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 20,
			cooldown: 14000,
			perTick: 20,
			totalTicks: 10,
			tickDelay: 6000,
			ignoreCooldown: false,
		},
	},
	2131: {
		name: 'Jerky',
		id: 2131,
		value: 75,
		tradeable: true,
		itemImage: '/images/cooking/jerky.png',
		extraTooltipInfo: 'A chewy treat.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 18,
			cooldown: 10000,
			perTick: 18,
			totalTicks: 7,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2132: {
		name: 'Pudding',
		id: 2132,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/pudding.png',
		extraTooltipInfo: "The old man's favorite. Don't steal it!",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 24,
			cooldown: 12500,
			perTick: 24,
			totalTicks: 5,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2133: {
		name: 'Ice Cream',
		id: 2133,
		value: 100,
		tradeable: true,
		itemImage: '/images/cooking/icecream.png',
		extraTooltipInfo: 'The ultimate dairy product. Comes with a free spoon!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 18,
			cooldown: 10000,
			perTick: 18,
			totalTicks: 7,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2134: {
		name: 'Monster Curry',
		id: 2134,
		value: 150,
		tradeable: true,
		itemImage: '/images/cooking/monster_curry.png',
		extraTooltipInfo: 'Might actually be mildly edible!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 33,
			cooldown: 21000,
			perTick: 35,
			totalTicks: 10,
			tickDelay: 5666,
			ignoreCooldown: false,
		},
	},
	2135: {
		name: 'Birdie Curry',
		id: 2135,
		value: 175,
		tradeable: true,
		itemImage: '/images/cooking/birdie_curry.png',
		extraTooltipInfo: 'Did you just put an entire chicken in this dish without preparing it first?',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 24,
			cooldown: 17500,
			perTick: 32,
			totalTicks: 3,
			tickDelay: 3000,
			ignoreCooldown: false,
		},
	},
	2136: {
		name: 'Beast Curry',
		id: 2136,
		value: 175,
		tradeable: true,
		itemImage: '/images/cooking/beast_curry.png',
		extraTooltipInfo: 'Meat, spice, and everything nice! Or grain, mostly grain.',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 43,
			cooldown: 19000,
			perTick: 35,
			totalTicks: 9,
			tickDelay: 5333,
			ignoreCooldown: false,
		},
	},
	2137: {
		name: 'Seafood Curry',
		id: 2137,
		value: 175,
		tradeable: true,
		itemImage: '/images/cooking/seafood_curry.png',
		extraTooltipInfo: "There's just a whole fish in there. Why did you do that?",
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 41,
			cooldown: 17000,
			perTick: 37,
			totalTicks: 8,
			tickDelay: 5000,
			ignoreCooldown: false,
		},
	},
	2138: {
		name: 'Monster Gelato',
		id: 2138,
		value: 225,
		tradeable: true,
		itemImage: '/images/cooking/monster_gelato.png',
		extraTooltipInfo: 'Only partially inedible!',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'cooking'],
		healing: {
			hp: 50,
			cooldown: 21000,
			perTick: 35,
			totalTicks: 9,
			tickDelay: 6000,
			ignoreCooldown: false,
		},
	},
	2139: {
		name: 'Common Potion',
		id: 2139,
		value: 5,
		tradeable: true,
		stackStrength: 1,
		stackMultiplier: 1,
		itemImage: '/images/cooking/alchemy/common.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'alchemy', 'cooking'],
	},
	2140: {
		name: 'Common Concentrated Potion',
		id: 2140,
		value: 125,
		tradeable: true,
		stackStrength: 2,
		stackMultiplier: 0.8,
		itemImage: '/images/cooking/alchemy/common-concentrated.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 2,
			cooldown: 5000,
			perTick: 2,
			totalTicks: 10,
			tickDelay: 1000,
			ignoreCooldown: true,
		},
	},
	2141: {
		name: 'Common Large Potion',
		id: 2141,
		value: 125,
		tradeable: true,
		stackStrength: 1,
		stackMultiplier: 6,
		itemImage: '/images/cooking/alchemy/common-large.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 2,
			cooldown: 5000,
			perTick: 2,
			totalTicks: 10,
			tickDelay: 1000,
			ignoreCooldown: true,
		},
	},
	2142: {
		name: 'Rare Potion',
		id: 2142,
		value: 10,
		tradeable: true,
		stackStrength: 2,
		stackMultiplier: 1,
		itemImage: '/images/cooking/alchemy/rare.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'alchemy', 'cooking'],
		rarity: 'rare',
	},
	2143: {
		name: 'Rare Concentrated Potion',
		id: 2143,
		value: 250,
		tradeable: true,
		stackStrength: 3,
		stackMultiplier: 0.9,
		itemImage: '/images/cooking/alchemy/rare-concentrated.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 4,
			cooldown: 10000,
			perTick: 4,
			totalTicks: 10,
			tickDelay: 1000,
			ignoreCooldown: true,
		},
		rarity: 'rare',
	},
	2144: {
		name: 'Rare Large Potion',
		id: 2144,
		value: 250,
		tradeable: true,
		stackStrength: 2,
		stackMultiplier: 6,
		itemImage: '/images/cooking/alchemy/rare-large.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 4,
			cooldown: 10000,
			perTick: 4,
			totalTicks: 10,
			tickDelay: 1000,
			ignoreCooldown: true,
		},
		rarity: 'rare',
	},
	2145: {
		name: 'Epic Potion',
		id: 2145,
		value: 15,
		tradeable: true,
		stackStrength: 3,
		stackMultiplier: 1,
		itemImage: '/images/cooking/alchemy/epic.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'alchemy', 'cooking'],
		rarity: 'epic',
	},
	2146: {
		name: 'Epic Large Potion',
		id: 2146,
		value: 375,
		tradeable: true,
		stackStrength: 3,
		stackMultiplier: 6,
		itemImage: '/images/cooking/alchemy/epic-large.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 6,
			cooldown: 15000,
			perTick: 6,
			totalTicks: 10,
			tickDelay: 1000,
			ignoreCooldown: true,
		},
		rarity: 'epic',
	},
	2147: {
		name: 'Legendary Potion',
		id: 2147,
		value: 20,
		tradeable: true,
		stackStrength: 4,
		stackMultiplier: 1,
		itemImage: '/images/cooking/alchemy/legendary.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'alchemy', 'cooking'],
		rarity: 'legendary',
	},
	2148: {
		name: 'Legendary Large Potion',
		id: 2148,
		value: 500,
		tradeable: true,
		stackStrength: 4,
		stackMultiplier: 6,
		itemImage: '/images/cooking/alchemy/legendary-large.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 8,
			cooldown: 20000,
			perTick: 8,
			totalTicks: 10,
			tickDelay: 1000,
			ignoreCooldown: true,
		},
		rarity: 'legendary',
	},
	2149: {
		name: 'Epic Concentrated Potion',
		id: 2149,
		value: 375,
		tradeable: true,
		stackStrength: 4,
		stackMultiplier: 1,
		itemImage: '/images/cooking/alchemy/epic-concentrated.png',
		class: 'cooking-ingredient',
		tags: ['consumable', 'food', 'alchemy', 'cooking'],
		healing: {
			hp: 6,
			cooldown: 15000,
			perTick: 6,
			totalTicks: 10,
			tickDelay: 1000,
		},
		rarity: 'epic',
	},
	3000: {
		name: 'Water Imbued Dust',
		id: 3000,
		value: 1,
		tradeable: true,
		itemImage: '/images/cooking/water_dust.png',
		extraTooltipInfo:
			'Crushed up water runes. Not exactly a delicacy, but it might have some sort of effect when cooked.',
		class: 'cooking-ingredient',
		tags: ['cooking', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 60,
			experience: 95,
			category: 'Dungeon',
			craftable: true,
			multiplier: 8,
			description:
				'Each craft results in 8 dust. Crushed up water runes. Not exactly a delicacy, but it might have some sort of effect when cooked.',
		},
	},
	3001: {
		name: 'Empty Vial',
		id: 3001,
		value: 1,
		tradeable: true,
		itemImage: '/images/cooking/alchemy/empty_vial.png',
		extraTooltipInfo: 'An empty vial to pour a standard potion into.',
		class: 'cooking-ingredient',
		tags: ['alchemy', 'cooking', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 11,
			category: 'General',
			craftable: true,
			multiplier: 300,
			description: 'A basic glass vial used in alchemy.',
		},
	},
	3002: {
		name: 'Empty Large Vial',
		id: 3002,
		value: 5,
		tradeable: true,
		itemImage: '/images/cooking/alchemy/empty_large_vial.png',
		extraTooltipInfo: 'A large empty vial to pour a large potion into.',
		class: 'cooking-ingredient',
		tags: ['alchemy', 'cooking'],
	},
	3003: {
		name: 'Empty Reinforced Vial',
		id: 3003,
		value: 50,
		tradeable: true,
		itemImage: '/images/cooking/alchemy/empty_reinforced_vial.png',
		extraTooltipInfo: 'A reinforced empty vial to pour a concentrated potion into.',
		class: 'cooking-ingredient',
		tags: ['alchemy', 'cooking'],
	},
	3004: {
		name: 'Watermelon',
		id: 3004,
		value: 150,
		tradeable: true,
		experience: 35,
		itemImage: '/images/cooking/alchemy/ingredients/watermelon.png',
		extraTooltipInfo: "Good for gathering! Now you only need 999,999 more! Is it spelled 'Melon' or 'Mellon'?",
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3005: {
		name: 'Durian',
		id: 3005,
		value: 200,
		tradeable: true,
		experience: 55,
		itemImage: '/images/cooking/alchemy/ingredients/durian.png',
		extraTooltipInfo: "INFO's Favorite!",
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3006: {
		name: 'Onion',
		id: 3006,
		value: 30,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/alchemy/ingredients/onion.png',
		extraTooltipInfo: "Makes you cry when it speaks. Luckily, it's incapable of speech. Or is it?",
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3007: {
		name: 'Mana Berry',
		id: 3007,
		value: 125,
		tradeable: true,
		experience: 25,
		itemImage: '/images/cooking/alchemy/ingredients/mana_berry.png',
		extraTooltipInfo: 'Named for its magic enhancing properties.',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3008: {
		name: 'Thorny Root',
		id: 3008,
		value: 50,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/alchemy/ingredients/thorn_1.png',
		extraTooltipInfo: 'This one would hurt to fall face first into!',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3009: {
		name: 'Thorny Vine',
		id: 3009,
		value: 50,
		tradeable: true,
		experience: 10,
		itemImage: '/images/cooking/alchemy/ingredients/thorn_2.png',
		extraTooltipInfo: "Don't let this one snag you by surprise!",
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3010: {
		name: 'Tangle Root',
		id: 3010,
		value: 80,
		tradeable: true,
		experience: 20,
		itemImage: '/images/cooking/alchemy/ingredients/root.png',
		extraTooltipInfo: 'Would probably try to trip someone if it was still in the ground.',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3011: {
		name: 'Mandrake',
		id: 3011,
		value: 140,
		tradeable: true,
		experience: 30,
		itemImage: '/images/cooking/alchemy/ingredients/mandrake.png',
		extraTooltipInfo: 'Does not look man or drake shaped at all. Who named this thing?',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3012: {
		name: 'Jellyfish',
		id: 3012,
		value: 200,
		tradeable: true,
		experience: 50,
		itemImage: '/images/cooking/alchemy/ingredients/jellyfish.png',
		extraTooltipInfo: 'How would it taste on a sandwich, you wonder.',
		class: 'cooking-ingredient',
		tags: ['alchemy', 'cooking', 'fishing'],
	},
	3013: {
		name: 'Frost Flowers',
		id: 3013,
		value: 180,
		tradeable: true,
		experience: 30,
		itemImage: '/images/cooking/alchemy/ingredients/frost_flowers.png',
		extraTooltipInfo: 'Perpetually cold. Does it remind you of home?',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3014: {
		name: 'Black Berry',
		id: 3014,
		value: 20,
		tradeable: true,
		experience: 40,
		itemImage: '/images/cooking/alchemy/ingredients/blackberry.png',
		extraTooltipInfo: "It doesn't know how to speak.",
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3015: {
		name: 'Fairy Dust',
		id: 3015,
		value: 165,
		tradeable: true,
		experience: 35,
		itemImage: '/images/cooking/alchemy/ingredients/fairy_dust.png',
		extraTooltipInfo: "Not actually from a Fairy. Everybody knows Fairies aren't real!",
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3016: {
		name: 'Lotus Flower',
		id: 3016,
		value: 300,
		tradeable: true,
		experience: 55,
		itemImage: '/images/cooking/alchemy/ingredients/lotus.png',
		extraTooltipInfo:
			'A rare flower that grows in the deepest parts of the Living Forest and is rumored to have minor magical properties when prepared through alchemy.',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3017: {
		name: 'Four-leaf Clover',
		id: 3017,
		value: 777,
		tradeable: true,
		experience: 77,
		itemImage: '/images/cooking/alchemy/ingredients/lucky_clover.png',
		extraTooltipInfo: 'Very lucky!',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3018: {
		name: 'Glow Spore Shroom',
		id: 3018,
		value: 90,
		tradeable: true,
		experience: 25,
		itemImage: '/images/cooking/alchemy/ingredients/glow_spores.png',
		extraTooltipInfo: 'The mushroom itself is actually useless with only the spores having value.',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3019: {
		name: 'Pineapple',
		id: 3019,
		value: 70,
		tradeable: true,
		experience: 15,
		itemImage: '/images/cooking/alchemy/ingredients/pineapple.png',
		extraTooltipInfo: 'Does it go on pizza or not?',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3020: {
		name: 'Ghost Shrooms',
		id: 3020,
		value: 160,
		tradeable: true,
		experience: 30,
		itemImage: '/images/cooking/alchemy/ingredients/ghost_shrooms.png',
		extraTooltipInfo: 'A handful of very pale, small mushrooms that emit the barest amount of light.',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	3021: {
		name: 'Yellow Pepper',
		id: 3021,
		value: 160,
		tradeable: true,
		experience: 30,
		itemImage: '/images/cooking/alchemy/ingredients/yellow_pepper.png',
		extraTooltipInfo: 'It may be a yellow pepper, but it is Red Hot! ... Sorry.',
		class: 'cooking-ingredient',
		tags: ['foraging', 'alchemy', 'cooking'],
	},
	4000: {
		id: 4000,
		name: 'Bone',
		value: 75,
		tradeable: true,
		itemImage: '/images/combat/materials/bone.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4001: {
		id: 4001,
		name: 'Bone Plate',
		value: 150,
		tradeable: true,
		itemImage: '/images/combat/materials/bone_plate.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4002: {
		id: 4002,
		name: 'Chitin',
		value: 2750,
		tradeable: true,
		itemImage: '/images/combat/materials/chitin_2.png',
		class: 'leather',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 0,
			category: 'General',
			craftable: true,
			multiplier: 5,
			description: 'Convert heavy chitin into chitin.',
		},
	},
	4003: {
		id: 4003,
		name: 'Heavy Chitin',
		value: 3750,
		tradeable: true,
		itemImage: '/images/combat/materials/heavy_chitin.png',
		class: 'leather',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4004: {
		id: 4004,
		name: 'Fur',
		value: 150,
		tradeable: true,
		itemImage: '/images/combat/materials/fur.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4005: {
		id: 4005,
		name: 'Thick Fur',
		value: 225,
		tradeable: true,
		itemImage: '/images/combat/materials/thick_fur.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4006: {
		id: 4006,
		name: 'Wool',
		value: 200,
		tradeable: true,
		itemImage: '/images/combat/materials/wool.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4007: {
		id: 4007,
		name: 'Hide',
		value: 50,
		tradeable: true,
		itemImage: '/images/combat/materials/hide.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4008: {
		id: 4008,
		name: 'Rough Hide',
		value: 125,
		tradeable: true,
		itemImage: '/images/combat/materials/rough_hide.png',
		class: 'fur',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4009: {
		id: 4009,
		name: 'Scale',
		value: 1250,
		tradeable: true,
		itemImage: '/images/combat/materials/scale.png',
		class: 'leather',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 0,
			category: 'General',
			craftable: true,
			multiplier: 5,
			description: 'Convert heavy scale into scale.',
		},
	},
	4010: {
		id: 4010,
		name: 'Heavy Scale',
		value: 1750,
		tradeable: true,
		itemImage: '/images/combat/materials/heavy_scale.png',
		class: 'leather',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['farming', 'hunting'],
	},
	4011: {
		id: 4011,
		name: 'Crystal Scale',
		value: 3000,
		tradeable: true,
		itemImage: '/images/combat/materials/crystal_scale.png',
		class: 'leather',
		extraTooltipInfo: 'Used to enhance Elven equipment.',
		tags: ['crafting'],
	},
	4012: {
		id: 4012,
		name: 'Leather',
		value: 300,
		tradeable: true,
		itemImage: '/images/combat/materials/leather.png',
		class: 'leather',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['crafting', 'craftable'],
		craftingStats: {
			level: 15,
			experience: 20,
			category: 'General',
			craftable: true,
		},
	},
	4013: {
		id: 4013,
		name: 'Rough Cloth',
		value: 30,
		tradeable: true,
		experience: 50,
		level: 1,
		time: 5000,
		itemImage: '/images/combat/materials/rough_cloth.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
	},
	4014: {
		id: 4014,
		name: 'Restored Cloth',
		value: 60,
		tradeable: true,
		experience: 75,
		level: 10,
		time: 5500,
		itemImage: '/images/combat/materials/restored_cloth_new.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
	},
	4015: {
		id: 4015,
		name: 'Lesser Cloth',
		value: 150,
		tradeable: true,
		experience: 125,
		level: 20,
		time: 6000,
		itemImage: '/images/combat/materials/lesser_cloth_new.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
	},
	4016: {
		id: 4016,
		name: 'Imbued Cloth',
		value: 500,
		tradeable: true,
		experience: 200,
		level: 30,
		time: 6500,
		itemImage: '/images/combat/materials/imbued_cloth_new.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
	},
	4017: {
		id: 4017,
		name: 'Major Cloth',
		value: 1000,
		tradeable: true,
		experience: 300,
		level: 40,
		time: 7000,
		itemImage: '/images/combat/materials/major_cloth_new.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
	},
	4018: {
		id: 4018,
		name: 'Greater Cloth',
		value: 2000,
		tradeable: true,
		experience: 500,
		level: 50,
		time: 7500,
		itemImage: '/images/combat/materials/greater_cloth_new.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
		rarity: 'uncommon',
	},
	4019: {
		id: 4019,
		name: 'Elder Cloth',
		value: 4000,
		tradeable: true,
		experience: 750,
		level: 60,
		time: 8000,
		itemImage: '/images/combat/materials/elder_cloth_new.png',
		class: 'cloth',
		extraTooltipInfo: 'Used as a crafting material.',
		tags: ['runecrafting'],
		rarity: 'rare',
	},
	4020: {
		id: 4020,
		name: 'Aqueous Grass',
		value: 30,
		tradeable: true,
		itemImage: '/images/fishing/material/fiber_1.png',
		class: 'fiber',
		extraTooltipInfo:
			'Some aqueous grass that was stuck to your fishing tools. It appears to be weakly magical. Used as a crafting material.',
		tags: ['fishing', 'runecrafting'],
	},
	4021: {
		id: 4021,
		name: 'Water Weeds',
		value: 60,
		tradeable: true,
		itemImage: '/images/fishing/material/fiber_2.png',
		class: 'fiber',
		extraTooltipInfo:
			'Water weeds that appear to have been exposed to some sort of magical source. Used as a crafting material.',
		tags: ['fishing', 'runecrafting'],
	},
	4022: {
		id: 4022,
		name: 'River Vines',
		value: 90,
		tradeable: true,
		itemImage: '/images/fishing/material/fiber_3.png',
		class: 'fiber',
		extraTooltipInfo:
			'Thick underwater vines found predominantly in rivers. These give off a faint magical aura. Used as a crafting material.',
		tags: ['fishing', 'runecrafting'],
	},
	4023: {
		id: 4023,
		name: 'Violet Ribbons',
		value: 120,
		tradeable: true,
		itemImage: '/images/fishing/material/fiber_4.png',
		class: 'fiber',
		extraTooltipInfo: 'Violet aquatic plants that radiate magical potential. Used as a crafting material.',
		tags: ['fishing', 'runecrafting'],
	},
	4024: {
		id: 4024,
		name: 'White Reeds',
		value: 150,
		tradeable: true,
		itemImage: '/images/fishing/material/fiber_5.png',
		class: 'fiber',
		extraTooltipInfo:
			'Aquatic reeds that appear to have grown near a font of magical energy and maintained some of its characteristics. Used as a crafting material.',
		tags: ['fishing', 'runecrafting'],
	},
	4025: {
		id: 4025,
		name: 'Ancient Kelp',
		value: 200,
		tradeable: true,
		itemImage: '/images/fishing/material/fiber_6.png',
		class: 'fiber',
		extraTooltipInfo:
			'Kelp that has been magically sustained for ages. Potent energy can be felt coursing through its fibers. Used as a crafting material.',
		tags: ['fishing', 'runecrafting'],
	},
	4026: {
		id: 4026,
		name: 'Aqueous Chunks',
		value: 700,
		tradeable: true,
		itemImage: '/images/combat/materials/aqueous_chunks.png',
		class: 'bar',
		extraTooltipInfo:
			'A chunk of stone supposedly from the bottom of the ocean, still pulsing with latent magical energy. Perhaps it could be used to augment something?',
		tags: ['magic', 'craftable'],
		craftingStats: {
			level: 85,
			experience: 2400,
			category: 'Rare',
			craftable: true,
			multiplier: 3,
		},
	},
	4027: {
		id: 4027,
		name: 'Frost Shard',
		value: 1000,
		tradeable: true,
		itemImage: '/images/combat/materials/frost_shard.png',
		class: 'bar',
		extraTooltipInfo:
			'A chunk of ice that never melts. The magic keeping it solid could be channeled somewhere by a skilled enchanter.',
		tags: ['magic', 'craftable'],
		craftingStats: {
			level: 85,
			experience: 2400,
			category: 'Rare',
			craftable: true,
		},
	},
	4028: {
		id: 4028,
		name: 'Elder Stone',
		value: 30000,
		tradeable: true,
		itemImage: '/images/combat/materials/elder_stone.png',
		class: 'bar',
		extraTooltipInfo: 'A condensed shard of Elder Magic formerly owned by the Elves.',
		tags: ['magic'],
		rarity: 'rare',
	},
	4500: {
		id: 4500,
		name: 'Box Trap',
		value: 25,
		experience: 160,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/hunting/box_trap.png',
		extraTooltipInfo: 'A box trap with bait only suitable to catch small game.',
		tags: ['seed', 'hunting', 'farming', 'craftable'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 1,
			image: '/images/farming/hunting/box_trap.png',
			align: 'bottom',
			experience: 30,
			time: 30,
			fixedSize: true,
		},
		craftingStats: {
			level: 1,
			experience: 160,
			category: 'Farming',
			craftable: true,
			description: 'A box trap with bait only suitable to catch small game.',
		},
	},
	4501: {
		id: 4501,
		name: 'Pitfall Trap',
		value: 50,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/hunting/pitfall.png',
		extraTooltipInfo: 'A pitfall trap with bait suitable to catch small and medium sized game.',
		tags: ['seed', 'hunting', 'farming', 'craftable'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 10,
			image: '/images/farming/hunting/pitfall.png',
			align: 'bottom',
			experience: 100,
			time: 35,
			fixedSize: true,
		},
		craftingStats: {
			level: 20,
			experience: 280,
			category: 'Farming',
			craftable: true,
			description: 'A pitfall trap with bait suitable to catch small and medium sized game.',
		},
	},
	4502: {
		id: 4502,
		name: 'Bird Trap',
		value: 100,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/hunting/bird_trap.png',
		extraTooltipInfo: 'A bird trap with bait suitable to catch small flying creatures.',
		tags: ['seed', 'hunting', 'farming', 'craftable'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 20,
			image: '/images/farming/hunting/bird_trap.png',
			align: 'bottom',
			experience: 325,
			time: 120,
			fixedSize: true,
		},
		craftingStats: {
			level: 25,
			experience: 790,
			category: 'Farming',
			craftable: true,
			description: 'A bird trap with bait suitable to catch small flying creatures.',
		},
	},
	4503: {
		id: 4503,
		name: 'Net Trap',
		value: 125,
		experience: 10,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/hunting/net_trap.png',
		extraTooltipInfo: 'A net trap with bait suitable to catch most creatures.',
		tags: ['seed', 'hunting', 'farming', 'craftable'],
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 25,
			image: '/images/farming/hunting/net_trap.png',
			align: 'bottom',
			experience: 300,
			time: 45,
			fixedSize: true,
		},
		craftingStats: {
			level: 35,
			experience: 810,
			category: 'Farming',
			craftable: true,
			description: 'A net trap with bait suitable to catch most creatures.',
		},
	},
	4504: {
		id: 4504,
		name: 'Bear Trap',
		value: 150,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/hunting/metal_trap.png',
		extraTooltipInfo: 'A bear trap with bait only suitable to catch large and medium sized creatures.',
		tags: ['seed', 'hunting', 'farming', 'craftable'],
		rarity: 'uncommon',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 35,
			image: '/images/farming/hunting/metal_trap.png',
			align: 'bottom',
			experience: 400,
			time: 50,
			fixedSize: true,
		},
		craftingStats: {
			level: 45,
			experience: 1320,
			category: 'Farming',
			craftable: true,
			description: 'A bear trap with bait only suitable to catch large and medium sized creatures.',
		},
	},
	4505: {
		id: 4505,
		name: 'Water Totem',
		value: 150,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/totem/water_totem_mj_v2.png',
		extraTooltipInfo:
			'A totem that periodically summons rainstorms. Has up to a 10% chance every farming tick to advance every plot by one growth stage. Its effect grows as it ages. Only the oldest water totem will take effect. The totem will automatically expire when it is fully grown.',
		tags: ['seed', 'farming', 'craftable'],
		rarity: 'rare',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 40,
			image: '/images/farming/totem/water_totem_mj_v2.png',
			align: 'bottom',
			experience: 10000,
			time: 10080,
		},
		craftingStats: {
			level: 66,
			experience: 15500,
			category: 'Farming',
			craftable: true,
			description:
				'A totem that periodically summons rainstorms. Has up to a 10% chance every farming tick to advance every plot by one growth stage. Its effect grows as it ages. Only the oldest water totem will take effect. The totem will automatically expire when it is fully grown.',
		},
	},
	4506: {
		id: 4506,
		name: 'Nature Totem',
		value: 150,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/totem/nature_orb.png',
		extraTooltipInfo:
			'A totem that imbues your farmland with nature energy. Has up to a 10% chance to make any automatic replant cost nothing. Its effect grows as it ages. Only the oldest nature totem will take effect. The totem will automatically expire when it is fully grown.',
		tags: ['seed', 'farming', 'craftable'],
		rarity: 'rare',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 40,
			image: '/images/farming/totem/nature_orb.png',
			align: 'bottom',
			experience: 10000,
			time: 10080,
		},
		craftingStats: {
			level: 66,
			experience: 16000,
			category: 'Farming',
			craftable: true,
			description:
				'A totem that imbues your farmland with nature energy. Has up to a 10% chance to make any automatic replant cost nothing. Its effect grows as it ages. Only the oldest nature totem will take effect. The totem will automatically expire when it is fully grown.',
		},
	},
	4507: {
		id: 4507,
		name: 'Earth Totem',
		value: 150,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/totem/earth_orb.png',
		extraTooltipInfo:
			"A totem that enhances your farmland's soil. Has up to a 10% chance to increase yields by 50% based on totem age. Its effect grows as it ages. Only the oldest earth totem will take effect. The totem will automatically expire when it is fully grown.",
		tags: ['seed', 'farming', 'craftable'],
		rarity: 'rare',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 40,
			image: '/images/farming/totem/earth_orb.png',
			align: 'bottom',
			experience: 10000,
			time: 10080,
		},
		craftingStats: {
			level: 66,
			experience: 16000,
			category: 'Farming',
			craftable: true,
			description:
				"A totem that enhances your farmland's soil. Has up to a 10% chance to increase yields by 50% based on totem age. Its effect grows as it ages. Only the oldest earth totem will take effect. The totem will automatically expire when it is fully grown.",
		},
	},
	4508: {
		id: 4508,
		name: 'Coalescing Totem',
		value: 150,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/totem/condensed_orb.png',
		extraTooltipInfo:
			'A totem that coalesces various energies into a single form. Gives up to a 15% chance to half fertilizer cost, increase yield by 50%, and increase growth speed with chance increasing most drastically when there are more unique things planted with various sizes to grow. Stacks with non-coalescing totems that have the same effect. Only the oldest coalescing totem will take effect. The totem will automatically expire when it is fully grown.',
		tags: ['seed', 'farming', 'craftable'],
		rarity: 'rare',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 40,
			image: '/images/farming/totem/condensed_orb.png',
			align: 'bottom',
			experience: 10000,
			time: 10080,
		},
		craftingStats: {
			level: 66,
			experience: 19000,
			category: 'Farming',
			craftable: true,
			description:
				'A totem that coalesces various energies into a single form. Gives up to a 15% chance to half fertilizer cost, increase yield by 50%, and increase growth speed with chance increasing most drastically when there are more unique things planted with various sizes to grow. Stacks with non-coalescing totems that have the same effect. Only the oldest coalescing totem will take effect. The totem will automatically expire when it is fully grown.',
		},
	},
	4509: {
		id: 4509,
		name: 'Monster Trap',
		value: 200,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/hunting/metal_trap.png',
		extraTooltipInfo: 'A trap designed to lure unusual monsters.',
		tags: ['seed', 'hunting', 'farming', 'craftable'],
		rarity: 'uncommon',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 50,
			image: '/images/farming/hunting/metal_trap.png',
			align: 'bottom',
			experience: 500,
			time: 60,
			fixedSize: true,
		},
		craftingStats: {
			level: 60,
			experience: 1600,
			category: 'Farming',
			craftable: true,
			description: 'A trap designed to lure unusual monsters.',
		},
	},
	4510: {
		id: 4510,
		name: 'Worm Composting Bin',
		value: 500,
		tradeable: true,
		class: 'seed',
		itemImage: '/images/farming/worm_bin.png',
		extraTooltipInfo: 'A composting bin that uses worms to break down organic material into nutrient-rich soil.',
		tags: ['seed', 'farming', 'craftable'],
		rarity: 'common',
		farmingStats: {
			height: 1,
			width: 1,
			requiredLevel: 5,
			image: '/images/farming/worm_bin.png',
			align: 'bottom',
			experience: 50,
			time: 30,
			fixedSize: true,
		},
		craftingStats: {
			level: 5,
			experience: 750,
			category: 'Farming',
			craftable: true,
		},
	},
	5000: {
		name: 'Shrimp Bauble',
		id: 5000,
		tradeable: false,
		enchantmentTier: 0,
		value: 10,
		itemImage: '/images/misc/shrimp_bauble.png',
		class: 'key',
		extraTooltipInfo: 'Untamed magic oozes out from inside.',
		tags: ['dungeon'],
		blockCritAugment: true,
		scrappingFailItem: {
			itemID: 9015,
		},
		augmentingForcedAmount: 1,
		augmentOverride: {
			fixedSuccessCount: 10,
			fixedBaseCount: 0,
		},
	},
	5001: {
		id: 5001,
		name: 'Shrimp',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/misc/schfftph_fzzglgl.png',
		class: 'fish',
		extraTooltipInfo: "One of perfection's many forms.",
		tags: [],
	},
	5002: {
		id: 5002,
		name: 'Shrimp Ascendant',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/misc/schfftph_fzzglgl_kakfzz.png',
		class: 'fish',
		extraTooltipInfo: 'Perfection comes in many forms, but this is a perversion of the art.',
		tags: [],
	},
	6000: {
		id: 6000,
		name: 'Gold Medal',
		tradeable: false,
		itemImage: '/images/ui/highscore_gold.png',
		extraTooltipInfo: 'First place award!',
		class: 'gold-medal',
		tags: ['unique'],
	},
	6001: {
		id: 6001,
		name: 'Silver Medal',
		tradeable: false,
		itemImage: '/images/ui/highscore_silver.png',
		extraTooltipInfo: 'Second place award!',
		class: 'silver-medal',
		tags: ['unique'],
	},
	6002: {
		id: 6002,
		name: 'Bronze Medal',
		tradeable: false,
		itemImage: '/images/ui/highscore_bronze.png',
		extraTooltipInfo: 'Third place award!',
		class: 'bronze-medal',
		tags: ['unique'],
	},
	6950: {
		id: 6950,
		name: 'Event Token',
		tradeable: false,
		itemImage: '/images/misc/event_token.png',
		extraTooltipInfo: 'Can be spent in the Event Shop for various event related items and buffs.',
		class: 'gold-medal',
		tags: ['event'],
	},
	7000: {
		id: 7000,
		name: 'Snow',
		tradeable: false,
		itemImage: '/images/christmas/snow.png',
		extraTooltipInfo: 'Used to make Snowballs!',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7001: {
		id: 7001,
		name: 'Snowball',
		tradeable: false,
		itemImage: '/images/christmas/snowball.png',
		extraTooltipInfo: "Use '/throw NAME' to throw at people!",
		class: 'christmas-drop',
		tags: ['crafting', 'event', 'craftable'],
		craftingStats: {
			level: 1,
			experience: 1,
			category: 'Event',
			craftable: true,
		},
	},
	7002: {
		id: 7002,
		name: 'Wrapping Paper',
		tradeable: false,
		itemImage: '/images/christmas/wrapping_paper.png',
		extraTooltipInfo: 'Born to be torn apart',
		class: 'christmas-drop',
		tags: ['crafting', 'event'],
	},
	7003: {
		id: 7003,
		name: 'Present',
		tradeable: false,
		itemImage: '/images/christmas/present.png',
		canBeOpened: true,
		extraTooltipInfo: 'Merry Christmas!',
		class: 'christmas-present',
		tags: ['event', 'chest'],
		rarity: 'rare',
	},
	7004: {
		id: 7004,
		name: 'Cookie Dough',
		tradeable: false,
		level: 1,
		experience: 1,
		itemImage: '/images/christmas/cookie_dough.png',
		extraTooltipInfo: 'It smells like cookies',
		class: 'christmas-food',
		time: 1000,
		tags: ['prepared', 'cooking', 'event'],
	},
	7005: {
		id: 7005,
		name: 'Cookie',
		tradeable: false,
		itemImage: '/images/christmas/cookie.png',
		extraTooltipInfo: 'It reminds you of your childhood',
		class: 'christmas-food',
		tags: ['consumable', 'food', 'cooking', 'event'],
		healing: {
			hp: 20,
			cooldown: 5000,
			perTick: 15,
			totalTicks: 4,
			tickDelay: 3000,
			ignoreCooldown: false,
		},
	},
	7006: {
		id: 7006,
		name: 'Gummy Fish',
		tradeable: false,
		level: 1,
		experience: 1,
		itemImage: '/images/christmas/gummy_fish.png',
		extraTooltipInfo: 'Delicious candy!',
		class: 'christmas-drop',
		time: 1000,
		tags: ['consumable', 'food', 'fishing', 'event'],
	},
	7007: {
		id: 7007,
		name: 'Gummy Shark Fin',
		tradeable: false,
		itemImage: '/images/christmas/gummy_shark_fin.png',
		extraTooltipInfo: 'Looks like it came off a Gummy Shark!',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7008: {
		id: 7008,
		name: "Krampus' Horn",
		tradeable: false,
		itemImage: '/images/christmas/krampus_horn.png',
		extraTooltipInfo: 'A deathly energy weakly pulses from them still...',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7009: {
		id: 7009,
		name: 'Candy Buttons',
		tradeable: false,
		itemImage: '/images/christmas/candy_buttons.png',
		extraTooltipInfo: 'The buttons from Lumpy, Dumpy and Clumpy!',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7010: {
		id: 7010,
		name: 'Scarf',
		tradeable: false,
		itemImage: '/images/christmas/scarf.png',
		extraTooltipInfo: 'Keeps you warm while you play in the snow!',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7011: {
		id: 7011,
		name: 'Evergreen Log',
		tradeable: false,
		level: 1,
		experience: 1,
		itemImage: '/images/christmas/evergreen_log.png',
		extraTooltipInfo: 'Smells faintly of mint and cinnamon',
		class: 'christmas-drop',
		tags: ['foraging', 'event'],
	},
	7012: {
		id: 7012,
		name: 'Corrupted Evergreen Log',
		tradeable: false,
		itemImage: '/images/christmas/corrupted_evergreen_log.png',
		extraTooltipInfo: 'It reeks of decay',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7013: {
		id: 7013,
		name: 'Magic Ice',
		tradeable: false,
		level: 1,
		experience: 1,
		time: 1000,
		itemImage: '/images/christmas/magic_ice.png',
		extraTooltipInfo: 'Magically makes you want to lick it',
		class: 'christmas-drop',
		tags: ['mining', 'event'],
	},
	7014: {
		id: 7014,
		name: 'Inferior Bearded Wanderer',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_helmet_worn.png',
		itemIcon: '/images/christmas/christmas_helmet.png',
		extraTooltipInfo: "The hat's a little loose and the beard is itchy",
		visual: 'christmas-helmet',
		class: 'christmas-tier1',
		tags: ['armor', 'event'],
		equipmentStats: {
			slot: 'helm',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7015: {
		id: 7015,
		name: "Inferior Reindeer Hunters' Armor",
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_chestplate_worn.png',
		itemIcon: '/images/christmas/christmas_chestplate.png',
		extraTooltipInfo: "Not the warmest or most protective, but it'll work!",
		visual: 'christmas-breastplate',
		class: 'christmas-tier1',
		tags: ['armor', 'event'],
		equipmentStats: {
			slot: 'body',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7016: {
		id: 7016,
		name: "Inferior Reindeer Hunters' Leggings",
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_leggings_worn.png',
		itemIcon: '/images/christmas/christmas_leggings.png',
		extraTooltipInfo: 'Chafes in all the wrong places',
		visual: 'christmas-leggings',
		class: 'christmas-tier1',
		tags: ['armor', 'event'],
		equipmentStats: {
			slot: 'legs',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7017: {
		id: 7017,
		name: 'Inferior Christmas Lights',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_weapon_worn.png',
		itemIcon: '/images/christmas/christmas_weapon.png',
		extraTooltipInfo: 'Still a little dusty from being in storage',
		visual: 'christmas-weapon',
		class: 'christmas-tier1',
		tags: ['event', 'onehand'],
		equipmentStats: {
			slot: 'weapon',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
		},
	},
	7018: {
		id: 7018,
		name: 'SuperSled 1337',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_shield_worn.png',
		itemIcon: '/images/christmas/christmas_shield.png',
		extraTooltipInfo: 'An elite sled for elite sledders',
		visual: 'christmas-shield',
		class: 'christmas-tier1',
		tags: ['event'],
		equipmentStats: {
			slot: 'shield',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7019: {
		id: 7019,
		name: 'Bearded Wanderer',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_helmet_worn.png',
		itemIcon: '/images/christmas/christmas_helmet.png',
		extraTooltipInfo: "The hat's too tight and the beard smells funny.",
		visual: 'christmas-helmet',
		class: 'christmas-tier2',
		tags: ['armor', 'event'],
		equipmentStats: {
			slot: 'helm',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7020: {
		id: 7020,
		name: "Reindeer Hunters' Armor",
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_chestplate_worn.png',
		itemIcon: '/images/christmas/christmas_chestplate.png',
		extraTooltipInfo: 'Hypothermia is no longer your biggest threat!',
		visual: 'christmas-breastplate',
		class: 'christmas-tier2',
		tags: ['armor', 'event'],
		equipmentStats: {
			slot: 'body',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7021: {
		id: 7021,
		name: "Reindeer Hunters' Leggings",
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_leggings_worn.png',
		itemIcon: '/images/christmas/christmas_leggings.png',
		extraTooltipInfo: "There's no holes, at least!",
		visual: 'christmas-leggings',
		class: 'christmas-tier2',
		tags: ['armor', 'event'],
		equipmentStats: {
			slot: 'legs',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7022: {
		id: 7022,
		name: 'Christmas Lights',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_weapon_worn.png',
		itemIcon: '/images/christmas/christmas_weapon.png',
		extraTooltipInfo: 'Not for the nice!',
		visual: 'christmas-weapon',
		class: 'christmas-tier2',
		tags: ['event', 'onehand'],
		equipmentStats: {
			slot: 'weapon',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
		},
	},
	7023: {
		id: 7023,
		name: 'SuperSled 9000',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_shield_worn.png',
		itemIcon: '/images/christmas/christmas_shield.png',
		extraTooltipInfo: 'For when sledding meets extreme sports',
		visual: 'christmas-shield',
		class: 'christmas-tier2',
		tags: ['event'],
		equipmentStats: {
			slot: 'shield',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
		},
	},
	7024: {
		id: 7024,
		name: 'Superior Bearded Wanderer',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_helmet_worn.png',
		itemIcon: '/images/christmas/christmas_helmet.png',
		extraTooltipInfo: "The hat's just right and the beard is perfectly groomed.",
		visual: 'christmas-helmet',
		class: 'christmas-tier3',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			cosmeticSlot: 'helm',
		},
	},
	7025: {
		id: 7025,
		name: "Superior Reindeer Hunters' Armor",
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_chestplate_worn.png',
		itemIcon: '/images/christmas/christmas_chestplate.png',
		extraTooltipInfo: 'The skull whispers to you softly, urging you to kill',
		visual: 'christmas-breastplate',
		class: 'christmas-tier3',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'body',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			cosmeticSlot: 'body',
		},
	},
	7026: {
		id: 7026,
		name: "Superior Reindeer Hunters' Leggings",
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_leggings_worn.png',
		itemIcon: '/images/christmas/christmas_leggings.png',
		extraTooltipInfo: 'The hide might not have protected the reindeer, but it will protect you.',
		visual: 'christmas-leggings',
		class: 'christmas-tier3',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			cosmeticSlot: 'legs',
		},
	},
	7027: {
		id: 7027,
		name: 'Superior Christmas Lights',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_weapon_worn.png',
		itemIcon: '/images/christmas/christmas_weapon.png',
		extraTooltipInfo: 'For the naughty!',
		visual: 'christmas-weapon',
		class: 'christmas-tier3',
		tags: ['event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			oneHanded: true,
			attackSpeed: 2.4,
			cosmeticSlot: 'weapon',
		},
	},
	7028: {
		id: 7028,
		name: 'SuperSled 9001',
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/christmas_shield_worn.png',
		itemIcon: '/images/christmas/christmas_shield.png',
		extraTooltipInfo: "It's over 9000!",
		visual: 'christmas-shield',
		class: 'christmas-tier3',
		tags: ['event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'shield',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			cosmeticSlot: 'shield',
		},
	},
	7029: {
		name: 'Magical Snowball',
		id: 7029,
		enchantmentTier: 5,
		value: 2840000,
		tradeable: true,
		requiredLevel: {
			attack: 50,
			strength: 50,
		},
		itemImage: '/images/christmas/magical_snowball.png',
		extraTooltipInfo: "Thankfully it's magical and not lemon infused.",
		class: 'equipment',
		visual: 'magical-snowball',
		tags: ['melee', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'shield',
			offensiveAccuracyAffinityRating: {
				Melee: 6,
				Magic: 18,
				Range: 0,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 1,
				Range: 1,
				Ice: 1.05,
			},
			defensiveDamageAffinity: {
				Melee: 1,
				Magic: 1,
				Range: 1,
				Ice: 1.05,
			},
			weaponBonus: {
				strength: 0,
				intellect: 4,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 0,
				stamina: 0,
			},
			augmentationBonus: [
				{
					stat: 'weaponBonus.intellect',
					value: 0.5,
				},
			],
		},
		rarity: 'uncommon',
	},
	7030: {
		name: 'Candy Cane Daggers',
		id: 7030,
		enchantmentTier: 5,
		value: 2840000,
		tradeable: true,
		requiredLevel: {
			strength: 50,
		},
		extraTooltipInfo: 'Best to not lick these.',
		itemImage: '/images/christmas/candy_cane_daggers_icon.png',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/christmas/candy_cane_dagger_1.png',
				css: 'candy-cane-daggers-1',
			},
			{
				image: '/images/christmas/candy_cane_dagger_2.png',
				css: 'candy-cane-daggers-2',
			},
		],
		tags: ['melee', 'twohand', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			offensiveCritical: {
				chance: 0.055,
				damageMultiplier: 1.75,
			},
			offensiveAccuracyAffinityRating: {
				Melee: 69,
				Magic: -10,
				Range: -10,
				Piercing: 43,
				Blunt: -8,
				Slashing: 43,
			},
			offensiveDamageAffinity: {
				Melee: 1,
				Magic: 0.75,
				Range: 0.75,
				Piercing: 1.1,
				Blunt: 0.33,
				Slashing: 0.75,
			},
			weaponBonus: {
				strength: 29,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 0,
				resistance: 0,
				agility: 10,
				stamina: 0,
			},
			attackSpeed: 2,
			augmentationBonus: [
				{
					stat: 'offensiveCritical.chance',
					value: 0.01,
				},
				{
					stat: 'offensiveCritical.damageMultiplier',
					value: 0.01,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
			],
		},
		rarity: 'uncommon',
	},
	7031: {
		name: 'Festive Shroud',
		id: 7031,
		enchantmentTier: 2,
		value: 5120000,
		tradeable: true,
		requiredLevel: {
			defense: 40,
			enchanting: 40,
		},
		itemImage: '/images/christmas/festive_shroud.png',
		itemIcon: '/images/christmas/festive_shroud_hood.png',
		class: 'equipment',
		extraTooltipInfo: 'Makes you feel rather jolly.',
		visual: 'cape',
		tags: ['melee', 'armor', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'cape',
			defensiveDamageAffinity: {
				Melee: 1.02,
				Magic: 1.02,
				Range: 1.02,
			},
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			armorBonus: {
				protection: 4,
				resistance: 4,
				agility: 4,
				stamina: 4,
			},
			augmentationBonus: [
				{
					stat: 'armorBonus.protection',
					value: 0.5,
				},
				{
					stat: 'armorBonus.resistance',
					value: 0.5,
				},
				{
					stat: 'armorBonus.agility',
					value: 0.5,
				},
				{
					stat: 'armorBonus.stamina',
					value: 0.5,
				},
			],
		},
		rarity: 'epic',
	},
	7032: {
		name: "Merry Maker's Mittens",
		id: 7032,
		itemImage: '/images/christmas/christmas_gloves.png',
		extraTooltipInfo: 'A pair of mittens to keep you warm in the bitter cold! Wait, are they fingerless?',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/christmas/christmas_gloves_1.png',
				css: 'christmas-gloves-1',
			},
			{
				image: '/images/christmas/christmas_gloves_2.png',
				css: 'christmas-gloves-2',
			},
			{
				image: '/images/christmas/christmas_gloves_3.png',
				css: 'christmas-gloves-3',
			},
		],
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'gloves',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			cosmeticSlot: 'gloves',
		},
	},
	7033: {
		name: 'Mistletoe Treads',
		id: 7033,
		itemImage: '/images/christmas/christmas_boots.png',
		itemIcon: '/images/christmas/christmas_boots_icon.png',
		extraTooltipInfo: 'For treading on snow or enemy faces.',
		class: 'equipment',
		visual: 'boots',
		tags: ['armor', 'event', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'boots',
			weaponBonus: {
				strength: 0,
				intellect: 0,
				dexterity: 0,
			},
			cosmeticSlot: 'boots',
		},
	},
	7034: {
		id: 7034,
		name: 'Magic Ice Bar',
		level: 1,
		experience: 100,
		time: 12000,
		itemImage: '/images/christmas/ice_bar.png',
		class: 'bar',
		extraTooltipInfo: 'A coalesced bar of magic ice. Cold to the touch!',
		tags: ['mining', 'smithing', 'event'],
	},
	7035: {
		name: 'Winter Rune',
		id: 7035,
		time: 5000,
		experience: 25,
		level: 1,
		itemImage: '/images/christmas/Snow_Rune.png',
		extraTooltipInfo: 'A slate imbued with the essence of winter.',
		class: 'rune',
		tags: ['ammunition', 'magic', 'runecrafting', 'event'],
	},
	7036: {
		name: 'Scroll of Candy Canes',
		activeMessage: 'Making Scrolls of Candy Canes',
		enchant: true,
		hidden: true,
		categories: ['christmas elf gear'],
		time: 15000,
		id: 7036,
		enchantmentID: 7000,
		level: 1,
		experience: 100,
		itemImage: '/images/christmas/xmas_scroll.png',
		class: 'enchanted-scroll',
		extraTooltipInfo:
			'A scroll enchanted with some sort of winter magic, not compatible with any gear you know of.',
		tags: ['enchanting', 'event'],
	},
	7037: {
		id: 7037,
		name: 'Gummy Lure',
		fishingBait: {
			level: 0,
			bait: 0,
			reel: 0,
			bonus: 0,
		},
		itemImage: 'images/christmas/gummy_bait.png',
		extraTooltipInfo: 'A gummy fish turned into a lure. Helps with Christmas zone fishin.',
		class: 'fish',
		tags: ['fishing', 'event'],
	},
	7038: {
		id: 7038,
		name: 'Winter Essence',
		tradeable: false,
		itemImage: '/images/christmas/snow_essence.png',
		extraTooltipInfo: 'Winter essence can be gathered by doing non-combat Christmas event activities.',
		class: 'rune',
		tags: ['magic', 'runecrafting', 'event'],
	},
	7039: {
		id: 7039,
		name: 'Gummy Shark',
		tradeable: false,
		level: 1,
		experience: 1,
		itemImage: '/images/christmas/monsters/gummy_shark.png',
		extraTooltipInfo: 'More likely to bite you than let you bite it!',
		class: 'christmas-drop',
		time: 1000,
		tags: ['fishing', 'event'],
	},
	7040: {
		id: 7040,
		name: 'Angry Letter',
		tradeable: false,
		itemImage: '/images/christmas/angry_letter.png',
		extraTooltipInfo: 'An angrily worded letter from someone you threw a snowball at!',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7041: {
		id: 7041,
		name: 'Baubles',
		tradeable: false,
		itemImage: '/images/christmas/baubles.png',
		extraTooltipInfo: 'Commemorates Christmas 2021! How many can you collect?',
		class: 'christmas-drop',
		tags: ['event'],
	},
	7050: {
		id: 7050,
		name: 'Snowglobe',
		tradeable: false,
		itemImage: '/images/christmas/snowglobe.png',
		extraTooltipInfo: 'Stores your Christmas Spirit!',
		class: 'christmas-globe',
		tags: ['event'],
	},
	7060: {
		id: 7060,
		name: 'Mr. Klimp',
		itemImage: '/images/combat/monsters/chicken.png',
		extraTooltipInfo: 'whatisthis? Fabled to have once costed trillions of gold.',
		class: 'equipment',
		visual: 'p-chicken',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7061: {
		id: 7061,
		name: 'The Mine Dwelling Hamster',
		itemImage: '/images/combat/monsters/elite/hamster.png',
		extraTooltipInfo: 'Back to the mines with you!!!',
		class: 'equipment',
		visual: 'hamster',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7062: {
		id: 7062,
		name: 'Lil Crown Hamperster',
		itemImage: '/images/combat/monsters/elite/hamster2.png',
		extraTooltipInfo: 'Little hat REQUIRED',
		class: 'equipment',
		visual: 'hamster-2',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7063: {
		name: 'Lightning Edge',
		id: 7063,
		itemImage: '/images/premium/cosmetic/equipment/lightning_edge.gif',
		class: 'equipment',
		extraTooltipInfo: 'Lightning never strikes twice, so surely this is an anomaly.',
		visual: 'zero',
		tags: ['melee', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			oneHanded: true,
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7064: {
		name: 'Frost Maul',
		id: 7064,
		itemImage: '/images/premium/cosmetic/equipment/frost_maul.gif',
		extraTooltipInfo: 'A hulking mass of snow covered ice.',
		class: 'equipment',
		visual: 'moss-maul',
		tags: ['melee', 'twohand', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7065: {
		id: 7065,
		name: 'Abusive',
		itemImage: '/images/combat/monsters/elite/hamster3.png',
		extraTooltipInfo: 'gamster',
		class: 'equipment',
		visual: 'hamster-3',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7066: {
		name: 'Elite Titan Helm',
		id: 7066,
		itemImage: '/images/premium/cosmetic/equipment/elite_titan_helmet.png',
		itemIcon: '/images/premium/cosmetic/equipment/elite_titan_helmet_icon.png',
		extraTooltipInfo: 'A particularly intricate helmet, far more than the average Titan would wear.',
		class: 'equipment',
		visual: 'black-knight-full-helm',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7067: {
		name: 'Ancient Crown',
		id: 7067,
		itemImage: '/images/premium/cosmetic/equipment/ancient_crown.png',
		itemIcon: '/images/premium/cosmetic/equipment/ancient_crown_icon.png',
		extraTooltipInfo: 'An incredible old crown. Could be a bracelet if you are a giant.',
		class: 'equipment',
		visual: 'kings-crown',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7068: {
		name: 'Idlescape Bond',
		id: 7068,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo:
			'A token that can be redeemed for a month of Idlescape Plus. Can be traded in the Marketplace. \nHas an increased tax-rate of 20% when sold on the Marketplace. WIP: NO FUNCTIONALITY YET',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7069: {
		name: 'Character Token',
		id: 7069,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: 'A token that can be redeemed for a new character slot.',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7070: {
		name: 'Active Character Token',
		id: 7070,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo:
			'A token that can be redeemed for a new active character slot. NOTE: You can only have one Default league character active at the same time.',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7071: {
		name: 'Item Name Token',
		id: 7071,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: "A token that can be redeemed to change an item's name. Will make the item untradeable.",
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7072: {
		name: 'Vacation Token',
		id: 7072,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: 'A token that can be redeemed to allow offline progress for 72 hours. (One-time effect)',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7073: {
		name: 'Loadout Token',
		id: 7073,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: 'A token that can be redeemed to increase base loadout count.',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7074: {
		name: 'Holy Avenger Helm',
		id: 7074,
		itemImage: '/images/premium/cosmetic/equipment/holy_avenger_helm.png',
		extraTooltipInfo: 'H... Pointy!',
		class: 'equipment',
		visual: 'avenger-helm',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7075: {
		name: 'Holy Avenger Breastplate',
		id: 7075,
		itemImage: '/images/premium/cosmetic/equipment/holy_avenger_breastplate.png',
		extraTooltipInfo: 'Full of righteous indignation!',
		class: 'equipment',
		visual: 'avenger-breastplate',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'body',
			cosmeticSlot: 'body',
		},
		rarity: 'legendary',
	},
	7076: {
		name: 'Holy Avenger Cape',
		id: 7076,
		itemImage: '/images/premium/cosmetic/equipment/holy_avenger_cape.png',
		extraTooltipInfo: 'Perpetually blowing in the wind. Very heroic looking.',
		class: 'equipment',
		visual: 'avenger-cape',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'cape',
		},
		rarity: 'legendary',
	},
	7077: {
		name: 'Holy Avenger Leggings',
		id: 7077,
		itemImage: '/images/premium/cosmetic/equipment/holy_avenger_leggings.png',
		extraTooltipInfo: "Don't actually try to wade through blood, it'll stain the cloth.",
		class: 'equipment',
		visual: 'avenger-leggings',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			cosmeticSlot: 'legs',
		},
		rarity: 'legendary',
	},
	7078: {
		name: 'Holy Avenger Boots',
		id: 7078,
		itemImage: '/images/premium/cosmetic/equipment/holy_avenger_boots.png',
		itemIcon: '/images/premium/cosmetic/equipment/holy_avenger_boots_icon.png',
		extraTooltipInfo: "Don't actually try to wade through blood, it'll stain the cloth.",
		class: 'equipment',
		visual: 'avenger-boots',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'boots',
			cosmeticSlot: 'boots',
		},
		rarity: 'legendary',
	},
	7079: {
		name: 'Holy Avenger Gloves',
		id: 7079,
		itemImage: '/images/premium/cosmetic/equipment/holy_avenger_gloves.png',
		itemIcon: '/images/premium/cosmetic/equipment/holy_avenger_gloves_icon.png',
		extraTooltipInfo: 'Perfect for clasping your hands in prayer or beating the snot out of something!',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/premium/cosmetic/equipment/holy_avenger_gloves_1.png',
				css: 'avenger-gloves-1',
			},
			{
				image: '/images/premium/cosmetic/equipment/holy_avenger_gloves_2.png',
				css: 'avenger-gloves-2',
			},
			{
				image: '/images/premium/cosmetic/equipment/holy_avenger_gloves_3.png',
				css: 'avenger-gloves-3',
			},
		],
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'gloves',
			cosmeticSlot: 'gloves',
		},
		rarity: 'legendary',
	},
	7080: {
		name: "A Mad Faith's Wrath",
		id: 7080,
		itemImage: '/images/premium/cosmetic/equipment/wrath.png',
		itemIcon: '/images/premium/cosmetic/equipment/wrath_icon.png',
		extraTooltipInfo: "Behold the wrath of the mad faith! War and battle! Madfaith's 'Warth'!",
		class: 'equipment',
		visual: 'madfaith',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7081: {
		id: 7081,
		name: 'Limpe K. Limpe',
		itemImage: '/images/combat/monsters/elite/limpe.png',
		extraTooltipInfo: 'A creature that is beyond comprehension.',
		class: 'equipment',
		visual: 'p-chicken-2',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7082: {
		name: 'Username Change Token',
		id: 7082,
		isTokenInput: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: 'A token that can be redeemed to change your username.',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7083: {
		name: 'Shapeless Bow',
		id: 7083,
		itemImage: '/images/premium/cosmetic/equipment/shapeless_bow.png',
		extraTooltipInfo: 'A bow fashioned to use chaos itself.',
		class: 'equipment',
		visual: 'shapeless-longbow',
		tags: ['range', 'twohand', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7084: {
		name: 'Shapeless Staff',
		id: 7084,
		itemImage: '/images/premium/cosmetic/equipment/shapeless_staff.png',
		extraTooltipInfo: 'A staff fashioned to use chaos itself.',
		class: 'equipment',
		visual: 'shapeless-staff',
		tags: ['magic', 'twohand', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7085: {
		name: 'Gift of the Lightning King',
		id: 7085,
		itemImage: '/images/premium/cosmetic/equipment/Gift_of_the_Lightning_King.png',
		itemIcon: '/images/premium/cosmetic/equipment/Gift_of_the_Lightning_King_Icon.png',
		extraTooltipInfo: 'Gift of the Lightning King',
		class: 'equipment',
		visual: 'kings-crown',
		tags: ['cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7086: {
		name: 'Put Something On',
		id: 7086,
		itemImage: '/images/premium/cosmetic/equipment/Updated_Player_Male.png',
		itemIcon: '/images/premium/cosmetic/equipment/Updated_Player_Male_Icon.png',
		extraTooltipInfo: 'Seriously, put something on.',
		class: 'equipment',
		visual: 'character-model',
		tags: ['cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		rarity: 'legendary',
	},
	7087: {
		name: 'Fresh Off the Island',
		id: 7087,
		itemImage: '/images/combat/character_model_beard.png',
		extraTooltipInfo: 'Do you even know what island?',
		class: 'equipment',
		visual: 'character-beard',
		tags: ['cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'face',
		},
		rarity: 'legendary',
	},
	7088: {
		name: 'Forgotten Platebody',
		id: 7088,
		itemImage: '/images/premium/cosmetic/equipment/ghost_body.png',
		extraTooltipInfo: "A forgotten relic 'farcasted' into the sea...",
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/premium/cosmetic/equipment/ghost_body_front.png',
				css: 'ghost-body-front',
			},
			{
				image: '/images/premium/cosmetic/equipment/ghost_body_back.png',
				css: 'ghost-body-back',
			},
		],
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'body',
			cosmeticSlot: 'body',
		},
		rarity: 'legendary',
	},
	7089: {
		name: 'Inventory Tab Token',
		id: 7089,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo:
			'A token that can be redeemed to increase your inventory tab count by 1. Tabs can be ordered and named.',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7090: {
		name: 'User Action Queue Count Token',
		id: 7090,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: 'A token that can be redeemed to increase the number of individual enqueued actions by 1.',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7091: {
		name: 'User Action Queue Count Token - Formerly Length Token',
		id: 7091,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo:
			'A token that can be redeemed to increase the number of individual enqueued actions by 1. (Formerly a User Action Queue Length Token)',
		class: 'platinum',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7092: {
		name: "Valiant Lumberjack's Vest",
		id: 7092,
		itemImage: '/images/foraging/Foraging_Top.png',
		extraTooltipInfo: 'Makes you want to shout timber.',
		class: 'equipment',
		visual: 'smock',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'body',
			cosmeticSlot: 'body',
		},
		rarity: 'legendary',
	},
	7093: {
		name: "Valiant Lumberjack's Hat",
		id: 7093,
		itemImage: '/images/foraging/Foraging_hat.png',
		extraTooltipInfo: 'If a tree falls on your head this will not help, but it can keep you warm!',
		class: 'equipment',
		visual: 'lumberjack-hat',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7094: {
		name: "Valiant Lumberjack's Trousers",
		id: 7094,
		itemImage: '/images/foraging/Foraging_legs.png',
		extraTooltipInfo: "You're a lumberjack and you're okay!",
		class: 'equipment',
		visual: 'lumberjack-legs',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			cosmeticSlot: 'legs',
		},
		rarity: 'legendary',
	},
	7095: {
		name: 'Valiant Camo Cloak',
		id: 7095,
		itemImage: '/images/combat/equipment/camo_cape.PNG',
		itemIcon: '/images/combat/equipment/camo_cape.PNG',
		specialVisuals: [
			{
				image: '/images/combat/equipment/camo_cape_back.png',
				css: 'camo-back',
			},
			{
				image: '/images/combat/equipment/camo_cape_front.png',
				css: 'camo-front',
			},
		],
		extraTooltipInfo:
			'A cloak with camo elements to help you avoid fights in dangerous places. Increases your combat ability in dangerous gathering encounters and gathering dungeons.',
		class: 'equipment',
		visual: 'cape',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'cape',
		},
		rarity: 'legendary',
	},
	7096: {
		name: 'Night Walker',
		id: 7096,
		itemImage: '/images/premium/cosmetic/equipment/Night_walker_base.png',
		extraTooltipInfo:
			'A true stalker of beasts, lurking forever in the overgrown woods. Not even a million Mellons could feed this beasts endless hunger.',
		class: 'equipment',
		visual: 'night-wanderer',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7097: {
		name: 'Night Wand3rer',
		id: 7097,
		itemImage: '/images/premium/cosmetic/equipment/Night_Wand3rer.png',
		extraTooltipInfo: 'The Face Of the Last "Wand3rer" from the overgrown woods he really just wants a hug..',
		class: 'equipment',
		visual: 'night-wanderer',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7098: {
		id: 7098,
		name: 'Celestial',
		itemImage: '/images/premium/cosmetic/equipment/celestial.png',
		itemIcon: '/images/premium/cosmetic/equipment/celestial_icon.png',
		extraTooltipInfo: 'Who would have guessed a simple cape would have had such power?',
		class: 'equipment',
		visual: 'celestial',
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		tags: ['armor', 'unique', 'cosmetic'],
		rarity: 'legendary',
	},
	7099: {
		name: 'Eyes of Chaos',
		id: 7099,
		itemImage: '/images/premium/cosmetic/equipment/Eyes_of_chaos.png',
		itemIcon: '/images/premium/cosmetic/equipment/Eyes_of_chaos_icon.png',
		extraTooltipInfo: "'Eye' see you.",
		class: 'equipment',
		visual: 'eyes-of-chaos',
		tags: ['melee', 'twohand', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7100: {
		name: 'Forgotten Platelegs',
		id: 7100,
		itemImage: '/images/premium/cosmetic/equipment/ghost_legs.png',
		extraTooltipInfo: "A forgotten relic 'farcasted' into the sea...",
		class: 'equipment',
		visual: 'ghost-legs',
		tags: ['melee', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			cosmeticSlot: 'legs',
		},
		rarity: 'legendary',
	},
	7101: {
		name: 'Tiny Wise Hat',
		id: 7101,
		itemImage: '/images/chaticons/chat-icon-dev.png',
		extraTooltipInfo: 'A tiny wise hat. Far too small for you!',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/premium/cosmetic/equipment/dev_hat_back.png',
				css: 'tiny-wise-hat-1',
			},
			{
				image: '/images/premium/cosmetic/equipment/dev_hat_front.png',
				css: 'tiny-wise-hat-2',
			},
		],
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7102: {
		name: 'Wise Hat',
		id: 7102,
		itemImage: '/images/chaticons/chat-icon-dev.png',
		extraTooltipInfo: 'A wise hat for a wise person! So, why do you have it?',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/premium/cosmetic/equipment/dev_hat_back.png',
				css: 'medium-wise-hat-1',
			},
			{
				image: '/images/premium/cosmetic/equipment/dev_hat_front.png',
				css: 'medium-wise-hat-2',
			},
		],
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7103: {
		name: 'Big Wise Hat',
		id: 7103,
		itemImage: '/images/chaticons/chat-icon-dev.png',
		extraTooltipInfo: 'A big hat that you could almost name a background character after. How can you see?',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/premium/cosmetic/equipment/dev_hat_back.png',
				css: 'big-wise-hat-1',
			},
			{
				image: '/images/premium/cosmetic/equipment/dev_hat_front.png',
				css: 'big-wise-hat-2',
			},
		],
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7104: {
		name: "Librarian's Chef Hat",
		id: 7104,
		itemImage: '/images/cooking/equipment/cooking_hat.png',
		extraTooltipInfo:
			'Kinda smells like your childhood meals. They must have really sucked since this hat came from a Goblin.',
		class: 'equipment',
		visual: 'chef-hat',
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7105: {
		name: "Librarian's Chef Coat",
		id: 7105,
		itemImage: '/images/cooking/equipment/cooking_body.png',
		extraTooltipInfo: 'Signifies your prestige and mastery of Cooking.',
		class: 'equipment',
		visual: 'chef-body',
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'body',
			cosmeticSlot: 'body',
		},
		rarity: 'legendary',
	},
	7106: {
		name: "Librarian's Chef Pants",
		id: 7106,
		itemImage: '/images/cooking/equipment/cooking_legs.png',
		extraTooltipInfo: 'Every chef needs a comfortable pair of pants!',
		class: 'equipment',
		visual: 'chef-legs',
		tags: ['armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			cosmeticSlot: 'legs',
		},
		rarity: 'legendary',
	},
	7107: {
		name: "Master Chef's Lesser Ladle",
		id: 7107,
		itemImage: '/images/combat/equipment/lesser_ladle.png',
		extraTooltipInfo: 'The lesser ladle of a Master Chef. Smells of legacy!',
		class: 'equipment',
		visual: 'lesser-ladle',
		tags: ['melee', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7108: {
		name: "Master Chef's Ladle",
		id: 7108,
		itemImage: '/images/combat/equipment/ladle.png',
		extraTooltipInfo: 'The ladle of a Master Chef. Smells of legacy!',
		class: 'equipment',
		visual: 'ladle',
		tags: ['melee', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7109: {
		name: "Master Chef's Greater Ladle",
		id: 7109,
		itemImage: '/images/combat/equipment/greater_ladle.png',
		extraTooltipInfo: 'The greater ladle of a Master Chef. Smells of legacy!',
		class: 'equipment',
		visual: 'greater-ladle',
		tags: ['melee', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7110: {
		name: "Master Chef's Golden Spoon",
		id: 7110,
		itemImage: '/images/cooking/golden_spoon.png',
		extraTooltipInfo: 'BIG GOLDEN SPOOOOOOON!',
		class: 'equipment',
		visual: 'ladle',
		tags: ['melee', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7111: {
		name: "Lich's Visage",
		id: 7111,
		itemImage: '/images/premium/cosmetic/equipment/C_Necro_head.png',
		extraTooltipInfo: 'Gazing out from beyond the pale.',
		class: 'equipment',
		visual: 'necro-cowl',
		tags: ['magic', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'helm',
			cosmeticSlot: 'helm',
		},
		rarity: 'legendary',
	},
	7112: {
		name: "Lich's Robe Top",
		id: 7112,
		itemImage: '/images/premium/cosmetic/equipment/C_Necro_top.png',
		extraTooltipInfo: 'The unbeating heart still pulses with malignant energy.',
		class: 'equipment',
		visual: 'necro-top',
		tags: ['magic', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'body',
			cosmeticSlot: 'body',
		},
		rarity: 'legendary',
	},
	7113: {
		name: "Lich's Robe Bottoms",
		id: 7113,
		itemImage: '/images/premium/cosmetic/equipment/C_Necro_legs.png',
		extraTooltipInfo: 'The darkest magics propel you forward.',
		class: 'equipment',
		visual: 'necro-legs',
		tags: ['magic', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			cosmeticSlot: 'legs',
		},
		rarity: 'legendary',
	},
	7114: {
		name: "Lich's Gloves",
		id: 7114,
		itemImage: '/images/premium/cosmetic/equipment/C_Necro_gloves_icon.png',
		itemIcon: '/images/premium/cosmetic/equipment/C_Necro_gloves_icon.png',
		extraTooltipInfo: 'These gloves maintain only the barest grip on life.',
		class: 'equipment',
		specialVisuals: [
			{
				image: '/images/premium/cosmetic/equipment/C_Necro_gloves_1.png',
				css: 'necro-gloves-1',
			},
			{
				image: '/images/premium/cosmetic/equipment/C_Necro_gloves_2.png',
				css: 'necro-gloves-2',
			},
			{
				image: '/images/premium/cosmetic/equipment/C_Necro_gloves_3.png',
				css: 'necro-gloves-3',
			},
		],
		visual: 'necro-gloves',
		tags: ['magic', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'gloves',
			cosmeticSlot: 'gloves',
		},
		rarity: 'legendary',
	},
	7115: {
		name: "Lich's Boots",
		id: 7115,
		itemImage: '/images/premium/cosmetic/equipment/C_Necro_boots.png',
		itemIcon: '/images/premium/cosmetic/equipment/C_Necro_boots_icon.png',
		extraTooltipInfo: 'For crushing the life out of your enemies.',
		class: 'equipment',
		visual: 'necro-boots',
		tags: ['magic', 'armor', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'boots',
			cosmeticSlot: 'boots',
		},
		rarity: 'legendary',
	},
	7116: {
		name: "Marshall the Rat's Gun",
		id: 7116,
		itemImage: '/images/premium/cosmetic/equipment/marshall_gun.png',
		extraTooltipInfo:
			"It's a rat-sized gun! Not a gun the size of a rat, a gun that is sized for use by a rat. That's really small.",
		class: 'equipment',
		visual: 'marshall-gun',
		tags: ['range', 'cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'weapon',
			cosmeticSlot: 'weapon',
		},
		rarity: 'legendary',
	},
	7117: {
		name: 'Profile Slot Token',
		id: 7117,
		isToken: true,
		itemImage: '/images/premium/premium_icon.png',
		extraTooltipInfo: 'A token that can be redeemed to increase your profile slot count by 1.',
		tags: ['platinum', 'token'],
		rarity: 'legendary',
	},
	7118: {
		name: 'Randomisator Horse',
		id: 7118,
		itemImage: '/images/combat/equipment/randomisator_horse.png',
		extraTooltipInfo: 'A random horse. Ride it into the sunset.',
		class: 'equipment',
		visual: 'randomisator-horse',
		tags: ['cosmetic'],
		equipmentStats: {
			isTransmog: true,
			slot: 'legs',
			cosmeticSlot: 'legs',
		},
	},
	8000: {
		name: 'RISE OF INFO',
		id: 8000,
		value: 500,
		tradeable: true,
		champEncounter: 3010,
		enchantmentTier: 0,
		overrideItemTier: 1,
		itemImage: '/images/misc/Elite_Scroll.png',
		extraTooltipInfo:
			'Ancient power emanates from the seal... It sorta smells like durian. Probably not a good idea to use this until raids are officially implemented.',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['dungeon'],
		rarity: 'legendary',
	},
	8001: {
		name: "Black Knight's Trial",
		id: 8001,
		value: 500,
		tradeable: true,
		champEncounter: 2000,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_BK.png',
		extraTooltipInfo: 'A dark scroll for a darker trial. Will you face it and reap the rewards?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8002: {
		name: 'Giant Scroll',
		id: 8002,
		value: 500,
		tradeable: true,
		champEncounter: 2001,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Giant.png',
		extraTooltipInfo: 'A gargantuan task for only the mightiest of heroes; are you counted amongst them?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8003: {
		name: 'Corroded Scroll',
		id: 8003,
		value: 500,
		tradeable: true,
		champEncounter: 2002,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Corroded.png',
		extraTooltipInfo:
			'The stench of decay hangs heavy about this squalid parchment. Are you ready to break the seal?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8004: {
		name: 'Stew Stained Page',
		id: 8004,
		value: 500,
		tradeable: true,
		champEncounter: 2003,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Goblin.png',
		extraTooltipInfo: 'A repulsive taunt from a repulsive creature. Will you break the seal?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8005: {
		name: 'Cluster-Cluck',
		id: 8005,
		value: 500,
		tradeable: true,
		champEncounter: 2004,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Chicken.png',
		extraTooltipInfo: "The secret location of Ot_to's prized hen house. Want to find out what's inside?",
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8006: {
		name: 'Demonic Trial',
		id: 8006,
		value: 500,
		tradeable: true,
		champEncounter: 2005,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Demon.png',
		extraTooltipInfo:
			'Unknown markings surround the stately seal binding this scroll closed. Are you sure you want to break the seal?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8007: {
		name: 'Taproot Scroll',
		id: 8007,
		value: 500,
		tradeable: true,
		champEncounter: 2006,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Spriggan.png',
		extraTooltipInfo: 'The parchment feels alive in your hands. Will you be able to conquer nature?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8008: {
		name: 'The Final Test',
		id: 8008,
		value: 500,
		tradeable: true,
		champEncounter: 5,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/Elite_Scroll.png',
		extraTooltipInfo: 'Can you do what no one has ever done before?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['dungeon'],
		rarity: 'legendary',
	},
	8009: {
		name: 'Shapeless Scroll',
		id: 8009,
		value: 500,
		tradeable: true,
		champEncounter: 2007,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Chaos.png',
		extraTooltipInfo: 'The scroll appears to be shifting in and out of reality. Do you dare unravel its secrets?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8010: {
		name: 'Stormy Sea Scroll',
		id: 8010,
		value: 500,
		tradeable: true,
		champEncounter: 2008,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Fish.png',
		extraTooltipInfo: "It's soaking wet but the parchment seems untearable. Will you dive in?",
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8011: {
		name: 'Enchanted Armory Scroll',
		id: 8011,
		value: 500,
		tradeable: true,
		champEncounter: 2009,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Armory.png',
		extraTooltipInfo: "A dignified invitation to test the armory's latest creations. Will you accept?",
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8012: {
		name: 'Fallen Academy Scroll',
		id: 8012,
		value: 500,
		tradeable: true,
		champEncounter: 2010,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Academy.png',
		extraTooltipInfo: 'An academy scroll that has been warped by some sort of demonic energy. Will you explore it?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8013: {
		name: 'Overgrown Woods Scroll',
		id: 8013,
		value: 500,
		tradeable: true,
		champEncounter: 2011,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Woods.png',
		extraTooltipInfo:
			'An old scroll you found in the woods that leads you further into the woods. Should you follow it?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	8014: {
		name: 'Elven Encampment Scroll',
		id: 8014,
		value: 500,
		tradeable: true,
		champEncounter: 2012,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 1,
		itemImage: '/images/misc/elite/Elite_Scroll_Elves.png',
		extraTooltipInfo:
			'An old scroll bearing the insignia of some long lost dynasty. Will you comb through history?',
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 1,
		},
		blockResearching: true,
		tags: ['elite'],
		rarity: 'uncommon',
	},
	9000: {
		name: "Giant's Keep Key",
		id: 9000,
		value: 10000000,
		champEncounter: 3003,
		enchantmentTier: 0,
		maxAugLevel: 20,
		tradeable: true,
		overrideItemTier: 2,
		itemImage: '/images/misc/keys/Complete_Disc.png',
		extraTooltipInfo:
			"The key to enter the Giant's Keep made of the various elemental fragments. Only five people could make it to the Keep without being spotted.",
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 90000,
			category: 'Dungeon',
			craftable: true,
			description: "Grants one-time access to the Giant's Keep dungeon. This is a 5 person encounter.",
		},
	},
	9001: {
		id: 9001,
		name: 'Nature Fragment',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/keys/Nature_Piece.png',
		extraTooltipInfo: 'A piece of living earth is imbued into this fragment.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 85,
			experience: 1500,
			category: 'Dungeon',
			craftable: true,
		},
	},
	9002: {
		id: 9002,
		name: 'Ice Fragment',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/keys/Water_Piece.png',
		extraTooltipInfo: 'An unmelting shard of ice is imbued into this fragment.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 85,
			experience: 1500,
			category: 'Dungeon',
			craftable: true,
		},
	},
	9003: {
		id: 9003,
		name: 'Fire Fragment',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/keys/Fire_Piece.png',
		extraTooltipInfo: 'An unfaltering gout of flames is imbued into this fragment.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 85,
			experience: 1500,
			category: 'Dungeon',
			craftable: true,
		},
	},
	9004: {
		id: 9004,
		name: 'Corrupted Fragment',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/keys/Death_Piece.png',
		extraTooltipInfo: 'A rotten slab of decay is imbued into this fragment.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	9005: {
		id: 9005,
		name: 'Chaos Fragment',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/keys/Chaos_Piece.png',
		extraTooltipInfo: 'An undescribable phenomenon is imbued into this fragment.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	9006: {
		name: 'Torn Goblin Settlement Map',
		id: 9006,
		value: 100000,
		tradeable: true,
		champEncounter: 3001,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 2,
		itemImage: '/images/misc/keys/goblin_map.png',
		extraTooltipInfo:
			'A map to find an elusive goblin settlement. You could probably only bring up to three people to this location safely.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 5030,
			category: 'Dungeon',
			craftable: true,
			description: 'Grants one-time access to the Goblin Settlement dungeon. This is a trio encounter.',
		},
	},
	9007: {
		name: 'Scrap of Stained Parchment',
		id: 9007,
		value: 7500,
		tradeable: true,
		itemImage: '/images/misc/keys/goblin_map_piece.png',
		extraTooltipInfo: 'A scrap of parchment that appears to be a part of a map.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	9008: {
		name: 'Dark Fortress Key',
		id: 9008,
		value: 750000,
		tradeable: true,
		champEncounter: 3002,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 2,
		itemImage: '/images/misc/keys/fortress_key.png',
		extraTooltipInfo:
			'A brutish key made of dark metal. The way to a fortress is treacherous, no more than three people could make the journey.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 30500,
			category: 'Dungeon',
			craftable: true,
			description: 'Grants one-time access to the Dark Fortress dungeon. This is a trio encounter.',
		},
	},
	9009: {
		name: 'Dark Key Piece',
		id: 9009,
		value: 100000,
		tradeable: true,
		itemImage: '/images/misc/keys/fortress_key_piece.png',
		extraTooltipInfo: 'A piece of dark metal that looks like part of a key.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	9010: {
		name: 'Cursed Spade',
		id: 9010,
		value: 2000000,
		tradeable: true,
		maxAugLevel: 20,
		champEncounter: 3006,
		overrideItemTier: 2,
		itemImage: 'images/misc/keys/cursed_spade.png',
		extraTooltipInfo: 'A cursed spade that is drawn to long forgotten lands...',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'uncommon',
		craftingStats: {
			level: 50,
			experience: 10020,
			category: 'Dungeon',
			craftable: true,
			description: 'Bringing the pieces together makes them emit a sickly pallor. Is it worth reassembling it?',
		},
	},
	9011: {
		name: 'Burning Feather',
		id: 9011,
		value: 2000000,
		tradeable: true,
		enchantmentTier: 0,
		maxAugLevel: 20,
		champEncounter: 3007,
		overrideItemTier: 2,
		itemImage: 'images/misc/keys/flame_feather.png',
		extraTooltipInfo:
			"The feather itself appears to be made out of fire! You could probably lead yourself and one other wherever it's pointing. It just wants to go home, but you'd probably melt in there. Better bring some Flame Resistance food!",
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 28000,
			category: 'Dungeon',
			craftable: true,
			description: 'Grants one-time access to the Palace of Flames dungeon. This is a duo encounter.',
		},
	},
	9012: {
		name: "Krampus' Key",
		id: 9012,
		value: 69,
		tradeable: false,
		enchantmentTier: 0,
		itemImage: '/images/christmas/krampus_key.png',
		extraTooltipInfo:
			'A piece of bone with some bits of metal sticking off of it. If you squint really hard it somewhat resembles a key.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'event', 'craftable'],
		blockCritAugment: true,
		craftingStats: {
			level: 1,
			experience: 0,
			category: 'Event',
			craftable: true,
		},
	},
	9013: {
		name: 'Molten Ashes',
		id: 9013,
		value: 150000,
		tradeable: true,
		itemImage: '/images/misc/keys/infernal_ashes.png',
		extraTooltipInfo: 'The remains of some infernal creature. Still warm to the touch.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	9014: {
		name: 'Infernal Flame',
		id: 9014,
		value: 250000,
		tradeable: true,
		itemImage: '/images/misc/keys/infernal_flame.png',
		extraTooltipInfo: 'The vestiges of an infernal soul, left to burn eternally.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 60,
			experience: 1250,
			category: 'Dungeon',
			craftable: true,
		},
	},
	9015: {
		name: 'Cracked Shrimp Bauble',
		id: 9015,
		value: 420,
		tradeable: false,
		champEncounter: 3000,
		itemImage: '/images/misc/shrimp_bauble_cracked.png',
		extraTooltipInfo: 'The roar of the ocean can be heard faintly while examining this bauble.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon'],
		blockCritAugment: true,
		rarity: 'uncommon',
	},
	9016: {
		name: 'Elder Lockstone',
		id: 9016,
		value: 2000000,
		tradeable: true,
		maxAugLevel: 20,
		champEncounter: 3004,
		overrideItemTier: 2,
		itemImage: 'images/misc/keys/lockstone.png',
		extraTooltipInfo:
			'A lockstone that can lead its owner to a hidden Elder Ruin and reveal its untouched treasures.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'epic',
		craftingStats: {
			level: 80,
			experience: 50500,
			category: 'Dungeon',
			craftable: true,
			description: 'Repairing the lockstone appears to activate some sort of latent power within it.',
		},
	},
	9017: {
		name: 'Castle Map',
		id: 9017,
		value: 2000000,
		tradeable: true,
		maxAugLevel: 20,
		champEncounter: 3005,
		overrideItemTier: 2,
		itemImage: 'images/misc/keys/castle_map.png',
		extraTooltipInfo: 'A full map that leads to an old castle.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'epic',
		craftingStats: {
			level: 74,
			experience: 27600,
			category: 'Dungeon',
			craftable: true,
			description: 'A full map that leads to an old castle.',
		},
	},
	9018: {
		name: 'Ancient Pyramid',
		id: 9018,
		value: 10000000,
		tradeable: true,
		maxAugLevel: 20,
		champEncounter: 3008,
		overrideItemTier: 2,
		itemImage: 'images/misc/keys/pyramid.png',
		extraTooltipInfo: 'An ancient chunk of stone curiously shaped into a pyramid.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		rarity: 'epic',
		blockCritAugment: true,
		craftingStats: {
			level: 80,
			experience: 41000,
			category: 'Dungeon',
			craftable: true,
			description: 'An ancient chunk of stone curiously shaped into a pyramid.',
		},
	},
	9019: {
		name: 'Ancient Maelstrom Orb',
		id: 9019,
		value: 20000000,
		tradeable: true,
		maxAugLevel: 20,
		champEncounter: 3009,
		overrideItemTier: 2,
		itemImage: 'images/misc/keys/prism.png',
		extraTooltipInfo: 'A prism trapped inside of a magical prison. What could go wrong if you released it?',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		blockCritAugment: true,
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 112500,
			category: 'Dungeon',
			craftable: true,
			description: 'A prism trapped inside of a magical prison. What could go wrong if you released it?',
		},
	},
	9020: {
		name: 'Ancient Crystal',
		id: 9020,
		value: 1750000,
		tradeable: true,
		itemImage: '/images/misc/keys/ancient_crystal.png',
		extraTooltipInfo:
			'An ancient crystal. It is chaotic in origin but is far older than any chaotic crystal you have ever seen.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'epic',
	},
	9021: {
		name: 'Ancient Stone',
		id: 9021,
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/keys/ancient_stone.png',
		extraTooltipInfo:
			'An ancient stone. Appears to be hewn in such a way that it would be able to fit nicely with other similar stones, despite its obvious age and damage.',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'epic',
	},
	9022: {
		name: 'Partial Map',
		id: 9022,
		value: 150000,
		tradeable: true,
		itemImage: '/images/misc/keys/partial_map.png',
		extraTooltipInfo:
			'A portion of a larger map. Useless on its own, but with the remaining pieces it may lead somewhere!',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'rare',
	},
	9023: {
		name: 'Elder Gem',
		id: 9023,
		value: 5000000,
		tradeable: true,
		itemImage: '/images/misc/keys/elder_gem.png',
		extraTooltipInfo: 'An elder gem. Prized heirloom of the Elves, or the final piece to a greater puzzle?',
		class: 'key',
		blockResearching: true,
		tags: ['fragment'],
		rarity: 'rare',
	},
	9024: {
		name: 'Awakened Shrimp Eye',
		id: 9024,
		value: 30000000,
		tradeable: true,
		champEncounter: 3011,
		enchantmentTier: 0,
		overrideItemTier: 2,
		itemImage: '/images/misc/shrimp_eye.png',
		extraTooltipInfo: "It's looking at you. Don't look too closely back.",
		class: 'elite-scroll',
		blockCritAugment: true,
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		tags: ['dungeon', 'craftable'],
		rarity: 'legendary',
		craftingStats: {
			level: 92,
			experience: 237000,
			category: 'Dungeon',
			craftable: true,
			description: 'You can awaken the eye through a series of ancient rituals. Are you prepared?',
		},
	},
	9025: {
		name: 'Dwarven Research Key',
		id: 9025,
		value: 10000000,
		champEncounter: 3014,
		tradeable: true,
		itemImage: '/images/misc/keys/dwarven_research.png',
		extraTooltipInfo: 'The key to enter the Dwarven Research Station.',
		class: 'key',
		blockResearching: true,
		blockAugmenting: true,
		blockCritAugment: true,
		tags: ['dungeon', 'craftable'],
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 120000,
			category: 'Dungeon',
			craftable: true,
			description:
				'Grants one-time access to the Dwarven Research Station dungeon. Augmentation level is randomly chosen up to (Smithing + Mining) / 40.',
			augmentation: (skills) =>
				Math.floor(
					Math.random() *
						((skills.mining.level +
							skills.mining.masteryLevel +
							skills.smithing.level +
							skills.smithing.masteryLevel) /
							40)
				),
		},
	},
	9026: {
		name: 'Heart of the Oak',
		id: 9026,
		value: 10000000,
		champEncounter: 3013,
		tradeable: true,
		itemImage: '/images/misc/keys/heart_oak.png',
		extraTooltipInfo: 'The key to enter the Nature Athaneum.',
		class: 'key',
		blockResearching: true,
		blockAugmenting: true,
		blockCritAugment: true,
		tags: ['dungeon'],
		rarity: 'epic',
	},
	9027: {
		name: 'Abandoned Academy Scroll',
		id: 9027,
		value: 10000000,
		champEncounter: 3012,
		enchantmentTier: 0,
		tradeable: true,
		itemImage: '/images/misc/keys/abandoned_academy.png',
		extraTooltipInfo: 'The key to enter the Abandoned Academy Base.',
		class: 'key',
		blockResearching: true,
		blockAugmenting: true,
		blockCritAugment: true,
		tags: ['dungeon', 'craftable'],
		rarity: 'epic',
		craftingStats: {
			level: 85,
			experience: 120000,
			category: 'Dungeon',
			craftable: true,
			description:
				'Grants one-time access to the Abandoned Academy Base dungeon. Augmentation level is randomly chosen up to (Fishing + Runecrafting) / 40.',
			augmentation: (skills) =>
				Math.floor(
					Math.random() *
						((skills.fishing.level +
							skills.fishing.masteryLevel +
							skills.runecrafting.level +
							skills.runecrafting.masteryLevel) /
							40)
				),
		},
	},
	9028: {
		id: 9028,
		name: 'Core Fragment',
		level: 90,
		value: 500000,
		tradeable: true,
		experience: 150,
		time: 5000,
		itemImage: '/images/mining/core.png',
		extraTooltipInfo:
			'A chunk of ore drawn from the core of the land itself. Not human mined, so where did it come from?',
		class: 'key',
		blockResearching: true,
		tags: ['fragment', 'mining', 'smithing'],
		rarity: 'legendary',
	},
	9029: {
		id: 9029,
		name: 'Core Ingot',
		level: 90,
		smithingTier: 7,
		value: 1000000,
		experience: 50000,
		time: 6400000,
		itemImage: '/images/smithing/core_ingot.png',
		class: 'bar',
		extraTooltipInfo:
			'A material that mimics Dwarven Craftdwarfship. Using this to craft a key would grant you access to long lost Dwarven ruins.',
		tags: ['mining', 'smithing', 'fragment'],
		rarity: 'legendary',
		tradeable: true,
	},
	9030: {
		id: 9030,
		name: 'Ancient Cloth Fragment',
		value: 500000,
		tradeable: true,
		itemImage: '/images/misc/keys/abandoned_academy_cloth.png',
		class: 'cloth',
		extraTooltipInfo:
			'A particularly powerful piece of arcane cloth. It could be used to craft an even more powerful scroll.',
		tags: ['runecrafting', 'fragment'],
		rarity: 'legendary',
	},
	9031: {
		id: 9031,
		name: 'Ancient Oak Seed',
		value: 500000,
		experience: 40,
		farmingStats: {
			requiredLevel: 90,
			experience: 325000,
			height: 6,
			width: 6,
			time: 1440,
			image: '/images/farming/elder_oak_plant.png',
			align: 'bottom',
		},
		class: 'seed',
		tradeable: true,
		itemImage: '/images/farming/elder_oak_seed.png',
		tags: ['seed', 'farming', 'foraging', 'fragment'],
		extraTooltipInfo: 'A seed that predates even the Elder Trees.',
		rarity: 'legendary',
	},
	9032: {
		id: 9032,
		name: 'Cursed Spade Head',
		value: 500000,
		tradeable: true,
		itemImage: 'images/misc/keys/cursed_spade_head.png',
		class: 'key',
		blockResearching: true,
		extraTooltipInfo:
			'The head of a broken spade, evidently used to unearth some forgotten evil. With the right process you could use it to find these crypts...',
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	9033: {
		name: 'Dark Vault Key',
		id: 9033,
		value: 1500000,
		tradeable: true,
		champEncounter: 3015,
		enchantmentTier: 0,
		maxAugLevel: 20,
		overrideItemTier: 2,
		itemImage: '/images/misc/keys/fortress_key.png',
		extraTooltipInfo: 'A brutish key made of dark metal, rumored to unlock hidden treasures within the Dark Vault.',
		class: 'key',
		augmentOverride: {
			fixedSuccessCount: 1,
			fixedBaseCount: 0,
		},
		blockResearching: true,
		blockCritAugment: true,
		tags: ['dungeon', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 85,
			experience: 113500,
			category: 'Dungeon',
			craftable: true,
			description: 'Grants one-time access to the Dark Vault dungeon. This is a trio encounter.',
		},
	},
	9034: {
		name: 'Dark Vault Identification Crystal',
		id: 9034,
		value: 500000,
		tradeable: true,
		itemImage: 'images/misc/keys/id.png',
		class: 'key',
		blockResearching: true,
		extraTooltipInfo:
			'An intact identification crystal. Can be used to fashion a key to gain access to the Dark Vault.',
		tags: ['fragment'],
		rarity: 'uncommon',
	},
	10000: {
		id: 10000,
		name: 'The Castled King',
		itemImage: '/images/combat/monsters/elite/true_info.png',
		itemIcon: '/images/magic/prismatic_gem.png',
		extraTooltipInfo: 'Show your true might.',
		class: 'equipment',
		visual: 'castled-king',
		equipmentStats: {
			isTransmog: true,
			fullTransmog: true,
			slot: 'cape',
			cosmeticSlot: 'base',
		},
		tags: ['armor', 'unique', 'cosmetic'],
		rarity: 'legendary',
	},
	10001: {
		name: 'Dextrous Scraps',
		id: 10001,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/Range_Scrap.png',
		class: 'gem',
		extraTooltipInfo: 'Perhaps you could salvage something dextrous from these scraps...',
		canBeOpened: true,
		tags: ['chest', 'range'],
	},
	10002: {
		name: 'Intellectual Scraps',
		id: 10002,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/Magic_Scrap.png',
		class: 'gem',
		extraTooltipInfo: 'Perhaps you could salvage something intellectual from these scraps...',
		canBeOpened: true,
		tags: ['chest', 'magic'],
	},
	10003: {
		name: 'Gem Package',
		id: 10003,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/package.png',
		class: 'gem',
		extraTooltipInfo: "A bag of gems! I wonder what's inside? ...Gems, hopefully.",
		canBeOpened: true,
		tags: ['mining', 'chest'],
	},
	10004: {
		name: 'Sealed Scroll',
		id: 10004,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/sealed_scroll.png',
		class: 'gem',
		extraTooltipInfo:
			"A sealed scroll. Doesn't come with postal information, so I guess it's yours now! What could its contents be?",
		canBeOpened: true,
		tags: ['chest'],
	},
	10005: {
		name: 'Small Gathering Supply Crate',
		id: 10005,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/supply_crate_small.png',
		class: 'gem',
		extraTooltipInfo:
			"A small crate addressed to someone named 'Diet$melter.' Sounds like a fake name, you should open it!",
		canBeOpened: true,
		tags: ['chest'],
	},
	10006: {
		name: 'Gathering Supply Crate',
		id: 10006,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/supply_crate.png',
		class: 'gem',
		extraTooltipInfo:
			"A crate addressed to someone named 'Diet$melter.' Sounds like a fake name, you should open it!",
		canBeOpened: true,
		tags: ['chest'],
	},
	10007: {
		name: 'Large Gathering Supply Crate',
		id: 10007,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/supply_crate_large.png',
		class: 'gem',
		extraTooltipInfo:
			"A large crate addressed to someone named 'Diet$melter.' Sounds like a fake name, you should open it!",
		canBeOpened: true,
		tags: ['chest'],
	},
	10008: {
		name: 'Bucket of Worms',
		id: 10008,
		value: 1000,
		tradeable: true,
		itemImage: '/images/fishing/bait/bucket.png',
		class: 'gem',
		extraTooltipInfo: "A bucket of soil with worms inside! At least it isn't just a can of worms!",
		canBeOpened: true,
		tags: ['fishing', 'chest'],
	},
	10009: {
		name: 'Box of Skill Books',
		id: 10009,
		value: 1,
		tradeable: true,
		itemImage: '/images/misc/gold_lockbox.png',
		class: 'gem',
		extraTooltipInfo: 'An ornate lockbox containing a variety of skill books. Testing Item.',
		canBeOpened: true,
		tags: ['chest'],
	},
	10010: {
		name: 'Box of Jewelry',
		id: 10010,
		value: 1,
		tradeable: true,
		itemImage: '/images/misc/gold_lockbox.png',
		class: 'gem',
		extraTooltipInfo: 'An ornate lockbox containing a variety of skill books. Testing Item.',
		canBeOpened: true,
		tags: ['chest'],
	},
	10011: {
		name: 'Magic Starter Kit',
		id: 10011,
		value: 1,
		tradeable: true,
		itemImage: '/images/misc/pouch.png',
		class: 'gem',
		extraTooltipInfo:
			'A simple pouch containing some starter supplies. Will eventually be tied to a tutorial quest.',
		canBeOpened: true,
		tags: ['chest', 'magic'],
	},
	10012: {
		name: 'Range Starter Kit',
		id: 10012,
		value: 1,
		tradeable: true,
		itemImage: '/images/misc/pouch.png',
		class: 'gem',
		extraTooltipInfo:
			'A simple pouch containing some starter supplies. Will eventually be tied to a tutorial quest.',
		canBeOpened: true,
		tags: ['chest', 'range'],
	},
	10013: {
		name: 'Lesser Jewelry Cache',
		id: 10013,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/lockbox.png',
		extraTooltipInfo: 'A small cache of jewelry, might contain something useful.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'uncommon',
	},
	10014: {
		name: 'Greater Jewelry Cache',
		id: 10014,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/gold_lockbox.png',
		extraTooltipInfo: 'A grand cache of jewelry, might contain something useful.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10015: {
		name: 'Goblin Cache',
		id: 10015,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/package.png',
		extraTooltipInfo: 'A cache containing loot from a Goblin Settlement. Smells like one too.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10016: {
		name: 'Dark Cache',
		id: 10016,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/dark_chest.png',
		extraTooltipInfo: 'A cache containing loot from a Dark Fortress. It feels powerful.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10017: {
		name: 'Giant Cache',
		id: 10017,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/treasure_chest.png',
		extraTooltipInfo: "A cache containing loot from the Giants Keep. It's incredibly heavy.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10018: {
		name: 'Elder Cache',
		id: 10018,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/green_chest.png',
		extraTooltipInfo: 'A cache containing loot from the Elders Ruin. It looks very elegant.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10019: {
		name: 'Overgrown Cache',
		id: 10019,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/green_bag.png',
		extraTooltipInfo: 'A cache containing loot from an Old Castle. Old and covered in moss.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10020: {
		name: 'Barrows Cache',
		id: 10020,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/old_fancy_bag.png',
		extraTooltipInfo:
			"A cache containing loot from the Ancient Barrows. It's a bit dusty but still obviously valuable.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10021: {
		name: 'Flaming Cache',
		id: 10021,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/red_lockbox.png',
		extraTooltipInfo: "A cache containing loot from the Palace of Flames. It's still warm.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10022: {
		name: 'Storm Cache',
		id: 10022,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/purple_chest.png',
		extraTooltipInfo: "A cache containing loot from the Eye of the Storm. You feel as if you're being watched.",
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10023: {
		name: 'Maelstrom Cache',
		id: 10023,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/purple_chest_3.png',
		extraTooltipInfo: 'A cache containing loot from the Maelstrom. It whispers to you.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10024: {
		name: 'INFO Cache',
		id: 10024,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/blue_box.png',
		extraTooltipInfo: 'A cache containing loot from INFO themself. They see you as worthy. At least a bit.',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10025: {
		name: 'Ancient Cache',
		id: 10025,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/purple_chest_3.png',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10026: {
		name: 'Dwarven Research Cache',
		id: 10026,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/supply_crate_large.png',
		extraTooltipInfo: 'A cache containing loot from a Dwarven Research Station. What secrets does it contain?',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10027: {
		name: 'Elven Research Cache',
		id: 10027,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/green_crate.png',
		extraTooltipInfo: 'A cache containing loot from the Nature Athaneum. What secrets does it contain?',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10028: {
		name: 'Academy Research Cache',
		id: 10028,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/supply_crate_small.png',
		extraTooltipInfo: 'A cache containing loot from the Abandoned Academy Base. What lost secrets does it contain?',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	10029: {
		name: 'Dark Vault Cache',
		id: 10029,
		value: 1000,
		tradeable: true,
		itemImage: '/images/misc/darker_chest.png',
		extraTooltipInfo: 'A cache containing loot from the Dark Vault. What treasures does it contain?',
		canBeOpened: true,
		class: 'gem',
		tags: ['chest'],
		rarity: 'epic',
	},
	11000: {
		id: 11000,
		name: 'Novice Tacklebox',
		value: 850,
		tradeable: true,
		enchantmentTier: 1,
		itemImage: '/images/fishing/Novice_Tacklebox.png',
		class: 'equipment',
		extraTooltipInfo:
			"A beginner's novice tacklebox designed to be used by children. Not much good to catch anything large.",
		tags: ['fishing', 'craftable'],
		equipmentStats: {
			slot: 'tacklebox',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 3,
				},
				{
					skill: 'fishingBaitPower',
					boost: 15,
				},
				{
					skill: 'fishingReelPower',
					boost: 5,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 0.5,
				},
			],
		},
		craftingStats: {
			level: 1,
			experience: 185,
			category: 'Fishing',
			craftable: true,
			description: 'A novice fishing tacklebox. Gives 3 fishing levels, 15 bait power and 5 reel power.',
		},
	},
	11001: {
		id: 11001,
		name: 'Basic Tacklebox',
		value: 8000,
		tradeable: true,
		enchantmentTier: 2,
		requiredLevel: {
			fishing: 10,
		},
		itemImage: '/images/fishing/Basic_Tacklebox.png',
		class: 'equipment',
		extraTooltipInfo:
			'A basic fishing tacklebox aimed for use by hobbyists and amateurs for basic fishing adventures to the local river or pond.',
		tags: ['fishing', 'craftable'],
		equipmentStats: {
			slot: 'tacklebox',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 6,
				},
				{
					skill: 'fishingBaitPower',
					boost: 20,
				},
				{
					skill: 'fishingReelPower',
					boost: 10,
				},
				{
					skill: 'fishingRarityPower',
					boost: 3,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 2,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
			],
		},
		craftingStats: {
			level: 10,
			experience: 1045,
			category: 'Fishing',
			craftable: true,
			description:
				'A basic fishing tacklebox. Gives 6 fishing levels, 20 bait power, 10 reel power, and 3 bonus rarity.',
		},
	},
	11002: {
		id: 11002,
		name: 'Advanced Tacklebox',
		value: 54500,
		tradeable: true,
		enchantmentTier: 3,
		requiredLevel: {
			fishing: 30,
		},
		itemImage: '/images/fishing/Advanced_Tacklebox.png',
		class: 'equipment',
		extraTooltipInfo: "An advanced fishing tacklebox for those who've gotten more than their toes wet in fishing.",
		tags: ['fishing', 'craftable'],
		equipmentStats: {
			slot: 'tacklebox',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 9,
				},
				{
					skill: 'fishingBaitPower',
					boost: 25,
				},
				{
					skill: 'fishingReelPower',
					boost: 15,
				},
				{
					skill: 'fishingRarityPower',
					boost: 6,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 1.5,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 2.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 3,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1.5,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 30,
			experience: 6255,
			category: 'Fishing',
			craftable: true,
			description:
				'An advanced fishing tacklebox. Gives 9 fishing levels, 25 bait power, 15 reel power, and 6 bonus rarity.',
		},
	},
	11003: {
		id: 11003,
		name: 'Pro Tacklebox',
		value: 500000,
		tradeable: true,
		enchantmentTier: 4,
		requiredLevel: {
			fishing: 40,
		},
		itemImage: '/images/fishing/Pro_Tacklebox.png',
		class: 'equipment',
		extraTooltipInfo: 'The pro fishing tacklebox has nearly everything you could possibly need to catch fish.',
		tags: ['fishing', 'craftable'],
		equipmentStats: {
			slot: 'tacklebox',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 12,
				},
				{
					skill: 'fishingBaitPower',
					boost: 30,
				},
				{
					skill: 'fishingReelPower',
					boost: 20,
				},
				{
					skill: 'fishingRarityPower',
					boost: 10,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 2,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 3.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 4,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 2,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 40,
			experience: 18150,
			category: 'Fishing',
			craftable: true,
			description:
				'A pro fishing tacklebox! Gives 12 fishing levels, 30 bait power, 20 reel power, and 10 bonus rarity.',
		},
	},
	11004: {
		id: 11004,
		name: 'Supreme Tacklebox',
		value: 2000000,
		tradeable: true,
		enchantmentTier: 5,
		requiredLevel: {
			fishing: 50,
		},
		itemImage: '/images/fishing/Supreme_Tacklebox.png',
		class: 'equipment',
		extraTooltipInfo:
			'A supreme fishing tacklebox rife with all the bits and bobs a master fisherman would need to fish up just about anything.',
		tags: ['fishing', 'craftable'],
		equipmentStats: {
			slot: 'tacklebox',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 15,
				},
				{
					skill: 'fishingBaitPower',
					boost: 35,
				},
				{
					skill: 'fishingReelPower',
					boost: 25,
				},
				{
					skill: 'fishingRarityPower',
					boost: 15,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 2.5,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 4.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 5,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 3.5,
				},
			],
		},
		rarity: 'epic',
		craftingStats: {
			level: 50,
			experience: 38920,
			category: 'Fishing',
			craftable: true,
			description:
				'A supreme fishing tacklebox! Gives 15 fishing levels, 35 bait power, 25 reel power, and 15 bonus rarity.',
		},
	},
	11005: {
		id: 11005,
		name: "Ocean's Embrace",
		value: 9927000,
		tradeable: false,
		enchantmentTier: 8,
		requiredLevel: {
			fishing: 80,
		},
		itemImage: '/images/fishing/Master_Tacklebox.png',
		class: 'equipment',
		extraTooltipInfo:
			'The origin of this mythical equipment is lost to history but there is no doubt regarding its power. Enhances ancient fishing.',
		tags: ['fishing', 'craftable'],
		equipmentStats: {
			slot: 'tacklebox',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 60,
				},
				{
					skill: 'fishingBaitPower',
					boost: 60,
				},
				{
					skill: 'fishingReelPower',
					boost: 60,
				},
				{
					skill: 'fishingRarityPower',
					boost: 60,
				},
			],
			itemSet: [10001, 10002],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 6,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 6,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 6,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 6,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 79000,
			category: 'Fishing',
			craftable: true,
			description:
				'The ultimate fishing implement! Gives 40 fishing levels, 100 bait power, 100 reel power, and 60 bonus rarity. Enhances ancient fishing.',
			unlockQuest: 215,
		},
	},
	11010: {
		id: 11010,
		name: 'Earthworm Bait',
		tradeable: true,
		value: 1,
		fishingBait: {
			bait: 5,
			reel: 5,
		},
		itemImage: 'images/fishing/bait/earthworm_hook.png',
		extraTooltipInfo: "A tiny lil' earthworm.",
		class: 'fish',
		tags: ['fishing'],
	},
	11011: {
		id: 11011,
		name: 'Fat Nightcrawler Bait',
		tradeable: true,
		value: 5,
		fishingBait: {
			level: 1,
			bait: 15,
			reel: 10,
			bonus: 5,
		},
		itemImage: 'images/fishing/bait/nightcrawler_hook.png',
		extraTooltipInfo: "A big ol' nightcrawler.",
		class: 'fish',
		tags: ['fishing'],
	},
	11012: {
		id: 11012,
		name: 'Glowbug Bait',
		tradeable: true,
		value: 5,
		fishingBait: {
			level: 1,
			bait: 10,
			reel: 15,
			bonus: 5,
		},
		itemImage: 'images/fishing/bait/glowbug_hook.png',
		extraTooltipInfo: 'A small glowbug.',
		class: 'fish',
		tags: ['fishing'],
	},
	11013: {
		id: 11013,
		name: 'Shrimp Tail Bait',
		tradeable: true,
		value: 35,
		fishingBait: {
			level: 1,
			bait: 20,
			reel: 10,
			bonus: 5,
		},
		itemImage: 'images/fishing/bait/shrimptail_hook.png',
		extraTooltipInfo: 'The tail of a shrimp.',
		class: 'fish',
		tags: ['crafting', 'fishing', 'craftable'],
		craftingStats: {
			level: 5,
			experience: 15,
			category: 'Fishing',
			craftable: true,
			multiplier: 5,
			description: 'Each craft results in 5 bait.',
		},
	},
	11014: {
		id: 11014,
		name: 'Fish Guts Bait',
		tradeable: true,
		value: 50,
		fishingBait: {
			level: 2,
			bait: 25,
			reel: 5,
			bonus: 10,
		},
		itemImage: 'images/fishing/bait/fish_guts_hook.png',
		extraTooltipInfo: 'Some fish guts. Ew.',
		class: 'fish',
		tags: ['crafting', 'fishing', 'craftable'],
		craftingStats: {
			level: 20,
			experience: 45,
			category: 'Fishing',
			craftable: true,
			multiplier: 3,
			description: 'Each craft results in 3 bait.',
		},
	},
	11015: {
		id: 11015,
		name: 'Smelly Meat Bait',
		tradeable: true,
		value: 75,
		fishingBait: {
			level: 2,
			bait: 15,
			reel: 15,
			bonus: 5,
		},
		itemImage: 'images/fishing/bait/smelly_meat_hook.png',
		extraTooltipInfo: 'Some smelly, probably not rotten, meat.',
		class: 'fish',
		tags: ['fishing'],
	},
	11016: {
		id: 11016,
		name: 'Hardened Hook Bait',
		tradeable: true,
		value: 100,
		fishingBait: {
			level: 3,
			reel: 35,
			bonus: 20,
			multReel: 1.3,
			multBonus: 1.1,
		},
		itemImage: 'images/fishing/bait/hardened_hook.png',
		extraTooltipInfo: 'A hook designed to make reeling in big catches easier.',
		class: 'fish',
		tags: ['crafting', 'fishing', 'craftable'],
		craftingStats: {
			level: 35,
			experience: 300,
			category: 'Fishing',
			craftable: true,
			multiplier: 12,
			description: 'Each craft results in 12 bait.',
		},
	},
	11017: {
		id: 11017,
		name: 'Alluring Lure Bait',
		tradeable: true,
		value: 100,
		fishingBait: {
			level: 3,
			bait: 20,
			bonus: 30,
			multBait: 1.2,
			multBonus: 1.1,
		},
		itemImage: 'images/fishing/bait/alluring_lure.png',
		extraTooltipInfo: 'A shiny lure designed to entice even the most wary catches.',
		class: 'fish',
		tags: ['crafting', 'fishing', 'craftable'],
		craftingStats: {
			level: 35,
			experience: 250,
			category: 'Fishing',
			craftable: true,
			multiplier: 14,
			description: 'Each craft results in 14 bait.',
		},
	},
	11018: {
		id: 11018,
		name: 'Writhing Tentacle Bait',
		tradeable: true,
		value: 666,
		fishingBait: {
			level: 5,
			bait: -30,
			reel: -30,
			bonus: 60,
			multReel: 1.3,
			multBait: 1.3,
			multBonus: 1.3,
		},
		itemImage: 'images/fishing/bait/writhing_tentacle_hook.png',
		extraTooltipInfo: 'An ancient writhing tentacle that reacts when you poke it. Enhances ancient fishing.',
		class: 'fish',
		tags: ['crafting', 'fishing', 'craftable'],
		rarity: 'uncommon',
		craftingStats: {
			level: 45,
			experience: 150,
			category: 'Fishing',
			craftable: true,
			multiplier: 6,
			description:
				'Each craft results in 6 bait. A writhing tentacle that reacts when you poke it. Enhances ancient fishing.',
		},
	},
	11019: {
		id: 11019,
		name: 'Mysterious Bait',
		tradeable: true,
		value: 500,
		fishingBait: {
			randomizeBoosts: true,
			level: 0,
			bait: 0,
			reel: 0,
			bonus: 0,
		},
		itemImage: 'images/fishing/bait/mysterious_bait_hook.png',
		extraTooltipInfo:
			'Who knows what effects using this bait would have? Each and every one of them is unique! Each bait has between +50 and +200 to a random fishing stat.',
		class: 'fish',
		tags: ['fishing'],
	},
	11030: {
		id: 11030,
		name: 'Rotten Driftwood',
		tradeable: true,
		value: 15,
		experience: 15,
		heat: 25,
		itemImage: 'images/fishing/material/rotten_driftwood.png',
		extraTooltipInfo: 'A rotten piece of driftwood. Could it still be used for something?',
		class: 'fish',
		tags: ['fishing', 'heat'],
	},
	11031: {
		id: 11031,
		name: 'Sturdy Driftwood',
		tradeable: true,
		value: 50,
		experience: 50,
		heat: 75,
		itemImage: 'images/fishing/material/sturdy_driftwood.png',
		extraTooltipInfo: 'A sturdy piece of driftwood. Could it still be used for something?',
		class: 'fish',
		tags: ['fishing', 'heat'],
	},
	11032: {
		id: 11032,
		name: 'Aquamarine',
		tradeable: true,
		value: 15000,
		experience: 250,
		itemImage: 'images/fishing/material/aquamarine.png',
		extraTooltipInfo: 'An aquamarine crystal. If you hold it up to your ear you can look like an idiot!',
		class: 'fish',
		tags: ['fishing'],
	},
	11033: {
		id: 11033,
		name: 'Ancient Fishing Rod',
		tradeable: false,
		value: 2500000,
		experience: 2500,
		itemImage: 'images/fishing/rod_master.png',
		extraTooltipInfo:
			"An ancient fishing rod, straight out of a madman's fantasy. Not much good on its own in this condition; it would take a master crafter to repair it...",
		class: 'fish',
		tags: ['fishing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Fishing',
			craftable: true,
		},
	},
	11034: {
		id: 11034,
		name: 'Ancient Harpoon',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/fishing/harpoon_master.png',
		extraTooltipInfo:
			'An ancient harpoon, probably dragged up from some ancient aquatic ruin. Not much good on its own in this condition; it would take a master crafter to repair it...',
		class: 'fish',
		tags: ['fishing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Fishing',
			craftable: true,
		},
	},
	11035: {
		id: 11035,
		name: 'Ancient Fishing Net',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/fishing/net_master.png',
		extraTooltipInfo:
			"An ancient fishing net, the material it's made out of appears to be alive. Not much good on its own in this condition; it would take a master crafter to repair it...",
		class: 'fish',
		tags: ['fishing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Fishing',
			craftable: true,
		},
	},
	11036: {
		id: 11036,
		name: 'Mystical Driftwood',
		tradeable: true,
		value: 150,
		experience: 150,
		heat: 125,
		itemImage: 'images/fishing/material/mystical_driftwood.png',
		extraTooltipInfo: 'An oddly glowing piece of driftwood. Could it still be used for something?',
		class: 'fish',
		tags: ['fishing', 'heat'],
		rarity: 'uncommon',
	},
	11037: {
		id: 11037,
		name: 'Dwarven Alloy',
		tradeable: false,
		value: 2500000,
		experience: 2500,
		itemImage: 'images/mining/dwarven_ingot.png',
		extraTooltipInfo: 'An alloy made from metals you do not recognize.',
		class: 'bar',
		tags: ['mining', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Mining',
			craftable: true,
		},
	},
	11038: {
		id: 11038,
		name: 'Dwarven Ingot',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/mining/dwarven_ingot_2.png',
		extraTooltipInfo: 'An incredibly hard dwarven ingot. It is basically impossible to work with.',
		class: 'bar',
		tags: ['mining', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Mining',
			craftable: true,
		},
	},
	11039: {
		id: 11039,
		name: 'Dwarven Mold',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/mining/dwarven_ingot_3.png',
		extraTooltipInfo: 'A sort of mold to form a tool with.',
		class: 'bar',
		tags: ['mining', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Mining',
			craftable: true,
		},
	},
	11040: {
		id: 11040,
		name: 'Very Mysterious Seedling',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/farming/mysterious_plant_44.png',
		extraTooltipInfo: 'It is looking at you.',
		class: 'bar',
		tags: ['foraging', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Foraging',
			craftable: true,
		},
	},
	11041: {
		id: 11041,
		name: 'Elder Sap',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/foraging/elder_sap.png',
		extraTooltipInfo: 'Sap taken from a tree that is much older than you are.',
		class: 'bar',
		tags: ['foraging', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Foraging',
			craftable: true,
		},
	},
	11042: {
		id: 11042,
		name: 'World Tree Branch',
		tradeable: false,
		value: 2500000,
		experience: 45000,
		itemImage: 'images/foraging/world_branch.png',
		extraTooltipInfo: 'Basically just a shiny branch!',
		class: 'bar',
		tags: ['foraging', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 90,
			experience: 45000,
			category: 'Foraging',
			craftable: true,
		},
	},
	12000: {
		name: "Fisherman's Smock",
		id: 12000,
		enchantmentTier: 3,
		forcedEnchant: 50,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'body',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
			],
			itemSet: [10001, 10002],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'fishingset',
		itemImage: '/images/fishing/equipment/smock.png',
		extraTooltipInfo: 'A smock for fishing! You can already feel it making your tools stronger.',
		class: 'equipment',
		visual: 'smock',
		tags: ['armor', 'fishing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Fishing',
			craftable: true,
			unlockQuest: 214,
		},
	},
	12001: {
		name: "Fisherman's Bucket Hat",
		id: 12001,
		enchantmentTier: 3,
		forcedEnchant: 51,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'helm',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
			],
			itemSet: [10001, 10002],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'fishingset',
		itemImage: '/images/fishing/equipment/bucket_hat.png',
		extraTooltipInfo: 'A hat for fishing! You can already feel it making your reflexes sharper.',
		class: 'equipment',
		visual: 'bucket-hat',
		tags: ['armor', 'fishing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Fishing',
			craftable: true,
			unlockQuest: 214,
		},
	},
	12002: {
		name: "Fisherman's Waders",
		id: 12002,
		enchantmentTier: 3,
		forcedEnchant: 49,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'legs',
			toolBoost: [
				{
					skill: 'fishing',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
			],
			itemSet: [10001, 10002],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'fishingset',
		itemImage: '/images/fishing/equipment/waders.png',
		extraTooltipInfo: 'Waders for fishing! You can already feel it making your ability to bait fish stronger.',
		class: 'equipment',
		visual: 'waders',
		tags: ['armor', 'fishing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Fishing',
			craftable: true,
			unlockQuest: 214,
		},
	},
	12003: {
		name: "Miner's Smock",
		id: 12003,
		enchantmentTier: 3,
		forcedEnchant: 68,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'body',
			toolBoost: [
				{
					skill: 'mining',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
			],
			itemSet: [10007, 10008],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'miningset',
		itemImage: '/images/fishing/equipment/smock.png',
		extraTooltipInfo: 'A smock to rub the coal dust on after a long day in the mines.',
		class: 'equipment',
		visual: 'smock',
		tags: ['armor', 'mining', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Mining',
			craftable: true,
			unlockQuest: 210,
		},
	},
	12004: {
		name: "Miner's Lantern Helmet",
		id: 12004,
		enchantmentTier: 3,
		forcedEnchant: 83,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'helm',
			toolBoost: [
				{
					skill: 'mining',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
			],
			itemSet: [10007, 10008],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'miningset',
		itemImage: '/images/fishing/equipment/bucket_hat.png',
		extraTooltipInfo: "OSHA approved! Let's you see better! Wow!",
		class: 'equipment',
		visual: 'bucket-hat',
		tags: ['armor', 'mining', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Mining',
			craftable: true,
			unlockQuest: 210,
		},
	},
	12005: {
		name: "Miner's Trousers",
		id: 12005,
		enchantmentTier: 3,
		forcedEnchant: 67,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'legs',
			toolBoost: [
				{
					skill: 'mining',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
			],
			itemSet: [10007, 10008],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'miningset',
		itemImage: '/images/fishing/equipment/waders.png',
		extraTooltipInfo:
			'A pair of trousers that you swear definitely makes you better at mining despite being a simple pair of pants. Are they magical? Probably not. Probably.',
		class: 'equipment',
		visual: 'waders',
		tags: ['armor', 'mining', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Mining',
			craftable: true,
			unlockQuest: 210,
		},
	},
	12006: {
		name: "Lumberjack's Vest",
		id: 12006,
		enchantmentTier: 3,
		forcedEnchant: 35,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'body',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
			],
			itemSet: [10005, 10006],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'foragingset',
		itemImage: '/images/foraging/Foraging_Top.png',
		extraTooltipInfo: 'Makes you want to shout timber.',
		class: 'equipment',
		visual: 'smock',
		tags: ['armor', 'foraging', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Foraging',
			craftable: true,
			unlockQuest: 212,
		},
	},
	12007: {
		name: "Lumberjack's Hat",
		id: 12007,
		enchantmentTier: 3,
		forcedEnchant: 55,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'helm',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
			],
			itemSet: [10005, 10006],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'foragingset',
		itemImage: '/images/foraging/Foraging_hat.png',
		extraTooltipInfo: 'If a tree falls on your head this will not help, but it can keep you warm!',
		class: 'equipment',
		visual: 'lumberjack-hat',
		tags: ['armor', 'foraging', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Foraging',
			craftable: true,
			unlockQuest: 212,
		},
	},
	12008: {
		name: "Lumberjack's Trousers",
		id: 12008,
		enchantmentTier: 3,
		forcedEnchant: 56,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'legs',
			toolBoost: [
				{
					skill: 'foraging',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
			],
			itemSet: [10005, 10006],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'foragingset',
		itemImage: '/images/foraging/Foraging_legs.png',
		extraTooltipInfo: "You're a lumberjack and you're okay!",
		class: 'equipment',
		visual: 'lumberjack-legs',
		tags: ['armor', 'foraging', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Foraging',
			craftable: true,
			unlockQuest: 212,
		},
	},
	12009: {
		id: 12009,
		name: 'Fishing Shard',
		tradeable: false,
		itemImage: '/images/fishing/fishing_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'fishing'],
		rarity: 'uncommon',
	},
	12010: {
		id: 12010,
		name: 'Mining Shard',
		tradeable: false,
		itemImage: '/images/mining/mining_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'mining'],
		rarity: 'uncommon',
	},
	12011: {
		id: 12011,
		name: 'Foraging Shard',
		tradeable: false,
		itemImage: '/images/foraging/foraging_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'foraging'],
		rarity: 'uncommon',
	},
	12012: {
		id: 12012,
		name: 'Prismatic Dust',
		tradeable: false,
		itemImage: '/images/magic/pris_dust.png',
		extraTooltipInfo:
			'A fine dust that radiates a prismatic luster; formed when too much power is imbued into a Black Opal',
		class: 'gem',
		tags: ['enchanting'],
		rarity: 'legendary',
	},
	12013: {
		name: "Chef's Hat",
		id: 12013,
		enchantmentTier: 3,
		equipmentStats: {
			slot: 'helm',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 5,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 1,
				},
			],
			itemSet: [10025, 10026],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'cookingset',
		itemImage: '/images/cooking/equipment/cooking_hat.png',
		extraTooltipInfo:
			'Kinda smells like your childhood meals. They must have really sucked since this hat came from a Goblin.',
		class: 'equipment',
		visual: 'chef-hat',
		tags: ['armor', 'cooking'],
		rarity: 'rare',
	},
	12014: {
		id: 12014,
		name: 'Infernal Dust',
		value: 100,
		tradeable: true,
		itemImage: '/images/magic/infernal_dust.png',
		extraTooltipInfo:
			'A fine dust that is fueled by some inner flame. Formed when too much power is imbued into Obsidian.',
		class: 'gem',
		tags: ['enchanting'],
		rarity: 'rare',
	},
	12015: {
		name: 'Golden Spoon',
		id: 12015,
		enchantmentTier: 8,
		requiredLevel: {
			cooking: 80,
		},
		value: 12960000,
		tradeable: false,
		enchantmentOverrideSlot: 'ladle',
		itemImage: '/images/cooking/golden_spoon.png',
		extraTooltipInfo:
			"It's a golden spoon! Rewards full XP when burning food and grants 4 hidden levels of haste while cooking.",
		class: 'equipment',
		tags: ['cooking', 'craftable'],
		equipmentStats: {
			slot: 'ladle',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 60,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 4,
				},
			],
			itemSet: [10025, 10026],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 250000,
			category: 'Skilling',
			craftable: true,
			description:
				"It's a golden spoon! Rewards full XP when burning food and grants 4 hidden levels of haste while cooking.",
			unlockQuest: 221,
		},
	},
	12016: {
		id: 12016,
		name: 'Cooking Shard',
		tradeable: false,
		itemImage: '/images/cooking/cooking_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'cooking'],
		rarity: 'uncommon',
	},
	12017: {
		id: 12017,
		name: 'Runecrafting Shard',
		tradeable: false,
		itemImage: '/images/runecrafting/runecrafting_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'runecrafting'],
		rarity: 'uncommon',
	},
	12018: {
		name: 'Camo Cloak',
		id: 12018,
		enchantmentTier: 1,
		value: 1000000,
		tradeable: true,
		requiredLevel: {
			enchanting: 40,
		},
		forcedEnchant: 9501,
		forcedEnchantAmount: 1,
		itemImage: '/images/combat/equipment/camo_cape.PNG',
		itemIcon: '/images/combat/equipment/camo_cape.PNG',
		specialVisuals: [
			{
				image: '/images/combat/equipment/camo_cape_back.png',
				css: 'camo-back',
			},
			{
				image: '/images/combat/equipment/camo_cape_front.png',
				css: 'camo-front',
			},
		],
		extraTooltipInfo:
			'A cloak with camo elements to help you avoid fights in dangerous places. Increases your combat ability in dangerous gathering encounters and gathering dungeons.',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'mining', 'foraging', 'fishing', 'craftable'],
		equipmentStats: {
			slot: 'cape',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 0.5,
				},
				{
					stat: 'toolBoost.foraging',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishing',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 0.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 20250,
			category: 'Skilling',
			craftable: true,
		},
	},
	12019: {
		name: 'Flamboyant Cape',
		id: 12019,
		enchantmentTier: 1,
		value: 1000000,
		tradeable: true,
		requiredLevel: {
			enchanting: 40,
		},
		forcedEnchant: 9500,
		forcedEnchantAmount: 1,
		itemImage: '/images/combat/equipment/flamboyant_cape.png',
		extraTooltipInfo:
			'A bright, flamboyant cape designed to catch the eye of monsters. Increases your combat ability in dangerous gathering encounters and gathering dungeons.',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'mining', 'foraging', 'fishing', 'craftable'],
		equipmentStats: {
			slot: 'cape',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 0.5,
				},
				{
					stat: 'toolBoost.foraging',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishing',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 0.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 20250,
			category: 'Skilling',
			craftable: true,
		},
	},
	12020: {
		name: 'Small Bag of Holding',
		id: 12020,
		enchantmentTier: 1,
		value: 10000,
		tradeable: true,
		requiredLevel: {
			enchanting: 25,
		},
		forcedEnchant: 80,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/bag_1.png',
		extraTooltipInfo:
			'A small bag that possesses some magic to help you gather all that you can from a resource. Will automatically be upgraded to the next bag of holding tier at +5 augment levels.',
		class: 'equipment',
		tags: ['armor', 'mining', 'foraging', 'fishing', 'craftable'],
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 0.25,
				},
				{
					stat: 'toolBoost.foraging',
					value: 0.25,
				},
				{
					stat: 'toolBoost.fishing',
					value: 0.25,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 0.25,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 0.25,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 0.25,
				},
			],
		},
		rarity: 'uncommon',
		craftingStats: {
			level: 20,
			experience: 3000,
			category: 'Skilling',
			craftable: true,
		},
	},
	12021: {
		name: 'Bag of Holding',
		id: 12021,
		enchantmentTier: 2,
		value: 100000,
		tradeable: true,
		requiredLevel: {
			enchanting: 50,
		},
		forcedEnchant: 80,
		forcedEnchantAmount: 2,
		itemImage: '/images/jewellery/bag_2.png',
		extraTooltipInfo:
			'A bag that possesses some magic to help you gather all that you can from a resource. Will automatically be upgraded to the next bag of holding tier at +10 augment levels.',
		class: 'equipment',
		tags: ['armor', 'mining', 'foraging', 'fishing'],
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 0.5,
				},
				{
					stat: 'toolBoost.foraging',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishing',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 0.5,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 0.5,
				},
			],
			toolBoost: [
				{
					skill: 'mining',
					boost: 2.5,
				},
				{
					skill: 'foraging',
					boost: 2.5,
				},
				{
					skill: 'fishing',
					boost: 2.5,
				},
				{
					skill: 'fishingBaitPower',
					boost: 2.5,
				},
				{
					skill: 'fishingRarityPower',
					boost: 2.5,
				},
				{
					skill: 'fishingReelPower',
					boost: 2.5,
				},
			],
		},
		rarity: 'rare',
	},
	12022: {
		name: 'Large Bag of Holding',
		id: 12022,
		enchantmentTier: 3,
		value: 1000000,
		tradeable: true,
		requiredLevel: {
			enchanting: 75,
		},
		forcedEnchant: 80,
		forcedEnchantAmount: 3,
		itemImage: '/images/jewellery/bag_3.png',
		extraTooltipInfo: 'A large bag that possesses some magic to help you gather all that you can from a resource.',
		class: 'equipment',
		tags: ['armor', 'mining', 'foraging', 'fishing'],
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
			],
			toolBoost: [
				{
					skill: 'mining',
					boost: 10,
				},
				{
					skill: 'foraging',
					boost: 10,
				},
				{
					skill: 'fishing',
					boost: 10,
				},
				{
					skill: 'fishingBaitPower',
					boost: 10,
				},
				{
					skill: 'fishingRarityPower',
					boost: 10,
				},
				{
					skill: 'fishingReelPower',
					boost: 10,
				},
			],
		},
		rarity: 'epic',
	},
	12023: {
		name: "Newbie Adventurer's Backpack",
		id: 12023,
		enchantmentTier: 1,
		value: 10000,
		tradeable: true,
		requiredLevel: {
			enchanting: 35,
		},
		forcedEnchant: 79,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/adventurer_1.png',
		extraTooltipInfo:
			'A backpack with all the goodies and supplies necessary to help an adventurer get more research done when gathering in the field. May require some adjustment by a new owner before becoming comfortable to wear!',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'mining', 'foraging', 'fishing', 'farming', 'runecrafting', 'smithing', 'cooking', 'craftable'],
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 1,
				},
				{
					stat: 'toolBoost.foraging',
					value: 1,
				},
				{
					stat: 'toolBoost.fishing',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 1,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 1,
				},
			],
			toolBoost: [
				{
					skill: 'mining',
					boost: -10,
				},
				{
					skill: 'foraging',
					boost: -10,
				},
				{
					skill: 'fishing',
					boost: -10,
				},
				{
					skill: 'fishingBaitPower',
					boost: -10,
				},
				{
					skill: 'fishingRarityPower',
					boost: -10,
				},
				{
					skill: 'fishingReelPower',
					boost: -10,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 35,
			experience: 3000,
			category: 'Skilling',
			craftable: true,
		},
	},
	12024: {
		name: "Dwarven Adventurer's Backpack",
		id: 12024,
		enchantmentTier: 2,
		value: 1000000,
		tradeable: true,
		requiredLevel: {
			enchanting: 90,
		},
		forcedEnchant: 79,
		forcedEnchantAmount: 2,
		itemImage: '/images/jewellery/adventurer_2.png',
		extraTooltipInfo:
			'A redesigned backpack drawing from dwarven research to massively increase research capabilities when gathering out in the field. May require some significant adjustment by a new owner before becoming comfortable to wear!',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'mining', 'foraging', 'fishing', 'farming', 'runecrafting', 'smithing', 'cooking', 'craftable'],
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.mining',
					value: 2,
				},
				{
					stat: 'toolBoost.foraging',
					value: 2,
				},
				{
					stat: 'toolBoost.fishing',
					value: 2,
				},
				{
					stat: 'toolBoost.fishingBaitPower',
					value: 2,
				},
				{
					stat: 'toolBoost.fishingRarityPower',
					value: 2,
				},
				{
					stat: 'toolBoost.fishingReelPower',
					value: 2,
				},
			],
			toolBoost: [
				{
					skill: 'mining',
					boost: -20,
				},
				{
					skill: 'foraging',
					boost: -20,
				},
				{
					skill: 'fishing',
					boost: -20,
				},
				{
					skill: 'fishingBaitPower',
					boost: -20,
				},
				{
					skill: 'fishingRarityPower',
					boost: -20,
				},
				{
					skill: 'fishingReelPower',
					boost: -20,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 85,
			experience: 121000,
			category: 'Skilling',
			craftable: true,
		},
	},
	12025: {
		name: 'Cloak of Many Pockets',
		id: 12025,
		enchantmentTier: 1,
		value: 1000000,
		tradeable: true,
		requiredLevel: {
			enchanting: 60,
		},
		forcedEnchant: 76,
		forcedEnchantAmount: 1,
		itemImage: '/images/combat/equipment/many_pockets.png',
		extraTooltipInfo: 'A cloak with many pockets to keep you more organized while processing materials.',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'smithing', 'runecrafting', 'cooking', 'craftable'],
		equipmentStats: {
			slot: 'cape',
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 0.5,
				},
				{
					stat: 'toolBoost.runecrafting',
					value: 0.5,
				},
				{
					stat: 'toolBoost.cooking',
					value: 0.5,
				},
			],
		},
		rarity: 'rare',
		craftingStats: {
			level: 60,
			experience: 20250,
			category: 'Skilling',
			craftable: true,
		},
	},
	12026: {
		name: 'Cloak of the Void',
		id: 12026,
		enchantmentTier: 2,
		value: 2000000,
		tradeable: true,
		requiredLevel: {
			enchanting: 85,
		},
		forcedEnchant: 76,
		forcedEnchantAmount: 2,
		itemImage: '/images/combat/equipment/void_cape.png',
		extraTooltipInfo:
			'A cloak with access to a small pocket dimension for storage. Greatly increases your ability to stay organized and efficient while processing materials.',
		class: 'equipment',
		visual: 'cape',
		tags: ['armor', 'smithing', 'runecrafting', 'cooking', 'craftable'],
		equipmentStats: {
			slot: 'cape',
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1,
				},
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
				{
					stat: 'toolBoost.cooking',
					value: 1,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 85,
			experience: 120250,
			category: 'Skilling',
			craftable: true,
		},
	},
	12027: {
		id: 12027,
		name: 'Dwarven Manufacturing Pendant',
		value: 200000,
		tradeable: true,
		enchantmentTier: 1,
		forcedEnchant: 84,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/engineering.png',
		class: 'equipment',
		extraTooltipInfo: 'Empowers you to hastily process materials and items.',
		tags: ['smithing', 'runecrafting', 'cooking'],
		requiredLevel: {
			crafting: 50,
		},
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1,
				},
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
				{
					stat: 'toolBoost.cooking',
					value: 1,
				},
			],
		},
		rarity: 'rare',
	},
	12028: {
		id: 12028,
		name: 'Elven Logistics Pendant',
		value: 200000,
		tradeable: true,
		enchantmentTier: 1,
		forcedEnchant: 85,
		forcedEnchantAmount: 1,
		itemImage: '/images/jewellery/logistics.PNG',
		class: 'equipment',
		extraTooltipInfo: 'Empowers you to efficiently process materials and items.',
		tags: ['smithing', 'runecrafting', 'cooking'],
		requiredLevel: {
			crafting: 50,
		},
		equipmentStats: {
			slot: 'arrows',
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1,
				},
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
				{
					stat: 'toolBoost.cooking',
					value: 1,
				},
			],
		},
		rarity: 'rare',
	},
	12029: {
		id: 12029,
		name: 'Farming Shard',
		tradeable: false,
		itemImage: '/images/farming/farming_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'cooking'],
		rarity: 'uncommon',
	},
	12030: {
		id: 12030,
		name: 'Dwarven Tongs',
		value: 13782100,
		tradeable: false,
		enchantmentTier: 8,
		requiredLevel: {
			smithing: 80,
		},
		itemImage: '/images/smithing/dwarven_tongs.png',
		class: 'equipment',
		extraTooltipInfo: 'A legendary set of tongs made from lost dwarven technology.',
		tags: ['smithing', 'craftable'],
		equipmentStats: {
			slot: 'tongs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 90,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 5,
				},
			],
			itemSet: [10023, 10024],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 250000,
			category: 'Skilling',
			craftable: true,
			description: 'A legendary set of tongs made from lost dwarven technology.',
			unlockQuest: 219,
		},
	},
	12031: {
		id: 12031,
		name: 'Smithing Shard',
		tradeable: false,
		itemImage: '/images/smithing/smithing_shard.png',
		extraTooltipInfo: 'A shard for creating skilling gear.',
		class: 'gem',
		tags: ['shard', 'smithing'],
		rarity: 'uncommon',
	},
	12032: {
		id: 12032,
		name: 'Elvish Hoe',
		value: 13782100,
		tradeable: false,
		enchantmentTier: 8,
		requiredLevel: {
			farming: 80,
		},
		itemImage: '/images/farming/elven_hoe.png',
		class: 'equipment',
		extraTooltipInfo: 'A legendary farming implement purportedly used by the Elves. Why does it need a diamond?',
		tags: ['farming', 'craftable'],
		equipmentStats: {
			slot: 'hoe',
			toolBoost: [
				{
					skill: 'farming',
					boost: 90,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.farming',
					value: 5,
				},
			],
		},
		rarity: 'legendary',
		craftingStats: {
			level: 90,
			experience: 508000,
			category: 'Farming',
			craftable: true,
			description: 'A legendary farming implement purportedly used by the Elves. Why does it need a diamond?',
			unlockQuest: 222,
		},
	},
	12033: {
		id: 12033,
		name: 'Void Portal Anchor',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/void_anchor.png',
		extraTooltipInfo: 'An anchor point for a void portal that can be used to create legendary equipment.',
		class: 'gem',
		blockResearching: true,
		tags: ['crafting'],
		rarity: 'legendary',
	},
	12034: {
		id: 12034,
		name: 'Defunct Golem Core',
		value: 1500000,
		tradeable: true,
		itemImage: '/images/misc/golem_core.png',
		extraTooltipInfo: 'Ancient dwarven tech that could be repurposed into a legendary piece of equipment.',
		class: 'bar',
		blockResearching: true,
		tags: ['crafting'],
		rarity: 'legendary',
	},
	12035: {
		id: 12035,
		name: 'Lava Heart',
		value: 150000,
		tradeable: true,
		itemImage: '/images/misc/lava_heart.png',
		extraTooltipInfo:
			'A mechanical heart that pumps molten stone instead of blood. With the right equipment it could enhance your Smithing capabilities.',
		class: 'bar',
		blockResearching: true,
		tags: ['crafting', 'smithing'],
		rarity: 'rare',
	},
	12036: {
		id: 12036,
		name: 'Bleached Cloth',
		value: 150000,
		tradeable: true,
		itemImage: '/images/misc/bleached_cloth.png',
		extraTooltipInfo:
			'A bolt of cloth bleached white by time that possesses a hint of intrinsic magical power. It appears to have absorbed some forgotten Cooking techniques in this Library. Knowledge Osmosis!',
		class: 'cloth',
		blockResearching: true,
		tags: ['crafting', 'cooking'],
		rarity: 'rare',
	},
	12037: {
		id: 12037,
		name: 'Academy Wards',
		value: 150000,
		tradeable: true,
		itemImage: '/images/misc/academy_wards.png',
		extraTooltipInfo:
			'Various totems and curios that were used to contain magical energy. They could be repurposed to help channel magic while Runecrafting.',
		class: 'cloth',
		blockResearching: true,
		tags: ['crafting', 'runecrafting'],
		rarity: 'rare',
	},
	12038: {
		name: "Smith's Golem Torso",
		id: 12038,
		enchantmentTier: 3,
		forcedEnchant: 90,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'body',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1,
				},
			],
			itemSet: [10023, 10024],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'smithingset',
		itemImage: '/images/foraging/Foraging_Top.png',
		extraTooltipInfo: 'A stony torso powered by lava.',
		class: 'equipment',
		visual: 'smock',
		tags: ['armor', 'smithing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Skilling',
			craftable: true,
			unlockQuest: 218,
		},
	},
	12039: {
		name: "Smith's Golem Legs",
		id: 12039,
		enchantmentTier: 3,
		forcedEnchant: 91,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'legs',
			toolBoost: [
				{
					skill: 'smithing',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.smithing',
					value: 1,
				},
			],
			itemSet: [10023, 10024],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'smithingset',
		itemImage: '/images/foraging/Foraging_legs.png',
		extraTooltipInfo: 'A stony set of legs powered by lava.',
		class: 'equipment',
		visual: 'lumberjack-legs',
		tags: ['armor', 'smithing', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Skilling',
			craftable: true,
			unlockQuest: 218,
		},
	},
	12040: {
		name: "Chef's Coat",
		id: 12040,
		enchantmentTier: 3,
		forcedEnchant: 97,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'body',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 1,
				},
			],
			itemSet: [10025, 10026],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'cookingset',
		itemImage: '/images/cooking/equipment/cooking_body.png',
		extraTooltipInfo: 'Signifies your prestige and mastery of Cooking.',
		class: 'equipment',
		visual: 'chef-body',
		tags: ['armor', 'cooking', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Skilling',
			craftable: true,
			unlockQuest: 220,
		},
	},
	12041: {
		name: "Chef's Pants",
		id: 12041,
		enchantmentTier: 3,
		forcedEnchant: 95,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'legs',
			toolBoost: [
				{
					skill: 'cooking',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.cooking',
					value: 1,
				},
			],
			itemSet: [10025, 10026],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'cookingset',
		itemImage: '/images/cooking/equipment/cooking_legs.png',
		extraTooltipInfo: 'Every chef needs a comfortable pair of pants!',
		class: 'equipment',
		visual: 'chef-legs',
		tags: ['armor', 'cooking', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Skilling',
			craftable: true,
			unlockQuest: 220,
		},
	},
	12042: {
		name: "Runecrafter's Robe Top",
		id: 12042,
		enchantmentTier: 3,
		forcedEnchant: 81,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'body',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
			],
			itemSet: [10027, 10028],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'runecraftingset',
		itemImage: '/images/fishing/equipment/smock.png',
		extraTooltipInfo: 'A robe top that can help you channel and shape magic into slates and fibers.',
		class: 'equipment',
		visual: 'smock',
		tags: ['armor', 'runecrafting', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 216,
		},
	},
	12043: {
		name: "Runecrafter's Robe Hood",
		id: 12043,
		enchantmentTier: 3,
		forcedEnchant: 27,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'helm',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
			],
			itemSet: [10027, 10028],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'runecraftingset',
		itemImage: '/images/fishing/equipment/bucket_hat.png',
		extraTooltipInfo: 'A hood that can help you channel and shape magic into slates and fibers.',
		class: 'equipment',
		visual: 'bucket-hat',
		tags: ['armor', 'runecrafting', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 216,
		},
	},
	12044: {
		name: "Runecrafter's Robe Bottoms",
		id: 12044,
		enchantmentTier: 3,
		forcedEnchant: 92,
		forcedEnchantAmount: 3,
		equipmentStats: {
			slot: 'legs',
			toolBoost: [
				{
					skill: 'runecrafting',
					boost: 0,
				},
			],
			augmentationBonus: [
				{
					stat: 'toolBoost.runecrafting',
					value: 1,
				},
			],
			itemSet: [10027, 10028],
		},
		value: 250000,
		tradeable: true,
		enchantmentOverrideSlot: 'runecraftingset',
		itemImage: '/images/fishing/equipment/waders.png',
		extraTooltipInfo: 'A robe bottom that can help you channel and shape magic into slates and fibers.',
		class: 'equipment',
		visual: 'waders',
		tags: ['armor', 'runecrafting', 'craftable'],
		rarity: 'rare',
		craftingStats: {
			level: 50,
			experience: 22500,
			category: 'Runecrafting',
			craftable: true,
			unlockQuest: 216,
		},
	},
	13000: {
		id: 13000,
		name: 'Combat Manual - Volume 1',
		itemImage: '/images/combat/ability_book/melee.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			"A manual on how to take advantage of your weapon's edge. Apparently using the sharp part means it will hurt something more, who could have known?",
		relatedAbility: 4,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13001: {
		id: 13001,
		name: 'Combat Manual - Volume 2',
		itemImage: '/images/combat/ability_book/melee.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A manual on how to take poke things really good. If the weapon has a decent tip, you can stab things! Wow!',
		relatedAbility: 5,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13002: {
		id: 13002,
		name: 'Combat Manual - Volume 3',
		itemImage: '/images/combat/ability_book/melee.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A manual on how to just hit things hard. Crushing things is deceptively simple and to truly pummel your enemy you must understand that being blunt is sometimes the only way to go.',
		relatedAbility: 6,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13003: {
		id: 13003,
		name: 'Spell Tome - Fire Ball',
		itemImage: '/images/combat/ability_book/red.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A basic tome from the Academy formerly used for teaching aspiring mages how to control and utilize fire. Be careful where you practice it!',
		relatedAbility: 7,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13004: {
		id: 13004,
		name: 'Spell Tome - Flamethrower',
		itemImage: '/images/combat/ability_book/red.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'An intermediate tome from the Academy formerly used for teaching mages how to utilize flames in a more lively manner.',
		relatedAbility: 18,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13005: {
		id: 13005,
		name: 'Ballistics 101',
		itemImage: '/images/combat/ability_book/range.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A textbook that explains the basic principles regarding how something that goes up usually comes back down.',
		relatedAbility: 12,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13006: {
		id: 13006,
		name: 'Projectile Based Diplomacy - I',
		itemImage: '/images/combat/ability_book/range.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A book that outline the merits of using projectiles, preferably several at once, to really get your point across.',
		relatedAbility: 21,
		class: 'gem',
		tags: ['book', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13007: {
		id: 13007,
		name: 'Projectile Based Diplomacy - II',
		itemImage: '/images/combat/ability_book/range.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A book that outline the merits of using projectiles, even just a single well placed one, to really get your point across.',
		relatedAbility: 23,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'uncommon',
		overrideItemTier: 4,
	},
	13008: {
		id: 13008,
		name: 'Projectile Based Diplomacy - III',
		itemImage: '/images/combat/ability_book/range.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A book that outline the merits of using projectiles, especially when trying to distract and divert, to really get your point across.',
		relatedAbility: 24,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13009: {
		id: 13009,
		name: 'Apocalyptic Tome',
		itemImage: '/images/combat/ability_book/highmagic.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A spell tome that contains the necessary rituals and rites for summoning your own miniature apocalypse.',
		relatedAbility: 25,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'legendary',
		overrideItemTier: 8,
	},
	13010: {
		id: 13010,
		name: 'Intermediate Combat Manual - Volume 1',
		itemImage: '/images/combat/ability_book/melee.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'A manual on how to hit two enemies at once in one swing.',
		relatedAbility: 22,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'uncommon',
		overrideItemTier: 4,
	},
	13011: {
		id: 13011,
		name: 'City Decree',
		itemImage: '/images/combat/ability_book/city_decree.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'Grants the owner the ability to summon City Guards as reinforcements in combat. You should probably feel a little guilty if you bring them into a fight they cannot survive.',
		relatedAbility: 48,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13012: {
		id: 13012,
		name: 'How To - First Aid',
		itemImage: '/images/combat/ability_book/green.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'Teaches the fundamentals of mid-combat first aid rending.',
		relatedAbility: 47,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13013: {
		id: 13013,
		name: 'Defending the Weak - An Excerpt',
		itemImage: '/images/combat/ability_book/shield.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A few pages that explain that you can actually use your shield to defend others instead of just yourself.',
		relatedAbility: 49,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'uncommon',
		overrideItemTier: 4,
	},
	13014: {
		id: 13014,
		name: 'Combat Manual - Volume 4',
		itemImage: '/images/combat/ability_book/melee.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A manual on stabbing through one enemy and into the next one. A battle cry is optional but recommended!',
		relatedAbility: 66,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		overrideItemTier: 3,
	},
	13015: {
		id: 13015,
		name: 'Attention Getting Tactics',
		itemImage: '/images/combat/ability_book/long_scroll.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			"It's a long scroll that explains in detail how awful you are. It gives you ideas on what to say to other people! Very healthy.",
		relatedAbility: 54,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'uncommon',
		overrideItemTier: 4,
	},
	13016: {
		id: 13016,
		name: 'Smoke and Mirrors',
		itemImage: '/images/combat/ability_book/magic.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'How to use the deceit and chaos to your aid on the battlefield.',
		relatedAbility: 57,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13017: {
		id: 13017,
		name: 'All Ghillied Up',
		itemImage: '/images/combat/ability_book/green_2.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'Sticks, grass, leaves, and other foliage can make a proper bush out of you!',
		relatedAbility: 58,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13018: {
		id: 13018,
		name: 'Ice, Ice, Ice',
		itemImage: '/images/combat/ability_book/magic.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'ICE!',
		relatedAbility: 59,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'epic',
		overrideItemTier: 6,
	},
	13019: {
		id: 13019,
		name: "Trapper's Guide",
		itemImage: '/images/combat/ability_book/range.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'Setting traps is just one of the many ways you may shape the battlefield.',
		relatedAbility: 60,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'uncommon',
		overrideItemTier: 4,
	},
	13020: {
		id: 13020,
		name: 'Spell Tome - Ice Shard',
		itemImage: '/images/combat/ability_book/magic.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A basic tome from the Academy formerly used for teaching aspiring mages how to control and utilize ice.',
		relatedAbility: 67,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'common',
		overrideItemTier: 3,
	},
	13021: {
		id: 13021,
		name: 'Notes on Acid - I',
		itemImage: '/images/combat/ability_book/scroll.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'Notes taken while engaging enemies that could use acid in combat. Perhaps there is a way to mimic this ability?',
		relatedAbility: 76,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13022: {
		id: 13022,
		name: 'Notes on Acid - II',
		itemImage: '/images/combat/ability_book/scroll.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'Notes taken while engaging enemies that could use acid in combat. Perhaps there is a way to mimic this ability?',
		relatedAbility: 77,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13023: {
		id: 13023,
		name: 'Armor and its Weaknesses',
		itemImage: '/images/combat/ability_book/shield.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'Notes on the various types of armor and how smarter monsters react to them. Perhaps there is something to learn here?',
		relatedAbility: 78,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13024: {
		id: 13024,
		name: 'Cultist Notes I',
		itemImage: '/images/combat/ability_book/scroll.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo: 'The ravings of a madman, outlining the necessary ritual to cast some sort of spell.',
		relatedAbility: 127,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'rare',
		overrideItemTier: 5,
	},
	13025: {
		id: 13025,
		name: 'Cultist Notes II',
		itemImage: '/images/combat/ability_book/scroll.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'The ravings of a madman, outlining the necessary ritual to cast some sort of spell. This time it is written in blood!',
		relatedAbility: 128,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'epic',
		overrideItemTier: 6,
	},
	13026: {
		id: 13026,
		name: 'Enlightened Parchment',
		itemImage: '/images/combat/ability_book/enlightened.png',
		value: 500000,
		heat: 25000,
		extraTooltipInfo:
			'A mostly nonsensical rant that claims to know the truth of all things. It does present some potential magical implications...',
		relatedAbility: 166,
		class: 'gem',
		tags: ['book', 'heat', 'ability'],
		researchesIntoDust: true,
		blockAugmenting: true,
		rarity: 'legendary',
		overrideItemTier: 7,
	},
	15000: {
		id: 15000,
		name: "Slayer's Mark",
		tradeable: false,
		itemImage: '/images/misc/slayertoken.png',
		heat: 1,
		extraTooltipInfo: 'A type of trophy taken from a slain foe.',
		class: 'gem',
		tags: ['heat'],
	},
	15001: {
		id: 15001,
		name: 'Token of Ownership',
		tradeable: false,
		itemImage: '/images/misc/itemtoken.png',
		heat: 1,
		extraTooltipInfo: 'A token representing that some item was obtained.',
		class: 'gem',
		tags: ['heat'],
	},
	15002: {
		id: 15002,
		name: 'Token of Action',
		tradeable: false,
		itemImage: '/images/misc/locationtoken.png',
		heat: 1,
		extraTooltipInfo: 'A token representing that some action was completed.',
		class: 'gem',
		tags: ['heat'],
	},
	15003: {
		id: 15003,
		name: 'Talent Respec Token',
		tradeable: false,
		itemImage: '/images/misc/talent_token.png',
		extraTooltipInfo: 'Allows a full respec of your talent tree. Consumed on use!',
		class: 'platinum',
		tags: ['token'],
		rarity: 'legendary',
	},
	25000: {
		id: 25000,
		name: 'Monster Tooth',
		itemImage: '/images/combat/materials/junk/tooth.png',
		value: 150,
		extraTooltipInfo: 'A tooth taken from a slain monster; might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25001: {
		id: 25001,
		name: 'Monster Horn',
		itemImage: '/images/combat/materials/junk/horn.png',
		value: 250,
		extraTooltipInfo: 'A horn taken from a slain monster; might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25002: {
		id: 25002,
		name: 'Sturdy Horn',
		itemImage: '/images/combat/materials/junk/horn_2.png',
		value: 500,
		extraTooltipInfo: 'A sturdy horn taken from a slain monster; might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25003: {
		id: 25003,
		name: 'Old Coins',
		itemImage: '/images/combat/materials/junk/old_coins.png',
		value: 1000,
		extraTooltipInfo: 'Some old coins. Might be worth something to a collector',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25004: {
		id: 25004,
		name: 'Primitive Coin',
		itemImage: '/images/combat/materials/junk/primitive_coin.png',
		value: 2500,
		extraTooltipInfo: 'A rather heavy, yet primitive, gold coin. This could easily be sold for a tidy sum.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25005: {
		id: 25005,
		name: 'Undead Token',
		itemImage: '/images/combat/materials/junk/undead_token.png',
		value: 5000,
		extraTooltipInfo: 'Some sort of token taken from the undead. Someone might be interested in it.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25006: {
		id: 25006,
		name: 'Woodland Trinket',
		itemImage: '/images/combat/materials/junk/woodland_trinket.png',
		value: 5000,
		extraTooltipInfo: 'A trinket taken from the Elves; might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25007: {
		id: 25007,
		name: 'Intact Wing',
		itemImage: '/images/combat/materials/junk/wing.png',
		value: 5000,
		extraTooltipInfo: 'A mostly intact wing. Someone might be interested in it.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25008: {
		id: 25008,
		name: 'Ornate Trinket',
		itemImage: '/images/combat/materials/junk/ornate_trinket.png',
		value: 15000,
		extraTooltipInfo: 'A rather ornate trinket; it should be worth a bit.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25009: {
		id: 25009,
		name: 'Useless Metal Scraps',
		itemImage: '/images/combat/materials/junk/metal_scraps.png',
		value: 100,
		extraTooltipInfo:
			'Useless metal scraps made from some poor quality material. Could probably be sold to someone for cheap.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25010: {
		id: 25010,
		name: 'Exotic Trinket',
		itemImage: '/images/combat/materials/junk/exotic_trinket.png',
		value: 25000,
		extraTooltipInfo:
			'An exotic trinket from some unknown origin. Should be worth quite a bit to the right person.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25011: {
		id: 25011,
		name: 'Demonic Statue',
		itemImage: '/images/combat/materials/junk/demonic_statue.png',
		value: 35000,
		extraTooltipInfo:
			'A statue made from some sort of gem-like material depicting a small demon. May be worth quite a bit if you can find a buyer.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25012: {
		id: 25012,
		name: 'Monster Claws',
		itemImage: '/images/combat/materials/junk/claws.png',
		value: 100,
		extraTooltipInfo: 'Claws taken from a slain monster; might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25013: {
		id: 25013,
		name: 'Bottled Blood',
		itemImage: '/images/combat/materials/junk/blood.png',
		value: 10000,
		extraTooltipInfo: 'Slightly magical blood taken from something. May be valuable to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25014: {
		id: 25014,
		name: 'Broken Armaments',
		itemImage: '/images/combat/materials/junk/broken_sword.png',
		value: 1000,
		extraTooltipInfo:
			'Shattered pieces of equipment that probably is not worth salvaging by you; perhaps someone else may want to buy it.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25015: {
		id: 25015,
		name: 'Creature Eggs',
		itemImage: '/images/combat/materials/junk/eggs.png',
		value: 3000,
		extraTooltipInfo: 'Eggs from some creature. Probably best to get rid of this quickly.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25016: {
		id: 25016,
		name: 'Dwarven Coins',
		itemImage: '/images/combat/materials/junk/dwarven_coins.png',
		value: 7500,
		extraTooltipInfo: 'Shiny dwarven coins that are of no use to you. A vendor could exchange the currency.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25017: {
		id: 25017,
		name: 'Bone Shards',
		itemImage: '/images/combat/materials/junk/bone_shards.png',
		value: 350,
		extraTooltipInfo: 'Splinters of bone taken from a slain creature. Might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25018: {
		id: 25018,
		name: 'Giant Heart',
		itemImage: '/images/combat/materials/junk/giant_heart.png',
		value: 17500,
		extraTooltipInfo:
			'A giant heart, probably taken from a giant or similarly large creature. Superstition suggests making a meal with it will increase your attractiveness, but sadly that may be a wasted effort on you.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25019: {
		id: 25019,
		name: 'Ancient Armor Scraps',
		itemImage: '/images/combat/materials/junk/ancient_armor_scraps.png',
		value: 4000,
		extraTooltipInfo:
			'Scraps of some ancient piece of equipment. Its origin is unknown, but it may be worth something to the right person.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25020: {
		id: 25020,
		name: 'Unknown Rune',
		itemImage: '/images/combat/materials/junk/unknown_rune.png',
		value: 12500,
		extraTooltipInfo:
			'It appears to be a rune not created through conventional means. It is no use to you but someone may want it.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25021: {
		id: 25021,
		name: 'Light Armor Scraps',
		itemImage: '/images/combat/materials/junk/armor_scraps_2.png',
		value: 1250,
		extraTooltipInfo: 'Pieces of light armor that is too damaged to salvage. Might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25022: {
		id: 25022,
		name: 'Armor Scraps',
		itemImage: '/images/combat/materials/junk/armor_scraps_3.png',
		value: 1500,
		extraTooltipInfo: 'Pieces of armor that is too damaged to salvage. Might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25023: {
		id: 25023,
		name: 'Heavy Armor Scraps',
		itemImage: '/images/combat/materials/junk/armor_scraps.png',
		value: 1750,
		extraTooltipInfo: 'Pieces of heavy armor that is too damaged to salvage. Might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25024: {
		id: 25024,
		name: 'Tusk',
		itemImage: '/images/combat/materials/junk/tusk.png',
		value: 750,
		extraTooltipInfo: 'A tusk taken from a slain monster; might be worth something to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25025: {
		id: 25025,
		name: 'Gem Shards',
		itemImage: '/images/combat/materials/junk/gem_shards.png',
		value: 2250,
		extraTooltipInfo:
			'Useless pieces of shattered gems. Not quite fine enough to be considered dust, but too small for you to properly refine or cut. Might be worth something to a vendor.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25026: {
		id: 25026,
		name: 'Goblin Fork',
		itemImage: '/images/combat/materials/junk/fork.png',
		value: 225,
		extraTooltipInfo:
			'A useless eating utensil used by goblins. Everyone knows you should just eat your meals whole! A vendor may want this.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25027: {
		id: 25027,
		name: 'Nature Stone',
		itemImage: '/images/combat/materials/junk/nature_stone.png',
		value: 10000,
		extraTooltipInfo:
			'A chunk of stone that appears to be imbued with life. Useless to you, but must be valuable to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25028: {
		id: 25028,
		name: 'Fire Stone',
		itemImage: '/images/combat/materials/junk/fire_stone.png',
		value: 10000,
		extraTooltipInfo:
			'A chunk of stone that appears to be imbued with flame. Useless to you, but must be valuable to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25029: {
		id: 25029,
		name: 'Ice Stone',
		itemImage: '/images/combat/materials/junk/ice_stone.png',
		value: 10000,
		extraTooltipInfo:
			'A chunk of stone that appears to be imbued with ice. Useless to you, but must be valuable to someone.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25030: {
		id: 25030,
		name: 'A Diamond Plated Bug',
		itemImage: '/images/chaticons/chat-icon-bugtester-diamond.png',
		value: 1000000,
		extraTooltipInfo: 'I wonder who added this in? Some nerd probably.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25031: {
		id: 25031,
		name: 'Valuable Furs',
		itemImage: '/images/combat/materials/junk/valuable_furs.png',
		value: 1500,
		extraTooltipInfo: 'Worthless for making equipment out of but may be worth something to a vendor.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	25032: {
		id: 25032,
		name: 'Metal Slag',
		itemImage: '/images/combat/materials/junk/slag.png',
		value: 50,
		extraTooltipInfo:
			'Slag from Smithing; it is completely useless to you but a vendor may be convinced to buy it.',
		class: 'junk',
		rarity: 'junk',
		tags: ['junk'],
	},
	100000: {
		name: 'ban_hamer',
		id: 100000,
		tradeable: false,
		itemImage: '/images/combat/equipment/ban_hamer.png',
		itemIcon: '/images/combat/equipment/ban_hamer_icon.png',
		extraTooltipInfo: 'with this hammer you can play whac-a-mole more efficiently',
		class: 'equipment',
		visual: 'ban-hamer',
		tags: ['melee', 'twohand'],
		equipmentStats: {
			slot: 'weapon',
			attackSpeed: 42,
		},
	},
};
