import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { ISkill, TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { shareSkillSelector } from '../../../atoms/chatInputAtom';
import { usePlayerField } from '../../../hooks/hooks';
import { experienceToLevel, maxNormalLevel } from '../../../utils/experienceFunctions';
import { leagueList } from '../../../utils/leagueList';
import { ITab, tabs } from '../../../helper/NavigationTabsList';
import Tooltip from '../UI/Tooltip';
import { SkillLevelBarNoIcon } from './SkillLevelBarNoIcon';
import { SkillLevelComponent } from './SkillLevelComponent';

export type TSkillLevelProps = {
	skill: TSkillTab;
	showMastery: boolean;
	skillData: ISkill;
	skillInfo: ITab;
	skillEffectiveLevel: number;
	levelProgress: number;
	skillImage: string;
	handleClick: (event: React.MouseEvent) => void;
	tooltip: React.ReactElement;
	notification?: string;
};

function SkillLevel(props: { skill: TSkillTab; width?: number; type?: 'circle' | 'barNoIcon' }) {
	const skills = usePlayerField('skills');
	const league = usePlayerField('league');
	const shareSkill = useSetRecoilState(shareSkillSelector);
	const setActiveTab = useSetRecoilState(activeTabAtom);

	const skillEquipmentStats = usePlayerField('skillEquipmentStats');

	if (!skills) return null;
	const expFormula = leagueList[league].rules.expFormula;
	const masteryLevel = maxNormalLevel(expFormula);

	const skillToolBoost = skillEquipmentStats[props.skill] || 0;

	const skillData = skills[props.skill];
	const skillInfo = tabs[props.skill];

	const showMastery = skillData.level === masteryLevel;

	const currentLevel = showMastery ? skillData.masteryLevel + masteryLevel : skillData.level;

	const currentExp = showMastery ? skillData.masteryExperience : skillData.experience;
	const nextLevelRequiredExp = experienceToLevel(currentLevel + 1, expFormula);
	const currentLevelRequiredExp = experienceToLevel(currentLevel, expFormula);
	const remainingExperience = nextLevelRequiredExp - currentExp;
	const levelProgress =
		((currentExp - currentLevelRequiredExp) / (nextLevelRequiredExp - currentLevelRequiredExp)) * 100;
	const skillEffectiveLevel = skillToolBoost + skillData.level + skillData.masteryLevel;

	function handleClick(event: React.MouseEvent) {
		if (event.ctrlKey || event.metaKey) {
			shareSkill(props.skill);
		} else {
			setActiveTab(props.skill);
		}
	}

	const tooltip = (
		<Tooltip>
			<Heading size='md' textAlign='center' margin='5px 0'>
				{skillInfo.name}
			</Heading>
			<Box>Effective Level: {skillEffectiveLevel.toFixed(2).replace(/\.00$/, '')}</Box>
			<Box>Current Exp: {Math.floor(currentExp).toLocaleString()}</Box>
			<Box>Required Exp: {nextLevelRequiredExp.toLocaleString()}</Box>
			<Box>Remaining Exp: {remainingExperience.toLocaleString()}</Box>
		</Tooltip>
	);

	const exposeLevelsToScripts = (
		<Box display='none' className={`anchor-levels-${props.skill}`}>
			<Box className='anchor-levels-level'>{skillData.level}</Box>
			<Box className='anchor-levels-experience'>{skillData.experience}</Box>
			<Box className='anchor-levels-mastery-level'>{skillData.masteryLevel}</Box>
			<Box className='anchor-levels-mastery-experience'>{skillData.masteryExperience}</Box>
			<Box className='anchor-levels-effective-level'>{skillEffectiveLevel}</Box>
		</Box>
	);

	let LevelComponent: (props: TSkillLevelProps) => React.ReactElement;
	switch (props.type) {
		case 'barNoIcon':
			LevelComponent = SkillLevelBarNoIcon;
			break;
		case 'circle':
		default:
			LevelComponent = SkillLevelComponent;
			break;
	}

	let notification = '';
	// If this is farming, set the notification text to the empty plots field from the player object
	if (props.skill === 'farming') {
		const spacesOpen = usePlayerField('farmingSpacesOpen');
		if (spacesOpen > 0) {
			notification = spacesOpen.toString();
		}
	}

	return (
		<>
			<LevelComponent
				skill={props.skill}
				showMastery={showMastery}
				skillData={skillData}
				skillInfo={skillInfo}
				skillEffectiveLevel={skillEffectiveLevel}
				levelProgress={levelProgress}
				skillImage={tabs[props.skill]?.icon ?? ''}
				handleClick={handleClick}
				tooltip={tooltip}
				notification={notification}
			/>
			{props.type === 'barNoIcon' && exposeLevelsToScripts}
		</>
	);
}

export default SkillLevel;
