import {
	Img,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Switch,
	Text,
	Flex,
	FormLabel,
} from '@chakra-ui/react';
import { socket } from '../../../services/socket.service';
import Timer from './timer';
import { IoRefreshOutline } from 'react-icons/io5';
import { usePlayerField } from '../../../hooks/hooks';
import { locations as locationList } from '../../../utils/locationList';
import { ItemLogSource2 } from './ItemLogSource2';
import React, { useEffect, useState } from 'react';
import { IItemSource } from '../../../../../game-server/src/modules/stat-tracking/StatTracking.interface';
import { formatNumberPerHour } from '../../../helper/helperFunctions';
import { drawerAtom } from '../../../atoms/drawerAtom';
import { useRecoilValue } from 'recoil';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import { closeTab, openTab } from '../../../helper/SendData';

export const ItemLog2 = () => {
	const drawerOpen = useRecoilValue(drawerAtom);
	const persistentLootLog = usePlayerField('lootlog');
	const expLog = usePlayerField('explog');
	const lootlogDate = new Date(usePlayerField('lootlogDate'));
	const [perHour, setPerHour] = useState(false);
	const [combine, setCombine] = useState(false);
	let lootLog = persistentLootLog;
	if (combine) {
		lootLog = mergeLoot(persistentLootLog);
		lootLog = mergeLocation(lootLog);
	}
	useEffect(() => {
		if (drawerOpen) {
			openTab('drawer', ['lootlog', 'explog', 'lootlogDate']);
		} else {
			closeTab('drawer');
		}
	}, [drawerOpen]);

	function resetLootLog(e: React.MouseEvent) {
		socket.emit('lootlog:fullClear');
		e.stopPropagation(); // wut dis
	}

	// Combine loot ignoring augmentation and enchantments
	function mergeLoot(sources: IItemSource[]) {
		const combinedSources = structuredClone(sources);
		for (const source of combinedSources) {
			const combinedItems: { itemID: number; count: number; consumed?: boolean }[] = [];
			for (const item of source.items) {
				const foundItem = combinedItems.find(
					(combinedItem) => combinedItem.itemID === item.itemID && combinedItem.consumed === item.consumed
				);
				if (!foundItem) {
					combinedItems.push({ itemID: item.itemID, count: item.count, consumed: item.consumed });
				} else {
					foundItem.count += item.count;
				}
			}
			source.items = combinedItems;
		}
		return combinedSources;
	}

	// Combine loot by location
	function mergeLocation(sources: IItemSource[]) {
		const combinedLocations: IItemSource[] = [];
		for (const source of sources) {
			if (source.location === locationsIds.the_void) {
				combinedLocations.push(structuredClone(source));
				continue;
			}
			const location = combinedLocations.find((location) => location.location === source.location);
			// Add the first entry of each unique location
			if (!location) {
				const newLocation = structuredClone(source);
				// Name it as the location
				newLocation.sourceName = locationList[newLocation.location].name;
				combinedLocations.push(newLocation);
			} else {
				location.count += source.count;
				const combinedItems = location.items;
				// Combine the count or add a new item
				source.items.forEach((item) => {
					const found = combinedItems.find((a) => a.itemID === item.itemID && a.consumed === item.consumed);
					if (found) {
						found.count += item.count;
					} else {
						combinedItems.push(structuredClone(item));
					}
				});
			}
		}
		return combinedLocations;
	}

	function renderExpLog() {
		const expSource: IItemSource = {
			location: 1,
			sourceName: 'Experience',
			sourceType: 'uncategorized',
			items: [],
			count: 0,
		};
		if (expLog?.length === 0) {
			return <></>;
		}
		for (const skill of expLog) {
			expSource.count += skill.count;
			expSource.items.push({ itemID: 1, count: skill.experience, additionalInfo: { exp: skill.skillName } });
		}

		return (
			<ItemLogSource2
				key='exp-log-source'
				sourceName='Experience'
				sourceData={expSource}
				sourceLogTime={lootlogDate}
				perHour={perHour}
			/>
		);
	}

	function renderLootLog() {
		const locations: number[] = lootLog.reduce((acc: number[], cur) => {
			if (!acc.includes(cur.location)) {
				acc.push(cur.location);
			}
			return acc;
		}, []);
		return locations.map((locationID) => {
			return (
				<React.Fragment key={`loot-log-location-${locationID}`}>
					{renderLootLocation(locationID)}
				</React.Fragment>
			);
		});
	}

	function renderLootLocation(locationID: number) {
		const count = lootLog.reduce((acc, cur) => {
			return acc + (cur.location === locationID ? cur.count : 0);
		}, 0);
		const countText = perHour ? formatNumberPerHour(count, lootlogDate) : 'x' + count.toLocaleString();
		// If the source doesn't exist, return ''
		const filteredLootLog = lootLog.filter((e) => e.location === locationID);
		if (filteredLootLog.length <= 0) return '';
		const sourceObjects: React.ReactElement[] = [];
		for (const source of filteredLootLog) {
			sourceObjects.push(renderLootSource(locationID, source.sourceName));
		}
		// Remove one nesting of accordions if the location and source names are the same
		if (
			filteredLootLog.length === 1 &&
			locationList[filteredLootLog[0].location].name === filteredLootLog[0].sourceName
		) {
			return <>{sourceObjects}</>;
		}
		// Remove top level if void. eg. essence
		if (locationID === locationsIds.the_void) {
			return <>{sourceObjects}</>;
		}
		return (
			<AccordionItem marginBottom={'10px'}>
				{({ isExpanded }) => (
					<>
						<AccordionButton
							padding='0 10px'
							border='none'
							margin='0'
							filter={!isExpanded ? 'grayscale(1)' : 'unset'}
							_hover={{ filter: 'brightness(1.2)' }}
							background={`linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 100%), url(${locationList[locationID]?.locationImage})`}
							backgroundPosition='center'
							borderRadius='5px'
							boxShadow='0 2px 5px #000000, 0 0 5px #000000 inset'
							zIndex='100'
							cursor='pointer'
						>
							<Flex
								width='100%'
								textShadow='0 0 2px #000000'
								fontWeight='bold'
								backdropBlur='lg'
								lineHeight='30px'
								fontSize='16px'
								justifyContent='space-between'
							>
								<Text margin={0} padding={0}>
									{locationList[locationID]?.name ?? 'ERROR'}
								</Text>{' '}
								<Text margin={0} padding={0} fontWeight={'bold'}>
									{countText}
								</Text>
							</Flex>
							<AccordionIcon />
						</AccordionButton>
						<Accordion allowMultiple variant='default'>
							<AccordionPanel paddingRight={0} paddingBottom={0}>
								<Accordion allowMultiple variant='default'>
									{sourceObjects}
									{sourceObjects.length === 0 && <Box padding='10px'>No loot yet.</Box>}
								</Accordion>
							</AccordionPanel>
						</Accordion>
					</>
				)}
			</AccordionItem>
		);
	}

	function renderLootSource(locationID: number, sourceID: string) {
		const thisSource = lootLog.find((e) => e.location === locationID && e.sourceName === sourceID);
		// Sort the items by ID and augmentations
		if (thisSource && thisSource.items) {
			thisSource.items.sort((a, b) => {
				if (a.itemID > b.itemID) return 1;
				if (a.itemID === b.itemID) {
					const augA = a.additionalInfo?.augmentations ?? 0;
					const augB = b.additionalInfo?.augmentations ?? 0;
					if (augA > augB) return 1;
					if (augB < augA) return -1;
				}
				if (a.itemID < b.itemID) return -1;
				return 0;
			});
		}
		if (thisSource) {
			return (
				<ItemLogSource2
					key={'loot-log-source-' + sourceID}
					sourceName={sourceID}
					sourceData={thisSource}
					sourceLogTime={lootlogDate}
					perHour={perHour}
				></ItemLogSource2>
			);
		}
		return <></>;
	}

	return (
		<Accordion allowToggle variant='default'>
			<AccordionItem>
				{({ isExpanded }) => (
					<>
						<AccordionButton
							padding='0 10px'
							background='transparent'
							border='none'
							margin={0}
							_expanded={{
								background:
									'linear-gradient(90deg, rgba(144, 205, 244, 0.25) 0, rgba(144, 205, 244, 0.05) 100%)',
								boxShadow: '0 0 10px 0 rgba(144, 205, 244, 0.5)',
							}}
							cursor='pointer'
						>
							<Img src='/images/ui/inventory_icon.png' width='25px' />
							<Box marginLeft='10px' lineHeight='30px' fontSize='16px'>
								Loot Log
							</Box>{' '}
							<AccordionIcon />
							{isExpanded && (
								<Flex marginLeft='auto'>
									<Timer
										key={String(lootlogDate)}
										// Server is slightly in the future by a couple seconds, prevent negative time
										time={lootlogDate > new Date() ? new Date() : lootlogDate}
									/>
									<Box
										onClick={(e) => resetLootLog(e)}
										marginLeft='5px'
										_hover={{ color: 'blue.200' }}
									>
										<IoRefreshOutline cursor='pointer' fontSize='16px' />
									</Box>
								</Flex>
							)}
						</AccordionButton>
						<AccordionPanel padding='2'>
							<Flex flexDirection='row' justifyContent='space-around' alignItems='center'>
								<FormLabel htmlFor='loot-log-combine' textAlign='center'>
									Combine
								</FormLabel>
								<Switch
									id='loot-log-combine'
									isChecked={combine}
									onChange={(e) => setCombine(e.target.checked)}
								/>
								<FormLabel htmlFor='loot-log-per-hour' textAlign='center'>
									Rate
								</FormLabel>
								<Switch
									id='loot-log-per-hour'
									isChecked={perHour}
									onChange={(e) => setPerHour(e.target.checked)}
								/>
							</Flex>
							<Accordion allowMultiple variant='default'>
								{renderExpLog()}
								{renderLootLog()}
								{Object.keys(lootLog).length === 0 && <Box padding='10px'>No loot yet.</Box>}
							</Accordion>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};
