import React, { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import MarketplaceBuyItemListItem from './MarketplaceBuyItemListItem';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { EnchantmentData } from '../EnchantmentData';
import { itemList } from '../../../utils/itemList';
import { buffStacks, getHealingStats, isTradeable } from '../../../utils/itemFunctions';
import { Box, Button, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import { IMarketListing } from '../../../../../game-server/src/repositories/MarketListing.repository';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { useRecoilValue } from 'recoil';
import { IEnchantmentData } from '../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { usePlayerField } from '../../../hooks/hooks';
import FakeItem from '../Inventory/FakeItem';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import { getEnchantmentStrength } from '../../../utils/itemFunctions';
import { BuyMarketplaceDialogue } from '../../layout/dialogs/BuyMarketplaceDialogue';
import { IdlescapeButton } from '@idlescape/ui';
import { SellMarketplaceDialogue } from '../../layout/dialogs/SellMarketplaceDialogue';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import SelectionTooltipBox from '../Tooltips/Types/SelectionTooltipBox';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';
import { filterItem } from '../../../helper/helperFunctions';

export interface IMarketListingWithStackSize extends IMarketListing {
	//Fields for sorting
	stackSize: number;
	costPerStack?: number;
	totalHP?: number;
	isOffer?: boolean;
	higherAugments?: boolean;
	anyEnchantment?: boolean;
	name?: string; // dummy key for sort
	enchantment?: number; // dummy key for sort
}

export default function MarketplaceBuyItemList() {
	const stockpile = usePlayerField('stockpile');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [openDialog, setOpenDialog] = useState<'offer' | 'listing'>();
	const itemToBuy = useRecoilValue(marketplaceItemAtom);
	const marketFilter = useRecoilValue(marketplaceFilterAtom);
	const itemToBuyData = itemToBuy ? itemList[itemToBuy] : undefined;
	const isGroup = !!itemToBuyData?.dummy;
	const isPot = itemToBuyData?.name.includes('Potion');
	const isFood = itemToBuyData?.tags?.includes('consumable');
	const [listingsData, setListingsData] = useState<IMarketListingWithStackSize[]>([]);
	const [offersData, setOffersData] = useState<IMarketListingWithStackSize[]>([]);
	const [ownListingsData, setOwnListingsData] = useState<IMarketListingWithStackSize[]>([]);
	const [ownOffersData, setOwnOffersData] = useState<IMarketListingWithStackSize[]>([]);
	const [showBuySell, setShowBuySell] = useState<string>(isGroup ? 'buy' : 'both');
	const [listingOrder, setListingOrder] = useState<{ field: keyof IMarketListingWithStackSize; asc: boolean }>({
		field: 'price',
		asc: true,
	});
	const [offerOrder, setOfferOrder] = useState<{ field: keyof IMarketListingWithStackSize; asc: boolean }>({
		field: 'price',
		asc: false,
	});
	const [selectedItem, setSelectedItem] = useState<IItem>();

	const filteredItems = stockpile.filter((item) => item.itemID === itemToBuy);
	const itemAmount = stockpile
		.filter((item) => item.itemID === itemToBuy && isTradeable(item, itemList))
		.reduce((count, item) => count + item.stackSize, 0);

	const noVariant =
		!isFood &&
		!itemToBuyData?.equipmentStats &&
		!itemToBuyData?.champEncounter &&
		itemToBuyData?.id !== itemsIds.book;

	//Fields for sorting
	const fields: { field: keyof IMarketListingWithStackSize; fieldName: string; extra?: boolean }[] = [];
	if (!isFood) fields.push({ field: 'name', fieldName: 'Name', extra: true });
	fields.push({ field: 'item', fieldName: 'Item' }); //Sort by augmentation
	fields.push({ field: 'stackSize', fieldName: 'Amount' });
	fields.push({ field: 'price', fieldName: 'Price' });
	if (isFood) {
		if (isPot) {
			fields.push({ field: 'enchantment', fieldName: 'Enchantment', extra: true });
			fields.push({ field: 'costPerStack', fieldName: 'Cost per Stack' });
		} else {
			fields.push({ field: 'totalHP', fieldName: 'Total HP', extra: true });
			fields.push({ field: 'costPerStack', fieldName: 'Cost per HP' });
		}
	}

	useEffect(() => {
		if (itemToBuy) {
			socket.emit('marketplace:listing:item:get', itemToBuy);
			socket.emit('marketplace:offer:items:get', itemToBuy);
		}
		socket.on('marketplace:listing:item:send', (data) => {
			if (data.length <= 0) {
				setListingsData([]);
				setOwnListingsData([]);
				return;
			}
			// more convenient for sorting
			let listingsWithStackSize: IMarketListingWithStackSize[] = [];
			data.forEach((listing) => {
				listingsWithStackSize.push({
					...listing,
					stackSize: listing.item.stackSize,
				});
			});
			if (isFood) {
				listingsWithStackSize = foodSpecific(listingsWithStackSize);
			}
			setListingsData(listingsWithStackSize);
			setOwnListingsData(listingsWithStackSize.filter((listing) => listing.belongsToCurrentUser));
		});

		socket.on('marketplace:offer:items:send', (data) => {
			if (data.length <= 0) {
				setOffersData([]);
				setOwnOffersData([]);
				return;
			}
			let listingsWithStackSize: IMarketListingWithStackSize[] = [];
			data.forEach((listing) => {
				listingsWithStackSize.push({
					listingId: listing.offerId,
					item: {
						id: 0,
						inventoryItemId: 0,
						name: itemList[listing.itemID].name,
						itemID: listing.itemID,
						stackSize: listing.leftToPurchase,
						augmentations: listing.augmentations ?? undefined,
						enchantmentID: listing.enchantment_id ?? undefined,
						enchantmentStrength: listing.enchantment_strength ?? undefined,
						order: 1,
					},
					price: listing.price,
					sellerId: listing.buyerId,
					listingDate: listing.offerDate,
					league: listing.league,
					belongsToCurrentUser: listing.belongsToCurrentUser,
					stackSize: listing.leftToPurchase,
					isOffer: true,
					higherAugments: listing.higherAugments,
					anyEnchantment: listing.anyEnchantment,
				});
			});
			if (isFood) {
				listingsWithStackSize = foodSpecific(listingsWithStackSize);
			}
			setOffersData(listingsWithStackSize);
			setOwnOffersData(listingsWithStackSize.filter((offers) => offers.belongsToCurrentUser));
		});

		return () => {
			socket.off('marketplace:listing:item:send');
			socket.off('marketplace:offer:items:send');
		};
	}, []);

	function orderBy(field: keyof IMarketListingWithStackSize, isOffer?: boolean) {
		let order = listingOrder;
		let setOrder = setListingOrder;
		if (isOffer) {
			order = offerOrder;
			setOrder = setOfferOrder;
		}
		let asc = true;
		if (order.field === field) {
			asc = !order.asc;
		}
		setOrder({ field: field, asc: asc });
	}

	function getEnchantment(listing: IMarketListingWithStackSize) {
		const item = listing.item;
		if (item.enchantmentID) {
			const name = enchantmentsList[item.enchantmentID].name;
			const strength = getEnchantmentStrength(item, itemList);
			return name + ' ' + strength;
		}
		return '';
	}

	function orderedData(
		data: IMarketListingWithStackSize[],
		sortField?: { field: keyof IMarketListingWithStackSize; asc: boolean }
	) {
		let order = listingOrder;
		if (data[0]?.isOffer) order = offerOrder;
		if (sortField) order = sortField;

		const sorted = data.sort((x, y) => {
			let x_value: any;
			let y_value: any;
			switch (order.field) {
				case 'enchantment':
					//Need to consider double sort if enchantmentStrength >= 10
					x_value = getEnchantment(x);
					y_value = getEnchantment(y);
					break;
				case 'item':
					x_value = x.item.augmentations ?? -1; //-1 since 0's are unsorted
					y_value = y.item.augmentations ?? -1;
					break;
				default:
					x_value = x[order.field];
					y_value = y[order.field];
			}

			// sort 0's (which can only occur for cost per stack) to the end
			if (x_value === 0 && y_value === 0) return 0;
			if (x_value === 0) return 1;
			if (y_value === 0) return -1;

			if (x_value === y_value) return 0;
			if (order.asc) {
				return x_value <= y_value ? -1 : 1;
			} else {
				return x_value <= y_value ? 1 : -1;
			}
		});
		return sorted;
	}

	function filteredData(data: IMarketListingWithStackSize[]) {
		// reset itemsdata
		if (marketFilter.length == 0 || (itemToBuy && filterItem({ itemID: itemToBuy }, marketFilter))) {
			return structuredClone(data); // Cloned so it's not sorted
		}

		// search for an enchant id
		const ench_id: IEnchantmentData['id'][] = [];
		for (const e in enchantmentsList) {
			if (enchantmentsList[e].id === undefined) continue;
			if (enchantmentsList[e].name.toLowerCase().includes(marketFilter.toLowerCase())) {
				ench_id.push(enchantmentsList[e].id);
			}
		}

		return data.filter((listing) => {
			return (
				(listing.item.enchantmentID && ench_id.includes(listing.item.enchantmentID)) ||
				(listing.item.augmentations ?? 0).toString() === marketFilter
			);
		});
	}

	function foodSpecific(data: IMarketListingWithStackSize[]) {
		data.map((entry) => {
			const item = entry.item;
			if (isPot && item.enchantmentID) {
				const enchantmentMultiplier = EnchantmentData.findEnchantmentByID(item.enchantmentID).stackMult;
				const stackCount = buffStacks(
					item.augmentations,
					itemList[entry.item.itemID].stackMultiplier,
					enchantmentMultiplier
				);
				entry.costPerStack = entry.price / stackCount;
			} else {
				const healing = getHealingStats(item, itemList[item.itemID], 0);
				const totalHP = (healing?.totalTicks ?? 0) * (healing?.healTick ?? 0) + (healing?.healing ?? 0);
				entry.costPerStack = entry.price / totalHP;
				entry.totalHP = totalHP;
			}
			return entry;
		});
		return data;
	}

	const noItems = listingsData.length === 0 && (
		<p className='marketplace-no-items'>There are currently no sales listed for this item. Check back later.</p>
	);
	const noOffers = offersData.length === 0 && (
		<p className='marketplace-no-items'>There are currently no offers listed for this item. Check back later.</p>
	);

	const stockpileTooltip = (
		<SelectionTooltipBox
			items={filteredItems}
			clickItem={(item) => {
				setOpenDialog('listing');
				setSelectedItem(item);
				onOpen();
			}}
			showEmpty={false}
		/>
	);

	function showFields(isOffer?: boolean) {
		let order = listingOrder;
		if (isOffer) {
			order = offerOrder;
		}
		const output: React.ReactElement[] = [];
		for (const field of fields) {
			output.push(
				<Box
					className={field.extra ? 'market-field-extra' : ''}
					key={field.field}
					onClick={() => orderBy(field.field, isOffer)}
					_hover={{ color: 'lightgreen', cursor: 'pointer' }}
				>
					{field.fieldName} {order.field === field.field && (order.asc ? '▲' : '▼')}
				</Box>
			);
		}
		return <>{output}</>;
	}

	function marketTable(isOffer?: boolean) {
		return (
			<div className='marketplace-table'>
				<Grid className='marketplace-table-header' gridTemplateColumns='repeat(auto-fit, minmax(50px, 1fr))'>
					{showFields(isOffer)}
				</Grid>
				<Box borderTop='1px solid white'>
					{orderedData(filteredData(isOffer ? offersData : listingsData)).map((listing) => {
						return (
							<MarketplaceBuyItemListItem listing={listing} key={listing.listingId} isOffer={isOffer} />
						);
					})}
				</Box>
			</div>
		);
	}

	return (
		<>
			<Flex className={`marketplace-show-${showBuySell}`} position='relative' flexDirection='column'>
				<Flex
					alignItems='center'
					className='anchor-market-tables-header market-tables-header'
					width='100%'
					justifyContent='center'
					gap='15px'
					filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
				>
					{!isGroup && (
						<IdlescapeButton
							variant={ownListingsData.length > 0 || filteredItems.length > 0 ? 'green' : 'disabled'}
							className='market-new-listing'
							onClick={() => {
								if (noVariant) {
									setOpenDialog('listing');
									onOpen();
								}
							}}
						>
							{noVariant && ownListingsData.length > 0 ? 'Edit' : 'New'} Listing
							{!noVariant && filteredItems.length > 0 && stockpileTooltip}
						</IdlescapeButton>
					)}
					<Button
						className='market-header-buy'
						height='50px'
						fontSize='2rem'
						onClick={() => {
							showBuySell === 'buy' ? setShowBuySell('both') : setShowBuySell('buy');
						}}
					>
						BUY
						<Box paddingLeft='5px' color='green' width='28px' height='28px'>
							<FaArrowAltCircleUp />
						</Box>
					</Button>
					<FakeItem item={{ itemID: itemToBuy ?? 0, stackSize: itemAmount }} minAmount={1} zIndex='1' />
					<Button
						className='market-header-sell'
						height='50px'
						fontSize='2rem'
						onClick={() => {
							showBuySell === 'sell' ? setShowBuySell('both') : setShowBuySell('sell');
						}}
					>
						SELL
						<Box paddingLeft='5px' color='red' width='28px' height='28px'>
							<FaArrowAltCircleDown />
						</Box>
					</Button>
					{!isGroup && (
						<IdlescapeButton
							variant={'green'}
							className='market-new-offer'
							onClick={() => {
								setOpenDialog('offer');
								onOpen();
							}}
						>
							{noVariant && ownOffersData.length > 0 ? 'Edit' : 'New'} Offer
						</IdlescapeButton>
					)}
				</Flex>
				<Grid gap='5px' gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'>
					<Box className='market-buy'>
						{marketTable()}
						{noItems}
					</Box>
					<Box className='market-sell'>
						{marketTable(true)}
						{noOffers}
					</Box>
				</Grid>
			</Flex>
			{isOpen && openDialog === 'offer' && itemToBuy && (
				<BuyMarketplaceDialogue
					itemIdProp={itemToBuy}
					listingProp={noVariant ? ownOffersData[0] : undefined}
					onClose={onClose}
				/>
			)}
			{isOpen && openDialog === 'listing' && (
				<SellMarketplaceDialogue
					itemProp={selectedItem ?? filteredItems[0]}
					listingProp={selectedItem ? undefined : ownListingsData[0]}
					onClose={onClose}
				/>
			)}
		</>
	);
}
