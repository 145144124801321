import React from 'react';
import { ISystemMessage } from '../../../../../game-server/src/modules/chat/Chat.interface';
import DOMPurify from 'dompurify';
import { tabs } from '../../../helper/NavigationTabsList';
import { useSetRecoilState } from 'recoil';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { Box, Text } from '@chakra-ui/react';
import ChatIcon from './ChatIcon';
import { usePlayerField } from '../../../hooks/hooks';
import ChatMessageTimestamp from './ChatMessageTimestamp';

const ChatSystemMessage = ({ message }: { message: ISystemMessage }) => {
	const playerSettings = usePlayerField('settings');
	const { showAugmentMessages, showRareDrops, showSnowballMessages } = playerSettings.chat;
	const setActiveTab = useSetRecoilState(activeTabAtom);

	switch (message.visualClass) {
		case 'message-success-augment':
			if (!showAugmentMessages) return null;
			break;
		case 'message-failed-augment':
			if (!showAugmentMessages) return null;
			break;
		case 'message-hitBySnowball':
			if (!showSnowballMessages) return null;
			break;
		case 'message-rare-loot':
			if (!showRareDrops) return null;
			break;
	}

	function renderUsername(username: string) {
		if (!username) return null;
		return <span className='chat-message-username'>[{username}] </span>;
	}

	function renderContent(message: string) {
		if (!message) return null;

		if (message.includes('#NAVTABLINK#')) {
			return renderContentWithNavTabLink(message);
		}
		return renderDangerousContent(message);
	}

	function renderContentWithNavTabLink(message: string): React.ReactElement[] | null {
		// #NAVTABLINK#tabId#label#
		// example: #NAVTABLINK#event-shop#event shop#
		const regex = /#NAVTABLINK#[a-z-]+#[a-zA-Z0-9- ]+#/g;
		const matches = message.match(regex);
		if (!matches) return null;
		// split message into parts
		const parts = message.split(regex);
		const result: React.ReactElement[] = [];
		for (let i = 0; i < parts.length; i++) {
			result.push(renderDangerousContent(parts[i], i));
			if (i < parts.length - 1) {
				const [, , tabId, label] = matches[i].split('#');
				const tab = tabs[tabId];
				if (!tab) continue;
				result.push(
					<Text
						as='span'
						className='chat-message-navtab-link'
						key={tab.id + i}
						onClick={() => setActiveTab(tab.id)}
						cursor='pointer'
						color='blue.500'
						_hover={{ textDecoration: 'underline' }}
					>
						{label}
					</Text>
				);
			}
		}
		return result;
	}

	function renderDangerousContent(message: string, index?: number) {
		message = DOMPurify.sanitize(message);
		return <span key={index} className='chat-message-content' dangerouslySetInnerHTML={{ __html: message }}></span>;
	}

	return (
		<Box className={'chat-message ' + message.visualClass}>
			<ChatMessageTimestamp message={message} />
			<ChatIcon iconID={message.iconID} message={message} />
			{renderUsername(message.username)}
			{renderContent(message.message)}
		</Box>
	);
};

export default ChatSystemMessage;
