import { IdlescapeSelect } from '@idlescape/ui';
import React from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { getLoadoutIcon } from '../../../../helper/helperFunctions';

function LoadoutSelector(props: { locationId: number }) {
	const loadouts = usePlayerField('loadouts');
	const selectedLoadoutId = loadouts.find((loadout) =>
		loadout.loadoutRelatedZones.includes(props.locationId)
	)?.loadoutId;
	const options = loadouts.reduce((acc, loadout) => {
		acc[loadout.loadoutId] = {
			label: loadout.loadoutName,
			image: getLoadoutIcon(loadout).icon,
		};
		return acc;
	}, {} as Record<string, { label: string; image: string }>);

	function select(id: string) {
		if (id == '') {
			// Find the loadout that is currently affiliated with this locationId
			const loadout = loadouts.find((loadout) => loadout.loadoutRelatedZones.includes(props.locationId));
			// If there is no loadout affiliated with this locationId, then we don't need to do anything
			if (!loadout) {
				return;
			}
			// Otherwise, we need to clear the loadout by running markLoadout because that just toggles it
			socket.emit('inventory:loadout:mark', {
				loadoutID: loadout.loadoutId,
				newLocationIDs: [props.locationId],
			});
			return;
		}

		socket.emit('inventory:loadout:mark', {
			loadoutID: parseInt(id),
			newLocationIDs: [props.locationId],
		});
	}

	// Add the option of 'None' to the list of loadouts
	options[''] = {
		label: 'Clear Selection',
		image: '',
	};

	return (
		<IdlescapeSelect
			options={options}
			selected={selectedLoadoutId?.toString()}
			placeholder='None'
			select={select}
			width='200px'
		/>
	);
}

export default LoadoutSelector;
