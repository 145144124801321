// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { IAffixes, TAffixesPaths } from '../../../game-server/src/modules/affixes/affixes.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';

export const affixList: Record<TAffixesPaths, IAffixes> = {
	// These common damage boosts shouldn't be very good and basically just be filler basic boosts
	'offense.strength': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'Strong',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 2,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'weapon.strength' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_101',
		slots: ['weapon', 'shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Strength stat by a flat amount.',
	},
	'offense.strength_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'Mighty',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 20,
				maxStrength: 30,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'weapon.strength' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_101',
		slots: ['weapon', 'shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Strength stat by a flat amount.',
	},
	'offense.dexterity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'Dextrous',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 2,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'weapon.dexterity' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_102',
		slots: ['weapon', 'shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Dexterity stat by a flat amount.',
	},
	'offense.dexterity_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'Agile',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 20,
				maxStrength: 30,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'weapon.dexterity' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_102',
		slots: ['weapon', 'shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Dexterity stat by a flat amount.',
	},
	'offense.intellect': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'Wise',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 2,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'weapon.intellect' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_103',
		slots: ['weapon', 'shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Intellect stat by a flat amount.',
	},
	'offense.intellect_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'Enlightened',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 20,
				maxStrength: 30,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'weapon.intellect' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_103',
		slots: ['weapon', 'shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Intellect stat by a flat amount.',
	},
	// This has the potential to be INSANELY good
	'offense.attack_speed': {
		type: 'multiplicative',
		rarity: 'legendary',
		hook: 'afterStatBuffs',
		name: 'of Haste',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.03,
				maxStrength: 0.06,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_104',
		slots: ['weapon'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Decreases the cooldown between attacks, making you attack faster.',
	},
	'offense.attack_speed_rare': {
		type: 'multiplicative',
		rarity: 'rare',
		hook: 'afterStatBuffs',
		name: 'of Speed',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.005,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_104',
		slots: ['weapon'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Decreases the cooldown between attacks, making you attack faster.',
	},
	'offense.critical_chance': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of Precision',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.04,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveCritical.chance' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_105',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Critical Chance by a flat amount.',
	},
	'offense.critical_chance_epic': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Clarity',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.05,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.08,
				maxStrength: 0.12,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveCritical.chance' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_105',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Critical Chance by a flat amount.',
	},
	'offense.critical_damage': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of Destruction',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.08,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.12,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.12,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveCritical.damageMultiplier' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_106',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Critical Damage stat by a flat amount.',
	},
	'offense.critical_damage_epic': {
		type: 'multiplicative',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Ruination',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.15,
				maxStrength: 0.2,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveCritical.damageMultiplier' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_106',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Critical Damage stat by a flat amount.',
	},
	// This is a flat damage boost, slightly stronger than a pure damage stat but also works for anything
	// I'm assuming this happens after everything else, so it will just flat out boost super low damage abilities outright
	'offense.additional_damage': {
		type: 'additive',
		hook: 'damageModifier',
		rarity: 'uncommon',
		name: 'of the Wolf',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 20,
				maxStrength: 40,
			},
			{
				tier: 3,
				requiredItemTier: 5,
				minStrength: 40,
				maxStrength: 60,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 75,
				maxStrength: 100,
			},
			{
				tier: 5,
				requiredItemTier: 8,
				minStrength: 100,
				maxStrength: 150,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_107',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Adds a flat amount of damage to any attack that lands and deals damage.',
	},
	'offense.additional_damage_epic': {
		type: 'additive',
		hook: 'damageModifier',
		rarity: 'epic',
		name: 'of the Direwolf',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 30,
				maxStrength: 50,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 50,
				maxStrength: 75,
			},
			{
				tier: 3,
				requiredItemTier: 5,
				minStrength: 75,
				maxStrength: 150,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 150,
				maxStrength: 250,
			},
			{
				tier: 5,
				requiredItemTier: 8,
				minStrength: 250,
				maxStrength: 400,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_107',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Adds a flat amount of damage to any attack that lands and deals damage.',
	},
	// Basically just a flat +dps % boost
	'offense.damage_multiplier': {
		type: 'multiplicative',
		hook: 'damageModifier',
		rarity: 'rare',
		name: 'of the Hawk',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.01,
				maxStrength: 1.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 1.02,
				maxStrength: 1.04,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 1.04,
				maxStrength: 1.06,
			},
		],
		displayType: 'multiplier',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_108',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Multiplies damage dealt by directly attacking an enemy, not affecting over time effects.',
	},
	'offense.elite_damage_multiplier': {
		type: 'multiplicative',
		hook: 'damageModifier',
		rarity: 'rare',
		name: 'of the Eagle',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.01,
				maxStrength: 1.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 1.02,
				maxStrength: 1.04,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 1.04,
				maxStrength: 1.06,
			},
		],
		displayType: 'multiplier',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_109',
		slots: ['weapon'],
		reducedEffectSlots: ['shield', 'ring', 'arrows', 'necklace'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Multiplies damage dealt by directly attacking elite enemies. Does not affect over time effects.',
	},
	'offense.elite_damage_multiplier_epic': {
		type: 'multiplicative',
		hook: 'damageModifier',
		rarity: 'epic',
		name: 'of the Griffin',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.03,
				maxStrength: 1.06,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 1.06,
				maxStrength: 1.08,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 1.08,
				maxStrength: 1.12,
			},
		],
		displayType: 'multiplier',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_109',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Multiplies damage dealt by directly attacking elite enemies. Does not affect over time effects.',
	},
	'offense.melee_affinities': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of the Gladiator',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.08,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Melee' },
			{ path: 'offensiveDamageAffinity.Piercing' },
			{ path: 'offensiveDamageAffinity.Blunt' },
			{ path: 'offensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_110',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases melee, piercing, blunt, and slashing offensive affinities by a flat amount.',
	},
	'offense.magic_affinities': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of the Archmage',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.08,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Magic' },
			{ path: 'offensiveDamageAffinity.Chaos' },
			{ path: 'offensiveDamageAffinity.Nature' },
			{ path: 'offensiveDamageAffinity.Fire' },
			{ path: 'offensiveDamageAffinity.Ice' },
			{ path: 'offensiveDamageAffinity.Lightning' },
		],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_111',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Increases magic, chaos, nature, fire, ice, and lightning offensive affinities by a flat amount.',
	},
	'offense.ranged_affinities': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of the Marksman',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.08,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Range' },
			{ path: 'offensiveDamageAffinity.Piercing' },
			{ path: 'offensiveDamageAffinity.Blunt' },
			{ path: 'offensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_112',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases range, piercing, blunt, and slashing offensive affinities by a flat amount.',
	},
	'offense.melee_affinities_uncommon': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'of the Warrior',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.04,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Melee' },
			{ path: 'offensiveDamageAffinity.Piercing' },
			{ path: 'offensiveDamageAffinity.Blunt' },
			{ path: 'offensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_110',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases melee, piercing, blunt, and slashing offensive affinities by a flat amount.',
	},
	'offense.magic_affinities_uncommon': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'of the Student',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.04,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Magic' },
			{ path: 'offensiveDamageAffinity.Chaos' },
			{ path: 'offensiveDamageAffinity.Nature' },
			{ path: 'offensiveDamageAffinity.Fire' },
			{ path: 'offensiveDamageAffinity.Ice' },
			{ path: 'offensiveDamageAffinity.Lightning' },
		],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_111',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Increases magic, chaos, nature, fire, ice, and lightning offensive affinities by a flat amount.',
	},
	'offense.ranged_affinities_uncommon': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'of the Archer',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.04,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'offensiveDamageAffinity.Range' },
			{ path: 'offensiveDamageAffinity.Piercing' },
			{ path: 'offensiveDamageAffinity.Blunt' },
			{ path: 'offensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_112',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases range, piercing, blunt, and slashing offensive affinities by a flat amount.',
	},
	'offense.chance_on_hit_stun': {
		type: 'additive',
		hook: 'hit',
		rarity: 'rare',
		name: 'Stunning',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.4,
			},
		],
		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.enhanced_stun,
			enchantmentAmount: 1,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			refreshToAmount: 1,
		},
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_113',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Adds a chance to apply Enhanced Stun to an enemy on each hit; Enhanced Stun almost guarantees the enemy will miss on their next attack.',
	},
	'offense.chance_on_hit_silence': {
		type: 'additive',
		hook: 'hit',
		rarity: 'rare',
		name: 'Silencing',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.4,
			},
		],
		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.enhanced_silence,
			enchantmentAmount: 1,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			refreshToAmount: 1,
		},
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_114',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Adds a chance to apply Enhanced Silence to an enemy on each hit; Enhanced Silence forces an enemy to cast an auto-attack instead of an ability on their next attack.',
	},
	'offense.chance_on_hit_blind': {
		type: 'additive',
		hook: 'hit',
		rarity: 'rare',
		name: 'Blinding',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.4,
			},
		],
		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.blind,
			enchantmentAmount: 1,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			refreshToAmount: 1,
		},
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_115',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			"Adds a chance to apply Blind to an enemy on each hit; Blind reduces the accuracy of the enemy's next attack by 50%.",
	},
	'offense.chance_on_hit_vulnerability': {
		type: 'additive',
		hook: 'hit',
		rarity: 'rare',
		name: 'of Vulnerability',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.4,
			},
		],
		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.enhanced_vulnerability,
			enchantmentAmount: 1,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			refreshToAmount: 1,
		},
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_116',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Adds a chance to apply Enhanced Vulnerability to an enemy on each hit; Enhanced Vulnerability increases the damage dealt to enemies by the player by 30%.',
	},
	'offense.chance_on_hit_weakness': {
		type: 'additive',
		hook: 'hit',
		rarity: 'rare',
		name: 'Weakening',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.4,
			},
		],
		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.weakness,
			enchantmentAmount: 1,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			refreshToAmount: 1,
		},
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_117',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Adds a chance to apply Weakness to an enemy on each hit; Weakness decreases the damage dealt to the player by enemies by 30%.',
	},
	'offense.melee_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Melee',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.15,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.15,
				maxStrength: 0.2,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Melee' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_118',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive melee affinity by a flat amount.',
	},
	'offense.magic_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Magic',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.15,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.15,
				maxStrength: 0.2,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Magic' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_119',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive magic affinity by a flat amount.',
	},
	'offense.ranged_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Ranged',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.15,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.15,
				maxStrength: 0.2,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Range' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_120',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive range affinity by a flat amount.',
	},
	'offense.slashing_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Slashing',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Slashing' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_201',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive slashing affinity by a flat amount.',
	},
	'offense.piercing_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Piercing',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Piercing' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_202',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive piercing affinity by a flat amount.',
	},
	'offense.blunt_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Blunt',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Blunt' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_203',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive blunt affinity by a flat amount.',
	},
	'offense.fire_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Fire',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Fire' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_204',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive fire affinity by a flat amount.',
	},
	'offense.ice_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Ice',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Ice' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_205',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive ice affinity by a flat amount.',
	},
	'offense.lightning_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Lightning',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Lightning' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_206',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive lightning affinity by a flat amount.',
	},
	'offense.chaos_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Chaos',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Chaos' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_207',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive chaos affinity by a flat amount.',
	},
	'offense.nature_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Nature',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.2,
			},
			{
				tier: 3,
				requiredItemTier: 8,
				minStrength: 0.2,
				maxStrength: 0.25,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'offensiveDamageAffinity.Nature' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_208',
		slots: ['weapon'],
		reducedEffectSlots: ['shield'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases only the offensive nature affinity by a flat amount.',
	},
	'defense.protection_and_resistance': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Turtle',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 10,
				maxStrength: 15,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 15,
				maxStrength: 20,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'armor.protection' }, { path: 'armor.resistance' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_209',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases the Protection and Resistance stats by a flat amount.',
	},
	'defense.protection_and_resistance_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of the Armadillo',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 20,
				maxStrength: 30,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 30,
				maxStrength: 40,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'armor.protection' }, { path: 'armor.resistance' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_209',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases the Protection and Resistance stats by a flat amount.',
	},
	'defense.agility': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Rat',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 10,
				maxStrength: 15,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 15,
				maxStrength: 20,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'armor.agility' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_210',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases the Agility stat by a flat amount.',
	},
	'defense.agility_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of the Rabbit',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 20,
				maxStrength: 30,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 30,
				maxStrength: 40,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'armor.agility' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_210',
		slots: ['legs'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases the Agility stat by a flat amount.',
	},
	'defense.melee_affinities': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'of the Guard',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Melee' },
			{ path: 'defensiveDamageAffinity.Piercing' },
			{ path: 'defensiveDamageAffinity.Blunt' },
			{ path: 'defensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_211',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases melee, piercing, blunt, and slashing defensive affinities by a flat amount.',
	},
	'defense.melee_affinities_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of the Knight',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Melee' },
			{ path: 'defensiveDamageAffinity.Piercing' },
			{ path: 'defensiveDamageAffinity.Blunt' },
			{ path: 'defensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_211',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases melee, piercing, blunt, and slashing defensive affinities by a flat amount.',
	},
	'defense.magic_affinities': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'of the Druid',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Magic' },
			{ path: 'defensiveDamageAffinity.Chaos' },
			{ path: 'defensiveDamageAffinity.Nature' },
			{ path: 'defensiveDamageAffinity.Fire' },
			{ path: 'defensiveDamageAffinity.Ice' },
			{ path: 'defensiveDamageAffinity.Lightning' },
		],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_212',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Increases magic, chaos, nature, fire, ice, and lightning defensive affinities by a flat amount.',
	},
	'defense.magic_affinities_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of the Magus',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Magic' },
			{ path: 'defensiveDamageAffinity.Chaos' },
			{ path: 'defensiveDamageAffinity.Nature' },
			{ path: 'defensiveDamageAffinity.Fire' },
			{ path: 'defensiveDamageAffinity.Ice' },
			{ path: 'defensiveDamageAffinity.Lightning' },
		],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_212',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Increases magic, chaos, nature, fire, ice, and lightning defensive affinities by a flat amount.',
	},
	'defense.ranged_affinities': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'of the Militiaman',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Range' },
			{ path: 'defensiveDamageAffinity.Piercing' },
			{ path: 'defensiveDamageAffinity.Blunt' },
			{ path: 'defensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_213',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases range, piercing, blunt, and slashing defensive affinities by a flat amount.',
	},
	'defense.ranged_affinities_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of the Warden',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.04,
				maxStrength: 0.08,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.08,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'defensiveDamageAffinity.Range' },
			{ path: 'defensiveDamageAffinity.Piercing' },
			{ path: 'defensiveDamageAffinity.Blunt' },
			{ path: 'defensiveDamageAffinity.Slashing' },
		],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_213',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases range, piercing, blunt, and slashing defensive affinities by a flat amount.',
	},
	'defense.melee_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Melee',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Melee' }],
		imageSheetCSS: 'affix_runes_sprite_1',
		imageCSS: 'affix_rune_214',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.magic_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Magic',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Magic' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_215',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.ranged_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Ranged',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Range' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_216',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.slashing_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Slashing',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Slashing' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_217',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.piercing_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Piercing',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Piercing' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_218',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.blunt_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Blunt',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Blunt' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_219',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.fire_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Fire',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Fire' }],
		imageSheetCSS: 'affix_runes_sprite_2',
		imageCSS: 'affix_rune_220',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.ice_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Ice',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Ice' }],
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_301',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.lightning_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Lightning',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Lightning' }],
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_302',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.chaos_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Chaos',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Chaos' }],
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_303',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.nature_affinity': {
		type: 'additive',
		hook: 'stats',
		rarity: 'epic',
		name: 'of Nature',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.02,
				maxStrength: 0.06,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.06,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		statBuffs: [{ path: 'defensiveDamageAffinity.Nature' }],
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_304',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
	},
	'defense.stamina': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Boar',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 10,
				maxStrength: 15,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 15,
				maxStrength: 20,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 20,
				maxStrength: 30,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'armor.stamina' }],
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_305',
		slots: ['body', 'legs', 'helm', 'boots'],
		reducedEffectSlots: ['shield', 'gloves'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Stamina stat by a flat amount.',
	},
	'defense.stamina_rare': {
		type: 'additive',
		hook: 'stats',
		rarity: 'rare',
		name: 'of the Bear',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 10,
				maxStrength: 20,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 20,
				maxStrength: 30,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 30,
				maxStrength: 40,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 40,
				maxStrength: 50,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'armor.stamina' }],
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_305',
		slots: ['shield'],
		reducedEffectSlots: ['body', 'legs', 'helm'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your Stamina stat by a flat amount.',
	},
	'defense.health': {
		type: 'additive',
		hook: 'afterStatBuffs',
		rarity: 'common',
		name: 'of the Monkey',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 10,
				maxStrength: 30,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 30,
				maxStrength: 60,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 60,
				maxStrength: 90,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 90,
				maxStrength: 120,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_306',
		slots: ['shield'],
		reducedEffectSlots: ['body', 'legs', 'helm'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your maximum health by a flat amount.',
	},
	'defense.health_rare': {
		type: 'additive',
		hook: 'afterStatBuffs',
		rarity: 'uncommon',
		name: 'of the Gorilla',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 40,
				maxStrength: 80,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 80,
				maxStrength: 150,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 150,
				maxStrength: 250,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 250,
				maxStrength: 350,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_306',
		slots: ['shield'],
		reducedEffectSlots: ['body', 'legs', 'helm'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your maximum health by a flat amount.',
	},
	'defense.health_multiplier': {
		type: 'multiplicative',
		hook: 'afterStatBuffs',
		rarity: 'uncommon',
		name: 'of the Whale',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.005,
				maxStrength: 1.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 1.01,
				maxStrength: 1.05,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 1.05,
				maxStrength: 1.08,
			},
		],
		displayType: 'multiplier',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_307',
		slots: ['shield'],
		reducedEffectSlots: ['body', 'legs', 'helm'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your maximum health by a multiplier.',
	},
	'defense.health_multiplier_legendary': {
		type: 'multiplicative',
		hook: 'afterStatBuffs',
		rarity: 'legendary',
		name: 'of the Leviathan',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.05,
				maxStrength: 1.1,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 1.1,
				maxStrength: 1.2,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 1.2,
				maxStrength: 1.3,
			},
		],
		displayType: 'multiplier',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_307',
		slots: ['shield'],
		reducedEffectSlots: ['body', 'legs', 'helm'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Increases your maximum health by a multiplier.',
	},
	'defense.health_regen': {
		type: 'multiplicative',
		hook: 'healthRegen',
		rarity: 'rare',
		name: 'Healthy',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.01,
				maxStrength: 1.05,
			},
		],
		displayType: 'multiplier',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_308',
		combatAffix: true,
		relatedCategory: ['combat'],
		inactive: true,
	},
	'defense.health_per_resource_spent': {
		type: 'additive',
		hook: 'resourceConsumption',
		rarity: 'epic',
		name: 'Gluttonous',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 2,
				maxStrength: 4,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 4,
				maxStrength: 6,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 6,
				maxStrength: 8,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_309',
		slots: ['weapon', 'cape', 'arrows'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription:
			'Heals you for a flat amount for each unique item, a rune or ammo, consumed during combat. For example, if you consume 2 runes and a charm, this will trigger 3 times, once for each unique item consumed.',
	},
	'defense.health_per_kill': {
		type: 'additive',
		hook: 'kill',
		rarity: 'epic',
		name: "Apothecary's",
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 5,
				maxStrength: 10,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 10,
				maxStrength: 15,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 15,
				maxStrength: 20,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_310',
		slots: ['weapon', 'cape', 'arrows'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Heals you for a flat amount when you defeat an enemy.',
	},
	'defense.life_steal': {
		type: 'multiplicative',
		hook: 'damageInflicted',
		rarity: 'epic',
		name: 'of the Vampire',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_311',
		slots: ['weapon', 'cape', 'arrows'],
		combatAffix: true,
		relatedCategory: ['combat'],
		inactive: true,
	},
	'defense.elite_damage_reduction': {
		type: 'multiplicative',
		hook: 'damageModifier',
		rarity: 'uncommon',
		name: 'of the Champion',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 0.02,
				maxStrength: 0.04,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.04,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_312',
		slots: ['cape'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Reduces damage taken when hit by an elite.',
	},
	'defense.elite_damage_reduction_epic': {
		type: 'multiplicative',
		hook: 'damageModifier',
		rarity: 'epic',
		name: 'of the Conqueror',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 0.05,
				maxStrength: 0.1,
			},
			{
				tier: 3,
				requiredItemTier: 6,
				minStrength: 0.1,
				maxStrength: 0.15,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_312',
		slots: ['cape'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Reduces damage taken when hit by an elite.',
	},
	'defense.thorns': {
		type: 'additive',
		hook: 'reactivePostHit',
		rarity: 'rare',
		name: 'of the Rose',
		nameType: 'suffix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 15,
				maxStrength: 30,
			},
			{
				tier: 2,
				requiredItemTier: 3,
				minStrength: 30,
				maxStrength: 50,
			},
			{
				tier: 3,
				requiredItemTier: 5,
				minStrength: 50,
				maxStrength: 80,
			},
			{
				tier: 4,
				requiredItemTier: 7,
				minStrength: 80,
				maxStrength: 120,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_3',
		imageCSS: 'affix_rune_313',
		slots: ['shield'],
		reducedEffectSlots: ['body'],
		combatAffix: true,
		relatedCategory: ['combat'],
		longDescription: 'Reflects a flat amount of damage back to your attacker when you are hit.',
	},
	'wealth.junk_multiplier': {
		type: 'multiplicative',
		hook: 'loot',
		rarity: 'uncommon',
		name: 'Scavenger',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.01,
				maxStrength: 1.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 1.05,
				maxStrength: 1.1,
			},
		],
		displayType: 'multiplier',
		slots: ['necklace'],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_401',
		longDescription:
			'Increases amount of junk looted when junk is rolled. Does not increase base chance to loot junk.',
		relatedCategory: ['combat'],
	},
	'experience.skill_experience_multiplier': {
		type: 'multiplicative',
		hook: 'experience',
		rarity: 'legendary',
		name: 'Skillful',
		nameType: 'prefix',
		tiers: [
			{
				tier: 1,
				requiredItemTier: 1,
				minStrength: 1.005,
				maxStrength: 1.02,
			},
			{
				tier: 2,
				requiredItemTier: 5,
				minStrength: 1.04,
				maxStrength: 1.08,
			},
			{
				tier: 2,
				requiredItemTier: 6,
				minStrength: 1.08,
				maxStrength: 1.12,
			},
		],
		displayType: 'multiplier',
		slots: ['ring'],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_402',
		relatedCategory: ['combat', 'skilling'],
	},
	'experience.item_experience_multiplier': {
		type: 'multiplicative',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'Ensouled',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
		],
		displayType: 'percent',
		slots: ['arrows'],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_403',
		inactive: true,
		relatedCategory: ['combat', 'skilling'],
	},
	// Gathering affixes below - images are probably copy+pasted
	'mining.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Miner',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'mining' }],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_404',
		slots: ['pickaxe'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['mining'],
	},
	'mining.original_superheat': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Dwarven Superheating',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.02,
				maxStrength: 0.03,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.03,
				maxStrength: 0.04,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_405',
		slots: ['pickaxe'],
		skillingAffix: true,
		longDescription: 'Gives a chance to instantly smelt an ore into a bar while mining.',
		relatedCategory: ['mining'],
	},
	'mining.minimum_yield': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Minimum Mining Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_406',
		slots: ['pickaxe'],
		skillingAffix: true,
		relatedCategory: ['mining'],
	},
	'mining.maximum_yield': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Maximum Mining Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_406',
		slots: ['pickaxe'],
		skillingAffix: true,
		relatedCategory: ['mining'],
	},
	'mining.yield_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Mining Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_407',
		slots: ['pickaxe'],
		skillingAffix: true,
		relatedCategory: ['mining'],
	},
	'mining.inferno_effect': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Dwarven Quick Mining',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_408',
		slots: ['pickaxe'],
		skillingAffix: true,
		longDescription: 'Gives a chance to instantly complete another mining action when one is completed.',
		relatedCategory: ['mining'],
	},
	'mining.trash_heap': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of the Trash Heap',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 2,
			},
			{
				tier: 2,
				requiredItemTier: 7,
				minStrength: 2,
				maxStrength: 3,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_409',
		slots: ['pickaxe'],
		skillingAffix: true,
		longDescription: 'Increases amount collected when you mine Clay, Stone, Sand, Rune Slate, Coal, or Salt.',
		relatedCategory: ['mining'],
	},
	'foraging.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Forager',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'foraging' }],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_410',
		slots: ['hatchet'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['foraging'],
	},
	'foraging.minimum_yield': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Minimum Foraging Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_411',
		slots: ['hatchet'],
		skillingAffix: true,
		relatedCategory: ['foraging'],
	},
	'foraging.maximum_yield': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Maximum Foraging Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_412',
		slots: ['hatchet'],
		skillingAffix: true,
		relatedCategory: ['foraging'],
	},
	'foraging.yield_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Foraging Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_413',
		slots: ['hatchet'],
		skillingAffix: true,
		relatedCategory: ['foraging'],
	},
	'foraging.node_bonus_size': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Foraging Node Size',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_414',
		slots: ['hatchet'],
		skillingAffix: true,
		longDescription: 'Multiplies the size of found Foraging nodes.',
		relatedCategory: ['foraging'],
	},
	'foraging.original_nature': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Natural Foraging',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.04,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.04,
				maxStrength: 0.05,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_415',
		slots: ['hatchet'],
		skillingAffix: true,
		longDescription:
			'Gives you a chance whilst foraging to produce an additional log relevant to the zone you are gathering in.',
		relatedCategory: ['foraging'],
	},
	'fishing.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Fisher',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'fishing' }],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_416',
		slots: ['tacklebox'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.fishing_stats': {
		type: 'additive',
		hook: 'stats',
		rarity: 'legendary',
		name: 'Prepared',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'fishingBaitPower' }, { path: 'fishingReelPower' }, { path: 'fishingRarityPower' }],
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_417',
		slots: ['tacklebox'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.fishing_stats_reel': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'Reeling',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'fishingReelPower' }],
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_712',
		slots: ['tacklebox'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.fishing_stats_bait': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'Baiting',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'fishingBaitPower' }],
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_713',
		slots: ['tacklebox'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.fishing_stats_bonus': {
		type: 'additive',
		hook: 'stats',
		rarity: 'uncommon',
		name: 'Detecting',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'fishingRarityPower' }],
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_714',
		slots: ['tacklebox'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.success_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'common',
		name: 'of the Successful Fisher',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_418',
		slots: ['tacklebox'],
		skillingAffix: true,
		longDescription: 'Increases your success chance for any fishing action by a flat amount.',
		relatedCategory: ['fishing'],
	},
	'fishing.minimum_yield': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Minimum Fishing Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_419',
		slots: ['tacklebox'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.maximum_yield': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Maximum Fishing Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_4',
		imageCSS: 'affix_rune_420',
		slots: ['tacklebox'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.yield_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Fishing Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_501',
		slots: ['tacklebox'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'fishing.node_bonus_size': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Fishing Node Size',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_502',
		slots: ['tacklebox'],
		skillingAffix: true,
		relatedCategory: ['fishing'],
	},
	'farming.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Farmer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'farming' }],
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_503',
		slots: ['hoe'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['farming'],
	},
	'fishing.instant_node_search': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Instant Node Finding',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_504',
		slots: ['tacklebox'],
		skillingAffix: true,
		longDescription: 'Gives a chance to instantly find a fishing node when you begin searching for one.',
		relatedCategory: ['fishing'],
	},
	'enchanting.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Enchanter',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'enchanting' }],
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_505',
		slots: ['tome'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['enchanting'],
	},
	'enchanting.augmenting_success_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Gambling',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.005,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.01,
				maxStrength: 0.015,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.01,
				maxStrength: 0.02,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_506',
		slots: ['tome'],
		skillingAffix: true,
		longDescription:
			'Gives a flat chance to successfully augment an item if there is a chance to fail, otherwise it adds a chance to succeed twice in one action.',
		relatedCategory: ['enchanting'],
	},
	'enchanting.augmenting_transform_chance_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'Transforming',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_507',
		slots: ['tome'],
		skillingAffix: true,
		longDescription:
			'Multiplies the chance to transform an item. If an item has a 10% chance to transform and you have a 10% bonus transform chance, it becomes a 11% chance to transform. Additive to Chances Enchantment.',
		relatedCategory: ['enchanting'],
	},
	'enchanting.chance_to_preserve': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Scrollcrafting Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_508',
		slots: ['tome'],
		skillingAffix: true,
		relatedCategory: ['enchanting'],
	},
	'enchanting.critical_augmenting': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Critical Augmenting',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.0025,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.01,
				maxStrength: 0.0125,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.0125,
				maxStrength: 0.015,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_509',
		slots: ['tome'],
		skillingAffix: true,
		longDescription:
			'Gives a flat chance to increase the augment level of an item twice when successfully augmenting an item. i.e. +3 -> +5',
		relatedCategory: ['enchanting'],
	},
	'enchanting.chance_to_save_item': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Item Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.0025,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.01,
				maxStrength: 0.0125,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.0125,
				maxStrength: 0.015,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_510',
		slots: ['tome'],
		skillingAffix: true,
		longDescription:
			'Gives a flat chance to keep an item from breaking on augment failure. Gives a flat chance to keep an item from breaking on research failure while also giving scraps.',
		relatedCategory: ['enchanting'],
	},
	'enchanting.chance_to_save_item_but_downgrade': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Lesser Item Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.0025,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.01,
				maxStrength: 0.0125,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.0125,
				maxStrength: 0.015,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_511',
		slots: ['tome'],
		skillingAffix: true,
		longDescription:
			'Gives a flat chance to keep an item from breaking on augment failure, but it will be downgraded an augment level. Gives a flat chance to keep an item from breaking on research failure.',
		relatedCategory: ['enchanting'],
	},
	'enchanting.soul_wisdom': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Soul Wisdom',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_512',
		slots: ['tome'],
		skillingAffix: true,
		inactive: true,
		relatedCategory: ['enchanting'],
	},
	'runecrafting.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Runecrafter',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'runecrafting' }],
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_513',
		slots: ['chisel'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['runecrafting'],
	},
	'runecrafting.bonus_gathering_essence': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'uncommon',
		name: 'of Bonus Gathering Essence',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 3,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 3,
				maxStrength: 4,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 4,
				maxStrength: 5,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_514',
		slots: ['chisel'],
		skillingAffix: true,
		longDescription: 'Increases essence given whilst gathering by a flat amount.',
		relatedCategory: ['runecrafting'],
	},
	'runecrafting.bonus_combat_essence': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'uncommon',
		name: 'of Bonus Combat Essence',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 3,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 3,
				maxStrength: 4,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 4,
				maxStrength: 5,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_515',
		slots: ['chisel'],
		skillingAffix: true,
		longDescription: 'Increases essence given whilst fighting by a flat amount.',
		relatedCategory: ['runecrafting'],
	},
	'runecrafting.bonus_production_essence': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'uncommon',
		name: 'of Bonus Production Essence',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 3,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 3,
				maxStrength: 4,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 4,
				maxStrength: 5,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_516',
		slots: ['chisel'],
		skillingAffix: true,
		longDescription: 'Increases essence given whilst producing by a flat amount.',
		relatedCategory: ['runecrafting'],
	},
	'smithing.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Smith',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'smithing' }],
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_517',
		slots: ['tongs'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['smithing'],
	},
	'cooking.effective_level': {
		type: 'additive',
		hook: 'stats',
		rarity: 'common',
		name: 'of the Chef',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 5,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 6,
				maxStrength: 10,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 11,
				maxStrength: 15,
			},
		],
		displayType: 'number',
		statBuffs: [{ path: 'cooking' }],
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_518',
		slots: ['ladle'],
		reducedEffectSlots: ['ring', 'necklace'],
		skillingAffix: true,
		relatedCategory: ['cooking'],
	},
	'gathering.haste': {
		type: 'additive',
		hook: 'skillingDuration',
		rarity: 'legendary',
		name: 'of the Quick Gatherer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_519',
		slots: ['necklace'],
		skillingAffix: true,
		longDescription: 'Increases action speed while gathering by an additive amount.',
		relatedCategory: ['gathering'],
	},
	'gathering.yield_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Gathering Yield',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_5',
		imageCSS: 'affix_rune_519',
		slots: ['ring'],
		skillingAffix: true,
		relatedCategory: ['gathering'],
	},
	'gathering.effective_level_mult': {
		type: 'multiplicative',
		hook: 'stats',
		rarity: 'legendary',
		name: 'of the Ultimate Gatherer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'mining' },
			{ path: 'foraging' },
			{ path: 'fishing' },
			{ path: 'fishingBaitPower' },
			{ path: 'fishingReelPower' },
			{ path: 'fishingRarityPower' },
		],
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_601',
		slots: ['ring'],
		skillingAffix: true,
		longDescription:
			'Multiplicatively boosts your Mining, Foraging and Fishing effective levels (Excludes personal levels)',
		relatedCategory: ['gathering'],
	},
	'gathering.greater_chest_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Greater Chests',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_602',
		slots: ['ring'],
		skillingAffix: true,
		longDescription: 'Increases the chance to convert a normal chest into a greater chest in gathering.',
		relatedCategory: ['gathering'],
	},
	'gathering.arch_bonus_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Greater Archaeology',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_603',
		slots: ['arrows'],
		skillingAffix: true,
		longDescription:
			'Multiplicatively boosts the strength of your Archaeology enchantment. Does nothing if you do not have Archaeology active.',
		relatedCategory: ['gathering'],
	},
	'gathering.chance_to_double': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Gathering Doubling',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_604',
		slots: ['arrows'],
		skillingAffix: true,
		relatedCategory: ['gathering'],
	},
	'gathering.dangerous_zone_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of the Dangerous Gatherer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.3,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_605',
		slots: ['cape'],
		skillingAffix: true,
		longDescription: 'Multiplicatively boosts the chance for a dangerous gathering encounter.',
		relatedCategory: ['gathering'],
	},
	'gathering.dangerous_zone_chance_inverted': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of the Sneaky Gatherer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.3,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_605',
		slots: ['cape'],
		skillingAffix: true,
		longDescription: 'Multiplicatively decreases the chance for a dangerous gathering encounter.',
		relatedCategory: ['gathering'],
	},
	'gathering.bonus_shard_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Gathering Shards',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_606',
		slots: ['cape'],
		skillingAffix: true,
		longDescription: 'Gives a chance to produce an extra gathering shard when one is created.',
		relatedCategory: ['gathering'],
	},
	'production.haste': {
		type: 'additive',
		hook: 'skillingDuration',
		rarity: 'legendary',
		name: 'of the Quick Producer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_607',
		slots: ['necklace'],
		skillingAffix: true,
		longDescription: 'Increases action speed while producing by an additive amount.',
		relatedCategory: ['production'],
	},
	'production.bonus_shard_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Production Shards',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_608',
		slots: ['cape'],
		skillingAffix: true,
		longDescription: 'Gives a chance to produce an extra production shard when one is created.',
		relatedCategory: ['production'],
	},
	'production.effective_level_mult': {
		type: 'multiplicative',
		hook: 'stats',
		rarity: 'legendary',
		name: 'of the Ultimate Producer',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		statBuffs: [
			{ path: 'farming' },
			{ path: 'enchanting' },
			{ path: 'runecrafting' },
			{ path: 'smithing' },
			{ path: 'cooking' },
		],
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_609',
		slots: ['necklace'],
		skillingAffix: true,
		longDescription:
			'Multiplicatively boosts your Farming, Enchanting, Runecrafting, Smithing, and Cooking effective levels. (Excludes personal levels)',
		relatedCategory: ['production'],
	},
	'production.chance_to_double': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Production Doubling',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_610',
		slots: ['arrows'],
		skillingAffix: true,
		relatedCategory: ['production'],
	},
	'production.chance_to_preserve': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Production Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_611',
		slots: ['arrows'],
		skillingAffix: true,
		relatedCategory: ['production'],
	},
	'runecrafting.chance_to_double': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Runecrafting Doubling',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_612',
		slots: ['chisel'],
		skillingAffix: true,
		relatedCategory: ['runecrafting'],
	},
	'runecrafting.chance_to_preserve': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Runecrafting Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_613',
		slots: ['chisel'],
		skillingAffix: true,
		relatedCategory: ['runecrafting'],
	},
	'smithing.chance_to_double': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Smithing Doubling',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_614',
		slots: ['tongs'],
		skillingAffix: true,
		relatedCategory: ['smithing'],
	},
	'smithing.chance_to_preserve': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Smithing Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_615',
		slots: ['tongs'],
		skillingAffix: true,
		relatedCategory: ['smithing'],
	},
	'smithing.heat_cost_reduction': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Smithing Heat Cost Reduction',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_616',
		slots: ['tongs'],
		skillingAffix: true,
		relatedCategory: ['smithing'],
	},
	'smithing.material_cost_reduction': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Smithing Material Cost Reduction',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_617',
		slots: ['tongs'],
		skillingAffix: true,
		relatedCategory: ['smithing'],
	},
	'cooking.chance_to_double': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Cooking Doubling',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_618',
		slots: ['ladle'],
		skillingAffix: true,
		relatedCategory: ['cooking'],
	},
	'cooking.chance_to_preserve': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Cooking Preservation',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.03,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.03,
				maxStrength: 0.05,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.05,
				maxStrength: 0.06,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_619',
		slots: ['ladle'],
		skillingAffix: true,
		relatedCategory: ['cooking'],
	},
	'cooking.food_quality_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Food Quality',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 2,
			},
			{
				tier: 2,
				requiredItemTier: 5,
				minStrength: 2,
				maxStrength: 3,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_6',
		imageCSS: 'affix_rune_620',
		slots: ['ladle'],
		skillingAffix: true,
		longDescription: 'Increases the quality of food produced by a flat amount.',
		relatedCategory: ['cooking'],
	},
	'cooking.food_quality_bonus_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'uncommon',
		name: 'of Food Quality Chance',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.1,
				maxStrength: 1.1,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.2,
				maxStrength: 1.5,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.3,
				maxStrength: 1.8,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_701',
		slots: ['ladle'],
		skillingAffix: true,
		longDescription:
			'Gives a chance to increase quality of food. Chances over 100% give a chance to increase quality by more than one tier.',
		relatedCategory: ['cooking'],
	},
	'cooking.alchemy_quality_bonus': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Alchemy Amount',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 1,
				maxStrength: 2,
			},
			{
				tier: 2,
				requiredItemTier: 5,
				minStrength: 2,
				maxStrength: 3,
			},
		],
		displayType: 'number',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_702',
		slots: ['ladle'],
		skillingAffix: true,
		longDescription: 'Increases the amount of potions produced by a flat amount. Only affects potions, not food.',
		relatedCategory: ['cooking'],
	},
	'cooking.alchemy_quality_bonus_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'uncommon',
		name: 'of Alchemy Amount Chance',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.1,
				maxStrength: 1.1,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.2,
				maxStrength: 1.5,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.3,
				maxStrength: 1.8,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_703',
		slots: ['ladle'],
		skillingAffix: true,
		longDescription:
			'Gives a chance to increase amount of potions. Chances over 100% give a chance to increase amount by more than one. Only affect potions, not food.',
		relatedCategory: ['cooking'],
	},
	'cooking.heat_cost_reduction': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'rare',
		name: 'of Heat Cost Reduction',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_704',
		slots: ['ladle'],
		skillingAffix: true,
		relatedCategory: ['cooking'],
	},
	'cooking.miracle_chance': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'epic',
		name: 'of Miraculous Cooking',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_705',
		slots: ['ladle'],
		skillingAffix: true,
		longDescription: 'Gives a chance to not burn an item whilst cooking.',
		relatedCategory: ['cooking'],
	},
	'foraging.instant_node_clear': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Speedster Foraging',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.005,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.01,
				maxStrength: 0.015,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.015,
				maxStrength: 0.02,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_706',
		slots: ['hatchet'],
		skillingAffix: true,
		longDescription:
			'Gives a chance when finding a node to force the minimum gathering time on all gathering actions until that node is cleared.',
		relatedCategory: ['foraging'],
	},
	'fishing.instant_node_clear': {
		type: 'additive',
		hook: 'scripted',
		rarity: 'legendary',
		name: 'of Speedster Fishing',
		nameType: 'suffix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.005,
				maxStrength: 0.01,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.01,
				maxStrength: 0.015,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.015,
				maxStrength: 0.02,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_707',
		slots: ['tacklebox'],
		skillingAffix: true,
		longDescription:
			'Gives a chance when finding a node to force the minimum gathering time on all gathering actions until that node is cleared.',
		relatedCategory: ['fishing'],
	},
	'farming.water_totem': {
		type: 'multiplicative',
		hook: 'scripted',
		rarity: 'epic',
		name: 'Water Totem Enhancing',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_708',
		slots: ['hoe'],
		skillingAffix: true,
		longDescription: 'Multiplicatively increases the potency of the totem.',
		relatedCategory: ['farming'],
	},
	'farming.earth_totem': {
		type: 'multiplicative',
		hook: 'scripted',
		rarity: 'epic',
		name: 'Earth Totem Enhancing',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_709',
		slots: ['hoe'],
		skillingAffix: true,
		longDescription: 'Multiplicatively increases the potency of the totem.',
		relatedCategory: ['farming'],
	},
	'farming.nature_totem': {
		type: 'multiplicative',
		hook: 'scripted',
		rarity: 'epic',
		name: 'Nature Totem Enhancing',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_710',
		slots: ['hoe'],
		skillingAffix: true,
		longDescription: 'Multiplicatively increases the potency of the totem.',
		relatedCategory: ['farming'],
	},
	'farming.coalescing_totem': {
		type: 'multiplicative',
		hook: 'scripted',
		rarity: 'epic',
		name: 'Coalescing Totem Enhancing',
		nameType: 'prefix',
		tiers: [
			{
				requiredItemTier: 1,
				tier: 1,
				minStrength: 0.01,
				maxStrength: 0.05,
			},
			{
				tier: 2,
				requiredItemTier: 4,
				minStrength: 0.05,
				maxStrength: 0.07,
			},
			{
				tier: 3,
				requiredItemTier: 7,
				minStrength: 0.07,
				maxStrength: 0.1,
			},
		],
		displayType: 'percent',
		imageSheetCSS: 'affix_runes_sprite_7',
		imageCSS: 'affix_rune_711',
		slots: ['hoe'],
		skillingAffix: true,
		longDescription: 'Multiplicatively increases the potency of the totem.',
		relatedCategory: ['farming'],
	},
};
