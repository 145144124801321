import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react';
import { IItemSource } from '../../../../../game-server/src/modules/stat-tracking/StatTracking.interface';
import { itemList } from '../../../utils/itemList';
import { locations } from '../../../utils/locationList';
import { monsters } from '../../../utils/monsterList';
import ChatItem from '../../game/Chat/Links/ChatItem';
import { useLocalStorage } from 'usehooks-ts';
import { formatNumberPerHour } from '../../../helper/helperFunctions';
import ChatSkill from '../../game/Chat/Links/ChatSkill';

export const ItemLogSource2 = ({
	sourceName,
	sourceData,
	sourceLogTime,
	perHour,
}: {
	sourceName: string;
	sourceData: IItemSource;
	sourceLogTime: Date;
	perHour: boolean;
}) => {
	const [showLevels] = useLocalStorage('navbar-showLevels', true);
	function getBackgroundImage() {
		const possibleLocation = Object.values(locations).find((location) => location.name === sourceName);
		if (possibleLocation) {
			return possibleLocation.locationImage;
		}

		const possibleMonster = Object.values(monsters).find((monster) => monster.name === sourceName);
		if (possibleMonster) {
			return possibleMonster.image;
		}
		if (sourceName === 'Experience') {
			return itemList[403].itemImage;
		}

		const firstLoot = sourceData.items ? sourceData.items[0] : null;
		return firstLoot ? itemList[firstLoot.itemID].itemImage : null;
	}

	function renderLoot(consumed?: boolean) {
		const lootData = sourceData.items.filter((item) => item.consumed === consumed);
		if (lootData.length === 0 && consumed) return;
		return (
			<AccordionPanel
				width='95%'
				margin='0 auto'
				borderBottomRadius='5px'
				boxShadow='0 2px 5px #000000, 0 0 5px #000000 inset'
				position='relative'
				overflow='hidden'
				paddingBottom='2'
			>
				<Box
					background={`${
						consumed ? 'linear-gradient(0deg, rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0.5)),' : ''
					} url(${getBackgroundImage()})`}
					backgroundSize='300% 300%'
					backgroundPosition='center'
					backgroundRepeat='no-repeat'
					filter='blur(50px)'
					position='absolute'
					top='0'
					left='0'
					right='0'
					bottom='0'
					zIndex='-1'
				/>
				{lootData.map((loot) => {
					let countText = consumed ? '-' : '';
					countText += perHour
						? formatNumberPerHour(loot.count, sourceLogTime)
						: (consumed ? '' : 'x') + loot.count.toLocaleString();
					const itemData = itemList[loot.itemID];
					return (
						<Flex
							key={'loot-log-source-' + sourceName + itemData.id + JSON.stringify(loot.additionalInfo)}
							margin='3px 0'
							justifyContent='space-between'
						>
							<Box>
								{sourceName === 'Experience' ? (
									<ChatSkill
										skill={{
											name: loot.additionalInfo?.exp,
											totalExp: loot.count,
										}}
										hideName={!showLevels}
									/>
								) : (
									<ChatItem
										item={{
											itemID: itemData.id,
											stackSize: loot.count,
											augmentations: loot.additionalInfo?.augmentations
												? Number(loot.additionalInfo?.augmentations)
												: undefined,
											enchantmentID: loot.additionalInfo?.enchantmentID
												? Number(loot.additionalInfo?.enchantmentID)
												: undefined,
										}}
										hideName={!showLevels}
									/>
								)}
							</Box>
							<Box fontWeight='bold'>{countText}</Box>
						</Flex>
					);
				})}
				{Object.keys(sourceData.items).length === 0 && <Box textAlign='center'>No loot yet.</Box>}
			</AccordionPanel>
		);
	}

	return (
		<AccordionItem key={'loot-log-source-' + sourceName.replaceAll(' ', '')} marginBottom='10px'>
			{({ isExpanded }) => {
				const countText = perHour
					? formatNumberPerHour(sourceData.count, sourceLogTime)
					: 'x' + sourceData.count.toLocaleString();
				return (
					<>
						<AccordionButton
							border='none'
							margin='0'
							filter={!isExpanded ? 'grayscale(1)' : 'unset'}
							_hover={{ filter: 'brightness(1.2)' }}
							background={`linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 100%), url(${getBackgroundImage()})`}
							backgroundPosition='center'
							borderRadius='5px'
							boxShadow='0 2px 5px #000000, 0 0 5px #000000 inset'
							zIndex='100'
							cursor='pointer'
						>
							<Flex
								width='100%'
								textShadow='0 0 2px #000000'
								fontWeight='bold'
								backdropBlur='lg'
								justifyContent='space-between'
							>
								<Text margin={0} padding={0}>
									{sourceName}
								</Text>{' '}
								<Text margin={0} padding={0} fontWeight='bold'>
									{countText}
								</Text>
							</Flex>

							<AccordionIcon />
						</AccordionButton>
						{renderLoot()}
						{renderLoot(true)}
					</>
				);
			}}
		</AccordionItem>
	);
};
