import { Text } from '@chakra-ui/react';
import React from 'react';
import { itemIcon, SubHeadingWithIcons } from '../../../../helper/NavigationTabsList';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';

function CraftingGuide() {
	return (
		<>
			Crafting works differently from all the other skills as in that it has no action time. When you craft
			something, it will be instantly made and placed into your inventory, so long as you have the required
			ingredients.
			<br />
			There are several different tabs you are able to click between, each one specializing on specific crafts for
			different skills or combat styles. Sometimes when clicking on a craft, it will have multiple different
			crafting recipes which can be switched between by clicking on the numbers above the ingredient requirements.
			For example, Fertilizer has 8 different crafts, each one giving you different ingredient requirements such
			as clay, sand, bones, and so forth.
			<br />
			To increase how many items you want to make per craft button click, you can input a value above said craft
			button or click the up and down arrows. Alternatively if you want to make as many as you possibly can with
			what ingredients you currently have available in your inventory, you can click max.
			<br />
			If you are unable to craft certain items, they may be locked behind a quest.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.scroll_of_crafting)]}>
				Crafting Enchantment
			</SubHeadingWithIcons>
			If you want to save yourself some ingredients per craft, you can enchantment some boots or gloves with the
			Crafting enchantment, which will save you 2% ingredients per enchantment level. Crafting 7 means 14% less
			crafting Ingredients needed per craft. Please note that this enchantment works per item, which means if
			something costs one ingredient per craft and you are making 270 of that item, it will still cost you 270 of
			that ingredient.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</>
	);
}

export default CraftingGuide;
