import { Box, Flex, Grid, Image, useDisclosure, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { IQuestData } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { socket } from '../../../services/socket.service';
import { questPointIcon } from '../../../helper/itemHelperFunctions';
import { itemList } from '../../../utils/itemList';
import { tabs } from '../../../helper/NavigationTabsList';
import { questList } from '../../../utils/questList';
import { talentList } from '../../../utils/talentList';
import FakeItem from '../../game/Inventory/FakeItem';

export default function QuestPopup() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [questID, setQuestID] = useState<number | null>(null);

	useEffect(() => {
		socket.on('quest', (data) => {
			setQuestID(data.questID);
			onOpen();
		});

		return () => {
			socket.off('quest');
		};
	}, []);

	function handleCloseModal() {
		setQuestID(null);
		onClose();
	}

	function renderQuestRewards(quest: IQuestData) {
		let experienceObj: React.ReactElement[] = [];
		if (quest.questRewards?.experience) {
			experienceObj = Object.entries(quest.questRewards.experience).map(([skill, expReward]) => {
				const skillIcon = tabs[skill].icon;
				return (
					<Flex
						key={`exp-reward-${skill}`}
						margin='1px'
						padding='1px'
						flexDirection='column'
						textAlign='center'
						justifyContent='center'
					>
						<Image width='50px' height='50px' src={skillIcon} />
						{expReward.toLocaleString()}
					</Flex>
				);
			});
		}
		let items: React.ReactNode[] = [];
		if (quest.questRewards?.items) {
			items = quest.questRewards.items.map((reward) => {
				if (!quest.questRewards?.items) return null;
				const itemData = itemList[reward.id];
				const newItem: TPartialItemWithItemID<IItem> = {
					itemID: itemData.id,
					augmentations: reward.extra?.augmentations,
					enchantmentID: reward.extra?.enchantmentID,
					enchantmentStrength: reward.extra?.enchantmentStrength,
					name: reward.extra?.name,
					stackSize: reward.amount,
				};
				return <FakeItem item={newItem} key={`reward-item-${reward.id}`} />;
			});
		}
		let talents: React.ReactNode[] = [];
		if (quest.questRewards?.relatedTalent) {
			talents = quest.questRewards.relatedTalent.map((talent) => {
				return (
					<Box key={`talent-reward-${talent}`} margin='1px' padding='1px'>
						{talentList[talent].name}
					</Box>
				);
			});
		}
		if (
			experienceObj.length === 0 &&
			items.length === 0 &&
			!quest.questRewards?.questPoints &&
			talents.length === 0
		) {
			return null;
		}
		return (
			<>
				<IdlescapeContainer display='flex' flexDirection='column' textAlign='center' width={'100%'}>
					<Flex justifyContent='center' margin='auto' flexDirection='column'>
						{quest.questRewards?.questPoints && (
							<Text fontSize='xl' marginBottom='1px'>
								{questPointIcon(24)}
								{quest.questRewards.questPoints} Quest Point
								{quest.questRewards.questPoints > 1 && 's'}
							</Text>
						)}

						{talents.length > 0 && (
							<>
								<Text fontSize='xl' marginBottom='1px'>
									Talents:
								</Text>
								<Grid
									gridTemplateColumns='repeat(auto-fill, minmax(100px, 1fr))'
									gap='10px'
									width='100%'
								>
									{talents.map((talent, index) => (
										<Box key={`${index}-${talent?.toString()}-talent`} padding='2px'>
											{talent}
										</Box>
									))}
								</Grid>
							</>
						)}

						{experienceObj.length > 0 && (
							<>
								<Text fontSize='xl' marginBottom='1px'>
									Experience:
								</Text>
								<Flex gap='10px' justifyContent='center' fontSize='xl' flexWrap='wrap'>
									{experienceObj.map((exp, index) => (
										<Box key={`${index}-${exp?.toString()}-exp`} margin='auto'>
											{exp}
										</Box>
									))}
								</Flex>
							</>
						)}

						{items.length > 0 && (
							<>
								<Text fontSize='xl' marginBottom='1px'>
									Items:
								</Text>
								<Flex width='100%' justifyContent='center' marginBottom='1px' flexWrap='wrap'>
									{items.map((item, index) => (
										<Box key={`${index}-${item?.toString()}-item`} padding='3px'>
											{item}
										</Box>
									))}
								</Flex>
							</>
						)}
					</Flex>
				</IdlescapeContainer>
			</>
		);
	}

	function renderQuest() {
		if (!questID) {
			return (
				<React.Fragment key={questID}>
					<DialogTitle>Invalid Quest</DialogTitle>
				</React.Fragment>
			);
		}
		const questData = questList[questID];
		if (!questData) {
			return (
				<React.Fragment key={questID}>
					<DialogTitle>Invalid Quest</DialogTitle>
				</React.Fragment>
			);
		}
		const isAchievement = questData.achievement;

		return (
			<React.Fragment key={questID}>
				<DialogTitle>
					{questData.difficulty} {isAchievement ? 'Achievement' : 'Quest'} Completed!
				</DialogTitle>
				<div className='popup-content'>
					<Flex direction='column' align='center'>
						{questData.questIcon ? (
							<Image
								src={questData.questIcon}
								alt={questData.name}
								width='auto'
								height='auto'
								maxWidth='150px'
								maxHeight='150px'
							/>
						) : (
							''
						)}
						<h1>{questData.name}</h1>
						{renderQuestRewards(questData)}
					</Flex>
				</div>
			</React.Fragment>
		);
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseModal}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			{renderQuest()}
			<DialogActions>
				<IdlescapeButton variant='red' onClick={handleCloseModal} size='medium'>
					Close
				</IdlescapeButton>
			</DialogActions>
		</Dialog>
	);
}
