import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { messageAtom } from '../atoms/messageAtom';
import { produce } from 'immer';
import { IChannelSettings, IChatMessage, IWhisperMessage } from '../../../game-server/src/modules/chat/Chat.interface';
import { getSocket } from './socket.service';
import { activeChannelAtom } from '../atoms/activeChannelAtom';
import { usePlayerField } from '../hooks/hooks';
import { changeFaviconToDefault, changeFaviconToNotify, playNotificationAudio } from '../helper/helperFunctions';

const MAX_MESSAGES = 150;

export const ChatMessageManager = ({
	loaded,
	setLoaded,
}: {
	loaded?: boolean;
	setLoaded: (value: boolean) => void;
}) => {
	const [messages, setMessages] = useRecoilState(messageAtom);
	const activeChannel = useRecoilValue(activeChannelAtom);
	const socket = getSocket();
	const playerSettings = usePlayerField('settings');
	const blockList = usePlayerField('blockList');

	const channelKeys = Object.keys(messages);
	const selectedChannelIdOrName = channelKeys[activeChannel];

	const username = usePlayerField('username');
	const accountStatus = usePlayerField('accountStatus');
	const isMod = accountStatus !== 'normal';
	const disableStaffPing = playerSettings.chat.disableStaffPing;
	const helper = playerSettings.chat.helper;

	useEffect(() => {
		// Mark messages as read if the tab is active
		if (document.hasFocus()) readChannelMessages(selectedChannelIdOrName);
	});

	let systemMessageIncrementingId = 0;

	useEffect(() => {
		console.log('Chat Message Manager started');
		setMessages(
			produce((draft) => {
				if (!draft['Activity']) {
					const settings: IChannelSettings = {
						channelName: 'Activity',
						channelId: null,
						type: 'settings',
					};

					draft['Activity'] = [settings];
				}
				if (!draft['Group Chat']) {
					const settings: IChannelSettings = {
						channelName: 'Group Chat',
						channelId: -1,
						type: 'settings',
					};

					draft['Group Chat'] = [settings];
				}
			})
		);
		/** Subscribe on mount */
		socket.on('chat:message:send', (data) => {
			setMessages(
				produce((draft) => {
					const channelId = data.channelId;
					const channelName = data.channelName;

					// This will send to all channels
					if (channelId === 0) {
						for (const channelID of Object.keys(draft)) {
							const channel = draft[channelID];

							channel.push({ ...data, read: true });
						}

						return;
					}

					/** Handles normal channel settings */
					if (
						channelId !== null &&
						!draft[channelId] &&
						'type' in data &&
						data.type === 'settings' &&
						channelName
					) {
						// Note from Nick- I have no idea when this particular case is actually hit?
						draft[channelId] = [data];
					}

					/** Handles whispers, as they require some custom logic (no channelId, instead use channelName) */
					if (data.type === 'whisper' && data.channelName !== 'Group Chat') {
						if (blockList.some((blockedUser) => blockedUser.username === data.username)) return;
						const whisperChannelName = data.username == username ? data.channelName : data.username;

						let hasMention = hasMessageMention(data.message, whisperChannelName, data.username);

						// prevents double ping
						let playedPing = false;
						if (hasMention) {
							changeFaviconToNotify();
							if (playerSettings.sounds.playSoundOnMention) {
								playNotificationAudio(playerSettings.sounds.volume);
								playedPing = true;
							}
						}

						if (!draft[whisperChannelName]) {
							const settings: IChannelSettings = {
								channelName: whisperChannelName,
								channelId: null,
								type: 'settings',
							};
							draft[whisperChannelName] = [settings];

							if (!playedPing && data.username !== username) {
								changeFaviconToNotify();
								if (playerSettings.sounds.playSoundOnWhisper) {
									playNotificationAudio(playerSettings.sounds.volume);
								}
							}

							// First message counts as a mention
							// Needed to change the favicon back to normal once the user reads it
							hasMention = true;
						}

						draft[whisperChannelName].push({
							...data,
							read: false,
							hasMention: hasMention,
						});

						const messageAmount = draft[whisperChannelName].length;
						if (messageAmount > MAX_MESSAGES) {
							// Splice X amount of messages from the array
							draft[whisperChannelName].splice(1, messageAmount - MAX_MESSAGES);
						}
						return;
					}

					if (channelId && data.type !== 'settings' && draft[channelId]) {
						if (blockList.some((blockedUser) => blockedUser.username === data.username)) return;
						const hasMention =
							data.type !== 'system-message' &&
							hasMessageMention(data.message, channelName, data.username);
						draft[channelId].push({
							...data,
							read: false,
							hasMention: hasMention,
						});

						if (hasMention) {
							changeFaviconToNotify();
							if (playerSettings.sounds.playSoundOnMention) {
								playNotificationAudio(playerSettings.sounds.volume);
							}
						}

						const messageAmount = draft[channelId].length;
						if (messageAmount > MAX_MESSAGES) {
							// Splice X amount of messages from the array
							draft[channelId].splice(1, messageAmount - MAX_MESSAGES);
						}
						return;
					}

					if (
						!channelId &&
						data.type === 'system-message' &&
						(channelName === 'Activity' || channelName === 'Group Chat')
					) {
						data.localIncrementingId = systemMessageIncrementingId++;
						draft[channelName].push({
							...data,
							read: false,
							hasMention: hasMessageMention(data.message, channelName, data.username),
						});

						const messageAmount = draft[channelName].length;
						if (messageAmount > MAX_MESSAGES) {
							// Splice X amount of messages from the array
							draft[channelName].splice(1, messageAmount - MAX_MESSAGES);
						}
						return;
					}

					if (!channelId && data.type === 'whisper' && channelName === 'Group Chat') {
						if (blockList.some((blockedUser) => blockedUser.username === data.username)) return;
						const hasMention = hasMessageMention(data.message, channelName, data.username);
						draft[channelName].push({
							...data,
							read: false,
							hasMention: hasMention,
						});

						if (hasMention) {
							changeFaviconToNotify();
							if (playerSettings.sounds.playSoundOnMention) {
								playNotificationAudio(playerSettings.sounds.volume);
							}
						}

						const messageAmount = draft[channelName].length;
						if (messageAmount > MAX_MESSAGES) {
							// Splice X amount of messages from the array
							draft[channelName].splice(1, messageAmount - MAX_MESSAGES);
						}
						return;
					}
				})
			);
		});

		socket.on('chat:channel:delete', (data) => {
			setMessages(
				produce((draft) => {
					delete draft[data.channelId];
				})
			);
		});

		socket.on('chat:message:history:send', (data) => {
			processHistory(data);
		});

		socket.on('chat:message:delete', (data) => {
			setMessages(
				produce((draft) => {
					const channelId = data.channelId;
					const messageId = data.messageId;
					const deleted = data.deleted;

					if (channelId && draft[channelId]) {
						draft[channelId] = draft[channelId].map((message) => {
							if (message.type === 'channel' && message.messageId === messageId) {
								message.deleted = deleted;
							}
							return message;
						}) as any; // Put as any, didn't find a way to type this properly
					}
				})
			);
		});

		socket.on('chat:user:purge', (username) => {
			setMessages(
				produce((draft) => {
					const now = new Date();
					const fiveMinutesAgo = new Date(now.getTime() - 5 * 60000);

					for (const channelId in draft) {
						draft[channelId] = draft[channelId].map((message) => {
							if (
								message.type === 'channel' &&
								message.username === username &&
								new Date(message.createdAt) > fiveMinutesAgo
							) {
								message.deleted = true;
							}

							return message;
						}) as any; // Put as any, didn't find a way to type this properly
					}
				})
			);
		});

		setLoaded(true);

		/** Unsubscribe on unmount */
		return () => {
			console.log('Chat Message Manager stopped working');
			socket.off('chat:message:send');
			socket.off('chat:message:history:send');
			socket.off('chat:message:delete');
			socket.off('chat:channel:delete');
			socket.off('chat:user:purge');
			setLoaded(false);
		};
	}, [blockList]);

	// changed the type from IChatMessage[] | IWhisperMessage[] to (IChatMessage | IWhisperMessage)[]
	// to make typescript happy even though it's less exact
	async function processHistory(data: (IChatMessage | IWhisperMessage)[]) {
		if (data.length === 0) return;
		setMessages(
			produce((draft) => {
				const channelId = data[0]?.channelId;
				const channelName = data[0]?.channelName;

				data = data.filter(
					(message) => !blockList.some((blockedUser) => blockedUser.username === message.username)
				);

				if (channelId !== null) {
					draft[channelId] = [
						draft[channelId][0],
						...data.map((message) => ({
							...message,
							read: true,
							hasMention: hasMessageMention(message.message, channelName, message.username),
						})),
					];
				} else {
					if (!draft[channelName]) {
						const settings: IChannelSettings = {
							channelName: channelName,
							channelId: null,
							type: 'settings',
						};
						draft[channelName] = [settings];
					}
					draft[channelName] = [
						draft[channelName][0],
						...data.map((message) => ({
							...message,
							read: true,
							hasMention: hasMessageMention(message.message, channelName, message.username),
						})),
					];
				}
			})
		);
	}

	function readChannelMessages(channelId: string) {
		setMessages(
			produce((draft) => {
				if (!draft[channelId]) return;

				for (const message of draft[channelId]) {
					if (message.type !== 'settings' && !message.read) {
						message.read = true;
						if (message.hasMention) changeFaviconToDefault();
					}
				}
			})
		);
	}

	function hasMessageMention(message: string, channelName: string, senderName?: string): boolean {
		const usernameRegex = new RegExp('@' + username.toLowerCase() + '(\\W+|$)', 'g');
		const usernameMatch = message.toLowerCase().match(usernameRegex) !== null;
		const modMention = hasModMention(message);
		const helperMention = hasHelperMention(channelName, senderName);
		return usernameMatch || modMention || helperMention;
	}

	function hasModMention(message: string): boolean {
		const modRegex = new RegExp('@(mod|mods|moderator|moderators)(\\W+|$)', 'g');
		return !disableStaffPing && isMod && message.toLowerCase().match(modRegex) !== null;
	}

	function hasHelperMention(channelName: string, senderName?: string): boolean {
		if (senderName && senderName === username) return false;
		if (helper && channelName === 'Help') return true;
		return false;
	}

	return null;
};
