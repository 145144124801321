import { Box, Flex, Grid, Image } from '@chakra-ui/react';
import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useState } from 'react';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { usePlayerField, usePlayerSetting } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { getLoadoutIcon } from '../../../../helper/helperFunctions';
import Mercenary from './Mercenary';
import getMercenaryStatus from './MercenaryStatus';

export default function MercenaryEditor() {
	const loadouts = usePlayerField('loadouts');
	const mercLoadoutIDs = usePlayerSetting('combat.mercLoadouts');
	const mercLoadouts = mercLoadoutIDs
		.map((id) => loadouts.find((l) => l.loadoutId === id))
		.filter((l): l is ILoadout => !!l);
	const availableLoadouts = loadouts.filter((l) => !mercLoadoutIDs.includes(l.loadoutId));
	const mercStatus = getMercenaryStatus(mercLoadouts);

	const [activeMercIndex, setActiveMercIndex] = useState(-1);

	function toggleMercenary(loadoutID: number) {
		socket.emit('settings:mercLoadout', loadoutID);
	}

	function handleDragStart({ active }: DragStartEvent) {
		const mercIndex = mercLoadouts.findIndex((merc) => merc.loadoutId.toString() === active.id);
		setActiveMercIndex(mercIndex);
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		setActiveMercIndex(-1);
		if (!over) return;
		const newIndex = mercLoadouts.findIndex((merc) => merc.loadoutId.toString() === over.id);
		if (newIndex === -1) return;
		socket.emit('settings:mercLoadout:order', { loadoutID: Number(active.id), newIndex });
	}

	return (
		<div className='loadout-container'>
			<div className='merc-info'>
				<p>
					Mercenaries will attempt to be summoned if there are missing group slots in a Combat activity
					according to priority.
				</p>
				<p>
					Every mercenary present in the group will reduce the amount of vendor junk loot received by the
					entire party.
				</p>
				<p>
					Each mercenary is restricted from sharing equipment with others. Therefore, the player and the
					mercenaries cannot wear the same equipment simultaneously.
				</p>
				<p>
					Mercenaries eat the food from your loadout inventory immediately on spawning to boost their max HP
					by half of the total HP you would gain from eating that food and immediately gaining all enchantment
					stacks.
				</p>
				<p>
					Mercenaries will only respawn when ALL mercenaries have been defeated. If you are defeated, you may
					still rejoin the combat as normal as long as at least one mercenary survives until your respawn
					timer expires.
				</p>
			</div>
			<Box>
				<Grid
					gridTemplateColumns='30px 50px 1fr 50px 1fr 30px'
					alignItems='center'
					paddingX='30px'
					textAlign='center'
				>
					<Box />
					<Box>Status</Box>
					<Box>Loadout</Box>
					<Box>Use Food</Box>
					<Box>HP from Food</Box>
				</Grid>
				<DndContext
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					modifiers={[restrictToVerticalAxis]}
				>
					<SortableContext
						items={mercLoadouts.map((merc) => merc.loadoutId.toString())}
						strategy={verticalListSortingStrategy}
					>
						{mercLoadouts.map((merc, index) => (
							<Mercenary
								key={merc.loadoutId}
								merc={merc}
								status={mercStatus[index]}
								active={merc.loadoutId === mercLoadouts[activeMercIndex]?.loadoutId}
							/>
						))}
						{mercLoadouts.length === 0 && (
							<Box textAlign='center' padding='20px'>
								No mercenaries are currently active. Select a loadout below.
							</Box>
						)}
					</SortableContext>
				</DndContext>
			</Box>
			<hr />
			<Flex justifyContent='center' padding='20px' flexWrap='wrap'>
				{availableLoadouts.length > 0
					? availableLoadouts.map((loadout) => {
							const icon = getLoadoutIcon(loadout);
							return (
								<IdlescapeButton
									key={loadout.loadoutId}
									variant='blue'
									onClick={() => toggleMercenary(loadout.loadoutId)}
									width='auto'
								>
									<Image
										src={icon.icon}
										alt={icon.alt}
										width='20px'
										height='20px'
										objectFit='contain'
										filter='drop-shadow(2px 2px 2px black)'
										paddingRight='5px'
									/>
									{loadout.loadoutName}
								</IdlescapeButton>
							);
					  })
					: 'No available loadouts.'}
			</Flex>
		</div>
	);
}
