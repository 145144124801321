// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { ActionTypes } from './enums/ActionTypes';
import { TLeagueList } from '../../../game-server/src/modules/leagues/league.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const leagueList: TLeagueList = {
	1: {
		// name: 'Legacy - Default',
		name: 'Default',
		id: 1,
		rules: {
			marketplace: true,
			playerTrading: true,
			shrine: true,
			globalBuffs: true,
			platinumBuffs: true,
			eventShop: true,
			affixes: true,
			quests: true,
			achievements: true,
			talents: true,
		},
		active: true,
		// chatChannelId: 1, // Everyone gets this channel, regardless of league, just leaving this for reference
		tradeChannelId: 2,
		// icon: '/images/leagues/default_league_icon_legacy.png',
		icon: '/images/leagues/default_league_icon.png',
		levelColor: '#9dc3cf',
	},

	2: {
		// name: 'Legacy - Ironman',
		name: 'Ironman',
		id: 2,
		rules: {
			marketplace: false,
			playerTrading: false,
			shrine: true,
			globalBuffs: false,
			platinumBuffs: true,
			eventShop: true,
			affixes: true,
			quests: true,
			achievements: true,
			talents: true,
		},
		chatChannelId: 4,
		active: true,
		// icon: '/images/leagues/ironman_league_icon_legacy.png',
		icon: '/images/leagues/ironman_league_icon_v5.png',
		info: 'Ironmen characters cannot access marketplace or benefit from shrine. Ironmen will have their own hiscores to compete in.',
		levelColor: '#eda24f',
	},

	3: {
		name: 'No Gather Ironman',
		id: 3,
		rules: {
			marketplace: false,
			playerTrading: false,
			shrine: false,
			globalBuffs: false,
			platinumBuffs: true,
			eventShop: true,
			blacklistedSkills: [ActionTypes.Mining, ActionTypes.Foraging, ActionTypes.Fishing],
			affixes: true,
			quests: true,
			achievements: true,
			talents: true,
		},
		active: true,
		chatChannelId: 5,
		icon: '/images/leagues/nogather_ironman_league.png',
		info: 'Work In Progress | No Gather Ironmen characters cannot access marketplace, benefit from shrine, or access gathering skills. Ironmen will have their own hiscores to compete in.',
		levelColor: '#ad2e37',
	},

	4: {
		name: 'Group Ironman',
		id: 4,
		rules: {
			marketplace: false,
			playerTrading: false,
			shrine: false,
			globalBuffs: false,
			platinumBuffs: true,
			eventShop: true,
			affixes: false,
			quests: true,
			achievements: true,
			talents: true,
		},
		active: false,
		chatChannelId: 6,
		icon: '/images/leagues/group_ironman.png',
		info: 'Work In Progress | Group Ironmen characters cannot access marketplace or benefit from shrine, but share an inventory within their group. Ironmen will have their own hiscores to compete in.',
		levelColor: '#ff4e92',
	},

	5: {
		name: 'Pre-Season',
		id: 5,
		rules: {
			marketplace: true,
			playerTrading: false,
			shrine: false,
			globalBuffs: false,
			platinumBuffs: true,
			eventShop: true,
			affixes: false,
			quests: false,
			achievements: false,
			talents: false,
			endDate: '2023-10-03T00:00:00.000Z',
			leagueBuffs: [
				{
					id: enchantmentsIds.haste,
					strength: 20, // 80%
				},
				{
					id: enchantmentsIds.intuition,
					strength: 20, // 100%
				},
				{
					id: enchantmentsIds.treasure_hunter,
					strength: 11, // 33%
				},
				{
					id: enchantmentsIds.enlightenment,
					strength: 5,
				},
			],
			leagueStartingItems: [
				{
					id: itemsIds.gold,
					amount: 10000,
				},
				{
					id: itemsIds.heat,
					amount: 10000,
				},
				{
					id: itemsIds.bronze_daggers,
					amount: 1,
				},
				{
					id: itemsIds.flimsy_longbow,
					amount: 1,
				},
				{
					id: itemsIds.driftwood_staff,
					amount: 1,
				},
				{
					id: itemsIds.bronze_pickaxe,
					amount: 1,
				},
				{
					id: itemsIds.bronze_hatchet,
					amount: 1,
				},
				{
					id: itemsIds.novice_tacklebox,
					amount: 1,
				},
			],
			ignoreCharacterCount: true,
			maxDuplicateLeagueCharacters: 1,
		},
		active: false,
		icon: '/images/leagues/pre_league_test.png',
		info: 'Season Testing Gamemode | Does NOT use a character slot or active slot',
		levelColor: '#d090ff',
	},

	6: {
		name: 'Season 1',
		id: 6,
		rules: {
			marketplace: true,
			playerTrading: false,
			shrine: false,
			globalBuffs: false,
			platinumBuffs: true,
			eventShop: true,
			affixes: true,
			quests: false,
			achievements: false,
			talents: false,
			endDate: '2024-02-01T00:00:00.000Z',
			leagueBuffs: [
				{
					id: enchantmentsIds.intuition,
					strength: 10,
				},
			],
			ignoreCharacterCount: true,
			maxDuplicateLeagueCharacters: 1,
		},
		active: true,
		chatChannelId: 9,
		tradeChannelId: 11,
		icon: '/images/leagues/season1.png',
		info: 'Season 1 | Affixes | Does NOT use a character slot or active slot',
		levelColor: '#d090ff',
	},

	7: {
		name: 'Season 1 Ironman',
		id: 7,
		rules: {
			marketplace: false,
			playerTrading: false,
			shrine: false,
			globalBuffs: false,
			platinumBuffs: true,
			eventShop: true,
			affixes: true,
			quests: false,
			achievements: false,
			talents: false,
			endDate: '2024-02-01T00:00:00.000Z',
			leagueBuffs: [
				{
					id: enchantmentsIds.intuition,
					strength: 10,
				},
			],
			ignoreCharacterCount: true,
			maxDuplicateLeagueCharacters: 1,
		},
		active: true,
		chatChannelId: 10,
		icon: '/images/leagues/season1ironman.png',
		info: 'Season 1 Ironman | Affixes | Does NOT use a character slot or active slot',
		levelColor: '#b25cbd',
	},
	8: {
		name: 'Magic Catastrophe',
		id: 8,
		rules: {
			marketplace: true,
			playerTrading: false,
			shrine: true,
			globalBuffs: true,
			platinumBuffs: true,
			eventShop: true,
			affixes: true,
			quests: true,
			achievements: true,
			talents: true,
			buffMadness: true,
			endDate: '2024-09-01T00:00:00.000Z',
			leagueStartingItems: [
				{
					id: itemsIds.bronze_daggers,
					amount: 1,
				},
				{
					id: itemsIds.book,
					amount: 1,
					extraInfo: {
						storyID: 16,
					},
				},
				{
					id: itemsIds.water_essence,
					amount: 1000,
				},
				{
					id: itemsIds.log,
					amount: 8,
				},
				{
					id: itemsIds.small_bag_of_holding,
					amount: 1,
					extraInfo: {
						enchantmentID: enchantmentsIds.bag_of_holding,
						enchantmentStrength: 1,
					},
				},
			],
			leagueBuffs: [
				{
					id: enchantmentsIds.intuition,
					strength: 10,
				},
			],
			ignoreCharacterCount: true,
			maxDuplicateLeagueCharacters: 1,
		},
		active: true,
		chatChannelId: 12,
		tradeChannelId: 13,
		icon: '/images/leagues/season2.png',
		info: 'Season 2 | The Magic Catastrophe | Does NOT use a character slot or active slot',
		levelColor: 'rgb(234, 12, 12)',
		rewards: {
			icons: [{ iconID: 2004, totalLevelRequired: 1 }],
			placementBonusMultiplier: 0.1,
			tokenMultiplier: 0.5,
		},
	},
	9: {
		name: 'Magic Catastrophe Ironman',
		id: 9,
		rules: {
			marketplace: false,
			playerTrading: false,
			shrine: true,
			globalBuffs: true,
			platinumBuffs: true,
			eventShop: true,
			affixes: true,
			quests: true,
			achievements: true,
			talents: true,
			buffMadness: true,
			endDate: '2024-09-01T00:00:00.000Z',
			leagueStartingItems: [
				{
					id: itemsIds.bronze_daggers,
					amount: 1,
				},
				{
					id: itemsIds.book,
					amount: 1,
					extraInfo: {
						storyID: 16,
					},
				},
				{
					id: itemsIds.water_essence,
					amount: 1000,
				},
				{
					id: itemsIds.log,
					amount: 8,
				},
				{
					id: itemsIds.small_bag_of_holding,
					amount: 1,
					extraInfo: {
						enchantmentID: enchantmentsIds.bag_of_holding,
						enchantmentStrength: 1,
					},
				},
			],
			leagueBuffs: [
				{
					id: enchantmentsIds.intuition,
					strength: 10,
				},
				{
					id: enchantmentsIds.haste,
					strength: 10,
				},
			],
			ignoreCharacterCount: true,
			maxDuplicateLeagueCharacters: 1,
		},
		active: true,
		chatChannelId: 14,
		icon: '/images/leagues/season2_ironman.png',
		info: 'Season 2 | The Magic Catastrophe - Ironman | Does NOT use a character slot or active slot',
		levelColor: 'rgb(168, 9, 9)',
		rewards: {
			icons: [{ iconID: 2004, totalLevelRequired: 1 }],
			tokenMultiplier: 0.5,
		},
	},
	10: {
		name: 'Pre-Launch',
		id: 10,
		rules: {
			marketplace: true,
			playerTrading: true,
			shrine: true,
			globalBuffs: false,
			platinumBuffs: false,
			eventShop: true,
			affixes: true,
			quests: true,
			achievements: true,
			talents: true,
			expFormula: 'cubic',
			ignoreCharacterCount: true,
			endDate: '2024-09-30T00:00:00.000Z',
		},
		active: true,
		chatChannelId: 16,
		tradeChannelId: 17,
		icon: '/images/leagues/default_league_icon_legacy.png',
		levelColor: 'rgb(210, 225, 255)',
		rewards: {
			icons: [{ iconID: 2005, totalLevelRequired: 600 }],
			tokenMultiplier: 2,
		},
	},
};
