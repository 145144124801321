// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { IAffixes, IAffixTier } from '../../../game-server/src/modules/affixes/affixes.interface';
import { IItemData } from '../../../game-server/src/modules/items/items.interface';
import {
	AFFIX_COSTS,
	AFFIX_DUST_PER_RARITY,
	AFFIX_GEAR_SCRAP_PER_RARITY,
	AFFIX_REDUCED_EFFECT_MULTIPLIER,
	AFFIX_2HANDED_EFFECT_MULTIPLIER,
	AFFIX_SLOTS_PER_RARITY,
	RANDOM_AFFIX_COST,
	AFFIX_CLEAR_DUST_COST_MULTIPLIER,
	AFFIX_REROLL_DUST_COST_MULTIPLIER,
	AFFIX_CLEAR_SCRAP_COST_MULTIPLIER,
	AFFIX_GOLD_PER_MULT_RARITY,
	AFFIX_GOLD_BASE_COST,
	AFFIX_CLEAR_GOLD_COST_MULTIPLIER,
	AFFIX_BLACKLISTED_SLOTS,
	AFFIX_COMBAT_SLOTS,
	AFFIX_JEWELRY_SLOTS,
	AFFIX_TOOL_SLOTS,
	AFFIX_MAX_RARITY_BY_ITEM_RARITY,
} from './constantsCollection';
import { getItemTier } from '../utils/augmentingFunctions';

export function itemIsAffixable(itemData: IItemData) {
	return itemData.equipmentStats !== undefined && !AFFIX_BLACKLISTED_SLOTS.includes(itemData.equipmentStats.slot);
}

export function getCalculatedAffixStrengths(
	affixData: IAffixes,
	itemData: IItemData
): {
	maxStrength: number;
	minStrength: number;
	reducedEffect: boolean | undefined;
	finalMultiplier: number;
} {
	const affixTierData = getApplicableAffixTier(affixData, itemData);
	if (!affixTierData) {
		return { minStrength: 0, maxStrength: 0, reducedEffect: false, finalMultiplier: 0 };
	}

	const slot = itemData.equipmentStats?.slot;
	let finalMultiplier = 1;
	const reducedEffect = slot && affixData.reducedEffectSlots?.includes(slot);
	if (reducedEffect) finalMultiplier *= AFFIX_REDUCED_EFFECT_MULTIPLIER;
	if (slot === 'weapon' && !itemData.equipmentStats?.oneHanded) {
		finalMultiplier *= AFFIX_2HANDED_EFFECT_MULTIPLIER;
	}
	const isMultiplierWithModifiedEffect = finalMultiplier !== 1 && affixData.displayType === 'multiplier';
	let minStrength = affixTierData.minStrength;
	let maxStrength = affixTierData.maxStrength;
	if (isMultiplierWithModifiedEffect) {
		minStrength = (minStrength - 1) * finalMultiplier + 1;
		maxStrength = (maxStrength - 1) * finalMultiplier + 1;
	} else {
		minStrength *= finalMultiplier;
		maxStrength *= finalMultiplier;
	}

	return { minStrength, maxStrength, reducedEffect, finalMultiplier };
}

export function getAffixStrength(affix: IAffixes, value: number, itemData: IItemData) {
	const affixTierData = getApplicableAffixTier(affix, itemData);
	if (!affixTierData) return 0;

	let floatMultiplier = 1;
	const reducedStrength = !!affix.reducedEffectSlots;
	if (!(Number.isInteger(affixTierData.minStrength) && Number.isInteger(affixTierData.maxStrength))) {
		const minStrengthDecimals = countDecimals(affixTierData.minStrength) + (reducedStrength ? 1 : 0);
		const maxStrengthDecimals = countDecimals(affixTierData.maxStrength) + (reducedStrength ? 1 : 0);
		const decimals = minStrengthDecimals > maxStrengthDecimals ? minStrengthDecimals : maxStrengthDecimals;
		floatMultiplier = Math.max(100, 10 ** decimals);
	}

	const { minStrength, maxStrength } = getCalculatedAffixStrengths(affix, itemData);
	const minStrengthScaled = minStrength * floatMultiplier;
	const maxStrengthScaled = maxStrength * floatMultiplier;
	let finalAffixStrength = minStrengthScaled + (maxStrengthScaled - minStrengthScaled + 1) * value;
	finalAffixStrength = Math.floor(finalAffixStrength);
	finalAffixStrength /= floatMultiplier;
	finalAffixStrength = Math.max(Math.min(finalAffixStrength, maxStrength), minStrength);
	return finalAffixStrength;
}

function countDecimals(value: number) {
	const text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf('e-') > -1) {
		const trail = text.split('e-')[1];
		return parseInt(trail, 10);
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split('.')[1].length || 0;
	}
	return 0;
}

export function getAffixesSlotsForItem(itemData: IItemData) {
	return AFFIX_SLOTS_PER_RARITY[itemData.rarity ?? 'common'];
}

export function getDustTypeIdForItem(itemData: IItemData) {
	return AFFIX_DUST_PER_RARITY[itemData.rarity ?? 'common'];
}

export function getAffixGoldCost(lockedAffixes: IAffixes[], itemData: IItemData): number {
	const itemRarity = itemData.rarity ?? 'common';
	const goldBaseCost = AFFIX_GOLD_BASE_COST * AFFIX_GOLD_PER_MULT_RARITY[itemRarity];
	let goldAffixMult = 1;
	for (const affix of lockedAffixes) {
		goldAffixMult *= AFFIX_GOLD_PER_MULT_RARITY[affix.rarity];
	}
	const finalGoldCost = Math.floor(goldBaseCost * goldAffixMult);

	return finalGoldCost;
}

export function getAffixCost(
	itemData: IItemData | undefined,
	affixes: IAffixes[],
	method: 'add' | 'reroll' | 'clear' = 'add'
): {
	dust: { id: number; amount: number };
	scrap: { id: number; amount: number };
	gold: { id: number; amount: number };
} {
	const cost = { dust: { id: -1, amount: 0 }, scrap: { id: -1, amount: 0 }, gold: { id: 1, amount: 0 } };
	if (!itemData) return cost;

	const randomAffixDustCost = RANDOM_AFFIX_COST;

	const maxAffixesSlots = getAffixesSlotsForItem(itemData);
	const numRandomAffixesNeeded = maxAffixesSlots - affixes.length;
	const randomAffixesCost = numRandomAffixesNeeded * randomAffixDustCost;
	const affixesDustCost = affixes.reduce((acc, affix) => {
		const cost = AFFIX_COSTS[affix.rarity];
		return acc + cost;
	}, 0);
	cost.dust.id = getDustTypeIdForItem(itemData);
	cost.dust.amount = randomAffixesCost + affixesDustCost;

	cost.gold.amount = getAffixGoldCost(affixes, itemData);
	cost.gold.amount *= method === 'clear' ? AFFIX_CLEAR_GOLD_COST_MULTIPLIER : 1;

	if (method === 'add') return cost;

	cost.dust.amount *= method === 'clear' ? AFFIX_CLEAR_DUST_COST_MULTIPLIER : AFFIX_REROLL_DUST_COST_MULTIPLIER;
	cost.scrap.id = AFFIX_GEAR_SCRAP_PER_RARITY[itemData.rarity ?? 'common'];
	cost.scrap.amount = method === 'clear' ? AFFIX_CLEAR_SCRAP_COST_MULTIPLIER : 1;

	return cost;
}

export function getApplicableAffixTier(affix: IAffixes, itemData: IItemData): IAffixTier | undefined {
	const itemTier = getItemTier(itemData);
	const sortedValidTiers = affix.tiers
		.filter((tier) => tier.requiredItemTier <= itemTier)
		.sort((a, b) => b.requiredItemTier - a.requiredItemTier);
	if (sortedValidTiers.length === 0) return undefined;
	return sortedValidTiers[0];
}

export function areValidAffixesForItem(itemData: IItemData, affixes: IAffixes[]) {
	const itemSlot = itemData?.equipmentStats?.slot;
	if (!itemSlot || AFFIX_BLACKLISTED_SLOTS.includes(itemSlot)) return false;

	const alreadySelectedAffixes: string[] = [];
	for (const affix of affixes) {
		if (!affix) {
			return false;
		}

		if (alreadySelectedAffixes.includes(affix.name)) {
			return false;
		}
		const affixTier = getApplicableAffixTier(affix, itemData);
		if (!affixTier) {
			return false;
		}
		if (affix.inactive) {
			return false;
		}
		if (affix.slots) {
			if (!itemSlot) {
				return false;
			}
			const allSlots = [...affix.slots, ...(affix.reducedEffectSlots ?? [])];
			if (!allSlots.includes(itemSlot)) {
				return false;
			}
		}
		if (itemSlot && (affix.skillingAffix || affix.combatAffix) && !AFFIX_JEWELRY_SLOTS.includes(itemSlot)) {
			if (affix.skillingAffix && AFFIX_COMBAT_SLOTS.includes(itemSlot)) {
				return false;
			}
			if (affix.combatAffix && AFFIX_TOOL_SLOTS.includes(itemSlot)) {
				return false;
			}
		}
		const isRarityExceedingMax = doesRarityExceedMax(itemData, affix);
		if (isRarityExceedingMax) {
			return false;
		}
		alreadySelectedAffixes.push(affix.name);
	}
	return true;
}

export function doesRarityExceedMax(itemData: IItemData, affix: IAffixes) {
	const rarityAsNumbers = ['common', 'uncommon', 'rare', 'epic', 'legendary'];
	const affixRarityIndex = rarityAsNumbers.indexOf(affix.rarity);
	const maxRarity = AFFIX_MAX_RARITY_BY_ITEM_RARITY[itemData.rarity ?? 'common'] as string;
	const maxRarityIndex = rarityAsNumbers.indexOf(maxRarity);
	return affixRarityIndex > maxRarityIndex;
}
