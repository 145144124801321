/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { itemIcon, SubHeading, SubHeadingWithIcons } from '../../../../helper/NavigationTabsList';
import { Text } from '@chakra-ui/react';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';

function EnchantingGuide() {
	return (
		<>
			Enchanting comes with four different uses: Scrollcrafting, Augmenting, Researching, and Enchanting, which
			can be switched between at any time using the tabs. Scrollcrafting and Enchanting go hand in hand together
			while Augmenting is how you increase the power of items, while Researching can create Runic Dusts and Scrap
			for affixing.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.lesser_magic_tome)]}>Magic Tomes</SubHeadingWithIcons>
			Your effective enchanting level can be increased by crafting Magic Tomes. They come in different tiers and
			each tier consumes the previous tier during crafting. Check out the Skilling Tab in Crafting to get find
			their required resources.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.scroll_of_the_shrimp_lord)]}>
				Scrollcrafting
			</SubHeadingWithIcons>
			Scrollcrafting allows you to create enchanted scrolls, which you can then put on your gear. Each enchanted
			scroll requires one Scroll, Silver from Mining, and a variety of Runes from Runecrafting. Each scroll has a
			success chance, which can be increased by increasing your Enchanting level. Upon failure to make a scroll,
			you will lose any resources used in the attempt. You can filter what type of enchantments you want by
			clicking on the gear filters at the top.
			<SubHeading>Enchanting</SubHeading>
			After you've made some scrolls, this is where you'd go to apply them on your applicable gear. Gear can only
			hold so many enchantments, as denoted by the circle enchantment slots on any given item (if it has any).
			Starting gear can usually only hold one enchantment while late game gear can have anywhere from six to
			eight. You can overwrite enchantments in items with a different one. You can disenchant an enchanted item by
			clicking on it and clicking "Disenchant".
			<SubHeading>Augmenting</SubHeading>
			Augmenting can increase the power of your equipment at the cost of resources. When augmenting any gear,
			you'll slowly increase an augment counter, that when maxed out, will increase the equipments level by +1 and
			then reset with a higher requirement. While augmenting you can get a Critical proc which will increase the
			count by 2 instead of 1, but only be the cost of one attempt. During augmenting at certain levels, you'll
			require an amount of the base item in order to increase the level of your gear.
			<SubHeading>Researching</SubHeading>
			Researching is a new mechanic that will research the choosen item and procude Runic Dusts and Scraps at the
			cost of some resources. Successful researches on an item will procude Runic Dust at the same rarity that the
			item is being researched, while failures will destroy the item and create scrap. High level augmented gear
			will produce more Runic Dust and Scrap than base gear.
			<SubHeading>Soulbinding</SubHeading>
			Soulbinding has been removed from the game, any soulbound gear you have will keep its augmented level and
			become tradable again.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</>
	);
}

export default EnchantingGuide;
