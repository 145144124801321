// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TSettingsList } from '../../../game-server/src/modules/player/PlayerSettings';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';
import { THREAT_PROFILES } from './constantsCollection';

export const settingsList: TSettingsList = {
	// Chat
	'chat.fontSize': {
		default: 14,
		name: 'Font Size',
		description: 'Changes the font size of the chat.',
		inSettingsPage: true,
		min: 10,
		max: 20,
		isInteger: true,
	},
	'chat.showLinks': {
		default: true,
		name: 'Hyperlinks',
		description: 'Shows clickable hyperlinks in the chat.',
		inSettingsPage: true,
	},
	'chat.showRareDrops': {
		default: true,
		name: 'Show Rare Drops',
		description: 'Shows rare drops announcements in chat.',
		inSettingsPage: true,
	},
	'chat.showAugmentMessages': {
		default: true,
		name: 'Show Augment Messages',
		description: 'Shows Augment related announcements in chat, such as someone producing a high level item.',
		inSettingsPage: true,
	},
	'chat.showSnowballMessages': {
		default: true,
		name: 'Show Snowball Messages',
		description: 'Shows Snowball related flavor text when players use the /throw command.',
		inSettingsPage: true,
	},
	'chat.showMeMessages': {
		default: true,
		name: 'Show /me Messages',
		description: 'Shows player 3rd person /me messages.',
		inSettingsPage: true,
	},
	'chat.showMyPremiumIcon': {
		default: true,
		name: 'Show My Idlescape Plus Icon',
		description: 'Shows your Idlescape Plus icon chat badge next to your name when you chat.',
		inSettingsPage: true,
	},
	'chat.profanityFilter': {
		default: true,
		name: 'Profanity Filter',
		description: 'Enables a rudimentary profanity filter in chat.',
		inSettingsPage: true,
	},
	'chat.privateMessageHistoryHours': {
		default: 1,
		name: 'Whisper History Retrieval in Hours',
		description: 'The number of hours to retrieve private messages when loading a new session.',
		inSettingsPage: true,
		min: 0,
		max: 128,
		isInteger: true,
	},
	'chat.helper': {
		default: false,
		name: 'Helper Ping (Refresh Page To Apply)',
		description:
			'Enables a ping whenever anyone chats in the Help channel to keep you constantly appraised of its activity. Can be very annoying if you do not plan on actually helping people!',
		inSettingsPage: true,
	},
	'chat.showMyAccountStatus': {
		default: true,
		name: 'Show My Account Status (For Staff)',
		description: 'Shows your account status while chatting. Turn it off to go (kinda) incognito!',
		inSettingsPage: true,
		modOnly: true,
	},
	'chat.disableStaffPing': {
		default: false,
		name: 'Disable @mods Ping (Refresh Page To Apply)',
		description:
			'Disables staff pings. Only intended to be used when you have multiple characters open at once to keep multiple tabs from lag pinging if your browser has weird optimizations.',
		inSettingsPage: true,
		modOnly: true,
	},
	'chat.selectedIcon': { default: null, backendOnly: true },
	'chat.showAchievementMessages': { default: true },

	// Activity Log
	'activityLog.notificationsToActivityLog': {
		default: false,
		name: 'Send Notifications as Activity Logs instead',
		description:
			'Alternatively sends Notifications as Activity Log messages. Can be useful if you want to keep logs of certain Notifications.',
		inSettingsPage: true,
	},
	'activityLog.showFarming': {
		default: true,
		name: 'Show Farming Notifications',
		description: 'Toggles all Farming related logging.',
		inSettingsPage: true,
	},
	'activityLog.showEnchanting': {
		default: true,
		name: 'Show Augmenting Results',
		description: 'Toggles Augmenting related logging.',
		inSettingsPage: true,
	},
	'activityLog.showActions': {
		default: true,
		name: 'Show Action Notifications',
		description: 'Toggles all Action and Action Queue related logging.',
		inSettingsPage: true,
	},
	'activityLog.showCombatDrops': {
		default: true,
		name: 'Show Combat Drops',
		description: 'Toggles Combat drop Activity Logs.',
		inSettingsPage: true,
	},
	'activityLog.showCombatMisc': {
		default: true,
		name: 'Show Combat Timers / Warnings',
		description: 'Toggles all Combat miscellaneous messages.',
		inSettingsPage: true,
	},
	'activityLog.showCombatExp': {
		default: true,
		name: 'Show Combat Experience',
		description: 'Toggles all Combat experience messages.',
		inSettingsPage: true,
	},
	'activityLog.showFlavorMessages': { default: true, name: 'Show Flavor Messages', inSettingsPage: true },
	'activityLog.showEnchantmentProcs': { default: true, name: 'Show Enchantment Procs', inSettingsPage: true },
	'activityLog.showShardMessages': { default: true, name: 'Show Shard Messages', inSettingsPage: true },
	'activityLog.showQuestProgress': { default: true, name: 'Show Quest Progress', inSettingsPage: true },

	// Sound
	'sounds.playSoundOnMention': { default: false, name: 'Play Sound On Mention', inSettingsPage: true },
	'sounds.playSoundOnWhisper': { default: false, name: 'Play Sound On Whisper', inSettingsPage: true },
	'sounds.playSoundOnInvite': { default: false, name: 'Play Sound On Group Invite', inSettingsPage: true },
	'sounds.playSoundOnIdle': { default: false, name: 'Play Sound On Idle (IS+)', inSettingsPage: true },
	'sounds.volume': { default: 5, name: 'Volume', inSettingsPage: true, min: 0, max: 10 },

	// Combat
	'combat.autoEatThreshold': {
		default: 50,
		name: 'Auto Eat Threshold %',
		description: `The Global Auto Eat Threshold. When your health drops below the threshold your character will
						automatically attempt to eat food in your combat inventory to increase your health above the threshold.
						Can also be set on an individual zone basis in the Combat Zone UI.`,
		inSettingsPage: true,
		min: 0,
		max: 100,
		marks: [
			{ value: 0, label: 'Off' },
			{ value: 25, label: '25%' },
			{ value: 50, label: '50%' },
			{ value: 75, label: '75%' },
			{ value: 100, label: '100%' },
		],
		labelFormat: (value) => (value ? value : 'Off'),
	},
	'combat.maxDifficulty': {
		default: 3,
		name: 'Max Difficulty',
		description:
			'The Global Max Difficulty a Combat zone may reach. Does not affect Dungeons, Elite Challenges, or anything else that is key based instead of free entry.',
		inSettingsPage: true,
		min: 0,
		max: 30,
	},
	'combat.startingDifficulty': {
		default: 0,
		name: 'Starting Difficulty',
		description:
			'The Global Starting Difficulty a Combat zone will attempt to start at. Relies on successful clears of the zone relevant Elite Challenge.',
		inSettingsPage: true,
		min: 0,
		max: 30,
	},
	'combat.forceFullInventory': {
		default: 1,
		name: 'Force Full Inventory',
		description: `If your Combat Inventory is not full at your designated zones you will be blocked
						from starting combat there. Recommended if you are prone to forgetting
						to refill your Combat Inventory between encounters.`,

		inSettingsPage: true,
		entries: ['Disable', 'Dungeons Only', 'All'],
	},
	'combat.showInventoryInGroup': {
		default: false,
		name: 'Show Inventory While Grouped',
		description: 'Shows your Inventory in the Group tab so you do not need to jump between tabs.',
		inSettingsPage: true,
	},
	'combat.receiveInvitations': {
		default: 0,
		name: 'Allow Invitations From',
		description: 'Who is allowed to send you Group invites.',
		inSettingsPage: true,
		entries: ['Anyone', 'Friends', 'Disable'],
	},
	'combat.autoRotation': {
		default: false,
		name: 'Automatic Ability Priority Handling',
		description:
			'Automatically resorts your Ability Priority when adding or removing abilities to attempt to maximize your damage output.',
		inSettingsPage: true,
	},
	'combat.forceValidMerc': {
		default: true,
		name: 'Force Valid Merc Loadout',
		description:
			'Keeps you from starting combat with a Merc that is only able to partially find their loadout and food.',
		inSettingsPage: true,
	},
	'combat.ignoreUpdateSafety': {
		default: false,
		name: 'Ignore Dungeon Update Safety',
		description:
			'Allows you to ignore the automatic canceling of Dungeon requeueing when a server update is scheduled.',
		inSettingsPage: true,
	},
	'combat.dungeonMisclickSafety': {
		default: true,
		name: 'Dungeon Misclick Safety',
		description:
			'Keeps you from accidentally running from Dungeon or Elite Challenge encounters. Recommended to be enabled.',
		inSettingsPage: true,
	},
	'combat.dungeonRequeue': {
		default: false,
		name: 'Dungeon Queue',
		description: 'Use the selected key on dungeon completion.',
		inSettingsPage: true,
	},
	'combat.dungeonRequeueIgnoreAug': {
		default: false,
		name: 'Queue All',
		description: 'Select all keys for the queue. Only active when Dungeon Queue is on.',
		inSettingsPage: true,
	},
	'combat.threatProfile': {
		default: 0,
		name: 'Threat Targeting Profile',
		description: 'The recommended threat profiles that affect your combat targeting. When in doubt, use Default.',
		entries: Object.values(THREAT_PROFILES).map((threat) => threat.name),
		inSettingsPage: true,
	},
	'combat.customThreatMults': {
		default: false,
		name: 'Use Custom Threat Sliders',
		description:
			'Uses the below Threat sliders instead of the threat profile above. It is highly recommended you choose a threat profile unless you are ready to tinker a lot to get a good result.',
		inSettingsPage: true,
	},
	'combat.hpThreat': { default: 2, name: 'Target Remaining HP Threat', inSettingsPage: true, min: -5, max: 10 },
	'combat.weaponThreat': { default: 3, name: 'Target Weapon Threat', inSettingsPage: true, min: -5, max: 10 },
	'combat.damageThreat': {
		default: 3,
		name: 'Target Potential Damage Threat',
		inSettingsPage: true,
		min: -5,
		max: 10,
	},
	'combat.armorThreat': { default: 2, name: 'Target Armor Threat', inSettingsPage: true, min: -5, max: 10 },
	'combat.attackSpeedThreat': {
		default: 3,
		name: 'Target Attack Speed Threat',
		inSettingsPage: true,
		min: -5,
		max: 10,
	},
	'combat.levelThreat': { default: 3 },
	'combat.mercLoadouts': { default: [], backendOnly: true },
	'combat.zoneSpecificSettings': { default: {}, backendOnly: true },
	'combat.dungeonRequeueTargetAugs': { default: [], backendOnly: true },

	// Tooltips
	'tooltips.useModernTooltips': {
		default: false,
		name: 'Use Modern Style Tooltips',
		description: 'Enables tooltips that resemble modern MMO styles.',
		inSettingsPage: true,
	},
	'tooltips.useCompactTooltips': {
		default: false,
		name: 'Use Compact Tooltips',
		description:
			'Condenses tooltips and removes information that expert players do not need. Not recommended to leave on unless you are very knowledgeable about the game!',
		inSettingsPage: true,
	},
	'tooltips.useEquipComparison': {
		default: false,
		name: 'Show current Equipped item comparison',
		description: 'Shows your currently equipped item stats beside the tooltip of the item you are looking at.',
		inSettingsPage: true,
	},

	// Miscellaneous
	'miscellaneous.sendLikeToVault': {
		default: false,
		name: 'Auto-Vault Like Items',
		description: 'Automatically attempts to stack new items into the Vault if the same item exists there.',
		inSettingsPage: true,
	},
	'miscellaneous.sendLikeToTacklebox': {
		default: false,
		name: 'Auto-Tacklebox Like Items',
		description: 'Automatically attempts to stack new items into the Tacklebox if the same item exists there.',
		inSettingsPage: true,
	},
	'miscellaneous.useLegacySorting': {
		default: true,
		name: 'Use Legacy Sorting (Ignores Other Sorting Options)',
		description:
			'Use original item sorting based on internal item ID instead of sorting by category and stats. Not recommended unless you are an old player who really hates change.',

		inSettingsPage: true,
	},
	'miscellaneous.sortByValue': {
		default: true,
		name: 'Sort By Value Before ID',
		description: 'Attempts to sort inventory by vendor value within each category.',
		inSettingsPage: true,
	},
	'miscellaneous.highPerformance': {
		default: false,
		name: 'High Performance Mode (Disables Some Graphics)',
		description: 'Disables a lot of animations that can be stressful on systems.',
		inSettingsPage: true,
	},
	'miscellaneous.pinnedTabs': { default: [], backendOnly: true },
	'miscellaneous.usePinnedTabs': {
		default: false,
		name: 'Enable Pinned Tabs',
		description: 'Allows you to pin tabs to your main game profile for quickly swapping between tabs.',
		inSettingsPage: true,
		noMobileView: true,
	},
	'miscellaneous.showCurrencyAsItems': {
		default: false,
		name: 'Show Currency (Gold, Heat, Essence) as Items',
		description:
			'Reveals hidden Currency items within the inventory; useful if you want to vault or otherwise interact with them.',
		inSettingsPage: true,
	},
	'miscellaneous.showEmptyMarketManifest': {
		default: false,
		name: 'Show Empty Market Manifests',
		description:
			'Shows all items in the Marketplace regardless of availability; good for when you want to add Buy Offers for items that are not being traded currently.',

		inSettingsPage: true,
	},
	'miscellaneous.showQueueButton': {
		default: true,
		name: 'Show Queue Button',
		description:
			'Adds a button to easily add actions to your Action Queue. Only visible when you have IS+ or queue tokens.',
		inSettingsPage: true,
	},
	'miscellaneous.queueRepeat': {
		default: false,
		name: 'Repeat Queue',
		description: 'Go back to the start after completing the last queue in your Action Queue.',
		inSettingsPage: true,
	},
	'miscellaneous.inventoryTabRules': { default: {}, backendOnly: true },
	'miscellaneous.inventoryTabNames': { default: { 0: 'Default Tab' }, backendOnly: true },
	'miscellaneous.inventoryTabOrder': { default: [], backendOnly: true },
	'miscellaneous.userActionQueue': { default: [], backendOnly: true },
	'miscellaneous.selectedTalents': { default: [], backendOnly: true },
	'miscellaneous.blacklistedBuffs': { default: [], backendOnly: true },

	// Quest
	'quest.pin': { default: null },

	// Cooking
	'cooking.sort': {
		default: 0,
		name: 'Cooking Sort',
		description: `Unsorted - Prepare the ingredients in the order they where selected.|
						Prepare Evenly - Prepare one of each ingredient in the queue, then repeat.|
						By Level - Prepare the ingredient with the lowest requirements first.|
						Highest Experience - Prepare the ingredient with the highest experience (difficulty) first.`,
		entries: ['Unsorted', 'Prepare Evenly', 'By Level', 'Highest Experience'],
		inSettingsPage: true,
	},

	// Augmenting
	'augmenting.maxAugmentLevel': {
		default: 0,
		name: 'Maximum Item Level',
		description: 'Augment items in the queue to this level.',
		min: 0,
		max: 25,
		inSettingsPage: true,
		marks: [{ value: 0, label: 'Unlimited' }],
		labelFormat: (value) => (value ? value : '∞'),
	},
	'augmenting.sortSuccessfulToStart': {
		default: true,
		name: 'Sort',
		description: 'Sets where successful augmentations are placed in the queue.',
		inSettingsPage: true,
	},

	// Cosmetics
	'cosmetic.selectedCosmetics': { default: {}, backendOnly: true },
	'cosmetic.unlockedCosmetics': { default: [], backendOnly: true },
	'cosmetic.profileVisibility': { default: 0, min: 0, max: 2 },
	'cosmetic.profileIcons': { default: [], backendOnly: true },
	'cosmetic.profileCosmetics': { default: {}, backendOnly: true },
	'cosmetic.itemShowcase': { default: [], backendOnly: true },
	'cosmetic.hiscoreShowcase': { default: [], backendOnly: true },
	'cosmetic.achievementShowcase': { default: [], backendOnly: true },
	'cosmetic.dungeonShowcase': { default: [], backendOnly: true },
	'cosmetic.backgroundLocation': { default: locationsIds.caves, backendOnly: true },
	// 'cosmetic.title': { default: '', backendOnly: true },
	// 'cosmetic.achievementShowcase': { default: [], backendOnly: true },
	// 'cosmetic.questShowcase': { default: [], backendOnly: true },

	// Privacy
	'privacy.optOutFromDataRelease': {
		default: false,
		name: 'Anonymous In Community Data Releases',
		inSettingsPage: true,
	},
	// Security
	'security.email': { default: '', name: 'Email', inSettingsPage: true },
};
