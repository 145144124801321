import { IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { enchantingSelectedItemIdAtom } from '../../../../atoms/enchantingSelectedItemIdAtom';
import { enchantingSelectedScrollIdAtom } from '../../../../atoms/enchantingSelectedScrollIdAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { filterItem } from '../../../../helper/helperFunctions';
import { itemList } from '../../../../utils/itemList';
import ItemSelector from '../../Inventory/ItemSelector';

export default function EnchantingScrollList() {
	const [selectedScrollId, setSelectedScrollId] = useRecoilState(enchantingSelectedScrollIdAtom);
	const selectedItemId = useRecoilValue(enchantingSelectedItemIdAtom);
	const stockpile = usePlayerField('stockpile');
	const selectedScroll = stockpile.find((item) => item.id === selectedScrollId);
	const selectedItem = stockpile.find((item) => item.id === selectedItemId);

	const [sort, setSort] = useState('');

	const filteredStockpile = stockpile
		.filter((item) => {
			const itemData = itemList[item.itemID];
			if (itemData.class !== 'enchanted-scroll') {
				return false;
			}
			const enchantment = itemData.enchantmentID;
			if (!enchantment) {
				return false;
			}

			if (selectedItem) {
				const selectedItemData = itemList[selectedItem.itemID];
				const targetSlot =
					selectedItemData.enchantmentOverrideSlot ?? selectedItemData?.equipmentStats?.slot ?? '';
				if (!itemData.categories?.includes(targetSlot)) {
					if (selectedItemData.forcedEnchant === enchantment) {
						return true;
					}
					return false;
				}
			}
			return true;
		})
		.filter((item) => filterItem(item, sort));

	function handleSelectScroll(item: IItem) {
		if (selectedScroll?.id === item.id) {
			setSelectedScrollId(-1);
		} else {
			setSelectedScrollId(item.id);
		}
	}

	return (
		<div className='enchanting-scrolls-container'>
			<h5 className='enchanting-title'>Scrolls</h5>
			<div className='enchanting-title-border'></div>
			<IdlescapeInput
				variant='search'
				setValue={setSort}
				value={sort}
				type='search'
				className={'anchor-enchanting-inventory-filter'}
				placeholder='Scroll Filter'
			/>
			<div className='enchanting-items'>
				<ItemSelector
					items={filteredStockpile}
					selectedId={selectedScroll?.id ?? -1}
					select={handleSelectScroll}
					multiSelection={false}
				/>
			</div>
		</div>
	);
}
