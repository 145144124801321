import { atom, DefaultValue, selector } from 'recoil';

export const chatInputAtom = atom({
	key: 'chatInputAtom',
	default: '',
});

function appendToChatInput(oldValue: string, newValue: string | DefaultValue, prefix: string) {
	if (newValue instanceof DefaultValue) return newValue;
	if (oldValue.includes(`|${prefix}${newValue}|`)) return oldValue;
	oldValue = oldValue.trim();
	return oldValue !== '' ? `${oldValue} |${prefix}${newValue}|` : `|${prefix}${newValue}|`;
}

export const shareItemSelector = selector({
	key: 'shareItemSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '@'));
	},
});
export const shareSkillSelector = selector({
	key: 'shareSkillSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '#'));
	},
});
export const shareChestSelector = selector({
	key: 'shareChestSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '€'));
	},
});
export const shareMarketplaceListingSelector = selector({
	key: 'shareMarketplaceListingSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '$'));
	},
});
export const shareMarketplaceOfferSelector = selector({
	key: 'shareMarketplaceOfferSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '¥'));
	},
});
export const shareItemSetSelector = selector({
	key: 'shareItemSetSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '%'));
	},
});
export const shareQuestSelector = selector({
	key: 'shareQuestSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => appendToChatInput(oldValue, newValue, '?'));
	},
});

export const addToChatInputSelector = selector({
	key: 'addToChatInputSelector',
	get: ({ get }) => get(chatInputAtom),
	set: ({ set }, newValue) => {
		set(chatInputAtom, (oldValue) => (oldValue !== '' ? `${oldValue} ${newValue}` : newValue));
	},
});
