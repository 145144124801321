import React from 'react';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { BaseTooltip } from './Types/BaseTooltip';
import './UI/Tooltips.css';
import {
	formatEnchant,
	formatEquipmentStats,
	formatHealing,
	formatItemSets,
	formatRequirements,
	formatSeedYield,
	formatStats,
	formatFoodEnchant,
	formatEffects,
	formatWeaponStats,
	formatAffixes,
	formatAugmentCounter,
} from './UI/ModernTooltipPresenter';
import { TSkills } from '../../../../../game-server/src/Atypes/Skills';
import { getDateOfNextMonth, getTimeString } from '../../../helper/helperFunctions';
import { TEquipments } from '../../../../../game-server/src/modules/player/PlayerTypes';
import { CraftingAugmentingData } from '../CraftingAugmenting/CraftingAugmentingData';
import { getItemTier } from '../../../utils/augmentingFunctions';

export class ModernTooltip extends React.Component<{
	item: TPartialItemWithItemID<IItem>;
	itemResource: IItemData;
	equipment: TEquipments;
	tooltipManager: BaseTooltip;
	skills: TSkills;
	compactVersion: boolean;
	listingDate?: Date;
}> {
	render() {
		const tooltipData = this.props.tooltipManager.generateTooltipData(
			this.props.item,
			this.props.itemResource,
			this.props.equipment
		);
		const stats = formatStats(tooltipData.stats);
		const weaponStats = formatWeaponStats(tooltipData.weaponStats);
		const equipmentStats = formatEquipmentStats(tooltipData.equipmentStats);
		const itemSets = formatItemSets(tooltipData.itemSet, this.props.compactVersion);
		const seedYield = formatSeedYield(tooltipData.yield);
		const healing = formatHealing(tooltipData.healing);
		const requirements = formatRequirements(tooltipData.requiredStats, this.props.skills);
		const enchant = formatEnchant(tooltipData.enchant, this.props.itemResource.enchantmentTier);
		const effects = formatEffects(tooltipData.enchant, this.props.compactVersion);
		const foodEnchant = formatFoodEnchant(tooltipData.foodEnchant);
		const augmentCounter = formatAugmentCounter(tooltipData.augmentCounter, this.props.item);
		const affixes = formatAffixes(tooltipData.affixes, this.props.item.itemID);
		const remainingTime = getDateOfNextMonth(this.props.listingDate ?? new Date(0)) - Date.now();
		const remainingString = getTimeString(remainingTime / 1000, 2);

		return (
			<div className='modern-tooltip'>
				<div className='tooltip-name'>
					<span className={`tooltip-rarity-${tooltipData.rarity}`}>
						<span className={`${tooltipData.premiumName ? 'tooltip-premium-name' : ''}`}>
							{tooltipData.customName.length > 0 ? tooltipData.customName : tooltipData.name}
						</span>
						{tooltipData.augment > 0 ? ' +' + tooltipData.augment : ''}
					</span>
				</div>
				{tooltipData.customName.length > 0 && (
					<div>
						<span className='tooltip-original-name'>{tooltipData.name}</span>
					</div>
				)}
				<div className='tooltip-type tooltip-columns'>
					<span>{tooltipData.type}</span>
					<span>{tooltipData.weaponSubtype}</span>
				</div>
				{(CraftingAugmentingData.getAugmentingByID(this.props.itemResource.id) ||
					CraftingAugmentingData.getScrappingByID(this.props.itemResource.id)) && (
					<div>Tier: {getItemTier(this.props.itemResource)}</div>
				)}
				{weaponStats}
				<div className='toolip-separator'></div>
				{augmentCounter}
				{equipmentStats}
				{affixes}
				{stats}
				{seedYield}
				{healing}
				{requirements}
				{enchant}
				{foodEnchant}
				<div className='tooltip-item-skill'>
					{tooltipData.itemAbilities.map((ability) => (
						<div className='tooltip-ability-active' key={ability.id}>
							Equip: {ability.name}
						</div>
					))}
				</div>
				<div hidden={tooltipData.enchantmentScrollCategories.length === 0}>
					Slot: {tooltipData.enchantmentScrollCategories.join(', ')}
				</div>
				<div className='tooltip-chest-use' hidden={!tooltipData.canBeOpened}>
					Use: Open Chest
				</div>
				{itemSets}
				{effects}
				<div className='tooltip-flavor' hidden={this.props.compactVersion}>
					{tooltipData.flavor}
				</div>
				<div className='toolip-separator'></div>
				<div className='tooltip-prices tooltip-columns'>
					<span className='tooltip-quantity' hidden={tooltipData.quantity === 0}>
						Quantity: {tooltipData.quantity.toLocaleString('en-us')}
					</span>
					<span hidden={tooltipData.quantity !== 0}></span>
					<span>
						<span className='tooltip-prices-vendor'>
							Vendor: {tooltipData.vendor.toLocaleString('en-us')}{' '}
							<img
								src='/images/gold_coin.png'
								alt='coins'
								className='icon16'
								style={{ verticalAlign: 'middle', height: '16px', width: '16px', marginRight: '5px' }}
							></img>
						</span>
						<span className='tooltip-heat' hidden={tooltipData.heat === 0}>
							{tooltipData.heat.toLocaleString('en-us')}{' '}
							<img src='/images/heat_small_icon.png' alt='heat' className='icon16'></img>
						</span>
					</span>
				</div>
				<div className='tooltip-prices-marketplace tooltip-columns'>
					<span></span>
					<span className='tooltip-prices-marketplace' hidden={tooltipData.market === 0}>
						Marketplace: {tooltipData.market.toLocaleString('en-us')}
						<img
							src='/images/gold_coin.png'
							alt='coins'
							className='icon16'
							style={{ verticalAlign: 'middle', height: '16px', width: '16px', marginRight: '2px' }}
						></img>
					</span>
				</div>
				{remainingTime > 0 ? <div>Expires in: {remainingString}</div> : ''}
				<div className='tooltip-not-tradeable tooltip-columns'>
					<span></span>
					<span hidden={!tooltipData.premiumName && tooltipData.tradeable}>Cannot be traded</span>
				</div>
			</div>
		);
	}
}
